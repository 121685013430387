//libraries
import React from "react";
import Workbook from "react-excel-workbook";
import { Doughnut } from "react-chartjs-2";
//assets
import { Excelv2Icon } from "assets/Iconsv2/Iconsv2";


const plugins = [{
  beforeInit: (chart) => {
    const dataset = chart.data.datasets[0];
    chart.data.labels = [dataset.label];
    dataset.data =[ dataset.percent, 100 - dataset.percent];
  },
  
    beforeDraw: (chart) => {
      const {width,height,ctx} = chart
      ctx.restore();
      var fontSize = (height / 150).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.fillStyle = "#9b9b9b";
      ctx.textBaseline = "middle";
      var text = chart.data.datasets[0].percent + "%",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 1.5;
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  
}]


const severityoption = [
  { value: 1, label: "Low" },
  { value: 2, label: "Medium" },
  { value: 3, label: "High" },
  { value: 4, label: "High Critical" },
];
const renderPercenChart = (value = 70) => {
  const data = {
    labels: ['Progress'],
    datasets: [
      {
        data: [70 , 100 - 70 ],
        backgroundColor: ['#0A58CA', '#F5F5F5'],
        hoverBackgroundColor: ['#0A58CA', '#F5F5F5'],
      },
    ],
  };
  const options = {
    cutout: 40, // Reduce this value to increase doughnut thickness
    responsive: true,
    maintainAspectRatio: false,
    rotation: -90,
    circumference: 180,
    textAlign:"center",
    plugins:{
      legend: {
        display: false,
      },
    },
    tooltips: {
      enabled: false,
    },
    elements: {
      center: {
        text: value + '%',
        color: '#333', // Customize the color if needed
        fontStyle: 'Arial', // Customize the font style if needed
        sidePadding: 20, // Adjust the padding if needed
      },
    }
  };
  return <Doughnut data={data}  options={options} />;
};
const content = {
  dasboardTabs: [
    {
      tabName: "Dashboard",
      key: "dashboard",
      keyClass: "ir1",
    },
    {
      tabName: "CAPA",
      key: "capa",
      keyClass: "ir2",
    },
    {
      tabName: "NM List",
      key: "list",
      keyClass: "ir3",
    },
  ],

  dashboardPageState: {
    isLoading: true,
    status: {},
    severity: [],
    uauc: [],
    category: [],
    contributing_factor: [],
    department_count: [],
    contractorWiseNmCount: [],
    locationWiseNMCount: [],
    zoneWiseNMCount: [],
    immediate_factor: [],
    rca_factor: [],
    serviceSeverityGraph: [],
    activitySeverityGraph: [],
    resetServiceSeverityGraph: [],
    LocationSeverityGraph: [],
    resetLocationSeverityGraph: [],
    month_wise_nm_reported: [],
    reset_month_wise_nm_reported: [],
    ServiceSeverityPopup: false,
    ProjectSeverityPopup: false,
    ActivitySeverityPopup: false,
    monthwisePopup: false,
    service: [],
    serviceSelect: "",
    project: [],
    projectSelect: "",
    R_person: [],
    extremeSelect: 0,
    extremeValue: 0,
    highSelect: 0,
    mediumSelect: 0,
    lowSelect: 0,
    all: 0,
    allValue: [],
    allS: [],
    severityoption: severityoption,
    selectedOption: null,
    selectedOptionseverity: null,
    selectedOptionproject: null,
    plantProjectFilterSelectedService: null,
    selectedProjectFilterOptionseverity: null,
    start_month: null,
    start_year: null,
    end_month: null,
    end_year: null,
    add: 1,
    selectedOptions: "",
    total_data: null,
    isFirstFetching: false,
    zoneStatus: null,
    locationStatus: null,
    departmentStatus: null,
    contractorStatus: null,
    userInfo: JSON.parse(localStorage.getItem("userData")) || null,
  },
  capaPageState: {
    dataLoading: true,
    currentPage: 1,
    nmHeaderCount: [],
    completed_number: "",
    inprogress_number: "",
    searchList: "",
    searchList2: "",
    searchBtnClicked: false,
    searchBtnClicked2: false,
    searchCompleted: [],
    trackerFilterPopup: false,
    startDate: new Date(),
    endDate: new Date(),
    overdue: 0,
    service: [],
    serviceSelect: "",
    project: [],
    projectSelect: "",
    R_person: [],
    extremeSelect: 0,
    extremeValue: 0,
    highSelect: 0,
    mediumSelect: 0,
    activeService: "",
    activeProject: "",
    lowSelect: 0,
    listMode: "kanban",
    all: 0,
    allValue: [],
    allS: [],
    severity: [],
    personSelect: [],
    CategoryFilter: [],
    dflag: JSON.parse(localStorage.getItem("userData")),

    fsToggler: false,
    toggler: false,
    lightboxactive: false,
    evidencelightbox: [],
    evidencelightboxType: [],

    modalParams: {},
    recommendationNumber: null,
    capaParams: {},
    evidenceParams: {},
    deleteIds: [],
    observationText: "",
    correctiveItem: {},
    correctionId: null,
    declineMessage: "",
    isDeclineViewEnabled: false,
    recommendation_id: null,
    nearmiss_id: null,
    showPopupSearch: false,
    obs_rec: "",
    activeService: "",
    activeProject: "",
  },
  capaResetState: {
    extremeSelect: 0,
    highSelect: 0,
    mediumSelect: 0,
    lowSelect: 0,
    startDate: new Date(),
    severity: [],
    endDate: new Date(),
    serviceSelect: null,
    projectSelect: null,
    categorySelect: null,
    personSelect: null,
    overdue: 0,
    all: 0,
  },
  listPageState: (props) => {
    return {
      currentPage: 1,
      dataLoading: true,
      pendingPopup: false,
      inProgressPopup: false,
      completedPopup: false,
      videoUrl: "",
      startDate: null,
      endDate: null,
      overdue: null,
      deviation: null,
      completed: props.nmCompleted,
      pending: props.nmPending,
      progress: props.nmProgress,
      listMode: "kanban",
      pendingService: [],
      inProgressService: [],
      completedService: [],
      service: [],
      serviceSelect: 0,
      pendingServiceSelect: 0,
      inProgressServiceSelect: 0,
      completedServiceSelect: 0,
      project: [],
      projectSelect: 0,
      pendingProject: [],
      pendingProjectSelect: 0,

      progressProject: [],
      progressProjectSelect: 0,

      completedProject: [],
      completedProjectSelect: 0,

      pendingStartDate: null,
      pendingEndDate: null,

      progressStartDate: null,
      progressEndDate: null,

      completedStartDate: null,
      completedEndDate: null,

      pendingExtremeSelect: 0,
      pendingHighSelect: 0,
      pendingMediumSelect: 0,
      pendingLowSelect: 0,
      pendingAll: 0,

      progressExtremeSelect: 0,
      progressHighSelect: 0,
      progressMediumSelect: 0,
      progressLowSelect: 0,
      progressAll: 0,

      completedExtremeSelect: 0,
      completedHighSelect: 0,
      completedMediumSelect: 0,
      completedLowSelect: 0,
      completedAll: 0,

      R_person: [],
      extremeSelect: 0,
      extremeValue: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      all: 0,
      allValue: [],
      allS: [],
      severity: [],
      pendingSeverity: [],
      progressSeverity: [],
      completedSeverity: [],
      personSelect: [],
      CategoryFilter: [],
      hasMore: [],
      dflag: JSON.parse(localStorage.getItem("userData")),
      isFilterApplied: false,
      isProgressFilterApplied: false,
      isCompletedFilterApplied: false,

      fsToggler: false,
      toggler: false,
      lightboxactive: false,
      evidencelightbox: [],
      evidencelightboxType: [],
      pendingFilterFlag: 0,
      pendingFilterPageNo: 1,
      inprogressFilterFlag: 0,
      inprogressFilterPageNo: 1,
      completedFilterFlag: 0,
      completedFilterPageNo: 1,
    };
  },
  listPageResetState: {
    pending: {
      pendingExtremeSelect: 0,
      pendingHighSelect: 0,
      pendingMediumSelect: 0,
      pendingLowSelect: 0,
      pendingStartDate: null,
      pendingEndDate: null,
      pendingServiceSelect: null,
      pendingProjectSelect: null,
      pendingAll: 0,
      pendingSeverity: [],
    },
    progress: {
      progressExtremeSelect: 0,
      progressHighSelect: 0,
      progressMediumSelect: 0,
      progressLowSelect: 0,
      progressStartDate: null,
      progressEndDate: null,
      inProgressServiceSelect: null,
      progressProjectSelect: null,
      progressAll: 0,
      overdue: 0,
      progressSeverity: [],
    },
    completed: {
      completedExtremeSelect: 0,
      completedHighSelect: 0,
      completedMediumSelect: 0,
      completedLowSelect: 0,
      completedStartDate: null,
      completedEndDate: null,
      completedServiceSelect: null,
      completedProjectSelect: null,
      completedAll: 0,
      deviation: 0,
      completedSeverity: [],
    },
  },
  dashboardLeftContent: {
    graphType: "donut",
    cardHeading: "Investigation Status",
  },
  chartJsOptions: {
    rotation: -90,
    circumference: 180,
    cutoutPercentage: 80,
    textAlign: "center",
  },
  excelExportCapa: (props) => (
    <div>
      <Workbook
        filename="NMTrackerList.xlsx"
        element={
          <button class="border-0 bg-transparent" title="Download Excel">
            <Excelv2Icon />
          </button>
        }
      >
        <Workbook.Sheet
          data={props?.progressTrackerExport}
          name="Inprogress Tracker list"
        >
          <Workbook.Column label="ACTIVITY" value="activity" />
          <Workbook.Column label="CATEGORY" value="category" />
          <Workbook.Column label="PROGRESS" value="progress" />
          <Workbook.Column label="TARGET DATE" value="target_date" />
          <Workbook.Column label="OVERDUE DAYS" value="overdue_deviation" />
          <Workbook.Column label="PROJECT" value="project" />
          <Workbook.Column label="RECOMMENDATION" value="recommendation" />
          <Workbook.Column label="RECOMMENDED BY" value="recommendedby" />
          <Workbook.Column label="SERVICE" value="service" />
          <Workbook.Column label="SEVERITY" value="severity" />
          <Workbook.Column label="RESPONSIBLE PERSON" value="person_name" />
        </Workbook.Sheet>
        <Workbook.Sheet
          data={props?.completedTrackerExport}
          name="Completed Tracker list"
        >
          <Workbook.Column label="ACTIVITY" value="activity" />
          <Workbook.Column label="CATEGORY" value="category" />
          <Workbook.Column label="PROGRESS" value="progress" />
          <Workbook.Column label="TARGET DATE" value="target_date" />
          <Workbook.Column label="OVERDUE DAYS" value="overdue_deviation" />
          <Workbook.Column label="PROJECT" value="project" />
          <Workbook.Column label="RECOMMENDATION" value="recommendation" />
          <Workbook.Column label="RECOMMENDED BY" value="recommendedby" />
          <Workbook.Column label="SERVICE" value="service" />
          <Workbook.Column label="SEVERITY" value="severity" />
          <Workbook.Column label="RESPONSIBLE PERSON" value="person_name" />
        </Workbook.Sheet>
      </Workbook>
    </div>
  ),

  excelExportNmlist: (props) => (
    <div>
      <Workbook
        filename="NMList.xlsx"
        element={
          <button class="border-0 bg-transparent" title="Download Excel">
            <Excelv2Icon />
          </button>
        }
      >
        <Workbook.Sheet data={props?.nmPendingexport} name="Pending list">
          <Workbook.Column label="ACTIVITY" value="activity" />
          <Workbook.Column label="CONTRACTOR" value="contractor" />
          <Workbook.Column label="DEPARTMENT" value="department" />
          <Workbook.Column label="HAZARD" value="hazard" />
          <Workbook.Column label="INCIDENT DATE" value="incident_date" />
          <Workbook.Column label="INCIDENT TIME" value="incident_time" />
          <Workbook.Column label="OBSERVATION" value="observation" />
          <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
          <Workbook.Column label="PROJECT" value="project" />
          <Workbook.Column label="REPORT DATE" value="report_date" />
          <Workbook.Column label="REPORTED BY" value="reported_by" />
          <Workbook.Column label="SERVICE" value="service" />
          <Workbook.Column label="SEVERITY" value="severity" />
          <Workbook.Column label="LOCATION" value="location" />
          <Workbook.Column label="ZONE" value="zone" />
        </Workbook.Sheet>
        <Workbook.Sheet data={props?.nmProgressexport} name="InProgress list">
          <Workbook.Column label="ACTIVITY" value="activity" />
          <Workbook.Column label="CONTRACTOR" value="contractor" />
          <Workbook.Column label="DEPARTMENT" value="department" />
          <Workbook.Column label="HAZARD" value="hazard" />
          <Workbook.Column label="INCIDENT DATE" value="incident_date" />
          <Workbook.Column label="INCIDENT TIME" value="incident_time" />
          <Workbook.Column label="OBSERVATION" value="observation" />
          <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
          <Workbook.Column label="PROJECT" value="project" />
          <Workbook.Column label="REPORT DATE" value="report_date" />
          <Workbook.Column label="REPORTED BY" value="reported_by" />
          <Workbook.Column label="SERVICE" value="service" />
          <Workbook.Column label="SEVERITY" value="severity" />
          <Workbook.Column label="LOCATION" value="location" />
          <Workbook.Column label="ZONE" value="zone" />
        </Workbook.Sheet>
        <Workbook.Sheet data={props?.nmCompletedexport} name="Completed list">
          <Workbook.Column label="ACTIVITY" value="activity" />
          <Workbook.Column label="CONTRACTOR" value="contractor" />
          <Workbook.Column label="DEPARTMENT" value="department" />
          <Workbook.Column label="HAZARD" value="hazard" />
          <Workbook.Column label="INCIDENT DATE" value="incident_date" />
          <Workbook.Column label="INCIDENT TIME" value="incident_time" />
          <Workbook.Column label="OBSERVATION" value="observation" />
          <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
          <Workbook.Column label="PROJECT" value="project" />
          <Workbook.Column label="REPORT DATE" value="report_date" />
          <Workbook.Column label="REPORTED BY" value="reported_by" />
          <Workbook.Column label="SERVICE" value="service" />
          <Workbook.Column label="SEVERITY" value="severity" />
          <Workbook.Column label="LOCATION" value="location" />
          <Workbook.Column label="ZONE" value="zone" />
        </Workbook.Sheet>
      </Workbook>
    </div>
  ),
};

export { content as nmContent, renderPercenChart };
