export const TIMEZONE_STR = "timezone";
export const mediaQuery = {
  desktop: 1200,
  tablet: 768,
  phone: 576,
};

export const formtypes = {
  Audit: 2,
  audit: 2,
  Inspection: 3,
  inspection: 3,
  Checklist: 4,
  checklist: 4,
  WorkPermit: 5,
  PSSR: 6,
  pssr: 6,
  BBS: 7,
  bbs: 7,
  Survey: 8,
  survey: 8,
  MockDrill: 9,
  mockdrill: 9,
};

export const modules = {
  Task: "tm",
  Goal: "goal",
  goal: "goal",
  task: "tm",
  Discussion: "sm",
};

// USED in FORM TYPES
export const DASHBOARD_TAB = "dashboard";
export const SCHEDULED_TAB = "scheduled";
export const INPROGRESS_TAB = "inprogress";
export const INITIATED_TAB = "initiated";
export const APPROVAL_TAB = "approval";
export const CLOSED_TAB = "closed";
export const SUBMIT_TAB = "submit";
export const DECLINED_TAB = "declined";
export const TEMPALTES_TAB = "templates";
export const ASSIGN_USER_TAB = "assign-user";
export const ALL_COMPLETED_TAB = "all-closed"
export const RECOMMENDATION_TAB = "recommendation"

// USED in GOALS
// export const DASHBOARD_TAB = "dashboard";
export const CREATE_TAB = "create";
// export const INPROGRESS_TAB = "inprogress";
export const COMPLETED_TAB = "completed";
export const INACTIVATED_TAB = "inactivated";
export const DELETED_TAB = "deleted";

// USED in POLLS
// export const DASHBOARD_TAB = "dashboard";
export const LIST_TAB = "list";

//USED in TASKS
export const TASK_LIST = "task";
