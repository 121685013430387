import React from "react";
import ReactDOMServer from "react-dom/server";
import Highcharts, { charts } from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PageFive = (data) => {
  const _getGraph = () => {
    try {
      let {
        nm_uc_number,
        nm_uc_color,
        nm_ua_number,
        nm_ua_color,
        nm_reported_for_same_activity,
      } = data.activity_analysis;

      Highcharts.chart("uauc-container", {
        chart: {
          type: "pie",
          height: "50%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
          align: "right",
          verticalAlign: "middle",
          symbolRadius: 0,
          symbolHeight: 12,
          symbolWidth: 12,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          useHTML: true,
          text: "",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            data: [
              { name: "UC", color: nm_uc_color, y: nm_uc_number },
              { name: "UA", color: nm_ua_color, y: nm_ua_number },
            ],
            size: "100%",
            innerSize: "80%",
          },
        ],
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const _getIRAGraph = () => {
    try {
      let {
        fa_number,
        lti_number,
        mtc_number,
        rta_number,
      } = data.activity_analysis;

      Highcharts.chart("ira-container", {
        chart: {
          type: "pie",
          height: "50%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
          align: "right",
          verticalAlign: "middle",
          symbolRadius: 0,
          symbolHeight: 12,
          symbolWidth: 12,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          useHTML: true,
          text: "",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            data: [
              { name: "FA", color: "#f00", y: fa_number },
              { name: "MTC", color: "#0f0", y: mtc_number },
              { name: "LTI", color: "#00f", y: lti_number },
              { name: "RTA", color: "#ff0", y: rta_number },
            ],
            size: "100%",
            innerSize: "80%",
          },
        ],
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  _getGraph();
  _getIRAGraph();

  let tempEvidence = "<tr>";

  try {
    if (data?.team?.length > 0) {
      let temp = [...data.team];
      temp.forEach((item, index) => {
        if (index + 1 != 1 && (index + 1) % 4 == 1) {
          tempEvidence += "<tr>";
        }
        tempEvidence += `<td style="text-align: center;" valign="top">
            <div><img src="${item.user_pic}" style="width:120px;border-radius: 60px;height: 120px"></div>
            <h5 style="margin: 4px 0 0;font-size: 14px;font-weight: normal;">${item.user_name}</h5>
            <h6 style="margin: 5px 0 0;font-size: 13px;font-weight: normal;color: #888">${item.user_role}</h6>
            <p style="color: #f5b134;font-weight: normal;margin: 4px 0 0;font-size: 14px;">${item.status}</p>
          </td>`;

        if ((index + 1) % 4 == 0) {
          tempEvidence += "</tr>";
        }
      });
    }

    return `<table cellpadding="15" cellspacing="10" width="800" style="margin: 0 auto 10px auto;font-family: arial;height: 100vh;font-size: 14px;border: 3px double #797878;position:relative;" class="full-table">
<tr>
  <td valign="top" class="inner-table">
  <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Investigation Team</h4>
  <hr />
    <table border="0" width="100%" cellpadding="10" cellspacing="0">
       ${tempEvidence}
    </table>
  </td>
</tr>
<tr>
  <td valign="top" class="inner-table">
  <span style="font-size: 16px;margin-bottom:1px;font-weight:bold"> Activity Analysis </span> <span style="font-size: 16px;margin-bottom: 5px;"> : ${
    data.activity || "N/A"
  }</span>
  <hr />
    <table width="100%" cellspacing="10">
      <tr>
        <td width="49%">
          <h4 style="margin: 0 0 5px; font-weight: normal; font-size: 15px;">IRA - UA Vs UC Analysis</h4>
          <div style="border: 1px solid #ddd;text-align: center;height:265px;">
            <div id="uauc-container" />
          </div>
        </td>
        <td width="49%">
          <h4 style="margin: 0 0 5px; font-weight: normal; font-size: 15px;">IRA Analysis</h4>
          
          <div style="border: 1px solid #ddd;text-align: center;height:265px;">
          <div id="ira-container" />
          </div>
        </td>
      </tr>
    </table>
    <span class="page-number" />
  </td>
</tr>
</table>`;
  } catch (error) {
    console.log(error);
  }
};

export default PageFive;
