const CorrectiveActionPlan = (data) => {
  let tempHtml = "";

  try {
    data.correctiveAction.forEach((item, index) => {
      tempHtml += `<tr>
        <td style="text-align: center;">${++index}</td>
        <td style="font-weight: bold;">${item.category_name || 'N/A'}</td>
        <td valign="middle" class="recommendation-cell">${item.recommendation || 'N/A'}</td>
        <td style="background:${item.type_of_incidence_color || ''};text-align: center;color:${item.type_of_incidence ? '#fff' : '#000'};font-weight: bold;" class="severity-cell">${item.severity || 'N/A'}</td>
        <td>${item.target_date || 'N/A'}</td>
        <td>${item.responsible_person_list.length ? item.responsible_person_list[0].person_name : 'N/A' }</td>
      </tr>`;
    });
  } catch (error) {}

  return (
    `<style>
      /* Define your styles here */
      table {
        margin: 0 auto 10px auto;
        width: 800px;
        font-family: Arial;
        font-size: 14px;
        border: 3px double #797878;
      }
      
      .full-table {
        height: 100vh;
        position: relative;
      }
      
      .inner-table {
        padding: 15px;
        vertical-align: top;
      }
      
      h4 {
        font-size: 16px;
        margin-bottom: 10px;
        color: black;
      }
      
      th, td {
        border: 1px solid #e2e2e2;
        padding: 8px;
      }
      
      th {
        background: #ddd;
      }
      
      .recommendation-cell {
        text-align: left;
        width: 40%;
      }
      
      .severity-cell {
        text-align: center;
        font-weight: bold;
      }
    </style>

    <table cellpadding="15" cellspacing="0" width="800" class="full-table">
      <tr>
        <td valign="top" class="inner-table">
          <h4>Corrective Action Plan - CAPA <span>(Recommendation shown as per priority)</span></h4>
          <table cellpadding="10" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%" style="margin-bottom: 10px">
            <tr>
              <th>#</th>
              <th style="text-align: left;" width="15%">Category</th>
              <th style="text-align: left;" width="40%">Recommendation</th>
              <th>Priority</th>
              <th>Due Date</th>
              <th>Assigned To</th>
            </tr>
            ${tempHtml}
          </table>
          <span class="page-number"></span>
        </td>
      </tr>
    </table>`
  );
};

export default CorrectiveActionPlan;
