import { ErrorToast } from "app/shared/components/Popups/ToastMessage";

const usePreventPaste = () => {
  const _preventPaste = (event) => {
    event.preventDefault();
    ErrorToast("Copy paste is not allowed");
  };
  return _preventPaste;
};

export default usePreventPaste;
