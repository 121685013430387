/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import ReactTooltip from "react-tooltip";
import ReadMoreReact from "read-more-react";
import { MoreVertical } from "react-feather";
import { Button, Dropdown } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
//components
import VideoBox from "app/shared/components/ReusableComponents/VideoPlayer/container";
import { CarouselComponent } from "app/shared/components/ReusableComponents/Carousel";
//assets
import {
  AnalysisFeed,
  BadgeIconFeed,
  CommentIconFeed,
  ActionTrash,
  Likev2FeedIcon,
  Likedv2FeedIcon,
  Likev2Icon,
  Likedv2Icon,
  ActiveLikeIcon,
  CrossWhite,
} from "assets/Iconsv2/Iconsv2";
import { useTranslation } from "react-i18next";
import ClideLoader from "app/shared/ui-kit/loader";
const debounceFunc = _.debounce(function (callback) {
  callback();
}, 300);
var inputMessageFlag = 0;

const footerContentRender = (moduleName = "", item = {}, content) => {
  if (moduleName === "analysis") {
    return (
      <div className="mt-2">
        <div className="clide-v2-fs-5 font-weight-bold">
          <div>
            <div className="sm-graph-modal-title-white">
              {`${item.activity_name} Analysis`}
            </div>
          </div>
        </div>

        <div className="modal-body">{content.getCharts(item)}</div>
      </div>
    );
  } else if (moduleName === "comment") {
    if (
      content.state?.isCommentLoadingList ||
      content.state?.subCommentLoading
    ) {
      return (
        <div className="mt-2">
          <ClideLoader />
        </div>
      );
    } else {
      return <CommentView content={content} item={item} />;
    }
  }
};

const ImageRender = _.memoize(({ el, idx, arr = [] }) => {
  const imageStyles = {
    maxHeight: "45vh",
    objectFit: "contain",
  };

  const ContentRender = ({ type, url }) => {
    if (type === "image") {
      return (
        <img
          style={{ ...imageStyles }}
          className="w-100 rounded-3"
          src={url}
          alt="evidence"
        />
      );
    }

    return <VideoBox url={url} />;
  };

  const isFirstItem = idx === 0;
  const format = el?.evidence_format ?? el?.format;

  if (format) {
    const type =
      format !== "text"
        ? format === "image" || format === "img"
          ? "image"
          : "video"
        : "video";

    const className =
      isFirstItem || arr.length === 1 || arr.length === 3 ? "col-12" : "col-6";

    return (
      <div className={className}>
        <ContentRender type={type} url={el?.evidence_image ?? el?.link} />
      </div>
    );
  }
  return "";
});

const FeedCard = ({ item, content, name = "", functionContent, props }) => {
  const [activeIds, setActiveIds] = React.useState("");
  const [activeModule, setActiveModule] = useState("");
  const { t } = useTranslation(['util']);
  if (name === "though") {
    return (
      <FeedThough
        item={item}
        content={content}
        functionContent={functionContent}
        props={props}
      />
    );
  }

  return (
    <div className="card mb-2 mb-5 p-3">
      <div className="row pb-2 d-flex align-items-center">
        <div className="col-8  px-0 pb-0">
          <div className="d-flex">
            <div>
              <img
                className="clide-v2-profile-img"
                style={{ height: "2vw", width: "2vw" }}
                src={item.user_pic}
                alt=""
              />
            </div>
            <Link
              to={(location) =>
                content?._handleDetailRedirection(location, item)
              }
            >
              <div>
                <label
                  className="fw-bold clide-v2-scroll-title clide-v2-secondary-color mb-0"
                  style={{ cursor: "pointer" }}
                >
                  {item.user_name} <span className="divider">|</span>{" "}
                  {item.service_name} <span className="divider">|</span>{" "}
                  {item.project_name} <span className="divider">|</span>{" "}
                  <span className="module-name  font-18 clide-v2-secondary-color">
                    {" "}
                    {item.module_lable?.toLowerCase() === "good act"
                      ? "GA"
                      : item.module_lable}
                  </span>
                </label>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-4  p-3 text-end d-flex gap-2 justify-content-end">
          {item.module_name == "ira" ? (
            <Button
              className="clide-v2-info-btn"
              style={{
                backgroundColor: item.status_color,
              }}
            >
              <h8 className={` ${item.status_color ? "" : "white"} `}>
                {item.module_severity}
              </h8>
            </Button>
          ) : null}
          {item.sm_delete_flag === 1 && (
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown>
                <Dropdown.Toggle className="border bg-transparent ">
                  <MoreVertical style={{ color: "black" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {item.sm_delete_flag === 1 && item.module_name !== "gp" ? (
                    <Dropdown.Item
                      as={Link}
                      to={(location) =>
                        content._handleDetailRedirection(location, item)
                      }
                    >
                      {t('edit')}
                    </Dropdown.Item>
                  ) : null}
                  {item.sm_delete_flag === 1 && (
                    <Dropdown.Item
                      className="border-top"
                      onClick={() =>
                        content.props._openDeletePopup(
                          item.report_id,
                          item.module_name
                        )
                      }
                    >
                      {t('delete')}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <CarouselComponent
          displayEmptyIndicator={false}
          items={content.__generateCarouselData(
            item.social_media_dashboard_evidence_images ?? []
          )}
          showArrows={false}
        />
      </div>

      <div className="d-flex mt-2 justify-content-between align-items-start flex-wrap border-bottom">
        <div className="me-3 mb-2 ">
          <b>
            {!(
              item.module_name == "gp" ||
              item.module_name == "training" ||
              item.module_name == "tbt"
            )
              ? `${t('open')}`
              : `${t('report')}`}{" "}
            Since :
          </b>
          <span className="color-primary ml-1">
            {item.open_since ? item.open_since : 0}
          </span>
        </div>
        {!(
          item.module_name == "training" ||
          item.module_name == "tbt" ||
          item.module_name == "gp"
        ) ? (
          <div className="me-3 mb-2">
            <b>{t('similarAct')} : </b>
            <span>{item.same_no_activity}</span>
          </div>
        ) : null}

        {CheckFormSettingField(item.form_setting?.setting, "activity") ||
        item.module_name == "gp" ||
        item.module_name == "training" ||
        item.module_name == "tbt" ? (
          <div className="me-3 mb-2">
            <b>{t('activity')} : </b>
            <span>{item.activity_name || "N/A"} </span>
          </div>
        ) : null}

        <div className="me-3 mb-2">
          {item.module_name != "ira" &&
          item.module_name != "gp" &&
          item.hazard_name ? (
            <>
              {CheckFormSettingField(item.form_setting?.setting, "hazard") ? (
                <h6>
                  <b>{t('hazard')} : </b>
                  <span>{item.hazard_name || "N/A"} </span>
                </h6>
              ) : null}
            </>
          ) : null}
        </div>

        {!(item.module_name == "training" || item.module_name == "tbt") ? (
          <div className="me-3 mb-2 d-flex">
            <b>{t('observation')} : </b>
            <span className="ml-1">
              <ReadMoreReact
                text={item.observation || "N/A"}
                readMoreText={t('readMore')}
              ></ReadMoreReact>
            </span>
          </div>
        ) : null}

        {/* show only in good practices */}
        {item.module_name == "gp" ? (
          <div className="me-3 mb-2">
            <b>{t('category')} : </b>
            <span>{item.category || "N/A"} </span>
          </div>
        ) : null}

        {item.module_name == "training" || item.module_name == "tbt" ? (
          <div className="me-3 mb-2">
            <b>{t('topic')} : </b>
            <span>{item.topic || "N/A"} </span>
          </div>
        ) : null}

        {item.module_name == "training" || item.module_name == "tbt" ? (
          <div className="me-3 mb-2">
            <b>{t('attendeesCount')} : </b>
            <span>{item?.total_attendees_count || "N/A"} </span>
          </div>
        ) : null}

        <div className="me-3 mb-2">
          {item.resolve_flag == 1 && (
            <div className="mb-2">
              <buttton
                className=" clide-v2-border-primary-color border rounded clide-v2-fs-5 px-3 py-1 clide-v2-active"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  // contentprp?.openResolve(item.report_id)
                  content?.resolve(item)
                }
              >
                {t('readMore')}
              </buttton>
            </div>
          )}
          <div>
            {["soi", "nm"].includes(item.module_name) ? (
              <b className="color-primary">
                {"R" + " - " + item.module_severity}
              </b>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex py-3 ">
        <div>
          {!(
            item.module_name == "training" ||
            item.module_name == "tbt" ||
            item.module_name == "gp"
          ) ? (
            <div className="text-center">
              {item.like_flag == 1 ? (
                <button
                  className="border-0 bg-transparent"
                  onClick={() =>
                    content.debounceFunc(() => {
                      content._removeLikePost(item.report_id, item.module_name);
                    })
                  }
                >
                  <Likedv2Icon />
                </button>
              ) : (
                <button
                  className="border-0 bg-transparent"
                  onClick={() =>
                    content.debounceFunc(() => {
                      content._likePost(item.report_id, item.module_name);
                    })
                  }
                >
                  <Likev2Icon />
                </button>
              )}
              <h6 title="Like_Number" className="mx-2">
                {item.like_number}
              </h6>
            </div>
          ) : null}
        </div>

        {/* Badges & Icon Section */}

        <div className="mx-2">
          {!(
            item.module_name == "gp" ||
            item.module_name == "training" ||
            item.module_name == "tbt"
          ) ? (
            <button
              className="border-0 bg-transparent"
              onClick={() => {
                setActiveModule(activeModule === "comment" ? "" : "comment");
                content.CommentList(item.report_id);
              }}
            >
              <CommentIconFeed />
              <h6 title="Comment_Number" className="mx-2">
                {item.comment_number}
              </h6>
            </button>
          ) : null}
        </div>

        <div className=" me-6 d-flex ">
          {!!item.escalation_key && (
            <Dropdown
              show={activeIds === item.report_id}
              onToggle={(data) => console.log(data)}
            >
              <button
                className="clide-v2-escalation-layout"
                onClick={() =>
                  setActiveIds(
                    activeIds === item.report_id
                      ? ""
                      : item?.escalationlist?.length
                      ? item.report_id
                      : ""
                  )
                }
              >
                <div>
                  <span> {item.escalation_value || "-"}</span>
                </div>
                <div>
                  {item?.escalationlist?.map((el, idx) => {
                    if (
                      el?.level_title === item.escalation_value &&
                      idx === 0
                    ) {
                      return el?.user_list?.length ? (
                        <>
                          {el?.user_list?.map((sub, sudIdx) => (
                            <div
                              className="d-flex align-items-center  px-3 overflow-null"
                              key={sudIdx}
                            >
                              <img
                                src={sub.pic}
                                title="Evidence"
                                style={{ width: "20px", height: "20px" }}
                              />
                              <span className=" clide-v2-fs-5 clide-v2-secondary-color ml-2">
                                {sub.name}
                              </span>
                            </div>
                          ))}
                        </>
                      ) : (
                        ""
                      );
                    }
                  })}
                </div>
              </button>
              <Dropdown.Menu>
                {!!item.escalation_key && (
                  <div>
                    {item?.escalationlist?.length ? (
                      item?.escalationlist?.map((el) => {
                        if (el?.level_title === item.escalation_value) {
                          return el?.user_list?.length ? (
                            <>
                              {el?.user_list?.map((sub, sudIdx) => (
                                <Dropdown.Item
                                  className="d-flex align-items-center py-1 px-3 overflow-null"
                                  key={sudIdx}
                                >
                                  <img
                                    className="user-icon"
                                    src={sub.pic}
                                    title={t('evidence')}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                  <span className="font-16 font-weight-500 ml-2">
                                    {sub.name}
                                  </span>
                                </Dropdown.Item>
                              ))}
                            </>
                          ) : (
                            ""
                          );
                        }
                      })
                    ) : (
                      <span className="font-16 font-weight-500 ml-2">
                        {t('noRecordFound')}
                      </span>
                    )}
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

        {!(
          item.module_name == "gp" ||
          item.module_name == "training" ||
          item.module_name == "tbt"
        ) ? (
          <div className="me-3">
            <button
              className="border-0 bg-transparent"
              onClick={() =>
                setActiveModule(activeModule === "analysis" ? "" : "analysis")
              }
            >
              <AnalysisFeed />
            </button>
          </div>
        ) : null}
      </div>

      {activeModule !== "" ? (
        <div className="border-top">
          {footerContentRender(activeModule, item, content)}
        </div>
      ) : null}
    </div>
  );
};

const FeedThough = ({ item, content, props, functionContent }) => {
  const [activeIds, setActiveIds] = React.useState("");
  const [activeModule, setActiveModule] = useState("");
  const { t } = useTranslation(['util']);
  return (
    <div className="card mb-2 mb-5 p-3">
      <div className="row">
        <div className="col-10 col-md-10 col-lg-10 pt-3 px-0 pb-0">
          <div className="d-flex">
            <div>
              <img className="clide-v2-profile-img" src={item.user_pic} />
            </div>

            <div>
              <div>
                <label className="fw-bold clide-v2-scroll-title mb-0">
                  {item.user_name} <span className="divider">|</span>{" "}
                </label>
                <label className="font-weight-normal font-18 mt-1">
                  {item.user_role} <span className="divider">| </span>
                  <span className="project-name">{item.open_since}</span>
                </label>
              </div>
              <div className="safety-thought-right-block"></div>
            </div>
          </div>
        </div>
        <div className="col-2 col-md-2 col-lg-2 pt-3  pb-0">
          <div
            onClick={(e) => e.stopPropagation()}
            className="d-flex justify-content-end "
          >
            <Dropdown>
              <Dropdown.Toggle className="border bg-transparent ">
                <MoreVertical style={{ color: "black" }} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item>Edit </Dropdown.Item> */}
                <Dropdown.Item
                  className="border-bottom"
                  onClick={() => props._openDeletePopup(item.report_id)}
                >
                  {t('delete')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="row pb-2">
        <CarouselComponent
          displayEmptyIndicator={false}
          items={content.__generateCarouselData(
            item.social_media_dashboard_evidence_images ?? []
          )}
          showArrows={false}
        />

        <div className="m-4 my-2">
          <h6>
            <span className="inside" style={{ color: "rgba(0, 0, 0, 0.7" }}>
              {content.state.thoughtExpandedView
                ? content._fullText(item.observation)
                : content._trimmedText(item.observation)}
            </span>
          </h6>
        </div>
        <div className="d-flex py-3 ">
          <div>
            <div className="text-center">
              {item.like_flag == 1 ? (
                <button
                  className="border-0 bg-transparent"
                  onClick={() =>
                    content.debounceFunc(() => {
                      content._removeLikePost(item.report_id, "st");
                    })
                  }
                >
                  <Likedv2Icon />
                </button>
              ) : (
                <button
                  className=" bg-transparent"
                  onClick={() =>
                    content.debounceFunc(() => {
                      content._likePost(item.report_id, "st");
                    })
                  }
                >
                  <Likev2Icon />
                </button>
              )}
              <h6 title="Like_Number" className="mx-2">
                {item.like_number}
              </h6>
            </div>
          </div>

          {/* Badges & Icon Section */}

          <div className="mx-2">
            {!(
              item.module_name == "gp" ||
              item.module_name == "training" ||
              item.module_name == "tbt"
            ) ? (
              <button
                className="border-0 bg-transparent"
                onClick={() => {
                  setActiveModule(activeModule === "comment" ? "" : "comment");
                  content.CommentList(item.report_id);
                }}
              >
                <CommentIconFeed />
                <h6 title="Comment_Number" className="mx-2">
                  {item.comment_number}
                </h6>
              </button>
            ) : null}
          </div>
        </div>
        {activeModule !== "" ? (
          <div className="border-top">
            {footerContentRender(activeModule, item, content)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const CommentView = ({ content }) => {
  const { profile_image } = JSON.parse(localStorage.getItem("userData")) ?? {};
  const { user_name } = content.state?.subcommentlist[0] ?? [];
    const { t } = useTranslation(['nm', 'util']);
  const inputsValues = (
    <div className="row mt-3 mb-3">
      <div className="col-12 col-md-9 col-lg-9">
        <div className="row">
          <div className="col-2 col-md-1 col-lg-1 mb-2 px-0">
            <img className="clide-v2-profile mr-2" src={profile_image} />
          </div>
          {content.state.comment == 0 ? (
            <div className="col-10 col-md-10 col-lg-10 mb-2">
              <input
                type="text"
                name=""
                className="clide-v2-input-box"
                placeholder={t('writeComment')}
                value={content.state.commentAdd}
                onChange={(e) =>
                  content.setState({
                    commentAdd: e.target.value.trimStart(),
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (inputMessageFlag === 0) {
                      inputMessageFlag = 1;

                      setTimeout(() => {
                        content.sendComment();
                        inputMessageFlag = 0;
                      }, 500);
                      // this.CommentAdd()
                    }
                  }
                }}
              />
            </div>
          ) : (
            <div className="col-10 col-md-10 col-lg-10 mb-2">
              <input
                type="text"
                name=""
                className="clide-v2-input-box"
                placeholder={t('writeReply')}
                value={content.state.replycommentAdd}
                onChange={(e) =>
                  content.setState({
                    replycommentAdd: e.target.value.trimStart(),
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (inputMessageFlag === 0) {
                      inputMessageFlag = 1;

                      setTimeout(() => {
                        content.sendReply();
                        inputMessageFlag = 0;
                      }, 500);
                      // this.CommentAdd()
                    }
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>

      {content.state.comment == 0 ? (
        <div className="col-12 col-md-2 mb-3 col-lg-3 text-end">
          <button
            className="clide-v2-button text-center p-2 color-white w-auto"
            onClick={() =>
              debounceFunc(() => {
                content.sendComment();
              })
            }
          >
            {t('comment')}
          </button>
        </div>
      ) : (
        <div className="col-12 col-md-2 mb-3 col-lg-3 text-end">
          <button
            className="clide-v2-button text-center p-2 color-white w-auto"
            onClick={() =>
              debounceFunc(() => {
                content.sendReply();
              })
            }
          >
            {t('reply')}
          </button>
        </div>
      )}
    </div>
  );
  if (_.isEmpty(content?.state?.commentlist)) {
    return (
      <div className="clide-v2-title mb-1">
        <label className="clide-v2-fs-4">{t('noCommentsAvailable')}</label>
        {inputsValues}
      </div>
    );
  }

  return (
    <div>
      {/* Main Comment Section */}
      {!content.state.dataLoading &&
      content.state.comment === 0 &&
      content.state.commentlist !== "" ? (
        <div>
          <Scrollbars
            style={{ minHeight: "20rem", maxHeight: "25rem" }}
            renderView={(props) => (
              <div
                style={{
                  paddingRight: "15px",
                  ...props.style,
                  overflowX: "hidden",
                }}
              />
            )}
          >
            <div className="d-flex flex-column flex-column-reverse">
              {content.state.commentlist.map((item, index) => (
                <div className="border-bottom " key={index}>
                  <div
                    className={`row pt-2 pb-2 ${
                      item?.delete_flag ? "flex-row-reverse" : ""
                    }`}
                  >
                    <div
                      className={`col-8 col-md-7 col-lg-7 p-0 ${
                        item?.delete_flag ? "text-end" : ""
                      }`}
                    >
                      <img
                        className="clide-v2-profile-img mr-2"
                        src={item.user_pic}
                      />
                      <label className="me-2 fw-semibold">
                        {item.user_name}
                      </label>{" "}
                      <span>{item.comment_time}</span>
                    </div>

                    <div
                      className={`col-4 col-md-5 col-lg-5  pt-2 pb-2 ${
                        !item?.delete_flag ? "text-end" : ""
                      }`}
                    >
                      {item.like_flag != 1 ? (
                        <a
                          onClick={() =>
                            content.LikeCommentAdd(item.comment_id, 1)
                          }
                          className="card-link text-decoration-none"
                        >
                          <Likev2Icon />
                          <span className="mx-1 clide-v2-secondary-color">
                            {item.like_number}
                          </span>
                        </a>
                      ) : (
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            content.LikeCommentRemove(item.comment_id, 1)
                          }
                          className="card-link text-decoration-none"
                        >
                          <Likedv2Icon />{" "}
                          <span className="mx-1 clide-v2-secondary-color">
                            {item.like_number}
                          </span>
                        </a>
                      )}
                      {item.delete_flag ? (
                        <button
                          className="clide-v2-icon-btn"
                          onClick={() =>
                            content.CommentRemove(item.comment_id, 1)
                          }
                        >
                          <ActionTrash />
                        </button>
                      ) : null}

                      <button
                        className="clide-v2-icon-btn clide-v2-margin-left-10"
                        onClick={() => {
                          content.togglecommentId(item.comment_id);
                        }}
                      >
                        {t('reply')} <span>{item.reply_number}</span>
                      </button>
                    </div>
                  </div>
                  <div
                    className={`px-3 pb-3 ${
                      item?.delete_flag ? "text-end" : "text-start"
                    }`}
                  >
                    {item.comment}

                    {/* showing subcomments here */}
                    <div>
                      {item.subComment.map((subItem, subIndex) => (
                        <div key={subIndex}>
                          <div
                            className={`row pt-2 pb-2 ${
                              item?.delete_flag ? "flex-row-reverse" : ""
                            }`}
                          >
                            <div
                              className={`col-8 col-md-7 col-lg-7 p-0 ${
                                item?.delete_flag ? "text-end" : ""
                              }`}
                            >
                              <img
                                className="clide-v2-profile-img mr-2 "
                                src={subItem.user_pic}
                              />
                              <label className="me-2 fw-semibold">
                                {subItem.user_name}
                              </label>{" "}
                              <span>{subItem.comment_time}</span>
                            </div>

                            <div
                              className={`col-4 col-md-5 col-lg-5  pt-2 pb-2 ${
                                !item?.delete_flag ? "text-end" : "text-start"
                              }`}
                            >
                              {subItem.like_flag != 1 ? (
                                <a
                                  onClick={() =>
                                    content.LikeCommentAdd(
                                      subItem.comment_id,
                                      2
                                    )
                                  }
                                  className="card-link text-decoration-none"
                                >
                                  <Likev2Icon />
                                </a>
                              ) : (
                                <a
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    content.LikeCommentRemove(
                                      subItem.comment_id,
                                      2
                                    )
                                  }
                                  className="card-link text-decoration-none"
                                >
                                  <Likedv2Icon />{" "}
                                </a>
                              )}
                              <span className="mx-1">
                                {subItem.like_number}
                              </span>
                              {subItem.delete_flag ? (
                                <button
                                  className="clide-v2-icon-btn"
                                  onClick={() =>
                                    content.CommentRemove(subItem.comment_id, 2)
                                  }
                                >
                                  <ActionTrash />
                                </button>
                              ) : null}
                            </div>
                          </div>
                          <div className="px-3 pb-3">
                            {subItem.comment}
                            <div></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Scrollbars>
        </div>
      ) : (
        <div className="comment-box">
          {content.state.comment === 0 && content.state.commentlist === "" && (
            <p>{t('noCommentsAvailable')}</p>
          )}
        </div>
      )}

      {/* Reply Section */}

      {!content.state.dataLoading &&
      content.state.comment === 1 &&
      content.state.subcommentlist !== "" ? (
        <div>
          <Scrollbars
            style={{ minHeight: "20rem", maxHeight: "25rem" }}
            renderView={(props) => (
              <div
                style={{
                  paddingRight: "15px",
                  ...props.style,
                  overflowX: "hidden",
                }}
              />
            )}
          >
            <div className="d-flex flex-column flex-column-reverse">
              {content.state.subcommentlist.map((item, index) => (
                <div key={index}>
                  <div
                    className={`row pt-2 pb-2 ${
                      item?.delete_flag ? "flex-row-reverse" : ""
                    }`}
                  >
                    <div
                      className={`col-8 col-md-7 col-lg-7 p-0 ${
                        item?.delete_flag ? "text-end" : ""
                      }`}
                    >
                      <img
                        className="clide-v2-profile-img mr-2"
                        src={item.user_pic}
                      />
                      <label className="me-2 fw-semibold">
                        {item.user_name}
                      </label>{" "}
                      <span>{item.comment_time}</span>
                    </div>

                    <div
                      className={`col-4 col-md-5 col-lg-5  pt-2 pb-2 ${
                        !item?.delete_flag ? "text-end" : ""
                      }`}
                    >
                      {item.like_flag != 1 ? (
                        <a
                          onClick={() =>
                            content.LikeCommentAdd(item.comment_id, 1)
                          }
                          className="card-link text-decoration-none"
                        >
                          <Likev2Icon />
                          <span className="mx-1 clide-v2-secondary-color">
                            {item.like_number}
                          </span>
                        </a>
                      ) : (
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            content.LikeCommentRemove(item.comment_id, 1)
                          }
                          className="card-link text-decoration-none"
                        >
                          <Likedv2Icon />{" "}
                          <span className="mx-1 clide-v2-secondary-color">
                            {item.like_number}
                          </span>
                        </a>
                      )}
                      {item.delete_flag ? (
                        <button
                          className="clide-v2-icon-btn"
                          onClick={() =>
                            content.CommentRemove(item.comment_id, 1)
                          }
                        >
                          <ActionTrash />
                        </button>
                      ) : null}

                      <button
                        className="clide-v2-icon-btn clide-v2-margin-left-10"
                        onClick={() => {
                          content.togglecommentId(item.comment_id);
                        }}
                      >
                        {t('reply')} <span>{item.reply_number}</span>
                      </button>
                    </div>
                  </div>
                  <div
                    className={`px-3 pb-3 ${
                      item?.delete_flag ? "text-end" : "text-start"
                    }`}
                  >
                    {item.comment}

                    {/* showing subcomments here */}
                    <div>
                      {item.subComment.map((subItem, subIndex) => (
                        <div key={subIndex}>
                          <div
                            className={`row pt-2 pb-2 ${
                              item?.delete_flag ? "flex-row-reverse" : ""
                            }`}
                          >
                            <div
                              className={`col-8 col-md-7 col-lg-7 p-0 ${
                                item?.delete_flag ? "text-end" : ""
                              }`}
                            >
                              <img
                                className="clide-v2-profile-img mr-2 "
                                src={subItem.user_pic}
                              />
                              <label className="me-2 fw-semibold">
                                {subItem.user_name}
                              </label>{" "}
                              <span>{subItem.comment_time}</span>
                            </div>

                            <div
                              className={`col-4 col-md-5 col-lg-5  pt-2 pb-2 ${
                                !item?.delete_flag ? "text-end" : "text-start"
                              }`}
                            >
                              {subItem.like_flag != 1 ? (
                                <a
                                  onClick={() =>
                                    content.LikeCommentAdd(
                                      subItem.comment_id,
                                      2
                                    )
                                  }
                                  className="card-link text-decoration-none"
                                >
                                  <Likev2Icon />
                                </a>
                              ) : (
                                <a
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    content.LikeCommentRemove(
                                      subItem.comment_id,
                                      2
                                    )
                                  }
                                  className="card-link text-decoration-none"
                                >
                                  <Likedv2Icon />{" "}
                                </a>
                              )}
                              <span className="mx-1">
                                {subItem.like_number}
                              </span>
                              {subItem.delete_flag ? (
                                <button
                                  className="clide-v2-icon-btn"
                                  onClick={() =>
                                    content.CommentRemove(subItem.comment_id, 2)
                                  }
                                >
                                  <ActionTrash />
                                </button>
                              ) : null}
                            </div>
                          </div>
                          <div className="px-3 pb-3">
                            {subItem.comment}
                            <div></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Scrollbars>
        </div>
      ) : (
        <div className="comment-box">
          {content.state.comment === 1 &&
            content.state.subcommentlist === "" && (
              <p>{t('noSubCommentsAvailable')}</p>
            )}
        </div>
      )}

      {/* Active Reply User View */}
      {content.state.comment === 1 ? (
        <div className="border-top pt-3 mt-2">
          <div className="align-items-center clide-v2-primary clide-v2-white-color d-flex justify-content-between justify-content-end px-3 rounded ">
            <div>{t('replyingTo')} {user_name} {t('comment')}</div>
            <div>
              <button
                onClick={() =>
                  content.debounceFunc(() => {
                    content.backToMainComment();
                  })
                }
                className="clide-v2-icon-btn py-2"
              >
                <CrossWhite data-tip data-for={`back`} />
              </button>
              <ReactTooltip effect="solid" place="right" id={`back`}>
                <>
                  <div className="output-text font-16 font-weight-500 ">
                    {t('backToMainComments')}
                  </div>
                </>
              </ReactTooltip>
            </div>
          </div>
        </div>
      ) : null}

      {/* Input & Button Section */}
      {inputsValues}
    </div>
  );
};
export { CommentView, FeedCard };
