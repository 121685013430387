import React, { Component } from "react";
import ReactJoyride from "react-joyride";
import { FaPlaneDeparture } from "react-icons/fa";
export default class Tour1 extends React.Component {
    constructor() {
        super();
        this.state={
           run: false,
           steps: [

            {
                target: ".tour1",
                content: " For configuring the Near Miss reporting form for your company set the reporting parameters. Tick and un-tick the parameter you wish to include in reporting form. "
              },
              {
                target: ".nm0",
                content: "Sample preview is where you can see the changes done by you and how you final near Miss reporting form will look like. We suggest not to make frequent changes to this form. "
              },
              {
                target: ".nm1",
                content: "First Reporting parameter - choose the parameter you want to include in the reporting form."
              },
            {
                target: ".nm2",
                content: "Second Location based parameter- These are set when you configure the plant and project."
              },
              {
                target: ".nm3",
                content: " Third is Evidence - You can set the number for photos which will be attached while reporting near miss observation."
              },
              {
                target: ".nm4",
                content: "Forth near-miss investigation steps which are divided into three steps."
              },
             
           
             
           

           ]
         }
    }
    handleClick = e => {
      e.preventDefault();
      
      this.setState({
       run: true
      });
    };
    manageReset = (state) => {
      if (state.action === "reset") {
        this.setState({
          run: false
        });
      }
    }
    render () {
      return (
        <div className="app">
          <ReactJoyride
            steps={this.state.steps}
            run={this.state.run}
            continuous
            showProgress
            showSkipButton
            disableScrolling={true}
            styles={{
             options: {
                // modal arrow and background color
                arrowColor: "white",
                backgroundColor: "white",
                // page overlay color
                overlayColor: "tomato",//"rgba(79, 45, 0, 0.4)"
                //button color
                primaryColor: "tomato",
                //text color
                textColor: "black",
     
                //width of modal
                width: 500,
                //zindex of modal
                zIndex: 1000,
                
            }
         }}
         callback={this.manageReset}
      />
         
  <div className="container">
  <div className="vertical-center">
  {/* <button className="btn btn-warning" onClick={this.handleClick}>Audit Tour</button> */}
  <img src={require("assets/sls-icons/V2. New Development/Take Tour.svg")} className="svg-icons cursor-pointer" title="UAUC Tour" onClick={this.handleClick} color="tomato"  /> 
  
  </div>
</div>
        
       
      </div>
       
      );
    }
}
