//libraries
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import DataTable from "react-data-table-component";
//component
import ClideLoader from "app/shared/ui-kit/loader";
const mycolumns = [
  {
    name: "Title",
    selector: (row) => row.title,
  },
  {
    name: "Year",
    selector: (row) => row.year,
  },
];

const myData = [
  {
    id: 1,
    title: "Beetlejuice",
    year: "1988",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
];
export const DateTableComponent = ({
  data = [],
  columns = [],
  bodyRendrer,
  scrollProps = {},
  customHeader = false,
  defaultHeight = `calc(100vh - 25vw)`,
  isLoading = false,
  loadingBlockRowLength = 5,
  loadingBlockColumnLength = 8,
  containerClass = "",
  paginationSetting = {},
  isNewVersion = false,
  dataTableProps = {
    columns : [],
    data:[],
  },
}) => {
  if (isNewVersion) {
    return (
      <div id="react-data-tablecomponent">
        <DataTable  persistTableHead={true}   {...dataTableProps}   progressComponent={<div className="my-3"><ClideLoader/></div>}  />
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <Scrollbars style={{ height: defaultHeight }} {...scrollProps}>
        <table
          id="example"
          className="table table-striped nowrap table-wrapper-center"
          style={{ width: "100%" }}
        >
          <thead className="text-center">
            <tr>
              {customHeader !== false
                ? customHeader
                : columns?.map((el, idx) => (
                    <th className="text-center" scope="col" key={idx}>
                      {el}
                    </th>
                  ))}
            </tr>
          </thead>

          {data?.length ? (
            <tbody>
              {data?.map((el, idx, arr) => bodyRendrer(el, idx, arr))}
            </tbody>
          ) : (
            <>
              {!isLoading ? (
                <div className=" my-3 w-100">
                  <span className="text-center my-2 mr-3">No Data Found</span>
                </div>
              ) : null}
            </>
          )}
        </table>
        {isLoading ? (
          <>
            {[...Array(loadingBlockRowLength)]?.map((_) => (
              <div className="d-flex common-flex-gap-1">
                {[...Array(loadingBlockColumnLength)]?.map((_) => (
                  <div className="skelton-wrapper d-flex-center">
                    <Skeleton count={1} height={20} width={150} />{" "}
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : null}
      </Scrollbars>
    </div>
  );
};
