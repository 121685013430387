import IraDashboard from "./Dashboard/IraDashboard";
import IraWorkFlow from "./WorkFlow/IraWorkFlow";
import IRAReport from "./Report/index"
import IRADetail from "./Detail/index"
import IraEditReportingDetail from "./Detail/IraEditReportingDetail";

export {
  IraDashboard,
  IraWorkFlow,
  IRAReport,
  IRADetail,
  IraEditReportingDetail
}