import React from "react";

const FormTypeDropDown = ({
  title,
  optionList,
  onChangeHandle,
  isRequired,
  titleview = true,
  value,
  selectClasses = "form-select  color-white bg-transparent border-0 clide-v2-fs-5 shadow-none"
}) => {
  return (
    <div >
      {isRequired && <span className="inputErrorMsg">&nbsp;*</span>}
      <select
        className={selectClasses}
        onChange={(event) => onChangeHandle(event.target.value)}
        value={value}
      >
        {optionList.length ? (
          <>
            <option value={""} className="clide-v2-secondary-color">{title}</option>
            {optionList.map((item, index) => (
              <option
                key={`project-option-${index}`}
                className="clide-v2-secondary-color"
                value={
                  Object.keys(item)[1] === "id"
                    ? item[`${Object.keys(item)[1]}`]
                    : item[`${Object.keys(item)[0]}`]
                }
              >
                {Object.keys(item)[1] !== "id"
                  ? item[`${Object.keys(item)[1]}`]
                  : item[`${Object.keys(item)[0]}`]}
              </option>
            ))}
          </>
        ) : (
          <option value={""} className="clide-v2-secondary-color">No {title} Available</option>
        )}
      </select>
    </div>
  );
};

FormTypeDropDown.defaultProps = {
  title: "",
  optionList: [],
  isRequired: true,
  onChangeHandle: () => {},
  value: "",
};

export default FormTypeDropDown;
