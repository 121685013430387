import React from "react";
import "./loaderStyles.scss";
const ClideLoader = () => {
  return (
    <div className="overlay-loader">
    <div className="spinner"></div>
  </div>
  );
};

export default ClideLoader;
