const ProbableRootCause = (data) => {
  let tempHtml = "";

  try {
    let CFData = data.contributing_factor;
    tempHtml += `
    <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Critical Factor</h4>
      <p>${CFData.contributing_factor || "N/A"}</p>
      `;

    // main category
    if (CFData.main_category.length) {
      tempHtml += `<h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Casual Factor</h4>
        `;

      CFData.main_category.forEach((mainItem, index) => {
        tempHtml += `<p> ${++index} ${mainItem.main_category}</p>`;

        // Sub category
        if (mainItem.sub_category.length) {
          tempHtml += `<div><h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Problem Category</h4>
            `;

          mainItem.sub_category.forEach((subItem, subIndex) => {
            tempHtml += `<p>${++subIndex} ${subItem.sub_category}</p>`;

            // Major category
            if (subItem.major_root_cause_category.length) {
              tempHtml += `<div><h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Major Root Cause</h4>
                `;

              subItem.major_root_cause_category.forEach(
                (majorItem, majorIndex) => {
                  tempHtml += `<p>${++majorIndex} ${majorItem.major_root_cause_category
                    }</p>`;

                  // near root cause category
                  if (majorItem.near_root_cause_category.length) {
                    tempHtml += `<div><h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Near Root Cause</h4>
                    `;

                    majorItem.near_root_cause_category.forEach(
                      (nearItem, nearIndex) => {
                        tempHtml += `<p>${++nearIndex} ${nearItem.near_root_cause_category
                          }</p>`;

                        // intermediate cause category
                        if (nearItem.intermediate_cause_category.length) {
                          tempHtml += `<div><h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Intermediate Cause</h4>
                          `;

                          nearItem.intermediate_cause_category.forEach(
                            (interItem, interIndex) => {
                              tempHtml += `<p>${++interIndex} ${interItem.intermediate_cause_category
                                }</p>`;
                            }
                          );
                          tempHtml += `</div>`;
                        }
                      }
                    );
                    tempHtml += `</div>`;
                  }
                }
              );
              tempHtml += `</div>`;
            }
          });
          tempHtml += `</div>`;
        }
      });
    }
  } catch (error) { }

  return `<table cellpadding="15" cellspacing="0" width="800" style="margin: 0 auto 10px auto;font-family: arial;height: 100vh;font-size: 14px;border: 3px double #797878;position:relative" class="full-table">
  <tr>
    <td valign="top" class="inner-table">
      <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Probable Cause</h4>
      <hr />
      <p>${data.probable_cause?.probable_cause || "N/A"}</p>

      <h5 style="margin-bottom:5px;font-size: 15px;font-weight:bold">Description</h5>
      <p>${data.probable_cause?.description || "N/A"}</p>

      <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Root Cause Analysis</h4>
      <hr />

      ${tempHtml}

      <span class="page-number" />
    </td>
  </tr>
</table>`;
};

export default ProbableRootCause;
