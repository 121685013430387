import { UPDATE_PERMITTED_MODULE_LIST } from "../constant/ActionType";

let initialState = {
  permittedModuleList: [],
};

function permittedModuleReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PERMITTED_MODULE_LIST:
      return {
        ...state,
        permittedModuleList: [...action.payload],
      };
      break;
    default:
      return state;
      break;
  }
}

export default permittedModuleReducer;
