import { CHANGE_SERVICE_PROJECT, CHANGE_TAB } from '../constant/ActionType';

let initialState = {
  ua_uc : {
    service_id : null,
    service_name : null,
    project_id : [],
    project_name : null,
    start_date : "",
    end_date : "",
  },
  activeTab : ""
};

function uaucReducer(state = initialState, action) {

  switch (action.type) {
    case CHANGE_SERVICE_PROJECT : 
      return {
        ...state,
        ua_uc : {
          ...action.payload
        }
      }
    case CHANGE_TAB : 
    return {
      ...state,
      activeTab : action.payload
    }
    break;
    default:
      return state;
      break;
  }  
};

export default uaucReducer;