/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import _ from "lodash";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { Row, Col } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import FishboneChart from "fishbone-chart";
import { useTranslation } from "react-i18next";
// ui kit components
import {
  Tooltip,
  Checkbox,
  Input,
  Datepicker,
  Timepicker,
} from "@salesforce/design-system-react";
//components
import ClideLoader from "app/shared/ui-kit/loader";
import TimelineSection from "../../../../timelineSection";
import { HorizontalNavTab } from "app/shared/components/ReportModal";
//assets

//assets
import {
  PlusIconRed,
  RoundSave,
  CrossRound,
  RoundEdit,
  TooltipInfoIcon,
} from "assets/Iconsv2/Iconsv2";

const rootCauseMenus = [
  {
    tabName: "Probable Cause",
    key: "1",
  },
  {
    tabName: "Root Cause Analysis",
    key: "2",
  },
];

const DetailStep4 = ({ state, functionContent, variablesObj }) => {
  const {
    isInprogress,
    isApproveEnabled,
    iraDetailStep3,
    iraDetailStep4,
    dataGathering,
    isCompleted,
    isRootCauseTabsDisabled,
    isTreeAvailable,
  } = variablesObj ?? {};

  const { chronology = {}, rootCause = {} } = state?.dataGathering ?? {};

  const {t} = useTranslation('ira');

  return (
    <div>
      <div className="row mt-3">
        <div className="col-12 ">
          <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-between px-0">
            <div className="clide-v2-title mb-1">
              <label className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                {" "}
                {t('step4RootCause')}
              </label>
            </div>
          </div>
        </div>
        <div className="col-12 ">
          <div className="row">
            <div className="col-12 px-0">
              <div className="row">
                <div className="col-10  px-0">
                  <div className="clide-v2-title mb-1">
                    <label className="clide-v2-fs-4 mb-0">
                      {t('chronologyEvents')}{" "}
                    </label>
                  </div>
                  <hr />
                </div>

                <div className="col-2 d-flex justify-content-end px-0">
                  {isInprogress && isApproveEnabled ? (
                    <button
                      className="clide-v2-icon-btn "
                      onClick={() =>
                        functionContent.debounceFunc(() => {
                          functionContent.manageDataGatheringParams(
                            "chronology",
                            {
                              isAddEnable: true,
                            }
                          );
                        })
                      }
                    >
                      <PlusIconRed />
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12">
                  {chronology.isAddEnable ? (
                    <div className="border rounded p-3 row">
                      <div className="d-flex flex-wrap col-12 col-md-10 col-lg-10 gap-2">
                        <div>
                          <Datepicker
                            labels={{
                              label: " Date",
                            }}
                            onChange={(event, data) => {
                              functionContent.manageDataGatheringParams(
                                "chronology",
                                { date: data.date }
                              );
                            }}
                            formatter={(date) => {
                              return date
                                ? moment(date).format("M/D/YYYY")
                                : "";
                            }}
                            value={chronology?.date}
                          />
                        </div>
                        <div>
                          <Timepicker
                            label="Time"
                            stepInMinutes={30}
                            onDateChange={(date, inputStr) => {
                              functionContent.manageDataGatheringParams(
                                "chronology",
                                { time: inputStr, dateTimeOf: date }
                              );
                            }}
                            placeholder={t('chooseTime')}
                            strValue={chronology?.time}
                          />
                        </div>
                        <Input
                          assistiveText={{ label: "Info Source" }}
                          label={"Info Source"}
                          placeholder={t('enterSource')}
                          value={chronology?.info_source}
                          onChange={(e) =>
                            functionContent.manageDataGatheringParams(
                              "chronology",
                              { info_source: e.target.value }
                            )
                          }
                        />
                        <div className="w-50">
                          <Input
                            assistiveText={{ label: "Description" }}
                            label={"Description"}
                            placeholder={t('enterDescription')}
                            value={chronology?.description}
                            onChange={(e) =>
                              functionContent.manageDataGatheringParams(
                                "chronology",
                                { description: e.target.value }
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-wrap col-12 col-md-2 col-lg-2 gap-2">
                        <div className="d-flex space-between ">
                          {chronology.isLoading ? (
                            <div className=" mx-3 mt-3">
                              <ClideLoader />{" "}
                            </div>
                          ) : (
                            <>
                              <div className="mt-3 d-flex">
                                <button
                                  className="cursor-p  clide-v2-icon-btn"
                                  onClick={() =>
                                    functionContent.debounceFunc(() => {
                                      functionContent.insertChronology();
                                    })
                                  }
                                >
                                  <RoundSave className="cursor-p " />
                                </button>
                                <button
                                  className="cursor-p mx-2 clide-v2-icon-btn"
                                  onClick={() =>
                                    functionContent.debounceFunc(() => {
                                      functionContent.manageDataGatheringParams(
                                        "",
                                        {},
                                        true
                                      );
                                    })
                                  }
                                >
                                  <CrossRound />
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {state.reportStatus === "completed" ? (
                        <div id="ira-details-chronology-events"></div>
                      ) : (
                        <TimelineSection
                          data={functionContent.renderChronology(
                            state?.iraDetailStep4?.chronology_of_events,
                            state,
                            isInprogress && isApproveEnabled
                          )}
                          state={state}
                          displayLabel={false}
                          isBackButtonEnabled={false}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 my-3">
          <div className="row">
            <div className="col-12 px-0">
              <div
                className="row"
                onMouseEnter={() => {
                  if (
                    !state.isRootCauseTooltipEnabed &&
                    !isRootCauseTabsDisabled
                  ) {
                    functionContent.setState({
                      isRootCauseTooltipEnabed: true,
                    });
                  }
                }}
                onMouseLeave={() => {
                  if (state.isRootCauseTooltipEnabed) {
                    functionContent.setState({
                      isRootCauseTooltipEnabed: false,
                    });
                  }
                }}
              >
                <div className="col-10  px-0">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6  px-0">
                      <div className="clide-v2-title mb-1">
                        <label className="clide-v2-fs-4 mb-0">
                          {t('rootCause')}{" "}
                        </label>
                        {!isRootCauseTabsDisabled && (
                          <Tooltip
                            id="help"
                            align="top left"
                            isOpen={state.isRootCauseTooltipEnabed}
                            content={
                              <div>
                                {t('userChoose')}
                                <br />
                                <div className="my-2">
                                  {t('1stMethod')}
                                </div>
                                <div className="my-2 mb-0">
                                  {t('2ndMethod')}
                                </div>
                                <div>
                                  {" "}
                                  {t('chooseMethod')}
                                </div>
                              </div>
                            }
                            variant="learnMore"
                          >
                            <button
                              className="clide-v2-icon-btn"
                              aria-describedby="help"
                            >
                              {" "}
                              <TooltipInfoIcon />
                            </button>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6  px-0">
                      {!isRootCauseTabsDisabled && (
                        <HorizontalNavTab
                          tabs={[
                            { tabName: "5Y Analysis", key: "5Y Analysis" },
                            {
                              tabName: "CLC Analysis",
                              key: "CLC Analysis",
                            },
                          ]}
                          activeItem={state.rootCauseActiveType}
                          handleChange={(key) =>
                            functionContent.setState({
                              rootCauseActiveType: key,
                            })
                          }
                          classes="w-100"
                        />
                      )}
                    </div>
                  </div>
                  <hr />
                </div>

                <div className="col-2 d-flex justify-content-end px-0 gap-2">
                  {!isCompleted ? (
                    <>
                      {isInprogress &&
                      isApproveEnabled &&
                      (state.rootCauseActiveType ||
                        state.iraDetail?.step_4_root_cause_option_selected) ===
                        "CLC Analysis" ? (
                        <button
                          className="clide-v2-icon-btn "
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "rootCause",
                                { isAddEnable: true }
                              );
                            })
                          }
                        >
                          <PlusIconRed />
                        </button>
                      ) : null}
                      {isTreeAvailable &&
                      (state.iraDetail?.step_4_root_cause_option_selected ||
                        state.rootCauseActiveType) === "CLC Analysis" &&
                      isApproveEnabled ? (
                        <button
                          className="clide-v2-icon-btn "
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent._intialValue(
                                state?.iraDetailStep4
                              );
                            })
                          }
                        >
                          <RoundEdit />
                        </button>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 ">
                  {(state.iraDetail?.step_4_root_cause_option_selected ||
                    state.rootCauseActiveType) === "CLC Analysis" ? (
                    <div>
                      {rootCause.isAddEnable ? (
                        <div className="border rounded row p-3 d-flex space-between ">
                          <div className="d-flex  flex-wrap flex-column col-12 col-md-10 col-lg-10">
                            <HorizontalNavTab
                              classes=" mb-4 w-100"
                              tabs={rootCauseMenus}
                              activeItem={rootCause.section}
                              handleChange={(key) =>
                                functionContent.manageDataGatheringParams(
                                  "rootCause",
                                  {
                                    section: key,
                                  }
                                )
                              }
                            />

                            <div className="d-flex  flex-column">
                              {rootCause.section === "1" ? (
                                <>
                                  <div className="d-flex  ">
                                    <span
                                      className="slds-form-element__label"
                                      id="file-selector-primary-label-101"
                                    >
                                      Probable Cause
                                    </span>
                                    <Tooltip
                                      id="help"
                                      align="top left"
                                      content={
                                        "Enter what could have cause the incident? and choose the category"
                                      }
                                      variant="learnMore"
                                    >
                                      <button
                                        className="clide-v2-icon-btn"
                                        aria-describedby="help"
                                      >
                                        {" "}
                                        <TooltipInfoIcon className="w-100 cursor-pointer" />
                                      </button>
                                    </Tooltip>
                                  </div>
                                  <Input
                                    placeholder={t('enterCause')}
                                    value={rootCause?.probable_cause}
                                    onChange={(e) =>
                                      functionContent.manageDataGatheringParams(
                                        "rootCause",
                                        { probable_cause: e.target.value }
                                      )
                                    }
                                  />
                                  <Input
                                    label="Description"
                                    placeholder={t('enterDescription')}
                                    value={rootCause?.description}
                                    onChange={(e) =>
                                      functionContent.manageDataGatheringParams(
                                        "rootCause",
                                        { description: e.target.value }
                                      )
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <div className="d-flex  ">
                                    <span
                                      className="slds-form-element__label"
                                      id="file-selector-primary-label-101"
                                    >
                                      {t('criticalFactor')}
                                    </span>
                                    <Tooltip
                                      id="help"
                                      align="top left"
                                      content={
                                        "Enter the contributing factors and choose the category"
                                      }
                                      variant="learnMore"
                                    >
                                      <button
                                        className="clide-v2-icon-btn"
                                        aria-describedby="help"
                                      >
                                        {" "}
                                        <TooltipInfoIcon className="w-100 cursor-pointer" />
                                      </button>
                                    </Tooltip>
                                  </div>
                                  <Input
                                    placeholder={t('enterFactor')}
                                    value={rootCause?.contributing_factor}
                                    onChange={(e) =>
                                      functionContent.manageDataGatheringParams(
                                        "rootCause",
                                        {
                                          contributing_factor: e.target.value,
                                        }
                                      )
                                    }
                                  />

                                  <div className="w-100 ">
                                    <Scrollbars
                                      renderView={(props) => (
                                        <div
                                          style={{
                                            paddingRight: "15px",

                                            ...props.style,
                                            overflowX: "hidden",
                                          }}
                                        />
                                      )}
                                      style={{ height: "14rem" }}
                                    >
                                      <div>
                                        <label className="slds-form-element__label font-16 my-2 font-weight-500">
                                          {" "}
                                          {t('casualFactor')}{" "}
                                        </label>
                                        {state.main_category &&
                                          state.main_category?.map(
                                            (data, mainIndex) => (
                                              <div key={mainIndex}>
                                                <Checkbox
                                                  checked={data.isChecked}
                                                  labels={{
                                                    label:
                                                      data["main_category"],
                                                  }}
                                                  value={data["main_category"]}
                                                  onChange={() => {
                                                    functionContent._toggleMainCategoryCheck(
                                                      data.main_category_id
                                                    );
                                                  }}
                                                />

                                                {data.isChecked && (
                                                  <div className="ml-3 my-1">
                                                    <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                      {" "}
                                                     {t('problemCategories')}{" "}
                                                    </label>
                                                    {data.sub_category.map(
                                                      (subData) => (
                                                        <>
                                                          <Checkbox
                                                            labels={{
                                                              label:
                                                                subData[
                                                                  "sub_category"
                                                                ],
                                                            }}
                                                            checked={
                                                              subData.isChecked
                                                            }
                                                            value={
                                                              subData[
                                                                "sub_category"
                                                              ]
                                                            }
                                                            onChange={() =>
                                                              functionContent._toggleSubCategoryCheck(
                                                                data.main_category_id,
                                                                subData.sub_category_id
                                                              )
                                                            }
                                                          />
                                                          {subData.isChecked && (
                                                            <div className="ml-3 my-1">
                                                              <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                                {" "}
                                                                {t('rootCauseCategory')}{" "}
                                                              </label>
                                                              {subData.major_root_cause_category.map(
                                                                (majorData) => (
                                                                  <>
                                                                    <Checkbox
                                                                      labels={{
                                                                        label:
                                                                          majorData[
                                                                            "major_root_cause_category"
                                                                          ],
                                                                      }}
                                                                      checked={
                                                                        majorData.isChecked
                                                                      }
                                                                      value={
                                                                        subData[
                                                                          "major_root_cause_category"
                                                                        ]
                                                                      }
                                                                      onChange={() =>
                                                                        functionContent._toggleMajorCategoryCheck(
                                                                          data.main_category_id,
                                                                          subData.sub_category_id,
                                                                          majorData.major_root_cause_category_id
                                                                        )
                                                                      }
                                                                    />
                                                                    {majorData.isChecked &&
                                                                      data.isChecked &&
                                                                      data?.showNearCouse && (
                                                                        <div className="ml-3 my-1">
                                                                          <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                                            {" "}
                                                                            {t('near')}
                                                                            {t('rootFUC')}
                                                                            {t('cause')}
                                                                            {t('category')}{" "}
                                                                          </label>
                                                                          {majorData?.near_root_cause_category.map(
                                                                            (
                                                                              nearData
                                                                            ) => (
                                                                              <>
                                                                                <Checkbox
                                                                                  labels={{
                                                                                    label:
                                                                                      nearData[
                                                                                        "near_root_cause_category"
                                                                                      ],
                                                                                  }}
                                                                                  checked={
                                                                                    nearData.isChecked
                                                                                  }
                                                                                  value={
                                                                                    subData[
                                                                                      "near_root_cause_category"
                                                                                    ]
                                                                                  }
                                                                                  onChange={() =>
                                                                                    functionContent._toggleNearCategoryCheck(
                                                                                      data.main_category_id,
                                                                                      subData.sub_category_id,
                                                                                      majorData.major_root_cause_category_id,
                                                                                      nearData.near_root_cause_category_id
                                                                                    )
                                                                                  }
                                                                                />
                                                                                {nearData?.isChecked &&
                                                                                  data?.isChecked &&
                                                                                  data?.showInterCouse && (
                                                                                    <div className="ml-3 my-1">
                                                                                      <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                                                        {" "}
                                                                                        {t('intermediate')}
                                                                                        {t('cause')}
                                                                                        {t('category')}{" "}
                                                                                      </label>
                                                                                      {nearData?.intermediate_cause_category.map(
                                                                                        (
                                                                                          interData
                                                                                        ) => (
                                                                                          <>
                                                                                            <Checkbox
                                                                                              labels={{
                                                                                                label:
                                                                                                  interData[
                                                                                                    "intermediate_cause_category"
                                                                                                  ],
                                                                                              }}
                                                                                              checked={
                                                                                                interData.isChecked
                                                                                              }
                                                                                              value={
                                                                                                subData[
                                                                                                  "intermediate_cause_category"
                                                                                                ]
                                                                                              }
                                                                                              onChange={() =>
                                                                                                functionContent._toggleInterCategoryCheck(
                                                                                                  data.main_category_id,
                                                                                                  subData.sub_category_id,
                                                                                                  majorData.major_root_cause_category_id,
                                                                                                  nearData.near_root_cause_category_id,
                                                                                                  interData.intermediate_cause_category_id
                                                                                                )
                                                                                              }
                                                                                            />
                                                                                          </>
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  )}
                                                                              </>
                                                                            )
                                                                          )}
                                                                        </div>
                                                                      )}
                                                                  </>
                                                                )
                                                              )}
                                                            </div>
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </Scrollbars>
                                  </div>
                                  <div className="w-100 my-2">
                                    <label className="slds-form-element__label font-16 my-2 font-weight-500">
                                      {" "}
                                      {t('rootCauseType')}{" "}
                                    </label>
                                    {state.intermediateRouteCause && (
                                      <Scrollbars
                                        renderView={(props) => (
                                          <div
                                            style={{
                                              paddingRight: "15px",

                                              ...props.style,
                                              overflowX: "hidden",
                                            }}
                                          />
                                        )}
                                        style={{ height: "8rem" }}
                                      >
                                        <div className="my-2">
                                          {state.root_cause_type.map((root) => (
                                            <React.Fragment>
                                              <Checkbox
                                                labels={{
                                                  label:
                                                    root["root_cause_type"],
                                                }}
                                                value={root["root_cause_type"]}
                                                checked={root?.isChecked}
                                                onChange={() =>
                                                  functionContent._toggleRootCauseTypeCheck(
                                                    root.root_cause_type_id
                                                  )
                                                }
                                              />
                                              {root?.isChecked && (
                                                <div className="ml-3 my-1">
                                                  <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                    {" "}
                                                    {t('rootCause')}{" "}
                                                  </label>
                                                  {root?.root_cause.map(
                                                    (routeCauseData) => (
                                                      <Checkbox
                                                        labels={{
                                                          label:
                                                            routeCauseData[
                                                              "root_cause"
                                                            ],
                                                        }}
                                                        key={
                                                          routeCauseData.root_cause_id
                                                        }
                                                        checked={
                                                          routeCauseData.isChecked
                                                        }
                                                        onChange={() =>
                                                          functionContent._toggleRootCauseCheck(
                                                            root.root_cause_type_id,
                                                            routeCauseData.root_cause_id
                                                          )
                                                        }
                                                      />
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </React.Fragment>
                                          ))}
                                        </div>
                                      </Scrollbars>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-2 col-lg-2">
                            <div className="d-flex space-between">
                              {rootCause.isLoading ? (
                                <div className=" mx-3 mt-3">
                                  <ClideLoader />{" "}
                                </div>
                              ) : (
                                <>
                                  <button
                                    className="clide-v2-icon-btn mr-2"
                                    onClick={() =>
                                      functionContent.debounceFunc(() => {
                                        rootCause.section === "1"
                                          ? functionContent.addProbableCause()
                                          : functionContent.addRootCause();
                                      })
                                    }
                                  >
                                    <RoundSave />
                                  </button>
                                  <button
                                    className="clide-v2-icon-btn"
                                    onClick={() =>
                                      functionContent.debounceFunc(async () => {
                                        functionContent.manageDataGatheringParams(
                                          "",
                                          {},
                                          true
                                        );
                                        await functionContent.setState(
                                          (prev) => {
                                            return {
                                              main_category:
                                                prev.masterList.main_category,
                                              root_cause_type:
                                                prev.masterList.root_cause_type,
                                            };
                                          }
                                        );
                                      })
                                    }
                                  >
                                    <CrossRound className="cursor-p mx-3" />
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="my-2">
                          {isTreeAvailable ? (
                            <div className="bg-gary p-5 rounded">
                              <Scrollbars
                                renderView={(props) => (
                                  <div
                                    style={{
                                      paddingRight: "15px",

                                      ...props.style,
                                      overflowX: "hidden",
                                    }}
                                  />
                                )}
                                style={{ height: "48rem" }}
                              >
                                <div className="mr-3">
                                  {functionContent.renderTree(
                                    iraDetailStep4,
                                    "",
                                    true
                                  )}
                                  <div className="my-5">
                                    {functionContent.renderTree(iraDetailStep4)}
                                  </div>
                                </div>
                              </Scrollbars>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className=" my-5">
                      {isInprogress && isApproveEnabled ? (
                        <Row className="my-5">
                          <Col md={5}>
                            <div
                              className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                              style={{ width: "100%" }}
                            >
                              <div className="timeline-header p-0">
                                <button
                                  style={{
                                    backgroundColor: "darkgray",
                                    color: "white",
                                  }}
                                  disabled={!isApproveEnabled}
                                  className="badge  border font-weight-500 px-3 py-3 rounded-pill"
                                  onClick={() =>
                                    functionContent.debounceFunc(() => {
                                      functionContent.toggleStep2Fun(
                                        "contributing"
                                      );
                                    })
                                  }
                                >
                                 {t("contributingFactor")}{" "}
                                </button>
                              </div>
                              <div className="mx-3 my-3 px-3 pt-5">
                                <Scrollbars
                                  renderView={(props) => (
                                    <div
                                      style={{
                                        paddingRight: "15px",

                                        ...props.style,
                                        overflowX: "hidden",
                                      }}
                                    />
                                  )}
                                  style={{ height: "15rem" }}
                                >
                                  <div className="pr-4">
                                    {iraDetailStep4?.ira_reported_detail_contrubuting_factor_list?.map(
                                      (el, idx) => (
                                        <div className="user-badge p-3 mb-2 mr-2 w-100">
                                          <div
                                            key={idx}
                                            className="clide-v2-fs-5"
                                          >
                                            {el.factor}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Scrollbars>
                              </div>
                            </div>
                          </Col>
                          <Col md={7}>
                            <div
                              className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                              style={{ width: "100%" }}
                            >
                              <div className="timeline-header p-0">
                                <button
                                  style={{
                                    backgroundColor: "darkgray",
                                    color: "white",
                                  }}
                                  className="badge border font-weight-500 px-3 py-3 rounded-pill"
                                  onClick={() =>
                                    functionContent.debounceFunc(() => {
                                      functionContent.toggleStep2Fun(
                                        "immediate"
                                      );
                                    })
                                  }
                                  disabled={!isApproveEnabled}
                                >
                                  {t('immediateFactor')}{" "}
                                </button>
                              </div>
                              <div className="mx-3 my-3 px-3 pt-5">
                                <Scrollbars
                                  renderView={(props) => (
                                    <div
                                      style={{
                                        paddingRight: "15px",

                                        ...props.style,
                                        overflowX: "hidden",
                                      }}
                                    />
                                  )}
                                  style={{ height: "15rem" }}
                                >
                                  <div
                                    className="pr-4 d-flex flex-wrap "
                                    style={{
                                      rowGap: "0.7rem",
                                      columnGap: "0.7rem",
                                    }}
                                  >
                                    {iraDetailStep4?.ira_reported_detail_immediate_factor_list?.map(
                                      (el, idx) => (
                                        <div className="user-badge p-3">
                                          <div
                                            key={idx}
                                            className="clide-v2-fs-5"
                                          >
                                            {el.factor}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Scrollbars>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col>
                            {/* Contributing Factor  */}

                            <div style={{ margin: "2vh" }}>
                              {iraDetailStep4
                                .ira_reported_detail_contrubuting_factor_list
                                ?.length
                                ? iraDetailStep4.ira_reported_detail_contrubuting_factor_list
                                    .slice(
                                      0,
                                      iraDetailStep4
                                        .ira_reported_detail_contrubuting_factor_list
                                        .length / 2
                                    )
                                    .map((item, index) => (
                                      <>
                                        <Row>
                                          <Col>
                                            <div
                                              class="vl"
                                              style={{
                                                marginLeft: "2rem",
                                                borderLeft: "3px solid red",
                                                height: "2rem",
                                                borderTop: "3px solid red",
                                                width: "1rem",
                                                display: "inline-block",
                                              }}
                                            ></div>
                                            <span
                                             style={{
                                              position: "absolute",
                                              lineHeight: "1.2rem",
            
                                              whiteSpace: "normal",
                                            }}
                                            className="clide-v2-fs-5"
                                            >
                                              {item.factor}
                                            </span>
                                          </Col>
                                        </Row>
                                      </>
                                    ))
                                : null}

                              <button
                                style={{
                                  background: "red",
                                  color: "white",
                                  borderRadius: "8px",
                                  border: "none",
                                  fontFamily: "monospace",
                                  padding: "2vh",
                                }}
                                disabled={!isApproveEnabled}
                              >
                                {t('contributing')}
                                <br />
                                {t('factor')}
                              </button>

                              {iraDetailStep4
                                .ira_reported_detail_contrubuting_factor_list
                                ?.length
                                ? iraDetailStep4.ira_reported_detail_contrubuting_factor_list
                                    .slice(
                                      iraDetailStep4
                                        .ira_reported_detail_contrubuting_factor_list
                                        .length / 2,
                                      iraDetailStep4
                                        .ira_reported_detail_contrubuting_factor_list
                                        .length
                                    )
                                    .map((item, index) => (
                                      <>
                                        <Row>
                                          <Col>
                                            <div
                                              class="vl"
                                              style={{
                                                marginLeft: "2rem",
                                                borderLeft: "3px solid red",
                                                height: "2rem",
                                                borderBottom: "3px solid red",
                                                width: "1rem",
                                                display: "inline-block",
                                              }}
                                            ></div>
                                            <span
                                            // style={{fontSize:' 2rem !important'}}
                                            className="clide-v2-fs-5"
                                            >
                                              {item.factor}
                                            </span>
                                          </Col>
                                        </Row>
                                      </>
                                    ))
                                : null}
                            </div>
                          </Col>

                          <Col>
                            {/* Immediate Factor  */}

                            {iraDetailStep4
                              .ira_reported_detail_immediate_factor_list?.length
                              ? iraDetailStep4.ira_reported_detail_immediate_factor_list
                                  .slice(
                                    0,
                                    iraDetailStep4
                                      .ira_reported_detail_immediate_factor_list
                                      .length / 2
                                  )
                                  .map((item, index) => (
                                    <>
                                      <Row>
                                        <Col>
                                          <div
                                            class="vl"
                                            style={{
                                              marginLeft: "2rem",
                                              borderLeft: "3px solid #ff4d6c",
                                              height: "2rem",
                                              borderTop: "3px solid red",
                                              width: "1rem",
                                              display: "inline-block",
                                            }}
                                          ></div>
                                          <span
                                            style={{
                                              position: "absolute",
                                              lineHeight: "1.2rem",
            
                                              whiteSpace: "normal",
                                            }}
                                            className="clide-v2-fs-5"
                                          >
                                            {item.factor}
                                          </span>
                                        </Col>
                                      </Row>
                                    </>
                                  ))
                              : null}

                            <div style={{ margin: "2vh" }}>
                              <button
                                style={{
                                  background: "#ff4d6c",
                                  color: "white",
                                  borderRadius: "8px",
                                  border: "none",
                                  fontFamily: "monospace",
                                  padding: "2vh",
                                }}
                              >
                               {t('immediateFUC')}
                                <br />
                                {t('factor')}
                              </button>
                            </div>

                            {iraDetailStep4
                              .ira_reported_detail_immediate_factor_list?.length
                              ? iraDetailStep4.ira_reported_detail_immediate_factor_list
                                  .slice(
                                    iraDetailStep4
                                      .ira_reported_detail_immediate_factor_list
                                      .length / 2,
                                    iraDetailStep4
                                      .ira_reported_detail_immediate_factor_list
                                      .length
                                  )
                                  .map((item, index) => (
                                    <>
                                      <Row>
                                        <Col>
                                          <div
                                            class="vl"
                                            style={{
                                              marginLeft: "2rem",
                                              borderLeft: "3px solid #ff4d6c",
                                              height: "2rem",
                                              borderBottom: "3px solid red",
                                              width: "1rem",
                                              display: "inline-block",
                                            }}
                                          ></div>
                                          <span className="clide-v2-fs-5">{item.factor}</span>
                                        </Col>
                                      </Row>
                                    </>
                                  ))
                              : null}
                          </Col>
                          <Col>
                            {/* Root Cause Analysis  */}

                            <div style={{ margin: "2vh" }}></div>
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-5">
                        <Col>
                          <div className="justify-content-center d-flex fishbon-chart-wrapper ">
                            <div
                              className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                              style={{ width: "100%" }}
                            >
                              <div className="timeline-header p-0">
                                <button
                                  style={{
                                    backgroundColor: "darkgray",
                                    color: "white",
                                  }}
                                  className="badge  border font-weight-500 px-3 py-3 rounded-pill"
                                  disabled={!isApproveEnabled}
                                  onClick={() => {
                                    if (state.reportStatus == "progress") {
                                      functionContent.toggleStep2Fun(
                                        "root cause"
                                      );
                                    }
                                  }}
                                >
                                  {state.reportStatus == "progress"
                                    ? "Add Root Cause Analysis"
                                    : "Root Cause Analysis"}
                                </button>
                              </div>
                              <div className="mx-3 my-3 px-3 pt-5">
                                <div className="d-flex-center overflow-auto py-4">
                                  {/* Root Cause<br/>Analysis */}
                                  <FishboneChart data={state.FishData} />
                                  <div className="ml-2 ">
                                    <ReactTooltip
                                      effect="solid"
                                      place="right"
                                      id={`Fishbone`}
                                    >
                                      <>
                                        <div className="output-text font-16 font-weight-500 ">
                                          {t('rootCauseAnalysis')}
                                        </div>
                                      </>
                                    </ReactTooltip>
                                    <TooltipInfoIcon
                                      data-tip
                                      data-for={`Fishbone`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailStep4;
