import React, { Component } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import Switch from "react-switch";
import { Mic } from "react-feather";
import Url from "app/shared/constants/Url";
import axios from "axios";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import Backbutton from "app/shared/components/Backbutton";
import LoadingView from "app/shared/components/Loader/LoadingView";
import ConfirmModal from "app/shared/utility/ConfirmModal";
import TourIRA from "./TourIRA";

const qs = require("qs");

const RequiredText = () => <span className="text-danger mr-1">*</span>;

const step_2_field = [
  {
    label: "Employee",
    parameter: "employee",
  },
  {
    label: "Contractor",
    parameter: "contractor",
  },
  {
    label: "Identity Proof",
    parameter: "identity_proof",
  },
  {
    label: "Person Work Type",
    parameter: "person_work_type",
  },
  {
    label: "Joining Date",
    parameter: "joining_date",
  },
  {
    label: "Month Year with this employee",
    parameter: "month_year_with_this_employee",
  },
  {
    label: "Month year doing this job",
    parameter: "month_year_doing_this_job",
  },
  {
    label: "Time of Incident",
    parameter: "time_of_incident",
  },
  {
    label: "Incident Analysis question",
    parameter: "incident_analysis_question",
  },
  {
    label: "Injured parts",
    parameter: "injured_parts",
  },
];

const step_3_field = [
  {
    label: "Add Witness",
    parameter: "add_witness",
  },
  {
    label: "Name of witness",
    parameter: "name_of_witness",
  },
  {
    label: "Pic of witness Proof",
    parameter: "pic_of_witness",
  },
  {
    label: "Statement of witness",
    parameter: "statement_of_witness",
  },
  {
    label: "Paper digital",
    parameter: "paper_digital",
  },
  {
    label: "Position equipment",
    parameter: "position_equipment",
  },
  {
    label: "Physical info",
    parameter: "physical_info",
  },
  {
    label: "Sketch",
    parameter: "sketch",
  },
  {
    label: "Job Analysis question",
    parameter: "job_analysis_question",
  },
];

const step_4_field = [
  {
    label: "Chronology of event",
    parameter: "chronology_of_event",
  },
  {
    label: "Probable cause",
    parameter: "probable_cause",
  },
  {
    label: "Contributing factor",
    parameter: "contributing_factor",
  },
  {
    label: "Categories contributing factor",
    parameter: "categories_contributing_factor",
  },
];

export default class IraWorkFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: true,
      checked: false,
      service: 0,
      activity: 1,
      hazard: 0,
      zone: 0,
      location: 0,
      department: 0,
      contractor: 0,
      person_id: 0,
      employee_search: 0,
      Incident_date: 1,
      Incident_time: 1,
      nameOfInjuredPerson: 0,
      step_1: 0,
      step_2: 0,
      step_3: 0,
      step_4: 0,
      step_5: 0,
      step_1_detail: {},
      step_2_detail: {},
      step_3_detail: {},
      step_4_detail: {},
      step_5_detail: {},
      workFlowPopup: false,
      showConfirmPopup: false,
    };
    // this._IRAWorkFlowView();
    // this._settingCheck2 = this._settingCheck2.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    this._IRAWorkFlowView();
  };

  _IRAWorkFlowView = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.iraModule.irasavedformsetting,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        await this.setState({ dataLoading: false });
        if (response.data.status == 200) {
          let settingData = response?.data?.data?.setting;
          try {
            await this.setState((prevState) => {
              let {
                type_of_act,
                hazard,
                observation,
                Incident_date,
                Incident_time,
                person,
                person_id,
                employee_search,
                zone,
                location,
                department,
                contractor,
                service,
                step_1,
                step_2,
                step_3,
                step_4,
                step_5,
                step_1_detail,
                step_2_detail,
                step_3_detail,
                step_4_detail,
                step_5_detail,
                activity,
              } = prevState;

              settingData.forEach((data) => {
                let { parameter_value, parameter } = data;
                switch (parameter) {
                  case "Type Of Act":
                    type_of_act = parameter_value;
                    break;
                  case "Hazard":
                    hazard = parameter_value;
                    break;
                  case "Observation":
                    observation = parameter_value;
                    break;
                  case "Incident date":
                    Incident_date = parameter_value;
                    break;
                  case "Incident time":
                    Incident_time = parameter_value;
                    break;
                  case "Name of injured person":
                    person = parameter_value;
                    break;
                  case "Person Id":
                    person_id = parameter_value;
                    break;
                  case "Employee Search":
                    employee_search = parameter_value;
                    break;
                  case "Zone":
                    zone = parameter_value;
                    break;
                  case "Location":
                    location = parameter_value;
                    break;
                  case "Department":
                    department = parameter_value;
                    break;
                  case "Contractor":
                    contractor = parameter_value;
                    break;
                  case "service":
                    service = parameter_value;
                    break;
                  case "Step 1":
                    step_1 = parameter_value;
                    step_1_detail = data;
                    break;
                  case "Step 2":
                    step_2 = parameter_value;
                    try {
                      data.step_setting = data.step_setting.map((item) => {
                        let findItem = step_2_field.find(
                          (subItem) => subItem.parameter == item.parameter
                        );
                        item.label = findItem.label;
                        return item;
                      });
                    } catch (error) { }
                    step_2_detail = data;
                    break;
                  case "Step 3":
                    step_3 = parameter_value;
                    try {
                      data.step_setting = data.step_setting.map((item) => {
                        try {
                          let findItem = step_3_field.find(
                            (subItem) => subItem.parameter == item.parameter
                          );
                          item.label = findItem.label || "";
                        } catch (error) { }
                        return item;
                      });
                    } catch (error) { }
                    step_3_detail = data;
                    break;
                  case "Step 4":
                    step_4 = parameter_value;
                    try {
                      data.step_setting = data.step_setting.map((item) => {
                        let findItem = step_4_field.find(
                          (subItem) => subItem.parameter == item.parameter
                        );
                        item.label = findItem.label;
                        return item;
                      });
                    } catch (error) { }
                    step_4_detail = data;
                    break;
                  case "Step 5":
                    step_5 = parameter_value;
                    step_5_detail = data;
                    break;
                  case "activity":
                    activity = parameter_value;
                    break;

                  default:
                    break;
                }
              });

              return {
                type_of_act,
                hazard,
                observation,
                Incident_date,
                Incident_time,
                person,
                person_id,
                employee_search,
                zone,
                location,
                department,
                contractor,
                service,
                step_1,
                step_2,
                step_3,
                step_4,
                step_5,
                step_1_detail,
                step_2_detail,
                step_3_detail,
                step_4_detail,
                step_5_detail,
                activity,
              };
            });
          } catch (error) {
            console.log(error);
          }
        }
      })
      .catch(async (error) => {
        await this.setState({ dataLoading: false });
        console.log(error);
      });
  };

  _IRAWorkFlow = async () => {
    await this.setState({ showConfirmPopup: false, dataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let { step_2_detail, step_3_detail, step_4_detail } = this.state;

    const step2Field = {};
    step_2_detail.step_setting.forEach((item) => {
      if (item.parameter == "contractor") {
        step2Field.contractor_status = item.parameter_value;
      } else {
        step2Field[item.parameter] = item.parameter_value;
      }
    });

    const step3Field = {};
    step_3_detail.step_setting.forEach((item) => {
      step3Field[item.parameter] = item.parameter_value;
    });

    const step4Field = {};
    step_4_detail.step_setting.forEach((item) => {
      step4Field[item.parameter] = item.parameter_value;
    });

    await axios
      .post(
        Url.iraModule.iraworkflowformsetting,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          // type_of_act : this.state.type_of_act,
          // activity : 1,
          hazard: this.state.hazard,
          zone: this.state.zone,
          location: this.state.location,
          department: this.state.department,
          contractor: this.state.contractor,
          incidentDate: this.state.Incident_date,
          incidentTime: this.state.Incident_time,
          nameOfInjuredPerson: this.state.person,
          step_1: this.state.step_1_detail.parameter_value,
          step_2: this.state.step_2_detail.parameter_value,
          step_3: this.state.step_3_detail.parameter_value,
          step_4: this.state.step_4_detail.parameter_value,
          step_5: this.state.step_5_detail.parameter_value,
          person_id: this.state.person_id,
          person: this.state.person,
          employee_search: this.state.employee_search,
          service: this.state.service,
          ...step2Field,
          ...step3Field,
          ...step4Field,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        await this.setState({ dataLoading: false });

        if (response.data.status == 200) {
          AlertMessage({
            message: "Setting updated successfully",
            type: "success",
          });
          this._IRAWorkFlowView();
        }
      })
      .catch(async (error) => {
        AlertMessage({ message: "Something Get Error", type: "error" });
        await this.setState({ dataLoading: false });
      });
  };

  _handleSubmitConfirmation = () => {
    this._showConfirmPopup();
  };

  WfModal = (visible) => {
    this.setState({ workFlowPopup: visible });
  };

  handleChange(checked) {
    this.setState({ checked });
  }

  _settingCheck2 = async (param, value) => {
    await this.setState((prevState) => {
      let {
        step_2_detail,
        step_3_detail,
        step_4_detail,
        service,
        hazard,
        observation,
        Incident_date,
        Incident_time,
        person,
        person_id,
        employee_search,
        zone,
        location,
        department,
        contractor,
      } = prevState;

      switch (value) {
        case "service":
          service = Number(!param);
          break;
        case "hazard":
          hazard = Number(!param);
          break;
        case "observation":
          observation = Number(!param);
          break;
        case "incident_date":
          Incident_date = Number(!param);
          break;
        case "incident_time":
          Incident_time = Number(!param);
          break;
        case "person":
          person = Number(!param);
          break;
        case "person_id":
          person_id = Number(!param);
          break;
        case "employee_search":
          employee_search = Number(!param);
          break;
        case "zone":
          zone = Number(!param);
          break;
        case "location":
          location = Number(!param);
          if (!Number(param)) {
            zone = Number(!param);
          }
          break;
        case "contractor":
          contractor = Number(!param);
          break;
        case "department":
          department = Number(!param);
          break;
        case "step_2":
          step_2_detail.parameter_value = Number(!param);
          break;
        case "step_3":
          step_3_detail.parameter_value = Number(!param);
          break;
        case "step_4":
          step_4_detail.parameter_value = Number(!param);
          break;
        default:
          break;
      }

      return {
        step_2_detail,
        step_3_detail,
        step_4_detail,
        service,
        hazard,
        observation,
        Incident_date,
        Incident_time,
        person,
        person_id,
        employee_search,
        zone,
        location,
        department,
        contractor,
      };
    });
  };

  _handleStep2Field = async (parameter) => {
    await this.setState((prevState) => {
      let { step_2_detail } = prevState;
      try {
        let fieldIndex = step_2_detail.step_setting.findIndex(
          (item) => item.parameter == parameter
        );
        let fieldObject = step_2_detail.step_setting[fieldIndex];
        step_2_detail.step_setting[
          fieldIndex
        ].parameter_value = fieldObject.parameter_value = Number(
          !fieldObject.parameter_value
        );
        step_2_detail.step_setting[fieldIndex] = fieldObject;
      } catch (error) {
        console.log(error);
      }
      return { step_2_detail };
    });
  };

  _handleStep3Field = async (parameter) => {
    await this.setState((prevState) => {
      let { step_3_detail } = prevState;
      try {
        let fieldIndex = step_3_detail.step_setting.findIndex(
          (item) => item.parameter == parameter
        );
        let fieldObject = step_3_detail.step_setting[fieldIndex];
        step_3_detail.step_setting[
          fieldIndex
        ].parameter_value = fieldObject.parameter_value = Number(
          !fieldObject.parameter_value
        );
        step_3_detail.step_setting[fieldIndex] = fieldObject;
      } catch (error) {
        console.log(error);
      }
      return { step_3_detail };
    });
  };

  _handleStep4Field = async (parameter) => {
    await this.setState((prevState) => {
      let { step_4_detail } = prevState;
      try {
        let fieldIndex = step_4_detail.step_setting.findIndex(
          (item) => item.parameter == parameter
        );
        let fieldObject = step_4_detail.step_setting[fieldIndex];
        step_4_detail.step_setting[
          fieldIndex
        ].parameter_value = fieldObject.parameter_value = Number(
          !fieldObject.parameter_value
        );
        step_4_detail.step_setting[fieldIndex] = fieldObject;
      } catch (error) {
        console.log(error);
      }
      return { step_4_detail };
    });
  };

  _checkIsMandatoryField = (paramName) => {
    return [
      "injured_parts",
      "add_witness",
      "contributing_factor",
      "categories_contributing_factor",
    ].includes(paramName);
  };

  _showConfirmPopup = async () => {
    await this.setState({ showConfirmPopup: true });
  };

  _hideConfirmPopup = async () => {
    await this.setState({ showConfirmPopup: false });
  };

  render() {
    let {
      step_1_detail,
      step_2_detail,
      step_3_detail,
      step_4_detail,
      step_5_detail,
    } = this.state;

    return (
      <div className="profile">
        
        <LoadingView showLoading={this.state.dataLoading} />
        <div className="page-full tour1">
          <div className="profile-top nm-top-head">
          <div className="d-flex-center">
            <h2>
              <Backbutton />
              IRA Reporting Form Setting
            </h2>
            <TourIRA />
            </div>
            <div className="nm-right-btn">
              <Button
                variant="outline-primary"
                className="nm-back-btn"
                onClick={this._handleSubmitConfirmation}
              >
                SAVE
              </Button>
              {/* <Button variant="outline-primary" className="nm-back-btn">
                BACK TO HOME
              </Button> */}
            </div>
          </div>
          <div className="nm-content">
            <div className="nm-left-content">
              <div className="rd-box ira1">
                <div className="rd-box-head">
                  <h3>Reporting Parameter</h3>
                </div>
                <div className="rd-box-bottom">
                  <div className="label">
                    Select checkbox for mandatory options
                  </div>
                </div>
                <div className="rd-box-bottom">
                  <Row className="row-gap">
                    {/* <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">Hazard
                          <input name="field.mandatory" type="checkbox"  checked={this.state.hazard} onClick={() => this._settingCheck2(this.state.hazard, 2)}/>
                          <span className="checkmark1"></span>
                        </label>
                      </div>
                    </Col> */}

                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Incident Date
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.Incident_date}
                            // onClick={() =>
                            //   this._settingCheck2(
                            //     this.state.Incident_date,
                            //     "incident_date"
                            //   )
                            // }
                            disabled
                          />
                          <span className="checkmark1 disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Incident Time
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.Incident_time}
                            // onClick={() =>
                            //   this._settingCheck2(
                            //     this.state.Incident_time,
                            //     "incident_time"
                            //   )
                            // }
                            disabled
                          />
                          <span className="checkmark1 disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Person
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.person}
                            onClick={() =>
                              this._settingCheck2(this.state.person, "person")
                            }
                          />
                          <span className="checkmark1"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          ID
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.person_id}
                            onClick={() =>
                              this._settingCheck2(this.state.person_id, "person_id")
                            }
                          />
                          <span className="checkmark1"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Employee Search
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.employee_search}
                            onClick={() =>
                              this._settingCheck2(this.state.employee_search, "employee_search")
                            }
                          />
                          <span className="checkmark1"></span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="rd-box ira2">
                <div className="rd-box-head">
                  <h3>Location Based Parameter </h3>
                </div>
                <div className="rd-box-bottom">
                  <div className="label">
                    Select checkbox for mandatory options
                  </div>
                </div>
                <div className="rd-box-bottom">
                  <Row className="row-gap">
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Zone
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.zone}
                            disabled
                          // onClick={() =>
                          //   this._settingCheck2(this.state.zone, "zone")
                          // }
                          />
                          <span className="checkmark1 disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Location
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.location}
                            disabled
                          // onClick={() =>
                          //   this._settingCheck2(
                          //     this.state.location,
                          //     "location"
                          //   )
                          // }
                          />
                          <span className="checkmark1 disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Department
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.department}
                            disabled
                          // onClick={() =>
                          //   this._settingCheck2(
                          //     this.state.department,
                          //     "department"
                          //   )
                          // }
                          />
                          <span className="checkmark1 disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Contractor
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.contractor}
                            disabled
                          // onClick={() =>
                          //   this._settingCheck2(
                          //     this.state.contractor,
                          //     "contractor"
                          //   )
                          // }
                          />
                          <span className="checkmark1 disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="rd-box ira3">
                <div className="rd-box-head">
                  <h3>Steps Parameter </h3>
                </div>
                <div className="rd-box-bottom">
                  <div className="label">
                    Select checkbox for mandatory options
                  </div>
                </div>
                <div className="rd-box-bottom">
                  <Row className="row-gap">
                    <Col sm={3}>
                      <p> Initial Response </p>
                      <div className="feild-group">
                        <label className="check-box">
                          Step 1
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={step_1_detail?.parameter_value || false}
                            // onClick={() =>
                            //   this._settingCheck2(step_1_detail?.parameter_value, 11)
                            // }
                            disabled
                          />
                          <span className="checkmark1 disabled-step" />
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <p> Initial Investigation </p>
                      <div className="feild-group">
                        <label className="check-box">
                          Step 2
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={step_2_detail?.parameter_value || false}
                            // onClick={() =>
                            //   this._settingCheck2(
                            //     step_2_detail?.parameter_value,
                            //     "step_2"
                            //   )
                            // }
                            disabled
                          />
                          <span className="checkmark1 disabled-step" />
                        </label>
                      </div>
                      <div>
                        {step_2_detail.step_setting?.map((item, index) => (
                          <div className="feild-group" key={`sub_field-${index}`}>
                            <label className="check-box">
                              {item.label}
                              <input
                                name="field.mandatory"
                                type="checkbox"
                                checked={item.parameter_value || false}
                                onClick={() =>
                                  this._handleStep2Field(item.parameter)
                                }
                                disabled={this._checkIsMandatoryField(
                                  item.parameter
                                )}
                              />
                              <span
                                className={`checkmark1 ${this._checkIsMandatoryField(item.parameter)
                                  ? "disabled-step"
                                  : ""
                                  }`}
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    </Col>

                    <Col sm={3}>
                      <p> Data gathering </p>
                      <div className="feild-group">
                        <label className="check-box">
                          Step 3
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={step_3_detail?.parameter_value || false}
                            // onClick={() =>
                            //   this._settingCheck2(
                            //     step_3_detail?.parameter_value,
                            //     "step_3"
                            //   )
                            // }
                            disabled
                          />
                          <span className="checkmark1 disabled-step" />
                        </label>
                      </div>
                      <div>
                        {step_3_detail.step_setting?.map((item, index) => (
                          <div className="feild-group" key={`sub_field-${index}`}>
                            <label className="check-box">
                              {item.label}
                              <input
                                name="field.mandatory"
                                type="checkbox"
                                checked={item.parameter_value || false}
                                onClick={() =>
                                  this._handleStep3Field(item.parameter)
                                }
                                disabled={this._checkIsMandatoryField(
                                  item.parameter
                                )}
                              />
                              <span
                                className={`checkmark1 ${this._checkIsMandatoryField(item.parameter)
                                  ? "disabled-step"
                                  : ""
                                  }`}
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col sm={3}>
                      <p> Root Cause Analysis </p>
                      <div className="feild-group">
                        <label className="check-box">
                          Step 4
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={step_4_detail?.parameter_value || false}
                            // onClick={() =>
                            //   this._settingCheck2(
                            //     step_4_detail?.parameter_value,
                            //     "step_4"
                            //   )
                            // }
                            disabled
                          />
                          <span className="checkmark1 disabled-step" />
                        </label>
                      </div>
                      <div>
                        {step_4_detail.step_setting?.map((item, index) => (
                          <div className="feild-group" key={`sub_field-${index}`}>
                            <label className="check-box">
                              {item.label}
                              <input
                                name="field.mandatory"
                                type="checkbox"
                                checked={item.parameter_value || false}
                                onClick={() =>
                                  this._handleStep4Field(item.parameter)
                                }
                                disabled={this._checkIsMandatoryField(
                                  item.parameter
                                )}
                              />
                              <span
                                className={`checkmark1 ${this._checkIsMandatoryField(item.parameter)
                                  ? "disabled-step"
                                  : ""
                                  }`}
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col sm={3}>
                      <p>CAPA</p>
                      <div className="feild-group">
                        <label className="check-box">
                          Step 5
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={step_5_detail?.parameter_value || false}
                            // onClick={() =>
                            //   this._settingCheck2(step_5_detail?.parameter_value, 15)
                            // }
                            disabled
                          />
                          <span className="checkmark1 disabled-step" />
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Row>
                <Col sm={6}>
                  <div className="rd-box ira4">
                    <div className="rd-box-head">
                      <h3>System Default Parameter </h3>
                    </div>
                    <div className="rd-box-bottom">
                      <div className="toggle-label">
                        <div className="output-text margin-right">Activity</div>
                      </div>
                    </div>
                    <div className="rd-box-bottom">
                      <div className="toggle-label">
                        <div className="output-text margin-right">
                          Type of Incident
                        </div>
                      </div>
                    </div>
                    <div className="rd-box-bottom">
                      <div className="toggle-label">
                        <div className="output-text margin-right">
                          Observation
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <Col sm={6}>
                  <div className="rd-box">
                    <div className="rd-box-head">
                      <h3>Evidence </h3>
                    </div>
                    <div className="rd-box-bottom">
                      <div className="label-input1">
                        <div className="output-text margin-top">Number of evidence per report</div>
                        <input type="text" placeholder="Evidence No." className="form-control" value={this.state.evidence_num} onChange={(e) => {
                      this.setState({evidence_num: e.target.value})}
                    }/>
                      </div>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </div>
            <div className="nm-right-content">
              <div className="rd-box ira0">
                <div className="rd-box-head rd-box-head1">
                  <h3>Sample Preview</h3>
                </div>
                <div className="workflow-form">
                  <div className="form-group">
                    <h6>
                      <RequiredText /> Observation
                    </h6>
                    <Mic className="nm-mic" />
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Write your observation here"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <h6>Service & Project</h6>
                    <Row>
                      {/* { this.state.service == 1 && */}
                      <Col sm={6}>
                        <div className="label">
                          <RequiredText />
                          Service
                        </div>
                        <select className="form-control">
                          <option>Select Service</option>
                        </select>
                      </Col>
                      <Col sm={6}>
                        <div className="label">
                          <RequiredText /> Project
                        </div>
                        <select className="form-control">
                          <option>Select Project</option>
                        </select>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <h6>About Incidence</h6>
                    <Row>
                      <Col sm={6}>
                        <div className="form-group">
                          <div className="label">
                            <RequiredText /> Activity
                          </div>
                          <select className="form-control">
                            <option>Select Activity</option>
                          </select>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="form-group">
                          <div className="label">
                            <RequiredText /> Type of Incident
                          </div>
                          <select className="form-control">
                            <option>Select Type of incident</option>
                          </select>
                        </div>
                      </Col>
                      {/* {this.state.hazard == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Hazard</div>
                            <select className="form-control">
                              <option>Select Hazard</option>
                            </select>
                          </div>
                        </Col>
                      )} */}
                      {this.state.Incident_date == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Incident Date</div>
                            <select className="form-control">
                              <option>Select Date</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.Incident_time == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Incident Time</div>
                            <select className="form-control">
                              <option>Select Time</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.person == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Name of Person</div>
                            <select className="form-control">
                              <option>Select Person</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.person_id == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label  ">ID</div>
                            <input type="text" className="form-control font-weight-500" placeholder="Enter Id " />
                          </div>
                        </Col>
                      )}
                      {this.state.employee_search == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Employee Search</div>
                            <select className="form-control">
                              <option>Select Employee</option>
                            </select>
                          </div>
                        </Col>
                      )}

                    </Row>
                  </div>
                  <div>
                    <h6>Department/Contractor/Location</h6>
                    <Row>
                      {this.state.department == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Department</div>
                            <select className="form-control">
                              <option>Select Department</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.contractor == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Contractor</div>
                            <select className="form-control">
                              <option>Select Contractor</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.zone == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Zone</div>
                            <select className="form-control">
                              <option>Select Zone</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.location == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Location</div>
                            <select className="form-control">
                              <option>Select Location</option>
                            </select>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div>
                    <h6>Steps</h6>
                    <Row>
                      {this.state.step_1 == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Step 1</div>
                          </div>
                        </Col>
                      )}
                      {this.state.step_2 == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Step 2</div>
                          </div>
                        </Col>
                      )}
                      {this.state.step_3 == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Step 3</div>
                          </div>
                        </Col>
                      )}
                      {this.state.step_4 == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Step 4</div>
                          </div>
                        </Col>
                      )}
                      {this.state.step_5 == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Step 5</div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          centered
          show={this.state.workFlowPopup}
          className="submit-modal"
        >
          <Modal.Header
            closeButton
            onClick={() => this.WfModal(false)}
          ></Modal.Header>

          <Modal.Body>
            {/* <img className="submit-img" src={require('assets/images/delete-popup.svg')} /> */}
            <p className="submit-text">UPDATED SUSSESSFULLY</p>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={() => {
                  this._IRAWorkFlowView();
                  this.WfModal(false);
                }}
              >
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <ConfirmModal
          showConfirmPopup={this.state.showConfirmPopup}
          _handleCancel={this._hideConfirmPopup}
          _handleConfirm={this._IRAWorkFlow}
        />
      </div>
    );
  }
}
