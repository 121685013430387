// const PageOne = (data, logo) => {
//   return `
//     <table cellpadding="0" cellspacing="0" width="800" style="margin: 0 auto; text-align: center; font-family: Arial, sans-serif; border: none; height: 98vh; position: relative;">
//       <tr>
//         <td class="container">
//           <style>
//             .container {
//               display: flex;
//               flex-direction: column;
//               justify-content: center;
//               align-items: center;
//               height: 100%;
//               padding: 20px; /* Adjust padding as needed */
//             }
//             h1, h2, h3, h4, h5, h6 {
//               margin: 0;
//               font-size: 22px;
//               line-height: 1.4;
//             }
//             h1 {
//               font-size: 35px;
//             }
//             .heading {
//               font-weight: bold;
//               margin-top: 10px;
//             }
//             img {
//               height: 180px;
//               width: 180px;
//               margin-bottom: 20px;
//             }
//           </style>
          
//           <img src="${logo}">
//           <h1>${data.mainheading || 'N/A'}</h1>
//           <h2>${data.heading || 'N/A'}</h2>
//           <h3 class="heading">Activity</h3>
//           <h4 class="heading">Management</h4>
//           <span class="page-number"></span>
//         </td>
//       </tr>
//     </table>
//   `;
// };

// export default PageOne;

const PageOne = (data, logo) => {
  return `
    <table cellpadding="0" cellspacing="0" width="800" style="margin: 0 auto; text-align: center; font-family: Arial, sans-serif; border: 3px double #797878; height: 98vh; position: relative;">
      <tr>
        <td class="container" style="padding: 20px;">
          <style>
            .container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
            }
            h1, h2, h3, h4, h5, h6 {
              margin: 0;
              font-size: 22px;
              line-height: 1.4;
            }
            h1 {
              font-size: 35px;
            }
            .heading {
              font-weight: bold;
              margin-top: 10px;
            }
            img {
              height: 180px;
              width: 180px;
              margin-bottom: 20px;
            }
          </style>
          
          <img src="${data.logo}" alt="Logo">
          <h1>${data.mainheading || 'N/A'}</h1>
          <h2>${data.heading || 'N/A'}</h2>
          <h3 class="heading">Activity</h3>
          <h4 class="heading">Management</h4>
          <span class="page-number"></span>
        </td>
      </tr>
    </table>
  `;
};

export default PageOne;
