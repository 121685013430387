import React from "react";
import { integerRegex } from "../constants/regex";
import { OverlayTrigger, Popover, Container } from "react-bootstrap";
import { Popover as SLDPopver } from "@salesforce/design-system-react";
//assets
import { TooltipInfoIcon,TooltipInfoIconLarge } from "assets/Iconsv2/Iconsv2";

export function isIntegerNumber(value = 0) {
  if (integerRegex.test(value)) {
    return true;
  } else {
    return false;
  }
}

export function getIntegeOnly(event) {
  const { value } = event?.target;
  let finalString = "";
  if (value === "-") {
    finalString = "";
  }
  if (value === "0" || value === "" || isIntegerNumber(value)) {
    finalString = value;
  }
  return finalString;
}

export function renderToolTip(
  content = "",
  placement = "right",
  toolTipStyle = { width: "15px" },
  type = 2,
  title = "Info",
  isSmallIcon = true
) {
  if (type === 2) {
    return (
      <SLDPopver
        body={content}
        heading={<div className="clide-v2-primary-color border-bottom pb-2">{title}</div>}
        align={placement}
        id="popover-heading"
        position="overflowBoundaryElement"
      >
        <button className="clide-v2-icon-btn">
          {isSmallIcon ? <TooltipInfoIcon /> : <TooltipInfoIconLarge /> }
        </button>
      </SLDPopver>
    );
  }
  if (type === 1) {
    return (
      <OverlayTrigger
        trigger="hover"
        placement={placement}
        rootClose={true}
        overlay={
          <Popover
            id="popover-positioned-bottom"
            style={{
              width: "13rem",
              borderRadius: "10px",
              minHeight: "1.5rem",
            }}
          >
            <Container>
              <h2 className=" my-2 clide-v2-fs-5 "> {content}</h2>
            </Container>
          </Popover>
        }
      >
        <button className="clide-v2-icon-btn">
          <TooltipInfoIcon />
        </button>
      </OverlayTrigger>
    );
  }
}
