import React from "react";
import LoaderImage from "../../../../assets/images/loader1.gif";
import ClideLoader from "../../ui-kit/loader";
const LoadingView = ({ showLoading }) => {
  return (
    <>
      {showLoading ? (
        <ClideLoader />
      ) : null}
    </>
  );
};

export default LoadingView;
