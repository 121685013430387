const PageTen = ( data ) => {

  let paperHTML = "<tr>";
  let positionHTML = '<tr>';

  try {
    data.paper_digital.forEach((item, index) => {
      if (index + 1 != 1 && (index + 1) % 2 == 1) {
        paperHTML += "<tr>";
      }
      paperHTML += `
      <td valign="top">
        <div><img src="${item.image_link}" style="width:232px;height: 150px;border:1px solid #ddd;padding: 10px"></div>
        <span style="margin-top:5px">${item.image_description || ''}</span>
      </td>
    `;
    if ((index + 1) % 2 == 0) {
      paperHTML += "</tr>";
    }
    });
  } catch (error) {}
  
  try {
    data.position_equipment.forEach((item, index) => {
      if (index + 1 != 1 && (index + 1) % 2 == 1) {
        positionHTML += "<tr>";
      }
      positionHTML += `<td valign="top">
        <div>
        <img src="${item.image_link}" style="width:232px;height: 150px;border:1px solid #ddd;padding: 10px"></div>
        <span style="margin-top:5px">${item.image_description || ''}</span>
      </td>
    `;
    if ((index + 1) % 2 == 0) {
      positionHTML += "</tr>";
    }
    });
  } catch (error) {}

return (`<table cellpadding="15" cellspacing="0" width="800" style="margin: 0 auto 10px auto;font-family: arial;height: 100vh;font-size: 14px;border: 3px double #797878;position:relative" class="full-table">
<tr>
  <td colspan="2" valign="top" class="inner-table">
    <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Paper Digital Evidences</h4>
    <hr />
    <table width="100%" style="text-align: center;"  cellpadding="10">
      ${paperHTML}
    </table>
    <br />
    <br />
    <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Position & Equipments Evidences</h4>
    <hr />
    <table width="100%" style="text-align: center;" cellpadding="10">
      ${positionHTML}
    </table>
    <span class="page-number" />
  </td>
</tr>
</table>`);
}

export default PageTen;
