import React from "react";
import { toast } from "react-toastify";

const alertStyle = (callbackFn) => ({
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  onClose: () => (callbackFn ? callbackFn() : null),
});

export const SuccessToast = (message, callBackHandler = null) => {
  const style = alertStyle(callBackHandler);
  toast.success(message, style);
};

export const ErrorToast = (message, callBackHandler = null) => {
  const style = alertStyle(callBackHandler);
  toast.error(message, style);
};

export const WarningToast = (message, callBackHandler = null) => {
  const style = alertStyle(callBackHandler);
  toast.warning(message, style);
};
