import React from "react";
import Highcharts, { charts } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import CountGraph from "app/views/Graphs/Graphs/CountGraph";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Row, Col, Container, Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";
import { connect } from "react-redux";
//components
import UaUcGraph from "app/shared/components/PredictiveAnalysis/UaUcGraph";
import ClideLoader from "app/shared/ui-kit/loader";
//constants & configs
import { config } from "config.js";
import { nmContent } from "../constants/nmConstant";
import Url from "app/shared/constants/Url";
//redux action types
import { CHANGE_TAB } from "app/redux/constant/ActionType";
import { Scrollbars } from "react-custom-scrollbars";
import UaUcHalfGraph from "app/shared/components/PredictiveAnalysis/UaUcHalfGraph";
import HalfCountGraph from "app/views/Graphs/Graphs/HalfCountGraph";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
const qs = require("qs");

//redux variables
const mapStateToProps = (state) => {
  const data = { ...state.nm.nm };
  return {
    service_id: data.service_id,
    project_id: data.project_id,
    service_name: data.service_name,
    project_name: data.project_name,
    start_date: data.start_date,
    end_date: data.end_date,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTab: (tabName) => {
      dispatch({ type: CHANGE_TAB, payload: tabName });
    },
  };
};

class NMDashboardV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...nmContent.dashboardPageState,
    };
    this._getService();
  }
  // defined function
  componentDidMount() {
    this.nmDashboard();
    if (this.state?.userInfo?.designation_flag == 4) {
      this._getZoneRights();
    } else {
      this.setState({
        zoneStatus: 1,
        locationStatus: 1,
        departmentStatus: 1,
        contractorStatus: 1,
      });
    }
    this.setState({ isFirstFetching: true });
  }

  _getZoneRights = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let URL = Url.soiDashboard.dashboard.zoneRights;

    let requestData = {
      auth_key: token,
      user_id: userId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(URL, qs.stringify(requestData), ContentType)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            zoneStatus: response.data.zone_right,
            locationStatus: response.data.location_right,
            departmentStatus: response.data.dept_right,
            contractorStatus: response.data.contractor_right,
          });
        }
      })
      .catch((error) => {});
  };

  // componentDidMount() {
  //   this.nmDashboard();
  //   this._getService();
  //   this._getProject();
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.project_id !== prevState.project_id ||
      nextProps.service_id !== prevState.service_id ||
      nextProps.start_date != prevState.start_date ||
      nextProps.end_date != prevState.end_date
    ) {
      return {
        service_id: nextProps.service_id,
        project_id: nextProps.project_id,
        start_date: nextProps.start_date,
        end_date: nextProps.end_date,
      };
    }
    return null;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.service_id !== prevProps.service_id ||
      this.props.project_id !== prevProps.project_id ||
      this.props.start_date != prevProps.start_date ||
      this.props.end_date != prevProps.end_date
    ) {
      // after redux store update function get excuted, to prevent first time call added condition
      if (this.state.isFirstFetching) {
        await this.setState({ isLoading: true });
        await this.nmDashboard();
      } else {
        await this.setState({ isFirstFetching: true });
      }
    }
  }

  nmDashboard = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();
    if (this.state.project_id && this.state.project_id.length > 0) {
      this.state.project_id.forEach((item) => {
        data.append("project_id", item);
      });
    }

    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("start_date", this.props.start_date);
    data.append("end_date", this.props.end_date);

    let URL = Url.nmModule.nmdashboard;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then(async (response) => {
        if (response.data.status === 200) {

          const {
            status,
            severity,
            uauc,
            category,
            contributing_factor,
            department_count,
            contractorWiseNmCount,
            locationWiseNMCount,
            zoneWiseNMCount,
            immediate_factor,
            rca_factor,
            service_severity_graph,
            type_of_act_graph,
            project_severity_graph,
            month_wise_nm_reported,
          } = response.data.data;

          await this.setState({
            total_data: response.data.data,
            status: status || {},
            uauc: uauc || {},
            category: category || {},
            contributing_factor: contributing_factor,
            department_count: department_count,
            contractorWiseNmCount: contractorWiseNmCount,
            locationWiseNMCount: locationWiseNMCount,
            zoneWiseNMCount: zoneWiseNMCount,
            immediate_factor: immediate_factor,
            rca_factor: rca_factor,
            severity: severity,
            serviceSeverityGraph: service_severity_graph,
            LocationSeverityGraph: project_severity_graph,
            month_wise_nm_reported: month_wise_nm_reported,
            resetServiceSeverityGraph: service_severity_graph,
            activitySeverityGraph: type_of_act_graph,
            resetLocationSeverityGraph: project_severity_graph,
            reset_month_wise_nm_reported: month_wise_nm_reported,
            isLoading: false,
          });
        }
        this.setState({ dataLoading: false, isLoading: false, });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  serviceseveriyFilter = async () => {
    await this.setState({
      ServiceSeverityPopup: false,
      dataLoading: true,
    });

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const serviceValue =
      this.state.selectedOption != null &&
      this.state.selectedOption.map((item) => item.service_id);
    const severityValue =
      this.state.selectedOptionseverity != null &&
      this.state.selectedOptionseverity.map((item) => item.value);
    const data = {
      auth_key: token,
      user_id: userId,
      severity: severityValue,
      service_id: serviceValue,
    };
    await axios
      .post(
        Url.nmModule.serviceandseverityfilter,
        qs.stringify(data, { arrayFormat: "repeat" }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            serviceSeverityGraph: response.data.data.service_severity_graph,
            isLoading: false,
          });
        }
        // this.resetState();
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  projectseveriyFilter = async () => {
    await this.setState({
      ProjectSeverityPopup: false,
      dataLoading: true,
    });

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const projectValue =
      this.state.selectedOptionproject != null &&
      this.state.selectedOptionproject.map((item) => item.project_id);
    const severityValue = this.state.selectedProjectFilterOptionseverity
      ? this.state.selectedProjectFilterOptionseverity.map((item) => item.value)
      : 0;

    await axios
      .post(
        Url.nmModule.projectandseverityfilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            severity: severityValue,
            project_id: projectValue,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            LocationSeverityGraph: response.data.data.project_severity_graph,
            isLoading: false,
          });
        }
        // this.resetState();
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  monthwiseFilter = async () => {
    await this.setState({
      monthwisePopup: false,
      dataLoading: true,
    });

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    await axios
      .post(
        Url.nmModule.monthwisereportfilter,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          start_month: this.state.start_month,
          start_year: this.state.start_year,
          end_month: this.state.end_month,
          end_year: this.state.end_year,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            month_wise_nm_reported: response.data.data.month_wise_nm_reported,
            isLoading: false,
          });
        }
        // this.resetState();
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  ServiceSeverity = (visible) => {
    this.setState({ ServiceSeverityPopup: visible });
  };

  ActivitySeverity = (visible) => {
    this.setState({ ActivitySeverityPopup: visible });
  };

  ProjectSeverity = (visible) => {
    this.setState({ ProjectSeverityPopup: visible });
  };

  Monthwise = (visible) => {
    this.setState({ monthwisePopup: visible });
  };

  resetState() {
    this.setState({
      selectedOption: null,
      selectedOptionseverity: null,
      selectedOptionproject: null,
      plantProjectFilterSelectedService: null,
      start_month: null,
      start_year: null,
      end_month: null,
      end_year: null,
    });
  }

  handleChanges = (selectedOptions) => {
    this.setState({ selectedOptions });
  };

  handleChange = async (selectedOption) => {
    await this.setState({ selectedOption });
    // await this._getProject(selectedOption);
  };

  handleProjectFilterServiceChange = async (
    plantProjectFilterSelectedService
  ) => {
    await this.setState({ plantProjectFilterSelectedService });
    await this._getProject(plantProjectFilterSelectedService);
  };

  handleChangeproject = (selectedOptionproject) => {
    this.setState({ selectedOptionproject });
  };

  handleChangeseverity = (selectedOptionseverity) => {
    this.setState({ selectedOptionseverity });
  };

  _handleProjectFilterChangeseverity = (
    selectedProjectFilterOptionseverity
  ) => {
    this.setState({ selectedProjectFilterOptionseverity });
  };

  _getService = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.serviceList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ service: response.data.data, dataLoading: false });
        }
        // console.log(this.state.service ? this.state.service : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _getProject = async (serviceId = this.state.selectedOption) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const serviceValue = serviceId.service_id;
    // this.state.selectedOption != null && this.state.selectedOption.service_id;
    // console.log('eee', this.state.selectedOption)
    await axios
      .post(
        Url.projectList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: serviceValue,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ project: response.data.data, dataLoading: false });
        }
        // console.log('result: ',this.state.project)
      })
      .catch((error) => {
        throw error;
      });
  };

  monthselect = (val) => {};

  resetMonthWiseReportFilter = async () => {
    this.Monthwise(false);

    await this.setState((prevState) => {
      let {
        month_wise_nm_reported,
        reset_month_wise_nm_reported,
        start_month,
        start_year,
        end_month,
        end_year,
      } = prevState;

      month_wise_nm_reported = [...reset_month_wise_nm_reported];
      start_month = null;
      start_year = null;
      end_month = null;
      end_year = null;

      return {
        month_wise_nm_reported,
        reset_month_wise_nm_reported,
        start_month,
        start_year,
        end_month,
        end_year,
      };
    });
  };

  _handleResetServiceseveriyFilter = async () => {
    this.ServiceSeverity(false);

    await this.setState((prevState) => {
      let {
        serviceSeverityGraph,
        resetServiceSeverityGraph,
        selectedOptionseverity,
        selectedOption,
      } = prevState;

      serviceSeverityGraph = [...resetServiceSeverityGraph];
      selectedOptionseverity = null;
      selectedOption = null;

      return {
        serviceSeverityGraph,
        resetServiceSeverityGraph,
        selectedOptionseverity,
        selectedOption,
      };
    });
  };

  _handleResetProjectSeverityGraphFilter = async () => {
    this.ProjectSeverity(false);

    await this.setState((prevState) => {
      let {
        resetLocationSeverityGraph,
        LocationSeverityGraph,
        selectedProjectFilterOptionseverity,
        selectedOptionproject,
        plantProjectFilterSelectedService,
      } = prevState;

      LocationSeverityGraph = [...resetLocationSeverityGraph];
      selectedProjectFilterOptionseverity = null;
      selectedOptionproject = null;
      plantProjectFilterSelectedService = null;

      return {
        resetLocationSeverityGraph,
        LocationSeverityGraph,
        selectedProjectFilterOptionseverity,
        selectedOptionproject,
        plantProjectFilterSelectedService,
      };
    });
  };

  CountDoughnut = (data, total, height, fontSize) => {
    return data.length > 0 ? (
      <div style={{ justifyContent: "center" }}>
        <UaUcGraph
          title=""
          graphData={data}
          total={total}
          height={height ?? 150}
          customTitles={{ useHTML: true }}
          list={false}
          cutout={"82%"}
          radius={"80%"}
          fontSize={"1.9rem"}
          fontWeight={500}
        />
      </div>
    ) : null;
  };

  CountDoughnutHalf = (data, total, height, fontSize) => {
    return data.length > 0 ? (
      <div style={{ justifyContent: "center" }}>
        <UaUcHalfGraph
          title=""
          graphData={data}
          total={total}
          height={height ?? 150}
          customTitles={{ useHTML: true }}
          list={false}
          cutout={"82%"}
          radius={"90%"}
          fontSize={"1.5rem"}
          fontWeight={500}
        />
      </div>
    ) : null;
  };

  // definded end
  render() {
    const {t} = this.props;
    //render variables & graph variables
    console.log("this", this);

    const {
      selectedOption,
      selectedOptionproject,
      selectedOptionseverity,
      status,
      severity,
      uauc,
      selectedProjectFilterOptionseverity,
      isLoading,
    } = this.state;
    if (isLoading) {
      return <ClideLoader />;
    }
    const totalstatus = status.total_nm || "0";
    let statusGraphValue = [];
    let chartData = [];
    if (Object.values(status).length) {
      chartData = [
        { color: "#FF7070", y: status.pending, name: "pending" },
        { color: "#23C6D0", y: status.inprogress, name: "inprogress" },
        { color: "#23D053", y: status.completed, name: "completed" },
      ];
      statusGraphValue = [
        ["pending", status.pending || 0],
        ["inprogress", status.inprogress || 0],
        ["completed", status.completed || 0],
      ];
    }

    const colors = this.state?.allGraphs?.uauc_barrier_wise?.map((data) => {
      chartData.push({
        color: data.color,
        y: data.count,
        name: data.barrier,
      });
      return { color: data.color, y: data.count, name: data.barrier };
    });
    const chart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          totalstatus +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: ["#FF7070", "#23C6D0", "#23D053"],
      series: [
        {
          data: statusGraphValue,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    // checking values
    let totalseverity = "";
    let severityGraphValue = [];
    if (Object.values(severity).length) {
      totalseverity =
        Number(severity.extreme_number || 0) +
        Number(severity.high_number || 0) +
        Number(severity.low_number || 0) +
        Number(severity.medium_number || 0);

      severityGraphValue = [
        ["extreme", severity.extreme_number],
        ["high", severity.high_number],
        ["medium", severity.medium_number],
        ["low", severity.low_number],
      ];
    }

    const severityGraphData = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          totalseverity +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: [
        severity.extreme_color,
        severity.high_color,
        severity.medium_color,
        severity.low_color,
      ],
      series: [
        {
          data: severityGraphValue,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    // const colors  = this.state?.allGraphs?.uauc_barrier_wise?.map(
    //     (data) =>({y:data.count, name : data.open_label})
    //   );

    const uaucChartData = [
      { color: uauc.resolve_color, name: "UC", y: uauc.resolve_number },
      { color: uauc.open_color, name: "UA", y: uauc.open_number },
    ];

    const uaucGraphData = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          totalstatus +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: [uauc.resolve_color, uauc.open_color],
      series: [
        {
          data: [uauc.resolve_number, uauc.open_number],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    const category_totalIra = this.state?.total_data?.total_category
      ? this.state.total_data.total_category
      : "0";

    console.log(
      "new",
      this.state.total_data.category.map((item) => item.category_number)
    );
    const CAPA = this.state.total_data.category.length > 0 ? this.state.total_data.category.map((data) => ({
      color: data.category_color,
      y: data.category_number,
      name: data.category_name,
    })) : [{}];
    // const CAPA  = this.state?.allGraphs?.uauc_barrier_wise?.map(
    //   (data) =>({color : data.color ,y:data.count, name : data.barrier})
    // );

    const category = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          category_totalIra +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.total_data.category.map((item) => item.category_color),
      series: [
        {
          data: CAPA,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };
    const cfcolour = this.state.contributing_factor.length > 0 ? this.state.contributing_factor.map((data) => ({
      color: data.factor_color,
      name: data.factor_name,
      y: data.factor_value,
    })) : [{}];
    const cfvalue = this.state.contributing_factor.map(
      (data) => data.factor_value
    );
    const totalCFValue = this.state.total_data.total_contributing_factor || "0";

    const cfGraphData = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          totalCFValue || 0 +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      // colors: cfcolour,
      series: [
        {
          data: cfcolour,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    const ifcolour = this.state.immediate_factor.length > 0 ? this.state.immediate_factor.map((data) => ({
      color: data.factor_color,
      name: data.factor_name,
      y: data.factor_value,
    })) : [{}];
    const ifvalue = this.state.immediate_factor.map(
      (data) => data.factor_value
    );

    const totalIFValue = this.state.total_data.total_immediate_factor || "0";

    const IFactor = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          totalIFValue +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: ifcolour,
      series: [
        {
          data: ifcolour,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    const rcacolour = this.state.rca_factor.length > 0 ? this.state.rca_factor.map((data) => ({
      color: data.factor_color,
      y: data.factor_value,
      name: data.factor_name,
    })) : [{}];
    const rcavalue = this.state.rca_factor.map((data) => data.factor_value);

    const rcaTotalValue = this.state.total_data.total_rca_factor || "0";

    const RCA = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          rcaTotalValue +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: rcacolour,
      series: [
        {
          data: rcacolour,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //department
    const dpcolour = this.state.department_count.length > 0 ? this.state.department_count.map((data) => ({
      color: data.color,
      y: data.count,
      name: data.department,
    })) : [{}];
    const dpvalue = this.state.department_count.map((data) => data.count);
    const totalDPValue = this.state.total_data.total_department_count || "0";

    const dpGraphData = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          totalDPValue +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: dpcolour,
      series: [
        {
          data: dpcolour,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //contractor
    const concolour = this.state.contractorWiseNmCount.length > 0 ? this.state.contractorWiseNmCount.map((data) => ({
      color: data.color,
      y: data.count,
      name: data.contractor,
    })) : [{}];
    const convalue = this.state.contractorWiseNmCount.map((data) => data.count);
    const totalContractorValue =
      this.state.total_data.total_contractorWiseNmCount || "0";

    const ContractorGraph = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          totalContractorValue +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: concolour,
      series: [
        {
          data: concolour,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //location
    const lcolour = this.state.locationWiseNMCount.length > 0 ? this.state.locationWiseNMCount.map((data) => ({
      color: data.color,
      y: data.count,
      name: data.location,
    })) : [{}];
    const lvalue = this.state.locationWiseNMCount.map((data) => data.count);
    const totalLocationValue =
      this.state.total_data.total_locationWiseNMCount || "0";

    const LocationGraph = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          totalLocationValue +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: lcolour,
      series: [
        {
          data: lcolour,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //zone
    const zoncolour = this.state.zoneWiseNMCount.length > 0 ? this.state.zoneWiseNMCount.map((data) => ({
      color: data.color,
      y: data.count,
      name: data.zone,
    })) : [{}];
    const zonvalue = this.state.zoneWiseNMCount.map((data) => data.count);
    const totalZoneValue = this.state.total_data.total_zoneWiseNMCount || "0";

    const ZoneGraph = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          totalZoneValue +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: zoncolour,
      series: [
        {
          data: zoncolour,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    // bar graph
    var SEC = this.state.serviceSeverityGraph
      .filter((item) => item.extreme_color)
      .map((item) => item.extreme_color);
    var SHC = this.state.serviceSeverityGraph
      .filter((item) => item.high_color)
      .map((item) => item.high_color);
    var SLc = this.state.serviceSeverityGraph
      .filter((item) => item.low_color)
      .map((item) => item.low_color);
    var SMC = this.state.serviceSeverityGraph
      .filter((item) => item.medium_color)
      .map((item) => item.medium_color);
    const service_name = this.state.serviceSeverityGraph.map(
      (item) => item.service_name
    );
    let gLength = service_name.length;
    for (let index = 0; index < gLength; index++) {
      var Data1 = this.state.serviceSeverityGraph.map(
        (item) => item.extreme_num
      );
      var Data2 = this.state.serviceSeverityGraph.map((item) => item.high_num);
      var Data4 = this.state.serviceSeverityGraph.map((item) => item.low_num);
      var Data3 = this.state.serviceSeverityGraph.map(
        (item) => item.medium_num
      );
    }

    const ssgraphData = {
      labels: service_name,
      datasets: [
        {
          label: "High Critical",
          backgroundColor: [SEC[0]],
          data: Data1,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },
        {
          label: "High",
          backgroundColor: [SHC[0]],
          data: Data2,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },
        {
          label: "Medium",
          backgroundColor: [SMC[0]],
          data: Data3,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },
        {
          label: "Low",
          backgroundColor: [SLc[0]],
          data: Data4,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },
      ],
    };
    const ssgraph = {
      chart: {
        type: "column",
        height: "30%",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: service_name,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Number",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: false,
      },
      colors: [SEC[0], SHC[0], SMC[0], SLc[0]],
      series: [
        {
          name: "High Critical",
          data: Data1,
        },
        {
          name: "High",
          data: Data2,
        },
        {
          name: "Medium",
          data: Data3,
        },
        {
          name: "Low",
          data: Data4,
        },
      ],
    };
    const barGraphOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Number",
          position: "left",
          align: "center",
          font: {
            weight: 300,
            lineHeight: 1.9,
            family: `"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif`,
            size: 12,
          },
        },
        tooltip: {
          backgroundColor: "white",
          bodyColor: "#666666",
          bodyFont: {
            weight: 500,
          },
          borderColor: "rgba(0, 0, 0, 0.08)",
          borderWidth: 2,
          cornerRadius: 4,
          bodySpacing: 4,
          padding: {
            top: -10,
            bottom: 8,
            left: 0,
            right: 0,
          },
          bodyAlign: "center",
          displayColors: false,
          titleColor: "transparent",
        },
        datalabels: {
          display: true,
          align: "top",
          anchor: "end",
          color: "black",
        },
      },
      scales: {
        x: {
          display: true,
          offset: true,
          grid: {
            display: false,
          },
          ticks: {
            color: "#8c8c8b",
            font: {
              size: 8,
              weight: "500",
            },
            padding: 0,
          },
        },
        y: {
          display: true,
          beginAtZero: true,
          grid: {
            color: "#ececec",
          },
          ticks: {
            color: "#8c8c8b",
            font: {
              size: 10,
              weight: "400",
            },
            padding: 0,
          },
        },
      },
    };
    const rskLevelTitle = "Risk Level";

    const rskData = {
      labels: ["High Critical", "High", "Medium", "Low"],
      datasets: [
        {
          label: "",
          barThickness: 33,
          backgroundColor: [SEC[0], SHC[0], SMC[0], SLc[0]],
          data: [
            severityGraphValue[0][1],
            severityGraphValue[1][1],
            severityGraphValue[2][1],
            severityGraphValue[3][1],
          ],
        },
      ],
    };
    const rskgraph = {
      chart: {
        type: "column",
        height: "55%",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: rskLevelTitle,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Number",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px"></span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: false,
      },
      colors: [SEC[0], SHC[0], SMC[0], SLc[0]],
      series: [
        {
          name: "High Critical",
          data: [severityGraphValue[0][1]],
        },
        {
          name: "High",
          data: [severityGraphValue[1][1]],
        },
        {
          name: "Medium",
          data: [severityGraphValue[2][1]],
        },
        {
          name: "Low",
          data: [severityGraphValue[3][1]],
        },
      ],
    };

    //type of Activity
    var SEC = this.state.activitySeverityGraph
      .filter((item) => item.ua_color)
      .map((item) => item.ua_color);
    var SHC = this.state.activitySeverityGraph
      .filter((item) => item.uc_color)
      .map((item) => item.uc_color);

    const activity_name = this.state.activitySeverityGraph.map(
      (item) => item.activity_name
    );
    let gLength1 = activity_name.length;
    for (let index = 0; index < gLength; index++) {
      var Data1 = this.state.activitySeverityGraph.map((item) => item.ua_num);
      var Data2 = this.state.activitySeverityGraph.map((item) => item.uc_num);
    }

    const activitygraphData = {
      labels: activity_name,
      datasets: [
        {
          label: "UA",
          backgroundColor: [SEC[0]],
          data: Data1,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },

        {
          label: "UC",
          backgroundColor: [SHC[0]],
          data: Data2,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },
      ],
    };
    const Activitygraph = {
      chart: {
        type: "column",
        height: "30%",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: activity_name,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Number",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: false,
      },
      colors: [SEC[0], SHC[0]],
      series: [
        {
          name: "UA",
          data: Data1,
        },
        {
          name: "UC",
          data: Data2,
        },
      ],
    };

    var PEC = this.state.LocationSeverityGraph.filter(
      (item) => item.extreme_color
    ).map((item) => item.extreme_color);
    var PHC = this.state.LocationSeverityGraph.filter(
      (item) => item.high_color
    ).map((item) => item.high_color);
    var PLc = this.state.LocationSeverityGraph.filter(
      (item) => item.low_color
    ).map((item) => item.low_color);
    var PMC = this.state.LocationSeverityGraph.filter(
      (item) => item.medium_color
    ).map((item) => item.medium_color);
    const project_name = this.state.LocationSeverityGraph.map(
      (item) => item.project_name
    );
    let graphLength = project_name.length;
    for (let index = 0; index < graphLength; index++) {
      var Data5 = this.state.LocationSeverityGraph.map(
        (item) => item.extreme_num
      );
      var Data6 = this.state.LocationSeverityGraph.map((item) => item.high_num);
      var Data7 = this.state.LocationSeverityGraph.map(
        (item) => item.medium_num
      );
      var Data8 = this.state.LocationSeverityGraph.map((item) => item.low_num);
    }

    const locationGraphData = {
      labels: project_name,
      datasets: [
        {
          label: "High Critical",
          backgroundColor: [PEC[0]],
          data: Data5,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },
        {
          label: "High",
          backgroundColor: [PHC[0]],
          data: Data6,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },
        {
          label: "Medium",
          backgroundColor: [PMC[0]],
          data: Data7,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },
        {
          label: "Low",
          backgroundColor: [PLc[0]],
          data: Data8,
          barPercentage: 0.7,
          categoryPercentage: 0.7,
        },
      ],
    };

    const Locationgraph = {
      chart: {
        type: "column",
        height: "30%",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: project_name,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Number",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: false,
      },
      colors: [PEC[0], PHC[0], PMC[0], PLc[0]],
      series: [
        {
          name: "High Critical",
          data: Data5,
        },
        {
          name: "High",
          data: Data6,
        },
        {
          name: "Medium",
          data: Data7,
        },
        {
          name: "Low",
          data: Data8,
        },
      ],
    };

    const gpCategorywiseData = {
      labels: this.state.month_wise_nm_reported?.map((item) => item.label),
      datasets: [
        {
          label: "",
          data: this.state.month_wise_nm_reported?.map((item) => item.value),
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };
    // end bar graph
    const monthWiseGraph = {
      chart: {
        type: "line",
        height: "30%",
      },
      legend: {
        enabled: false,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.state.month_wise_nm_reported.map((item) => item.label),
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "Number",
        },
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: false,
        },
      },
      series: [
        {
          data: this.state.month_wise_nm_reported.map((data) => data.value),
        },
      ],
    };

    const getCapaCount = (totalCount) => {
      return {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            totalCount +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: [
          this.state.total_data.total_capa_recommendation_count == totalCount
            ? "#A4E9FF"
            : this.state.total_data.total_capa_open_recommendation_count ==
              totalCount
            ? "red"
            : "green",
        ],
        series: [
          {
            data: [totalCount || 0],
            size: "100%",
            innerSize: "84%",
          },
        ],
      };
    };

    const categoryC = this.state.total_data.categorywiseCapaOpenCount.length > 0 ? this.state.total_data.categorywiseCapaOpenCount?.map(
      (data) => ({ color: data.color, y: data.count, name: data.category })
    ) : [{}];

    const categoryCapaChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.total_data.categorywiseOpenCount || "0" +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.total_data.categorywiseCapaOpenCount?.map(
        (data) => data.color
      ),
      category: this.state.total_data.categorywiseCapaOpenCount?.map(
        (data) => data.category
      ),
      series: [
        {
          data: categoryC,
          size: "100%",
          innerSize: "84%",
        },
      ],
    };
    const prior = this.state.total_data.prioritywiseCapaOpenCount.length > 0 ? this.state.total_data.prioritywiseCapaOpenCount?.map(
      (data) => ({ color: data.color, y: data.count, name: data.priority })
    ) : [{}];

    const priorityCapaChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.total_data.prioritywiseOpenCount || "0" +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.total_data.prioritywiseCapaOpenCount?.map(
        (data) => data.color
      ),
      category: this.state.total_data.prioritywiseCapaOpenCount?.map(
        (data) => data.category
      ),
      series: [
        {
          data: prior,
          size: "100%",
          innerSize: "84%",
        },
      ],
      //variables end here
    };
    return (
      <div className="row ">
        <div className="col-12 col-md-12 col-lg-4 mb-2">
          <div className="clide-v2-card-box p-3">
            <div className="clide-v2-scroll-bar  clide-v2-p-0">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-12 mb-3 ">
                  <div className="clide-v2-white-box h-100 my-2 d-flex">
                    <div className="row w-100 align-items-center">
                      <div className="col-12 ">
                      <div className="sm-graph-modal-legend-text text-center clide-v2-primary-color my-2">
                            <b>{t('investigationStatus')}</b>
                          </div>
                        {config.isChartJsEnabled ? (
                          this.CountDoughnut(chartData, totalstatus)
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={chart}
                          />
                        )}
                      </div>
                      <div className="col-12 ">
                        <div className="outer-legend-block">
                          
                          <div className="soi-kanban-inner-legend-block ">
                            <span
                              className="soi-kanban-modal-legend-box clide-v2-square-span "
                              style={{ backgroundColor: "#FF7070" }}
                            ></span>
                            <span className=" font-weight-300">{t('pending')}</span>
                            <span className="legend-number float-end ">
                              {this.state.status.pending}
                            </span>
                          </div>
                          <div className="soi-kanban-inner-legend-block  ">
                            <span
                              className="soi-kanban-modal-legend-box clide-v2-square-span"
                              style={{ backgroundColor: "#23C6D0" }}
                            ></span>
                            <span className="sm-graph-modal-legend-text ">
                            {t('inProgress')}
                            </span>
                            <span className="legend-number float-end ">
                              {this.state.status.inprogress}
                            </span>
                          </div>
                          <div className="soi-kanban-inner-legend-block pt-1">
                            <span
                              className="soi-kanban-modal-legend-box clide-v2-square-span"
                              style={{ backgroundColor: "#23D053" }}
                            ></span>
                            <span className="sm-graph-modal-legend-text  font-weight-300">
                              {t('completed')}
                            </span>
                            <span className="legend-number float-end">
                              {this.state.status.completed}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-12 mb-3">
                  <div className="clide-v2-white-box h-100 my-2">
                    <div className="row align-items-center">
                      <div className="col-12 ">
                      <div className="sm-graph-modal-legend-text text-center clide-v2-primary-color my-2">
                            <b>{t('totalConributingFactor')}</b>
                          </div>
                        {config.isChartJsEnabled ? (
                          this.CountDoughnut(cfcolour, totalCFValue)
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={cfGraphData}
                          />
                        )}
                      </div>
                      <div className="col-12  ">
                        <div>
                          <div className="outer-legend-block">
                            <Scrollbars
                              style={{
                                height: `6rem`,
                                margin: "0.5rem 0.2rem 0.5rem 0",
                              }}
                              renderView={(props) => (
                                <div
                                  {...props}
                                  style={{
                                    paddingRight: "15px",
                                    ...props.style,
                                    overflowX: "hidden",
                                  }}
                                />
                              )}
                            >
                              {this.state.contributing_factor.map(
                                (cf, index) => (
                                  <div
                                    className="soi-kanban-inner-legend-block"
                                    key={index}
                                  >
                                    <span
                                      className="soi-kanban-modal-legend-box clide-v2-square-span "
                                      style={{
                                        backgroundColor:
                                          cf.factor_color != ""
                                            ? cf.factor_color
                                            : "#FAB95C",
                                      }}
                                    ></span>
                                    <span
                                      className="sm-graph-modal-legend-text my-2 "
                                     
                                    >
                                      {cf.factor_name}{" "}
                                    </span>
                                    <span className="legend-number float-end">
                                      {cf.factor_value}
                                    </span>
                                  </div>
                                )
                              )}
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-12 mb-3">
                  <div className="clide-v2-white-box h-100 my-2">
                    <div className="row align-items-center">
                      <div className="col-12 ">
                      <div className="sm-graph-modal-legend-text text-center clide-v2-primary-color my-2">
                            <b>{t('totalImmediateFactor')}</b>
                          </div>
                        {config.isChartJsEnabled ? (
                          this.CountDoughnut(ifcolour, totalIFValue)
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={IFactor}
                          />
                        )}
                      </div>
                      <div className="col-12  ">
                        <div>
                          <div className="outer-legend-block">
                            
                            <Scrollbars
                              style={{
                                height: `6rem`,
                                margin: "0.5rem 0.2rem 0.5rem 0",
                              }}
                              renderView={(props) => (
                                <div
                                  {...props}
                                  style={{
                                    paddingRight: "15px",
                                    ...props.style,
                                    overflowX: "hidden",
                                  }}
                                />
                              )}
                            >
                              {this.state.immediate_factor.map(
                                (IFactor, index) => (
                                  <div
                                    className="soi-kanban-inner-legend-block"
                                    key={index}
                                  >
                                    <span
                                      className="soi-kanban-modal-legend-box clide-v2-square-span "
                                      style={{
                                        backgroundColor: IFactor.factor_color,
                                      }}
                                    ></span>
                                    <span className="sm-graph-modal-legend-text my-2 ">
                                      {IFactor.factor_name}
                                    </span>
                                    <span className="legend-number float-end">
                                      {IFactor.factor_value}
                                    </span>
                                  </div>
                                )
                              )}
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-12 mb-3">
                  <div className="clide-v2-white-box h-100 my-2">
                    <div className="row align-items-center">
                      <div className="col-12 ">
                      <div className="sm-graph-modal-legend-text text-center clide-v2-primary-color my-2">
                            <b>{t('totalRootCauses')}</b>
                          </div>
                        {this.state.rca_factor ? (
                          <div>
                            {config.isChartJsEnabled ? (
                              this.CountDoughnut(rcacolour, rcaTotalValue)
                            ) : (
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={RCA}
                              />
                            )}
                          </div>
                        ) : (
                          <h5 className="header-title">{t('noData')}</h5>
                        )}
                      </div>
                      <div className="col-12  ">
                        <div>
                          <div className="outer-legend-block">
                           
                            <Scrollbars
                              style={{
                                height: `6rem`,
                                margin: "0.5rem 0.2rem 0.5rem 0",
                              }}
                              renderView={(props) => (
                                <div
                                  {...props}
                                  style={{
                                    paddingRight: "15px",
                                    ...props.style,
                                    overflowX: "hidden",
                                  }}
                                />
                              )}
                            >
                              {this.state.rca_factor.map((rca, index) => (
                                <div
                                  className="soi-kanban-inner-legend-block"
                                  key={index}
                                >
                                  <span
                                    className="soi-kanban-modal-legend-box clide-v2-square-span "
                                    style={{
                                      backgroundColor: rca.factor_color,
                                    }}
                                  ></span>
                                  <span className="sm-graph-modal-legend-text my-2">
                                    {rca.factor_name}{" "}
                                  </span>
                                  <span className="legend-number float-end">
                                    {rca.factor_value}
                                  </span>
                                </div>
                              ))}
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 col-lg-12 mb-3">
                <div className="clide-v2-white-box h-100 my-2">
                  <div className="row align-items-center">
                    <div className="col-12 ">
                    <div className="sm-graph-modal-legend-text text-center clide-v2-primary-color my-2">
                            <b>{t('nmReportedDepartmentwise')}</b>
                          </div>
                      {config.isChartJsEnabled ? (
                        this.CountDoughnut(dpcolour, totalDPValue)
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={dpGraphData}
                        />
                      )}
                    </div>
                    <div className="col-12  ">
                      <div>
                        <div className="outer-legend-block">
                          
                          
                          <Scrollbars
                            style={{
                              height: `6rem`,
                              margin: "0.5rem 0.2rem 0.5rem 0",
                            }}
                            renderView={(props) => (
                              <div
                                {...props}
                                style={{
                                  paddingRight: "15px",
                                  ...props.style,
                                  overflowX: "hidden",
                                }}
                              />
                            )}
                          >
                            {this.state.department_count.map((dp, index) => (
                              <div
                                className="soi-kanban-inner-legend-block"
                                key={index}
                              >
                                <span
                                  className="soi-kanban-modal-legend-box clide-v2-square-span "
                                  style={{
                                    backgroundColor:
                                      dp.color != "" ? dp.color : "#FAB95C",
                                  }}
                                ></span>
                                <span className="sm-graph-modal-legend-text my-2">
                                  {dp.department}{" "}
                                </span>
                                <span className="legend-number float-end">
                                  {dp.count}
                                </span>
                              </div>
                            ))}
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 col-lg-12 mb-3">
                <div className="clide-v2-white-box h-100 my-2">
                  <div className="row  align-items-center">
                    <div className="col-12 ">
                    <div className="sm-graph-modal-legend-text text-center clide-v2-primary-color my-2">
                            <b>{t('nmReportedContractorwise')}</b>
                          </div>
                      {config.isChartJsEnabled ? (
                        this.CountDoughnut(concolour, totalContractorValue)
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={ContractorGraph}
                        />
                      )}
                    </div>
                    <div className="col-12  ">
                      <div>
                        <div className="outer-legend-block">
                          
                          <Scrollbars
                            style={{
                              height: `6rem`,
                              margin: "0.5rem 0.2rem 0.5rem 0",
                            }}
                            renderView={(props) => (
                              <div
                                {...props}
                                style={{
                                  paddingRight: "15px",
                                  ...props.style,
                                  overflowX: "hidden",
                                }}
                              />
                            )}
                          >
                            {this.state.contractorWiseNmCount.map(
                              (con, index) => (
                                <div
                                  className="soi-kanban-inner-legend-block"
                                  key={index}
                                >
                                  <span
                                    className="soi-kanban-modal-legend-box clide-v2-square-span "
                                    style={{
                                      backgroundColor:
                                        con.color != "" ? con.color : "#FAB95C",
                                    }}
                                  ></span>
                                  <span className="sm-graph-modal-legend-text my-2">
                                    {con.contractor}{" "}
                                  </span>
                                  <span className="legend-number float-end">
                                    {con.count}
                                  </span>
                                </div>
                              )
                            )}
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-8">
          <div className="clide-v2-card-box p-3">
            <div className="clide-v2-scroll-bar pe-3 clide-v2-p-0">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <div className="clide-v2-white-box h-100 my-2 text-center">
                    <label className="clide-v2-primary-color my-3">{t('nmReportedRiskWise')}</label>
                    <div className="graph-block">
                      {config.isChartJsEnabled ? (
                        <Bar data={rskData} options={barGraphOptions} />
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={rskgraph}
                          containerProps={{
                            style: { width: "100%", height: "100%" },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <div className="clide-v2-white-box h-100 my-2 text-center">
                    <label className="clide-v2-primary-color my-3">{t('nmReportedType')}</label>

                    {config.isChartJsEnabled ? (
                      this.CountDoughnutHalf(
                        uaucChartData,
                        totalstatus,
                        120,
                        "1.9rem"
                      )
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={uaucGraphData}
                      />
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <div className="clide-v2-white-box h-100 my-2 text-center">
                    <label className="my-3 align-items-center clide-v2-primary-color">
                    {t('totalCapaCategory')}
                    </label>
                    <div className="graph-block">
                      {config.isChartJsEnabled ? (
                        this.CountDoughnut(
                          CAPA,
                          category_totalIra,
                          120,
                          "1.9rem"
                        )
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={category}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <div className="clide-v2-white-box h-100 my-2 text-center">
                    <div className="d-flex flex-column">
                      <label className="align-items-center clide-v2-primary-color mb-2 my-3">
                        {t('capaRecommendation')}
                      </label>
                      <label className="align-items-center clide-v2-fs-4 mt-0 my-3">
                      {t('totalCount')}
                      </label>
                    </div>

                    <div className="graph-block">
                      {config.isChartJsEnabled ? (
                        <HalfCountGraph
                          height={120}
                          count={
                            this.state.total_data
                              .total_capa_recommendation_count
                          }
                          radius={"77%"}
                          cutout={"82%"}
                          fontWeight={500}
                          fontSize={"1.8rem"}
                          rotation={"-90"}
                          circumference={"180"}
                          cutoutPercentage={"80"}
                          textAlign="bottom"
                        />
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={getCapaCount(
                            this.state.total_data
                              .total_capa_recommendation_count
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <div className="clide-v2-white-box h-100 my-2 text-center">
                    <div className="d-flex flex-column">
                      <label className="align-items-center clide-v2-primary-color mb-2 my-3">
                      {t('capaRecommendation')}
                      </label>
                      <label className="align-items-center clide-v2-fs-4 mt-0 my-3">
                      {t('openCount')}
                      </label>
                    </div>
                    <div className="graph-block">
                      {config.isChartJsEnabled ? (
                        <HalfCountGraph
                          height={120}
                          count={
                            this.state.total_data
                              .total_capa_open_recommendation_count
                          }
                          radius={"77%"}
                          cutout={"82%"}
                          fontWeight={500}
                          fontSize={"1.8rem"}
                          color={"red"}
                        />
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={getCapaCount(
                            this.state.total_data
                              .total_capa_open_recommendation_count
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <div className="clide-v2-white-box h-100 my-2 text-center">
                    <div className="d-flex flex-column">
                      <label className="align-items-center clide-v2-primary-color mb-2 my-3">
                      {t('capaRecommendation')}
                      </label>
                      <label className="align-items-center clide-v2-fs-4 mt-0 my-3">
                      {t('closedCount')}
                      </label>
                    </div>
                    <div className="graph-block">
                      {config.isChartJsEnabled ? (
                        <HalfCountGraph
                          height={120}
                          count={
                            this.state.total_data
                              .total_capa_close_recommendation_count
                          }
                          radius={"77%"}
                          cutout={"82%"}
                          fontWeight={500}
                          fontSize={"1.8rem"}
                          color={"green"}
                        />
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={getCapaCount(
                            this.state.total_data
                              .total_capa_close_recommendation_count
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mb-3">
                  <div className="clide-v2-white-box h-100 my-2 text-center">
                    <div className="row align-items-center">
                      <div className="col-12 ">
                        <div className="d-flex flex-column">
                          <label className="align-items-center clide-v2-primary-color mb-2 my-3">
                          {t('categorywiseCapa')}
                          </label>
                          <label className="align-items-center clide-v2-fs-4 mt-0 my-3">
                          {t('openCount')}
                          </label>
                        </div>
                        {config.isChartJsEnabled ? (
                          this.CountDoughnut(
                            categoryC,
                            this.state.total_data.categorywiseOpenCount,
                            120,
                            "1.9rem"
                          )
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={categoryCapaChart}
                          />
                        )}
                      </div>

                      <div className="col-12  ">
                        <div>
                          <div className="outer-legend-block">
                            <Scrollbars
                              style={{
                                height: `6rem`,
                                margin: "0.5rem 0.2rem 0.5rem 0",
                              }}
                              renderView={(props) => (
                                <div
                                  {...props}
                                  style={{
                                    paddingRight: "15px",
                                    ...props.style,
                                    overflowX: "hidden",
                                  }}
                                />
                              )}
                            >
                              {this.state.total_data.categorywiseCapaOpenCount.map(
                                (data, index) => (
                                  <div
                                    className="soi-kanban-inner-legend-block text-start"
                                    key={index}
                                  >
                                    <span
                                      className="soi-kanban-modal-legend-box clide-v2-square-span "
                                      style={{
                                        backgroundColor:
                                          data.color != ""
                                            ? data.color
                                            : "#FAB95C",
                                      }}
                                    ></span>
                                    <span className="sm-graph-modal-legend-text my-2">
                                      {data.category}
                                    </span>
                                    <span className="legend-number float-end">
                                      {data.count}
                                    </span>
                                  </div>
                                )
                              )}
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mb-3">
                  <div className="clide-v2-white-box h-100 my-2 text-center">
                    <div className="row align-items-center">
                      <div className="col-12 ">
                        <div className="d-flex flex-column">
                          <label className="align-items-center clide-v2-primary-color mb-2 my-3">
                            {t('prioritywiseCapa')}
                          </label>
                          <label className="align-items-center clide-v2-fs-4 mt-0 my-3">
                          {t('openCount')}
                          </label>
                        </div>
                        {config.isChartJsEnabled ? (
                          this.CountDoughnut(
                            prior,
                            this.state.total_data.prioritywiseOpenCount,
                            120,
                            "1.9rem"
                          )
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={priorityCapaChart}
                          />
                        )}
                      </div>
                      <div className="col-12  ">
                        <div>
                          <div className="outer-legend-block">
                            <Scrollbars
                              style={{
                                height: `6rem`,
                                margin: "0.5rem 0.2rem 0.5rem 0",
                              }}
                              renderView={(props) => (
                                <div
                                  {...props}
                                  style={{
                                    paddingRight: "15px",
                                    ...props.style,
                                    overflowX: "hidden",
                                  }}
                                />
                              )}
                            >
                              {this.state.total_data.prioritywiseCapaOpenCount.map(
                                (data, index) => (
                                  <div
                                    className="soi-kanban-inner-legend-block text-start"
                                    key={index}
                                  >
                                    <span
                                      className="soi-kanban-modal-legend-box clide-v2-square-span "
                                      style={{
                                        backgroundColor:
                                          data.color != ""
                                            ? data.color
                                            : "#FAB95C",
                                      }}
                                    ></span>
                                    <span className="sm-graph-modal-legend-text my-2">
                                      {data.priority}
                                    </span>
                                    <span className="legend-number float-end">
                                      {data.count}
                                    </span>
                                  </div>
                                )
                              )}
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.locationStatus == 1 && (
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <div className="clide-v2-white-box h-100 my-2 my-2 ">
                      <div className="row align-items-center">
                        <div className="col-12 ">
                          <div className="d-flex flex-column text-center">
                            <label className="align-items-center clide-v2-primary-color mb-2 my-3">
                            {t('nmReportedby')}
                            </label>
                            <label className="align-items-center clide-v2-fs-4 mt-0 my-3">
                              {t('locationWise')}
                            </label>
                          </div>
                          {config.isChartJsEnabled ? (
                            this.CountDoughnut(
                              lcolour,
                              totalLocationValue,
                              120,
                              "1.9rem"
                            )
                          ) : (
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={LocationGraph}
                            />
                          )}
                        </div>
                        <div className="col-12  ">
                          <div>
                            <div className="outer-legend-block">
                              <Scrollbars
                                style={{
                                  height: `6rem`,
                                  margin: "0.5rem 0.2rem 0.5rem 0",
                                }}
                                renderView={(props) => (
                                  <div
                                    {...props}
                                    style={{
                                      paddingRight: "15px",
                                      ...props.style,
                                      overflowX: "hidden",
                                    }}
                                  />
                                )}
                              >
                                <div className="outer-legend-block">
                                  {this.state.locationWiseNMCount.map(
                                    (loc, index) => (
                                      <div
                                        className="soi-kanban-inner-legend-block"
                                        key={index}
                                      >
                                        <span
                                          className="soi-kanban-modal-legend-box clide-v2-square-span "
                                          style={{
                                            backgroundColor:
                                              loc.color != ""
                                                ? loc.color
                                                : "#FAB95C",
                                          }}
                                        ></span>
                                        <span className="sm-graph-modal-legend-text my-2">
                                          {loc.location}{" "}
                                        </span>
                                        <span className="legend-number float-end">
                                          {loc.count}
                                        </span>
                                      </div>
                                    )
                                  )}
                                </div>
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.zoneStatus == 1 && (
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <div className="clide-v2-white-box h-100 my-2 my-2">
                      <div className="row align-items-center">
                        <div className="col-12 ">
                          <div className="d-flex flex-column text-center">
                            <label className="align-items-center clide-v2-primary-color mb-2 my-3">
                            {t('nmReportedby')}
                            </label>
                            <label className="align-items-center clide-v2-fs-4 mt-0 my-3">
                            {t('zoneWise')}
                            </label>
                          </div>
                          {config.isChartJsEnabled ? (
                            this.CountDoughnut(
                              zoncolour,
                              totalZoneValue,
                              120,
                              "1.9rem"
                            )
                          ) : (
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={ZoneGraph}
                            />
                          )}
                        </div>
                        <div className="col-12 ">
                          <div className="outer-legend-block">
                            <Scrollbars
                              style={{
                                height: `6rem`,
                                margin: "0.5rem 0.2rem 0.5rem 0",
                              }}
                              renderView={(props) => (
                                <div
                                  {...props}
                                  style={{
                                    paddingRight: "15px",
                                    ...props.style,
                                    overflowX: "hidden",
                                  }}
                                />
                              )}
                            >
                              <div className="outer-legend-block">
                                {this.state.zoneWiseNMCount.map(
                                  (zon, index) => (
                                    <div
                                      className="soi-kanban-inner-legend-block"
                                      key={index}
                                    >
                                      <span
                                        className="soi-kanban-modal-legend-box clide-v2-square-span "
                                        style={{
                                          backgroundColor:
                                            zon.color != ""
                                              ? zon.color
                                              : "#FAB95C",
                                        }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text my-2">
                                        {zon.zone}{" "}
                                      </span>
                                      <span className="legend-number float-end">
                                        {zon.count}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-12 col-md-12 col-lg-12 mb-3">
                  <div className="clide-v2-white-box h-100 my-2 p-3">
                    <div className="d-flex border- align-items-centerbottom mb-2">
                      <div className="p-2 w-100 clide-v2-primary-color">{t('monthwiseNmReported')}</div>
                      <div className="p-2 flex-shrink-1">
                        <svg
                          onClick={() => this.Monthwise(true)}
                          xmlns="http://www.w3.org/2000/svg"
                          width={32}
                          height={32}
                          fill="none"
                          viewBox="0 0 32 32"
                        >
                          <circle
                            cx={16}
                            cy={16}
                            r="15.5"
                            fill="#fff"
                            stroke="tomato"
                          />
                          <path
                            fill="tomato"
                            d="M13.37 24.193l1.8 1v-6.59a1.06 1.06 0 00-.26-.7l-5.833-6.57h14.004l-5.823 6.57a1.06 1.06 0 00-.26.7l-.028 7.73 1.8 1v-8.32l5.922-6.68c.237-.267.372-.625.378-1v-1c0-.265-.095-.52-.264-.707a.857.857 0 00-.636-.293H7.97a.857.857 0 00-.636.293 1.06 1.06 0 00-.264.707v1c.006.375.141.733.378 1l5.922 6.68v5.18z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="full-graph new-height-nm">
                      {config.isChartJsEnabled ? (
                        <Line
                          data={gpCategorywiseData}
                          plugins={[ChartDataLabels]}
                          options={{
                            ...barGraphOptions,
                            scales: {
                              x: {
                                offset: true,
                                ticks: { font: { size: 12 } },
                              },
                            },
                          }}
                          height={90}
                        />
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={monthWiseGraph}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="clide-v2-white-box h-100 my-2 p-3">
                  <div className="d-flex border- align-items-centerbottom mb-2">
                    <div className="p-2 w-100 clide-v2-primary-color">{t('monthwiseNmReported')}</div>
                    <div className="p-2 flex-shrink-1">
                      <svg
                        onClick={() => this.ServiceSeverity(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        fill="none"
                        viewBox="0 0 32 32"
                      >
                        <circle
                          cx={16}
                          cy={16}
                          r="15.5"
                          fill="#fff"
                          stroke="tomato"
                        />
                        <path
                          fill="tomato"
                          d="M13.37 24.193l1.8 1v-6.59a1.06 1.06 0 00-.26-.7l-5.833-6.57h14.004l-5.823 6.57a1.06 1.06 0 00-.26.7l-.028 7.73 1.8 1v-8.32l5.922-6.68c.237-.267.372-.625.378-1v-1c0-.265-.095-.52-.264-.707a.857.857 0 00-.636-.293H7.97a.857.857 0 00-.636.293 1.06 1.06 0 00-.264.707v1c.006.375.141.733.378 1l5.922 6.68v5.18z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                      <Bar
                        data={ssgraphData}
                        options={{
                          ...barGraphOptions,
                          scales: { x: { ticks: { font: { size: 12 } } } },
                        }}
                        height={90}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ssgraph}
                      />
                    )}
                  </div>
                </div>

                <div className="clide-v2-white-box h-100 my-2 p-3">
                  <div className="d-flex border- align-items-centerbottom mb-2">
                    <div className="p-2 w-100 clide-v2-primary-color">
                       {t('nmReportedbyServiceRiskType')}
                    </div>
                    <div className="p-2 flex-shrink-1">
                      <svg
                        onClick={() => this.ServiceSeverity(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        fill="none"
                        viewBox="0 0 32 32"
                      >
                        <circle
                          cx={16}
                          cy={16}
                          r="15.5"
                          fill="#fff"
                          stroke="tomato"
                        />
                        <path
                          fill="tomato"
                          d="M13.37 24.193l1.8 1v-6.59a1.06 1.06 0 00-.26-.7l-5.833-6.57h14.004l-5.823 6.57a1.06 1.06 0 00-.26.7l-.028 7.73 1.8 1v-8.32l5.922-6.68c.237-.267.372-.625.378-1v-1c0-.265-.095-.52-.264-.707a.857.857 0 00-.636-.293H7.97a.857.857 0 00-.636.293 1.06 1.06 0 00-.264.707v1c.006.375.141.733.378 1l5.922 6.68v5.18z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                      <Bar
                        data={ssgraphData}
                        options={{
                          ...barGraphOptions,
                          scales: { x: { ticks: { font: { size: 12 } } } },
                        }}
                        height={90}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ssgraph}
                      />
                    )}
                  </div>
                </div>

                <div className="clide-v2-white-box h-100 my-2 p-3">
                  <div className="d-flex border- align-items-centerbottom mb-2">
                    <div className="p-2 w-100 clide-v2-primary-color">
                      {t('nmReportedPlantProjectRiskType')}{" "}
                    </div>
                    <div className="p-2 flex-shrink-1">
                      <svg
                        onClick={() => this.ProjectSeverity(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        fill="none"
                        viewBox="0 0 32 32"
                      >
                        <circle
                          cx={16}
                          cy={16}
                          r="15.5"
                          fill="#fff"
                          stroke="tomato"
                        />
                        <path
                          fill="tomato"
                          d="M13.37 24.193l1.8 1v-6.59a1.06 1.06 0 00-.26-.7l-5.833-6.57h14.004l-5.823 6.57a1.06 1.06 0 00-.26.7l-.028 7.73 1.8 1v-8.32l5.922-6.68c.237-.267.372-.625.378-1v-1c0-.265-.095-.52-.264-.707a.857.857 0 00-.636-.293H7.97a.857.857 0 00-.636.293 1.06 1.06 0 00-.264.707v1c.006.375.141.733.378 1l5.922 6.68v5.18z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                      <Bar
                        data={locationGraphData}
                        options={{
                          ...barGraphOptions,
                          scales: { x: { ticks: { font: { size: 12 } } } },
                        }}
                        height={90}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={Locationgraph}
                      />
                    )}
                  </div>
                </div>

                <div className="clide-v2-white-box h-100 my-2 p-3">
                  <div className="d-flex border- align-items-centerbottom mb-2">
                    <div className="p-2 w-100 clide-v2-primary-color">
                      {" "}
                      {t('nmReportedActivityType')}
                    </div>
                    {/* <div className="p-2 flex-shrink-1">
                      <svg
                        onClick={() => this.ProjectSeverity(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        fill="none"
                        viewBox="0 0 32 32"
                      >
                        <circle
                          cx={16}
                          cy={16}
                          r="15.5"
                          fill="#fff"
                          stroke="tomato"
                        />
                        <path
                          fill="tomato"
                          d="M13.37 24.193l1.8 1v-6.59a1.06 1.06 0 00-.26-.7l-5.833-6.57h14.004l-5.823 6.57a1.06 1.06 0 00-.26.7l-.028 7.73 1.8 1v-8.32l5.922-6.68c.237-.267.372-.625.378-1v-1c0-.265-.095-.52-.264-.707a.857.857 0 00-.636-.293H7.97a.857.857 0 00-.636.293 1.06 1.06 0 00-.264.707v1c.006.375.141.733.378 1l5.922 6.68v5.18z"
                        />
                      </svg>
                    </div> */}
                  </div>
                  <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                      <Bar
                        data={activitygraphData}
                        options={{
                          ...barGraphOptions,
                          scales: { x: { ticks: { font: { size: 12 } } } },
                        }}
                        height={90}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={Activitygraph}
                      />
                    )}
                  </div>
                </div>

                <Modal
                  centered
                  show={this.state.ServiceSeverityPopup}
                  onHide={() => this.ServiceSeverity(false)}
                >
                  <Modal.Header
                    closeButton
                    onClick={() => this.ServiceSeverity(false)}
                  >
                    <Modal.Title>{t('filter')}</Modal.Title>
                    <Button
                      className="modal-btn1"
                      onClick={this._handleResetServiceseveriyFilter}
                    >
                      {t('reset')} {" |"}
                    </Button>
                    <Button
                      disabled={
                        selectedOptionseverity == null || selectedOption == null
                      }
                      className="modal-btn"
                      onClick={() => this.serviceseveriyFilter()}
                    >
                      {t('apply')}
                    </Button>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="nm-filter-modal">
                      <div className="form-group position-relative">
                        <Row>
                          <Col sm={6}>
                            <label>{t('riskLevel')}</label>
                            <Select
                              isClearable
                              isSearchable
                              options={this.state.severityoption}
                              isMulti
                              value={selectedOptionseverity}
                              onChange={this.handleChangeseverity}
                            />
                          </Col>
                          <Col sm={6}>
                            <label>{t('service')}</label>
                            <Select
                              isClearable
                              isSearchable
                              options={this.state.service}
                              isMulti
                              value={selectedOption}
                              onChange={this.handleChange}
                              getOptionLabel={(option) =>
                                option["service_name"]
                              }
                              getOptionValue={(option) => option["service_id"]}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  centered
                  show={this.state.ProjectSeverityPopup}
                  onHide={() => this.ProjectSeverity(false)}
                >
                  <Modal.Header
                    closeButton
                    onClick={() => this.ProjectSeverity(false)}
                  >
                    <Modal.Title>{t('filter')}</Modal.Title>
                    <Button
                      className="modal-btn1"
                      onClick={this._handleResetProjectSeverityGraphFilter}
                    >
                       {t('reset')} {" |"}
                    </Button>
                    <Button
                      disabled={
                        selectedProjectFilterOptionseverity == null ||
                        selectedOptionproject == null
                      }
                      className="modal-btn"
                      onClick={this.projectseveriyFilter}
                    >
                      {t('apply')}
                    </Button>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="nm-filter-modal">
                      <div className="form-group position-relative">
                        <Row>
                          <Col sm={6}>
                            <label>{t('service')}</label>
                            <Select
                              options={this.state.service}
                              value={
                                this.state.plantProjectFilterSelectedService
                              }
                              onChange={this.handleProjectFilterServiceChange}
                              getOptionLabel={(option) =>
                                option["service_name"]
                              }
                              getOptionValue={(option) => option["service_id"]}
                              placeholder= {t('selectService')}
                            />
                          </Col>
                          <Col sm={6}>
                            <label>{t('plantProject')}</label>
                            <Select
                              isClearable
                              isSearchable
                              options={this.state.project}
                              isMulti
                              value={selectedOptionproject}
                              onChange={this.handleChangeproject}
                              getOptionLabel={(option) => option["project"]}
                              getOptionValue={(option) => option["project_id"]}
                              placeholder={t('selectPlantProject')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <label>{t('riskLevel')}</label>
                            <Select
                              isClearable
                              isSearchable
                              options={this.state.severityoption}
                              isMulti
                              value={selectedProjectFilterOptionseverity}
                              onChange={this._handleProjectFilterChangeseverity}
                              placeholder={t('selectRiskLevel')}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  centered
                  show={this.state.monthwisePopup}
                  onHide={() => this.Monthwise(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t('filter')}</Modal.Title>
                    <Button
                      className="modal-btn1"
                      onClick={this.resetMonthWiseReportFilter}
                    >
                      {t('reset')} {" |"}
                    </Button>
                    <Button
                      disabled={
                        this.state.start_month == null ||
                        this.state.start_year == null ||
                        this.state.end_month == null ||
                        this.state.end_year == null
                      }
                      className="modal-btn"
                      onClick={this.monthwiseFilter}
                    >
                      {t('apply')}
                    </Button>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="nm-filter-modal">
                      <div className="form-group position-relative">
                        <div className="form-group">
                          <Row>
                            <Col>
                              <label>{t('startMonth')}</label>
                              <div className="form-group calender-div">
                                <MonthPicker
                                  selected={this.state.start_month}
                                  onChange={(month) =>
                                    this.setState({
                                      start_month: parseFloat(month) + 1,
                                    })
                                  }
                                  defaultValue={t('selectMonth')}
                                  id={"month"}
                                  name={"month"}
                                  classes={"classes"}
                                  optionClasses={"option classes"}
                                />
                                {/* <Calendar className="calender-icon" /> */}
                              </div>
                            </Col>
                            <Col>
                              <label>{t('startYear')}</label>
                              <div className="form-group calender-div">
                                <YearPicker
                                  selected={this.state.start_year}
                                  onChange={(year) =>
                                    this.setState({
                                      start_year: parseFloat(year),
                                    })
                                  }
                                  defaultValue={t('selectYear')}
                                  start={2010}
                                />
                                {/* <Calendar className="calender-icon" /> */}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label>{t('endMonth')}</label>
                              <div className="form-group calender-div">
                                <MonthPicker
                                  selected={this.state.end_month}
                                  onChange={(month) =>
                                    this.setState({
                                      end_month: parseFloat(month) + 1,
                                    })
                                  }
                                  defaultValue={t('selectMonth')}
                                  id={"month"}
                                  name={"month"}
                                  classes={"classes"}
                                  optionClasses={"option classes"}
                                />
                                {/* <Calendar className="calender-icon" /> */}
                              </div>
                            </Col>
                            <Col>
                              <label>{t('endYear')}</label>
                              <div className="form-group calender-div">
                                <YearPicker
                                  selected={this.state.end_year}
                                  onChange={(year) =>
                                    this.setState({
                                      end_year: parseFloat(year),
                                    })
                                  }
                                  defaultValue={t('selectYear')}
                                  start={2010}
                                />
                                {/* <Calendar className="calender-icon" /> */}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("nm")
)(NMDashboardV2);