import NmDashboard from "./Dashboard/NmDashboard";
import NmWorkFlow from "./WorkFlow/NmWorkFlow";
import NMReport from "./Report/";
import NMDetail from "./Detail/";
import NmEditReportingDetail from "./Detail/NmEditReportingDetail";

export {
  NmDashboard,
  NmWorkFlow,
  NMReport,
  NMDetail,
  NmEditReportingDetail
}