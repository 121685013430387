/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import makeAnimated from "react-select/animated";
import Select from "react-select";
//components
import ClideLoader from "app/shared/ui-kit/loader";
import { useTranslation } from "react-i18next";
//assets
import {
  PlusIconRed,
  RoundSave,
  CrossRound,
  RoundDelete,
  RoundEdit,
} from "assets/Iconsv2/Iconsv2";
const animatedComponents = makeAnimated();
const DetailStep1 = ({ state, functionContent, variablesObj }) => {
  const {t} = useTranslation('ira');
  const { isInprogress, isApproveEnabled, iraDetailStep1 } = variablesObj ?? {};

  return (
    <div>
      
      <div className="row mt-3">
        <div className="col-12 col-md-12 col-lg-12">
          <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-between px-0">
            <div className="clide-v2-title mb-1">
              <label className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                {" "}
                {t('initialResponse')}
              </label>
            </div>
            <div>
              {isInprogress && isApproveEnabled && !iraDetailStep1?.length ? (
                <button
                  className="clide-v2-icon-btn "
                  onClick={() =>
                    functionContent.debounceFunc(() => {
                      functionContent.setState({
                        addIntialResponse: true,
                      });
                    })
                  }
                >
                  <PlusIconRed />
                </button>
              ) : null}
            </div>
          </div>

          
            {state.addIntialResponse ? (
             <div className="border p-2 rounded row w-100">
                <div className="col-12 col-lg-10 col-md-10">
                  <div class="slds-form-element slds-has-error">
                    <label
                      class="fs-6 slds-m-bottom_xx-small "
                      for="form-element-05  "
                    >
                      
                      {t('addInitialResponse')}
                      <abbr class="slds-required" title="required">
                        *{" "}
                      </abbr>
                    </label>
                    <div class="slds-form-element__control">
                      <input
                        type="text"
                        id="form-element-05"
                        className="form-control"
                        value={state.initialResponse.description}
                        onChange={(e) =>
                          functionContent.setState({
                            initialResponse: {
                              ...state.initialResponse,
                              description: e.target.value,
                            },
                          })
                        }
                        placeholder={t('enterInitialResponse')}
                      />
                    </div>
                    {/* <div class="slds-form-element__help" id="form-error-01">Enter a value.</div> */}
                  </div>
                  <div>
                    <label class="fs-6" for="select-01">
                     
                      {t("action")}  <abbr class="slds-required" title="required">
                        *{" "}
                      </abbr>
                    </label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      minHeight="100"
                      isMulti
                      value={state.initialResponse.actions}
                      options={state.actionsmaster ?? []}
                      onChange={(el) => {
                        console.log(el);
                        functionContent.setState({
                          initialResponse: {
                            ...state.initialResponse,
                            actions: el,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-2 col-md-2">
                  <div className="d-flex space-between justify-content-end mt-2">
                    {state.initialResponse.isLoading ? (
                      <div className=" mx-3 mt-3">
                        <ClideLoader />{" "}
                      </div>
                    ) : (
                      <div>
                        <button
                          className="cursor-p  clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent._initialresponseAdd();
                            })
                          }
                        >
                          <RoundSave className="cursor-p " />
                        </button>
                        <button
                          className="cursor-p mx-2 clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.resetIntialParams();
                            })
                          }
                        >
                          <CrossRound />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {!state.addIntialResponse ? (
              <>
                {iraDetailStep1.map((el, idx) => (
                  <div className="border p-2 rounded row w-100">
                    <div className="col-12 col-lg-9 col-md-9" key={idx}>
                      
                      <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 fw-normal w-space-nobreak">
                              {el?.initial_response}
                              </label>
                            </div>
                      <div className=" d-flex flex-column">
                        <span className="   font-16 common-black-1 font-weight-500 my-2">
                          {t('action')} :
                        </span>
                        <Scrollbars
                          style={{
                            height: "2.8rem",
                            width: 'calc(100% - 10%)'
                          }}
                          renderView={(props) => (
                            <div
                              style={{
                                paddingRight: "15px",
                                ...props.style,
                                overflowX: "hidden",
                              }}
                            />
                          )}
                        >
                          <div className="d-flex">
                            {el.action_taken?.map((sub, i) => (
                              <span
                                key={i}
                                className="slds-badge px-3 py-2 font-weight-500 slds-badge_lightest mr-3"
                              >
                                {sub.action}
                              </span>
                            ))}
                          </div>
                        </Scrollbars>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-3">
                      <div className="d-flex space-between mt-2 justify-content-end">
                        {isInprogress && isApproveEnabled ? (
                          <div>
                            <button
                              onClick={() =>
                                functionContent.debounceFunc(() => {
                                  functionContent.setState({
                                    addIntialResponse: true,
                                    initialResponse: {
                                      editId: el?.initial_response_id,
                                      description: el?.initial_response,
                                      actions: el?.action_taken.map((sub) => {
                                        return {
                                          value: sub?.id,
                                          label: sub?.action,
                                        };
                                      }),
                                    },
                                  });
                                })
                              }
                              className="clide-v2-icon-btn mr-2"
                            >
                              <RoundEdit />
                            </button>
                          </div>
                        ) : null}
                        {isInprogress && isApproveEnabled ? (
                          <div>
                            <button
                              onClick={() =>
                                functionContent.debounceFunc(() => {
                                  functionContent.manageDataGatheringParams(
                                    "",
                                    {
                                      isDeleteEnable: true,
                                      parameters: {
                                        initial_response_id:
                                          el?.initial_response_id,
                                      },
                                      stepNo: "step1",
                                    },
                                    false,
                                    "deleteParams"
                                  );
                                })
                              }
                              className="clide-v2-icon-btn mr-2"
                            >
                              <RoundDelete />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          
        </div>
      </div>
    </div>
  );
};

export default DetailStep1;
