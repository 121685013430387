/* eslint-disable jsx-a11y/alt-text */
import { ErrorToast } from "app/shared/components/Popups/ToastMessage";
import Url from "app/shared/constants/Url";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import FsLightbox from "fslightbox-react";
import { Link } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//assets
import { Calendar, X } from "react-feather";
import { KanbanListTypeIcon, KanbanGridIcon } from "assets/Iconsv2/Iconsv2";
//components
import VideoBox from "app/shared/components/ReusableComponents/VideoPlayer/container";
import ClideLoader from "app/shared/ui-kit/loader";
import ListInprogressSection from "../components/list/inprogressSection";
import ListCompletedSection from "../components/list/completedSection";
import ListPendingSection from "../components/list/pendingSection";
//constants
import { nmContent } from "../constants/nmConstant";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

const qs = require("qs");

  export default withTranslation('nm')(
    class NmListV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...nmContent.listPageState(this.props),
    };

    this.selectSeverity = this.selectSeverity.bind(this);
    this.child = React.createRef();
  }

  componentDidMount() {
    this._getNMList();
    this._getService();
  }

  pendingModal = (visible) => {
    if (this.props.nmPending?.length || this.state.isFilterApplied) {
      this.setState({ pendingPopup: visible, isFilterApplied: true });
    } else {
      ErrorToast("Filter Can't apply on empty list");
    }
  };

  inProgressModal = (visible) => {
    if (this.props.nmProgress?.length || this.state.isProgressFilterApplied) {
      this.setState({
        inProgressPopup: visible,
        isProgressFilterApplied: true,
      });
    } else {
      ErrorToast("Filter Can't apply on empty list");
    }
  };

  completedModal = (visible) => {
    if (this.props.nmCompleted?.length || this.state.isCompletedFilterApplied) {
      this.setState({
        completedPopup: visible,
        isCompletedFilterApplied: true,
      });
    } else {
      ErrorToast("Filter Can't apply on empty list");
    }
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  _getNMList = async () => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();

    data.append("auth_key", token);
    data.append("user_id", userId);
    await axios
      .post(Url.nmModule.nmList, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            progress: response.data.data.inprogressnm.nm_basic_info,
            pending: response.data.data.pendingnm.nm_basic_info,
            completed: response.data.data.completednm.nm_basic_info,
            dataLoading: false,
          });
          this.props.nmlistfilter(
            this.state.pending,
            this.state.progress,
            this.state.completed
          );
        }
        // console.log(response.data.data)
      })
      .catch((error) => { })
      .finally(() =>
        this.setState({
          dataLoading: false,
        })
      );
  };

  _getService = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    await axios
      .post(
        Url.serviceList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            service: response.data.data,
            pendingService: response.data.data,
            inProgressService: response.data.data,
            completedService: response.data.data,
            dataLoading: false,
          });
          if (this.state.dflag.designation_flag == 4) {
            var serviceobs = this.state.service.map((item) => item.service_id);
            this.setState({ serviceSelect: serviceobs[0] });
            this._getProject("pending", serviceobs[0]);
            this._getProject("progress", serviceobs[0]);
            this._getProject("completed", serviceobs[0]);
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  _getProject = async (
    type = "pending ",
    serviceId = this.state.pendingServiceSelect
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.projectList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: serviceId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          switch (type) {
            case "pending":
              this.setState({
                pendingProject: response.data.data,
                dataLoading: false,
              });
              if (this.state.dflag.designation_flag == 4) {
                var projectobs = this.state.pendingProject.map(
                  (item) => item.project_id
                );
                this.setState({ pendingProjectSelect: projectobs[0] });
              }
              break;
            case "progress":
              this.setState({
                progressProject: response.data.data,
                dataLoading: false,
              });
              if (this.state.dflag.designation_flag == 4) {
                var projectobs = this.state.progressProject.map(
                  (item) => item.project_id
                );
                this.setState({ progressProjectSelect: projectobs[0] });
              }
              break;
            case "completed":
              this.setState({
                completedProject: response.data.data,
                dataLoading: false,
              });
              if (this.state.dflag.designation_flag == 4) {
                var projectobs = this.state.completedProject.map(
                  (item) => item.project_id
                );
                this.setState({ completedProjectSelect: projectobs[0] });
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  pendingResetState = () => {
    this.setState({
      ...nmContent.listPageResetState.pending,
    });
  };

  progressResetState = () => {
    this.setState({
      ...nmContent.listPageResetState.progress,
    });
  };

  completedResetState = () => {
    this.setState({
      ...nmContent.listPageResetState.completed,
    });
  };

  resetState() {
    this.setState({
      extremeSelect: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      startDate: null,
      endDate: null,
      serviceSelect: null,
      projectSelect: null,
      categorySelect: null,
      personSelect: null,
      overdue: 0,
      all: 0,
    });
  }

  pendingSelectSeverity(e) {
    const severity = this.state.pendingSeverity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ pendingSeverity: severity });
  }

  progressSelectSeverity(e) {
    const severity = this.state.progressSeverity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ progressSeverity: severity });
  }

  completedSelectSeverity(e) {
    const severity = this.state.completedSeverity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ completedSeverity: severity });
  }

  selectSeverity(e) {
    const severity = this.state.severity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ severity: severity });
  }

  pendingFilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ pendingExtremeSelect: 4, pendingAll: 0 });
      } else {
        this.setState({ pendingExtremeSelect: 0, pendingAll: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ pendingHighSelect: 3, pendingAll: 0 });
      } else {
        this.setState({ pendingHighSelect: 0, pendingAll: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ pendingMediumSelect: 2, pendingAll: 0 });
      } else {
        this.setState({ pendingMediumSelect: 0, pendingAll: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ pendingLowSelect: 1, pendingAll: 0 });
      } else {
        this.setState({ pendingLowSelect: 0, pendingAll: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, all: 0 });
      } else {
        this.setState({ overdue: 0, all: 0 });
      }
    }

    if (value == "all") {
      if (param == 0) {
        this.setState({
          pendingAll: 1,
          pendingExtremeSelect: 4,
          pendingHighSelect: 3,
          pendingMediumSelect: 2,
          pendingLowSelect: 1,
          pendingSeverity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          pendingAll: 0,
          pendingExtremeSelect: 0,
          pendingHighSelect: 0,
          pendingMediumSelect: 0,
          pendingLowSelect: 0,
          pendingSeverity: [],
        });
      }
    }
  }

  progressFilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ progressExtremeSelect: 4, progressAll: 0 });
      } else {
        this.setState({ progressExtremeSelect: 0, progressAll: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ progressHighSelect: 3, progressAll: 0 });
      } else {
        this.setState({ progressHighSelect: 0, progressAll: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ progressMediumSelect: 2, progressAll: 0 });
      } else {
        this.setState({ progressMediumSelect: 0, progressAll: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ progressLowSelect: 1, progressAll: 0 });
      } else {
        this.setState({ progressLowSelect: 0, progressAll: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, all: 0 });
      } else {
        this.setState({ overdue: 0, all: 0 });
      }
    }

    if (value == "all") {
      if (param == 0) {
        this.setState({
          progressAll: 1,
          progressExtremeSelect: 4,
          progressHighSelect: 3,
          progressMediumSelect: 2,
          progressLowSelect: 1,
          progressSeverity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          progressAll: 0,
          progressExtremeSelect: 0,
          progressHighSelect: 0,
          progressMediumSelect: 0,
          progressLowSelect: 0,
          progressSeverity: [],
        });
      }
    }
  }

  completedFilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ completedExtremeSelect: 4, completedAll: 0 });
      } else {
        this.setState({ completedExtremeSelect: 0, completedAll: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ completedHighSelect: 3, completedAll: 0 });
      } else {
        this.setState({ completedHighSelect: 0, completedAll: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ completedMediumSelect: 2, completedAll: 0 });
      } else {
        this.setState({ completedMediumSelect: 0, completedAll: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ completedLowSelect: 1, completedAll: 0 });
      } else {
        this.setState({ completedLowSelect: 0, completedAll: 0 });
      }
    }
    if (value == "deviation") {
      if (param == 0) {
        this.setState({ deviation: 1, all: 0 });
      } else {
        this.setState({ deviation: 0, all: 0 });
      }
    }

    if (value == "all") {
      if (param == 0) {
        this.setState({
          completedAll: 1,
          completedExtremeSelect: 4,
          completedHighSelect: 3,
          completedMediumSelect: 2,
          completedLowSelect: 1,
          completedSeverity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          completedAll: 0,
          completedExtremeSelect: 0,
          completedHighSelect: 0,
          completedMediumSelect: 0,
          completedLowSelect: 0,
          completedSeverity: [],
        });
      }
    }
  }

  FilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ extremeSelect: 4, all: 0 });
      } else {
        this.setState({ extremeSelect: 0, all: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ highSelect: 3, all: 0 });
      } else {
        this.setState({ highSelect: 0, all: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ mediumSelect: 2, all: 0 });
      } else {
        this.setState({ mediumSelect: 0, all: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ lowSelect: 1, all: 0 });
      } else {
        this.setState({ lowSelect: 0, all: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, all: 0 });
      } else {
        this.setState({ overdue: 0, all: 0 });
      }
    }

    if (value == "all") {
      if (param == 0) {
        this.setState({
          all: 1,
          extremeSelect: 4,
          highSelect: 3,
          mediumSelect: 2,
          lowSelect: 1,
          severity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          all: 0,
          extremeSelect: 0,
          highSelect: 0,
          mediumSelect: 0,
          lowSelect: 0,
          severity: [],
        });
      }
    }
  }

  _handlePendingServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ pendingServiceSelect: selectedServiceID });
      this._getProject("pending", selectedServiceID);
    }
  };

  _handleProgressServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ inProgressServiceSelect: selectedServiceID });
      this._getProject("progress", selectedServiceID);
    }
  };

  _handleCompletedServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ completedServiceSelect: selectedServiceID });
      this._getProject("completed", selectedServiceID);
    }
  };

  _handleServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ serviceSelect: selectedServiceID });
      this._getProject();
    }
  };

  _handlePendingFilter = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.pendingStartDate
        ? moment(data.pendingStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.pendingEndDate
        ? moment(data.pendingEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmPendingListFilter(
        data.pendingSeverity,
        start_date,
        end_date,
        data.pendingServiceSelect,
        data.pendingProjectSelect,
        // data.pending.length / 10 + 1
        data.pendingFilterPageNo
      );
      this.pendingModal(false);

      this.setState({
        pendingFilterFlag: 1,
      });
    } catch (error) { }
  };

  _pendingFilterPagination = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.pendingStartDate
        ? moment(data.pendingStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.pendingEndDate
        ? moment(data.pendingEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmPendingListFilter(
        data.pendingSeverity,
        start_date,
        end_date,
        data.pendingServiceSelect,
        data.pendingProjectSelect
        // data.pending.length / 10 + 1,
        // data.pendingFilterPageNo + 1,
      );
      this.pendingModal(false);

      this.setState({
        pendingFilterFlag: 1,
      });
    } catch (error) { }
  };

  _handlePendingScrollbarPagination = () => {
    if (this.state.pendingFilterFlag == 1) {
      if (this.props.pendingFilterCount != this.props.nmPending.length) {
        // this._handlePendingFilter();
        this._pendingFilterPagination();
      }
    } else {
      if (this.props.nmPending.length != this.props.nmPendingCount) {
        this.props._getNMList(this.props.nmPendingPageNo + 1);
      }
    }
  };

  _handleInprogressScrollbarPagination = () => {
    if (this.state.inprogressFilterFlag == 1) {
      if (this.props.inprogressFilterCount != this.props.nmProgress.length) {
        // this._handlePendingFilter();
        this._inProgressFilterPagination();
      }
    } else {
      if (this.props.nmProgress.length != this.props.nmProgressCount) {
        this.props._getNMProgressList(this.props.nmProgressPageNo + 1);
      }
    }
  };

  _inProgressFilterPagination = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.progressStartDate
        ? moment(data.progressStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.progressEndDate
        ? moment(data.progressEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmProgressListFilter(
        data.progressSeverity,
        start_date,
        end_date,
        data.inProgressServiceSelect,
        data.progressProjectSelect,
        data.overdue
        // data.inprogressFilterPageNo
      );
      this.inProgressModal(false);

      this.setState({
        inprogressFilterFlag: 1,
      });
    } catch (error) { }
  };

  _handleInProgressFilter = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.progressStartDate
        ? moment(data.progressStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.progressEndDate
        ? moment(data.progressEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmProgressListFilter(
        data.progressSeverity,
        start_date,
        end_date,
        data.inProgressServiceSelect,
        data.progressProjectSelect,
        data.overdue,
        data.inprogressFilterPageNo
      );
      this.inProgressModal(false);

      this.setState({
        inprogressFilterFlag: 1,
      });
    } catch (error) { }
  };

  _handleCompletedFilter = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.completedStartDate
        ? moment(data.completedStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.completedEndDate
        ? moment(data.completedEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmCompletedListFilter(
        data.completedSeverity,
        start_date,
        end_date,
        data.completedServiceSelect,
        data.completedProjectSelect,
        data.deviation,
        data.completedFilterPageNo
      );
      this.completedModal(false);
      this.setState({
        completedFilterFlag: 1,
      });
    } catch (error) { }
  };

  _completedPaginationFilter = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.completedStartDate
        ? moment(data.completedStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.completedEndDate
        ? moment(data.completedEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmCompletedListFilter(
        data.completedSeverity,
        start_date,
        end_date,
        data.completedServiceSelect,
        data.completedProjectSelect,
        data.deviation
      );
      this.completedModal(false);
      this.setState({
        completedFilterFlag: 1,
      });
    } catch (error) { }
  };

  _handleCompleteScrollbarPagination = () => {
    if (this.state.completedFilterFlag == 1) {
      if (this.props.completeFilterCount != this.props.nmCompleted.length) {
        this._completedPaginationFilter();
      }
    } else {
      if (this.props.nmCompleted.length != this.props.nmCompletedCount) {
        this.props._getNMCompletedList(this.props.nmCompletedPageNo + 1);
      }
    }
  };

  _handleFilterReset = async (moduleName = "pending") => {
    switch (moduleName) {
      case "pending":
        this.props.filterReset("pending");
        this.pendingResetState();
        this.pendingModal(false);
        break;
      case "progress":
        this.props.filterReset("progress");
        this.progressResetState();
        this.inProgressModal(false);
        break;
      case "completed":
        this.props.filterReset("completed");
        this.completedResetState();
        this.completedModal(false);
        break;
      default:
        break;
    }

    this.setState({
      pendingFilterFlag: 0,
      completedFilterFlag: 0,
      inprogressFilterFlag: 0,
    });
  };

  comapareTargetDate = (date = "") => {
    if (date) {
      const targetDate = moment(date, "DD/MM/YYYY");
      const today = moment();
      if (targetDate.isSame(today, "day")) {
        return true;
      }
      return false;
    }
  };

  _handleLightBox = async (list) => {
    console.log(list.map((item) => item.format));
    await this.setState({
      lightboxactive: true,
      fsToggler: !this.state.fsToggler,
      evidencelightbox: list.map((item) => item.link),
      evidencelightboxType: list.map((item) =>
        item.format == "img" ? "image" : item.format
      ),
    });
  };

  __toggleGridView = (tabName) => {
    if (this.state.listMode !== tabName) {
      this.setState({ listMode: tabName });
    }
  };
  __generateTableBody = (data, idx, customColumns = [], status = "pending") => {
    return (
      <tr key={idx}>
        <td>
          {" "}
          <Link to={`/nm-detail/${data.nearmiss_id}/kanban/${status}`} className="text-decoration-none clide-v2-secondary-color"
          >
            {data.type_of_act || ""}{" "}
          </Link>
        </td>
        <td>{data.activity}</td>
        <td>
          {data.evidence.length ? (
            <>
              {data.evidence[0]?.format === "video" ? (
                <img
                  src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
                  className="cursor-pointer svg-icons "
                  onClick={() =>
                    this.setState({ videoUrl: data.evidence[0]?.link })
                  }
                />
              ) : (
                <div>
                  <img
                    className="clide-v2-profile-img cursor-pointer rounded"
                    src={data.evidence[0]?.link}
                    title="Evidence"
                    onClick={() => this._handleLightBox(data.evidence)}
                  />
                </div>
              )}
            </>
          ) : (
            <div>
              <img
                className="clide-v2-profile-img "
                src={require("assets/images/icons/fimage.svg")}
                title="Evidence"
              />
            </div>
          )}
        </td>
        <td>{data.service}</td>
        <td>{data.project}</td>
        <td>
          <img src={data.reported_by_pic} className="clide-v2-profile-img " />
          {data.reported_by}
        </td>
        <td>{data.report_date}</td>
        {customColumns.length ? (
          <>
            {customColumns.includes("Due Date") ? (
              <td>
                <div
                  style={{
                    border: `1px solid #faac15`,
                    borderRadius: "28px",
                    color: "#faac15",
                    width: "max-content",
                  }}
                  className="px-3 py-1 my-2 font-weight-500 "
                >
                  {data.target_date || "NA"}
                </div>
              </td>
            ) : null}
            {customColumns.includes("Closed On") ? (
              <td>
                <div
                  style={{
                    border: `1px solid #faac15`,
                    borderRadius: "28px",
                    color: "#faac15",
                    width: "max-content",
                  }}
                  className="px-3 py-1 my-2 font-weight-500 "
                >
                  {data.closing_date || "NA"}
                </div>
              </td>
            ) : null}
            <td>
              {data.team != undefined &&
                data.team.map((team, index) => (
                  <div key={index}>
                    {team.status == 1 && (
                      <div className="people-lead border-0 d-flex align-items-center">
                        <img
                          src={team.pic}
                          className="clide-v2-profile-img"
                          title={team.name}
                          alt="team"
                        />
                        <div>
                          <span className="font-16 font-weight-500">
                            {team.name}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </td>
            <td>
              <div
                style={{
                  border: `1px solid #FF0000`,
                  borderRadius: "28px",
                  color: "#FF0000",
                  width: "max-content",
                }}
                className="px-3 py-1 my-2 font-weight-500 "
              >
                {customColumns.includes("Overdue")
                  ? data.overdue_days || data.overdue
                  : data.overdue_days || data.overdue || "NA"}
              </div>
            </td>
          </>
        ) : null}
      </tr>
    );
  };
  getFieldProps = (data, customColumns = [], status) => {
    const {t} = this.props;
    return {
      data,
      columns: [
        t('act'),
        t('activity'),
        t('evidence'),
        t('service'),
        t('plantProject'),
        t('reportedBy'),
        t('reportedOn'),
        ...customColumns,
      ],
      bodyRendrer: (data, idx) =>
        this.__generateTableBody(data, idx, customColumns, status),
    };
  };
  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);
  render() {
    const { dataLoading } = this.state;
    const {t} = this.props;

    if (dataLoading) {
      return <ClideLoader />;
    }
    return (
      <>
        <div className="row  clide-v2-colume_title">
          <div class="col-12 col-md-5">
            <div className="clide-v2-white-box mb-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('searchByCategory')}
                  aria-label="Search"
                  aria-describedby="button-addon2"
                  value={this.props.state.searchList2}
                  onChange={(e) => this.props.handleInputChange(e)}
                />
                <button
                  className="btn btn-secondary clide-v2-bg-white clide-v2-border-color border-start-0"
                  type="button"
                  id="button-addon2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="#F56620"
                      d="M8.333 3.334a5 5 0 100 10 5 5 0 000-10zm-6.666 5A6.667 6.667 0 1113.6 12.422l4.49 4.49a.833.833 0 01-1.18 1.178l-4.488-4.49A6.667 6.667 0 011.667 8.334z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-5"></div>
          <div class="col-12 col-md-2 mb-2">
            <div class="d-flex justify-content-end">
              <button className="border-0 bg-transparent" title="List view" onClick={() => this.__toggleGridView("list")}>
                <KanbanListTypeIcon />
              </button>
              <button className="border-0 bg-transparent" title="Kanban view" onClick={() => this.__toggleGridView("kanban")}>
                <KanbanGridIcon />
              </button>


              <div>
                <div>{nmContent.excelExportNmlist(this.props.state)}</div>
              </div>
            </div>

          </div>

          <ListPendingSection
            state={this.state}
            functionContent={this}
            props={this.props}
          />
          <ListInprogressSection
            state={this.state}
            functionContent={this}
            props={this.props}
          />
          <ListCompletedSection
            state={this.state}
            functionContent={this}
            props={this.props}
          />
        </div>
        <Modal
          centered
          show={this.state.pendingPopup}
          onHide={() => this.pendingModal(false)}
          size="lg"
        >
          <Modal.Header className="align-items-center">
            <div className="align-items-center d-flex justify-content-between">
              <div>
                <span className="font-20 font-weight-500 common-white-2">
                  {" "}
                  {t('pendingFilter')}
                </span>
              </div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button onClick={() => this._handleFilterReset("pending")}>
                  {t('reset')}
                </button>
                <button
                  onClick={() =>
                    this.debounceFunc(() => {
                      this._handlePendingFilter();
                    })
                  }

                // onClick={this._handlePendingFilter}
                >
                  {t('apply')}
                </button>
              </div>
              <X
                style={{
                  height: "1.4vw",
                  width: "1.4vw",
                }}
                className="cursor-pointer mx-2 common-white-2"
                onClick={() =>
                  this.setState({
                    pendingPopup: false,
                  })
                }
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <label>{t('riskLevel')}</label>
                <div className="along-label1">
                  <label className="check-box">
                    <input
                      name="abc"
                      type="checkbox"
                      checked={this.state.pendingAll}
                      onClick={() =>
                        this.pendingFilterData(this.state.pendingAll, "all")
                      }
                    />
                    <span className="checkmark1"></span>
                  </label>
                </div>
                <Row>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('highCritical')}
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.pendingExtremeSelect}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(
                            this.state.pendingExtremeSelect,
                            4
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('high')}
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.pendingHighSelect}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(
                            this.state.pendingHighSelect,
                            3
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('medium')}
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.pendingMediumSelect}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(
                            this.state.pendingMediumSelect,
                            2
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('low')}
                      <input
                        value={1}
                        type="checkbox"
                        checked={this.state.pendingLowSelect}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(
                            this.state.pendingLowSelect,
                            1
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  {/* <Col sm={6}>
                    <label className="check-box">
                      Overdue
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.overdue}
                        onClick={() =>
                          this.FilterData(this.state.overdue, "overdue")
                        }
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col> */}
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>{t('service')}</label>
                    <select
                      className="form-control"
                      value={this.state.pendingServiceSelect}
                      onChange={this._handlePendingServiceChange}
                    >
                      <option value="">Select Service</option>
                      {this.state.pendingService.map((service, index) => (
                        <option key={index} value={service.service_id}>
                          {service.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>{t('plantProject')}</label>
                    <select
                      className="form-control"
                      value={this.state.pendingProjectSelect}
                      onChange={(e) => {
                        this.setState({ pendingProjectSelect: e.target.value });
                      }}
                    >
                      <option>{t('select')}{t('plantProject')}</option>
                      {this.state.pendingProject.map((project, index) => (
                        <option key={index} value={project.project_id}>
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label>{t('byReportOn')}</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.pendingStartDate}
                        onChange={(jsDate) =>
                          this.setState({ pendingStartDate: jsDate })
                        }
                        placeholderText={t('selectStartDate')}
                        maxDate={new Date()}
                        portalId="root"
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">{t('to')}</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.pendingEndDate}
                        onChange={(jsDate) =>
                          this.setState({ pendingEndDate: jsDate })
                        }
                        placeholderText={t('selectEndDate')}
                        minDate={this.state.pendingStartDate}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {/* <Button 
              type="submit"
              variant="outline-primary"
              className="modal-btn"
              onClick={this._handlePendingFilter}
            >
             APPLY 
            </Button> */}
          </Modal.Body>
        </Modal>

        {/* In progress filter popup */}
        <Modal
          centered
          show={this.state.inProgressPopup}
          onHide={() => this.inProgressModal(false)}
          size="lg"
        >
          <Modal.Header className="align-items-center">
            <div className="align-items-center d-flex justify-content-between">
              <div>
                <span className="font-20 font-weight-500 common-white-2">
                  {" "}
                  {t('inProgressFilter')}
                </span>
              </div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button onClick={() => this._handleFilterReset("progress")}>
                  {t('reset')}
                </button>
                <button
                  onClick={() =>
                    this.debounceFunc(() => {
                      this._handleInProgressFilter();
                    })
                  }

                // onClick={this._handleInProgressFilter}
                >
                  {t('apply')}
                </button>
              </div>
              <X
                style={{
                  height: "1.4vw",
                  width: "1.4vw",
                }}
                className="cursor-pointer mx-2 common-white-2"
                onClick={() =>
                  this.setState({
                    inProgressPopup: false,
                  })
                }
              />
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <label>{t('riskLevel')}</label>
                <div className="along-label">
                  <label className="check-box">
                    <input
                      name="abc"
                      type="checkbox"
                      checked={this.state.progressAll}
                      onClick={() =>
                        this.progressFilterData(this.state.progressAll, "all")
                      }
                    />
                    <span className="checkmark1"></span>
                  </label>
                </div>
                <Row>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('highCritical')}
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.progressExtremeSelect}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(
                            this.state.progressExtremeSelect,
                            4
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('high')}
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.progressHighSelect}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(
                            this.state.progressHighSelect,
                            3
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('medium')}
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.progressMediumSelect}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(
                            this.state.progressMediumSelect,
                            2
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('low')}
                      <input
                        value={1}
                        type="checkbox"
                        checked={this.state.progressLowSelect}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(
                            this.state.progressLowSelect,
                            1
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('overdue')}
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.overdue}
                        onClick={() =>
                          this.progressFilterData(this.state.overdue, "overdue")
                        }
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
                <div className="modal-footer-btn "></div>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>{t('service')}</label>
                    <select
                      className="form-control"
                      value={this.state.inProgressServiceSelect}
                      onChange={this._handleProgressServiceChange}
                    >
                      <option value="">{t('selectService')}</option>
                      {this.state.inProgressService.map((service, index) => (
                        <option key={index} value={service.service_id}>
                          {service.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>{t('plantProject')}</label>
                    <select
                      className="form-control"
                      value={this.state.progressProjectSelect}
                      onChange={(e) => {
                        this.setState({
                          progressProjectSelect: e.target.value,
                        });
                      }}
                    >
                      <option>{t('selectPlantProject')}</option>
                      {this.state.progressProject.map((project, index) => (
                        <option key={index} value={project.project_id}>
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label>{t('byReportDate')}</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.progressStartDate}
                        onChange={(jsDate) =>
                          this.setState({ progressStartDate: jsDate })
                        }
                        placeholderText={t('selectStartDate')}
                        maxDate={new Date()}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">{t('to')}</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.progressEndDate}
                        onChange={(jsDate) =>
                          this.setState({ progressEndDate: jsDate })
                        }
                        placeholderText={t('selectEndDate')}
                        minDate={this.state.progressStartDate}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Completed Filter */}

        <Modal
          centered
          show={this.state.completedPopup}
          onHide={() => this.completedModal(false)}
          size="lg"
        >
          <Modal.Header className="align-items-center">
            <div className="align-items-center d-flex justify-content-between">
              <div>
                <span className="font-20 font-weight-500 common-white-2">
                  {" "}
                  {t('completedFilter')}
                </span>
              </div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button onClick={() => this._handleFilterReset("completed")}>
                  {t('reset')}
                </button>
                <button
                  onClick={() =>
                    this.debounceFunc(() => {
                      this._handleCompletedFilter();
                    })
                  }

                // onClick={this._handleCompletedFilter}
                >
                  {t('apply')}
                </button>
              </div>
              <X
                style={{
                  height: "1.4vw",
                  width: "1.4vw",
                }}
                className="cursor-pointer mx-2 common-white-2"
                onClick={() =>
                  this.setState({
                    completedPopup: false,
                  })
                }
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <label>{t('riskLevel')}</label>
                <div className="along-label">
                  <label className="check-box">
                    <input
                      name="abc"
                      type="checkbox"
                      checked={this.state.completedAll}
                      onClick={() =>
                        this.completedFilterData(this.state.completedAll, "all")
                      }
                    />
                    <span className="checkmark1"></span>
                  </label>
                </div>
                <Row>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('highCritical')}
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.completedExtremeSelect}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(
                            this.state.completedExtremeSelect,
                            4
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('high')}
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.completedHighSelect}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(
                            this.state.completedHighSelect,
                            3
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('medium')}
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.completedMediumSelect}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(
                            this.state.completedMediumSelect,
                            2
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                    {t('low')}
                      <input
                        value={1}
                        type="checkbox"
                        checked={this.state.completedLowSelect}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(
                            this.state.completedLowSelect,
                            1
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      {t('deviation')}
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.deviation}
                        onClick={() =>
                          this.completedFilterData(
                            this.state.deviation,
                            "deviation"
                          )
                        }
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>{t('service')}</label>
                    <select
                      className="form-control"
                      value={this.state.completedServiceSelect}
                      onChange={this._handleCompletedServiceChange}
                    >
                      <option value="">{t('selectService')}</option>
                      {this.state.completedService.map((service, index) => (
                        <option key={index} value={service.service_id}>
                          {service.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>{t('plantProject')}</label>
                    <select
                      className="form-control"
                      value={this.state.completedProjectSelect}
                      onChange={(e) => {
                        this.setState({
                          completedProjectSelect: e.target.value,
                        });
                      }}
                    >
                      <option>{t('selectPlantProject')}</option>
                      {this.state.completedProject.map((project, index) => (
                        <option key={index} value={project.project_id}>
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label>{t('byReportDate')}</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.completedStartDate}
                        onChange={(jsDate) =>
                          this.setState({ completedStartDate: jsDate })
                        }
                        maxDate={new Date()}
                        placeholderText={t('selectStartDate')}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">{t('to')}</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.completedEndDate}
                        onChange={(jsDate) =>
                          this.setState({ completedEndDate: jsDate })
                        }
                        minDate={this.state.completedStartDate}
                        maxDate={new Date()}
                        placeholderText={t('selectEndDate')}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal centered show={this.state.videoUrl !== ""}>
          <Modal.Header
            closeButton
            onClick={() => this.setState({ videoUrl: "" })}
          >
            <Modal.Title>{t('evidence')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <VideoBox url={this.state.videoUrl} />
          </Modal.Body>
        </Modal>
        {!!this.state.lightboxactive && (
          <FsLightbox
            toggler={this.state.fsToggler}
            types={this.state.evidencelightboxType}
            disableLocalStorage={true}
            sources={this.state.evidencelightbox}
          />
        )}
      </>
    );
  }
}
  )
