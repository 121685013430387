import moment from "moment";
import { TIMEZONE_STR } from "./helperStrings";
import { isValid } from "./UtilFunction";

const COORDINATED_UNIVERSAL_TIME = "UTC";
export const INDIAN_STANDARD_TIME = "Asia/Kolkata";

function convertDateFromTimezone(datetime, format = "DD-MM-YYYY HH:mm:ss ") {
  // get timezone from LoggedIn User
  const timezone = localStorage.getItem(TIMEZONE_STR);
  const tz = isValid(timezone)
    ? timezone
    : Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!!datetime) return moment.utc(datetime).tz(tz).format(format);
  return datetime;
}

function convertDateFromTimezoneIST(datetime, format = "YYYY-MM-DD HH:mm:ss") {
  // get timezone from LoggedIn User
  const timezone = localStorage.getItem(TIMEZONE_STR);
  const tz = isValid(timezone)
    ? timezone
    : Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!!datetime) return moment.utc(datetime).tz(tz).format(format);
  return datetime;
}

function convertDateToUTC(datetime, format = "YYYY-MM-DD HH:mm:ss") {
  // get timezone from LoggedIn User
  const timezone = localStorage.getItem(TIMEZONE_STR);
  const tz = isValid(timezone)
    ? timezone
    : Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!!datetime) return moment.tz(datetime, tz).utc().format(format);
  return datetime;
}

const toDateByFormat = (date, format) => {
  var normalized = date.replace(/[^a-zA-Z0-9]/g, "-");
  var normalizedFormat = format.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-");
  var formatItems = normalizedFormat.split("-");
  var dateItems = normalized.split("-");

  var monthIndex = formatItems.indexOf("mm");
  var dayIndex = formatItems.indexOf("dd");
  var yearIndex = formatItems.indexOf("yyyy");
  var hourIndex = formatItems.indexOf("hh");
  var minutesIndex = formatItems.indexOf("ii");
  var secondsIndex = formatItems.indexOf("ss");

  var today = new Date();

  var year = yearIndex > -1 ? dateItems[yearIndex] : today.getFullYear();
  var month =
    monthIndex > -1 ? dateItems[monthIndex] - 1 : today.getMonth() - 1;
  var day = dayIndex > -1 ? dateItems[dayIndex] : today.getDate();

  var hour = hourIndex > -1 ? dateItems[hourIndex] : today.getHours();
  var minute = minutesIndex > -1 ? dateItems[minutesIndex] : today.getMinutes();
  var second = secondsIndex > -1 ? dateItems[secondsIndex] : today.getSeconds();

  return new Date(year, month, day, hour, minute, second);
};


export { convertDateFromTimezone, convertDateToUTC, toDateByFormat, convertDateFromTimezoneIST };
