// User from submit validation messages
export const requiredMessage = "This is mandatory field";
export const validNumberMessage = "Please Enter valid Number";
export const validHoursMessage = "Please Enter valid Hours";
export const userFormSubmitMessage = "User form submitted successfully";
export const userFormSaveMessage = "User form saved successfully";
export const somethingWentWrong = "Something went wrong, please try again";
export const DuplicateQuestionName = "Form have duplicate question name";

export const observerDeleteTransfer = `No other observer user found to transfer data. You can create new observer user and transfer data, to do so press cancel, If your press transfer data will be transferred to "Project Head" by default.`;

export const projectHeadDeleteTransfer = `No other Project Head user found to transfer data. You can create new Project Head user and transfer data, to do so press cancel, If your press transfer data will be transferred to "Service Head or Safety Head" by default.`;

export const safetyHeadDeleteTransfer = `No other Service Head user found to transfer data. You can create new Service Head user and transfer data, to do so press cancel, If your press transfer data will be transferred to "Safety Head" by default.`;

export const corporateUserDeleteTransfer = `No other Corporate user found to transfer data. You can create new Corporate user and transfer data, to do so press cancel, If your press transfer data will be transferred to "Plant Head" by default.`;

// Tooltip messages

export const tooltipMessage = {
  serviceHeading:
    "Create new service, service is defined as the vertical in the organization. For ex. Road Construction, High Rise Building or Manufacturing Unit.",
  service:
    "Create new plant / project. Plant / Project is defined as the manufacturing plant or contruction project. For Ex. Plant - API manufacturing or Project - 21 Floor High Rise Building",
  master: {
    contributingFactor:
      "Create a list of propobal contributing factor or choose from the recommendation list for incident investigation.",
    immediateFactor:
      "Create a list of propobal immediate factor or choose from the recommendation list for incident investigation.",
    rootCauseFactor:
      "Create a list of propobal root causes or choose from the recommendation list for incident investigation.",
    category:
      "Create a list of CAPA categories or choose from the recommendation list to categories the recommendation given for implementation.",
  },
  users: {
    createdPage: "List of overall users created in the system.",
    projectHead:
      "Plant / Project Head will have access to assigned plant / project and will be overall reponsible for the plant / project activities. For Ex. Factor Manager or Unit Head can be made as plant head, in case of construction site, person responsible for construction site can be made as project head.",
    safetyHead:
      "Safety Head will have access to overall system and will have all the rights. System can have only one safety head. Safety head is assigned to all service and its plant and projects by default.",
    corporateUser:
      "Corporate User will have limited access to plant of project assigned to in respect to just view certain reports or be part of the discussion. Corporate user example are HR manager, Purchnase Manager, Store incharge, department head, contractor head.",
    observer:
      "Observer have very limited rights in the system and they can only operate in the plant of project they are assigned to. Observer example are any person reposible for safety of an particular area. Observer example - Supervisor, maintance incharge, quality incharge, safety officer, and so on. ",
    department:
      "Create list of master departemnt and assign the department head to each department. Corporate user can only become master department head.",
    contractor:
      "Create list of master contractors and assign the contractor head to each contractor. Corporate user can only become master contractor head",
  },
  hira: {
    activity:
      "Create activity or choose from the recommendation list. Activity is defined as day to day work performed in the plant or project. For example - transfer of chmeical to reactor becomes transfer activtty or performting a routine maintenance can be named as maintenance work.",
    hazard:
      "Create hazard or choose from the recommendation list as per your industry type. A hazard is a potential source of harm.",
    barrier:
      "Create barriers or choose from the recommendation list. Barrier is defined as the act due to which the person is not able to carry out the work safely. For example - Pressure of work, faituge",
  },
  space:
    "Space used in different modules can be seen here in calculated graphical view in terms of module based and service - plant/project based.",
  module: {
    activeModule: "List of modules which are already purchased by company",
    availableModules:
      "List of modules from CLIDEOffice which can be activated in system by requesting for purchase",
  },
  companyProfile:
    "Start date is the date to which the company is registerd in CLIDEOffice and expiry date is the last date up to which the system will be activated.",
  project: {
    details:
      "The summary of selected plant/project can be seen in detail page. Admin can replace/remove/assign plant/project head from list of available plant/project head user list, Admin can assign/remove contractor to selected plant/project, Admin can close/reopen selected project.",
    zoneLocation:
      "Admin can create/edit/delete zone and location. There can be 'n' no of zones and for each zone 'n' no of locations. Admin can assign/replace/remove zone and location head from list of available observer of that particular plant/project",
    department:
      "Admin can add multiple departments to selected plant/project from a list of Department Master. Admin can assign/replace/remove project/plant department head from list of available observer of that particular plant/project",
    contractor:
      "Admin can add multiple contractors to selected plant/project from a list of Contractor Master. Admin can assign/replace/remove project/plant contractor head from list of available observer of that particular plant/project.",
    user: {
      reportingUser: "This user type has reporting permissions only. Reporting Users are limited to submitting reports on UAUC, NM, IRA, GA, and TBT events.",
      observer:
        "Assigned to a single plant or project, Observer Users have permissions to report and analyze data within their assigned area. They can contribute to closing CAPAs and implementing recommendations provided by other users. Additionally, they have access to data analytics and dashboards specific to the data they have reported.",
      plantProjectSafetyHead:
        "The Plant/Project Safety Head is a designated user with limited administrative rights, including the ability to add users to their assigned plant or project. This role allows the Plant / Project Safety Head to report and analyze data within their designated area, contribute to closing CAPAs, and implement recommendations from other users. \n The Plant/Project Safety Head has access to both data analytics and dashboards specific to their own reports as well as the overall data analytics for the assigned plant or project. Each plant or project may have only one Plant / Project Safety Head, though a single Safety Head can be assigned to multiple plants or projects to manage administrative configurations across these locations.",
      plantProjectHead: "The Plant/Project Head is a senior-level user, such as a Factory Manager or Unit Head, who holds overall responsibility for a specific plant or project. This role includes permissions to report and analyze data within their assigned area, contribute to closing CAPAs, and implement recommendations provided by other users. \n The Plant/Project Head has access to comprehensive data analytics and dashboards, covering both their own reports and overall analytics for the assigned plant or project. While each plant or project may have only one Plant/Project Head, a single Plant/Project Head can be assigned to multiple plants or projects to oversee overall safety across multiple locations.",
      corporateUser:
        "The Corporate User is a senior-level user from the corporate office, such as a Quality Head or Production Head, with overall responsibility for a specific department. This role includes permissions to report and analyze data within their assigned department, contribute to closing CAPAs, and implement recommendations from other users. \n Corporate Users have access to detailed data analytics and dashboards, encompassing both their individual reports and comprehensive analytics for the entire department. Multiple Corporate Users can be assigned to a plant or project, each responsible for the safety management and oversight within their respective departments.",
      safetyHead: "The Safety Head is a high-level user with full administrative rights across the system, including capabilities to add users, configure plants/projects, and manage various master data. This role allows the Safety Head to report and analyze data across all areas, contribute to closing CAPAs, and implement recommendations from other users. \n With access to advanced analytics and dashboards, the Safety Head can view both specific reports and a comprehensive, bird’s-eye view of company-wide analytics. By default, the Safety Head is added to each service, plant, or project created, ensuring oversight across all safety-related activities and data.",
      safetyTeam:
        "The Safety Team consists of members from the safety department responsible for overseeing and managing safety at the assigned plant or project. When an Observer User is assigned to the safety department, they will automatically be included in the Safety Team. To assign an Observer User to the Safety Team, navigate to the Observer User tab and select 'Safety' as the designated department.",
      safetyTeamContractor:
        "For Assigning the safety team from the contractor company, navigate to the Observer tab and assign 'Contractor Company name' to the observer user, once done click the 'Add Contractor' and select the user to add in the safety team.",
    },
    hira: {
      activity:
        "Admin can add/remove activity to selected plant/project from a list of Master Activities.",
      hazardSubhazard:
        "Admin can add/remove hazard-sub hazard to selected plant/project from a list of Master Hazards",
      barrier:
        "Admin can add/remove barrier to selected plant/project from a list of Master Barriers",
    },
    module: {
      activeModule:
        "List of available and activated modules can be seen here, configuration of individual module is done by clicking the module name.",
      deactive:
        "Admin can activate/deactivate any module to selected plant/project.",
    },
    space:
      "Space used in selected project/plant can be seen here in module based graphical distrubution system.",
  },
  uaucModule: {
    escalationMatrix:
      "Admin can add/edit/delete esacalation level based example hour, day week and can also add/delete plant/proejct user to any selected level.",
    reports:
      "Admin can add/remove plant/project user to any given report. Reports will be emails on the user email address based on report assigned to them.",
    closingRightsHeading:
      "Admin can select the closing rights from zone-location/department/contractor to resolve the UAUC observation reported in the system.",
    closingRights:
      "Admin can add remove observer from observer list of that selected plant/project to selected closing rights parameter. Only observer are assigned to closing rights of the respective plant / project.",
  },
};
