const PageEight = (data) => {
  let tempHtml = "";

  try {
    data.incident_analysis_questions.forEach((item, index) => {
      tempHtml += `<tr>
      <td style="text-align: center;">${++index}</th>
      <td >${item.question || 'N/A'}</td>
      <td >${item.response || 'N/A'}</td>
    </tr>`;
    });
  } catch (error) {}

  return `<table cellpadding="15" cellspacing="0" width="800" style="margin: 0 auto 10px auto;font-family: arial;height: 100vh;font-size: 14px;border: 3px double #797878;position:relative" class="full-table">
<tr>
  <td valign="top" class="inner-table">
  <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Incident Analysis Question</h4>
  <br />
    <table cellpadding="10" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%">    
    <tr style="background: #ddd">
    <th style="text-align: center;">#</th>
    <th style="text-align: left;">Question</th>
    <th style="text-align: left;">Answer</th>
    </tr>
    ${tempHtml}
    </table>
    <span class="page-number" />
  </td>
</tr>
</table>`;
};

export default PageEight;
