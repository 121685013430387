import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { Calendar, Edit2, Save, Trash } from "react-feather";
import React, { Component } from "react";
import {
  convertDateFromTimezone,
  convertDateToUTC,
} from "app/shared/utility/tzUtilFunction";
import {
  requiredMessage,
  userFormSubmitMessage,
  validHoursMessage,
  validNumberMessage,
} from "app/shared/constants/Messages";
import { saveMessage, submitMessage } from "./constant";

import AlertMessage from "app/shared/components/Popups/AlertMessage";
import Backbutton from "app/shared/components/Backbutton";
import CommonDropdown from "app/shared/components/ReportModal/CommonDropdown";
import ConfirmPopup from "app/shared/components/Popups/ConfirmPopup";
import DatePicker from "react-datepicker";
import FsLightbox from "fslightbox-react";
import Lightbox from "react-image-lightbox";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import LowSpace from "app/shared/components/Popups/LowSpace";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
import VideoBox from "app/shared/components/ReusableComponents/VideoPlayer/container";
import Url from "app/shared/constants/Url";
import VideoPlayer from "simple-react-video-thumbnail";
import axios from "axios";
import { isValid } from "app/shared/utility/UtilFunction";
import { mediaQuery } from "app/shared/utility/helperStrings";
import moment from "moment";
import { withRouter } from "react-router-dom";

// import 'plyr-react/dist/plyr.css'

const qs = require("qs");
const rfdc = require("rfdc")();
const formType = [
  "",
  "Daily",
  "Weekly",
  "15 Days",
  "Monthly",
  "Quartely",
  "Half Yearly",
  "Yearly",
];

const USERLIST_URL = Url.UserList;
const DEPT_URL = Url.departmentList;
const CONT_URL = Url.contractorList;
const ZONE_URL = Url.zoneList;
const ACT_URL = Url.activitylist;

class ExternalUserFormView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitPopup: false,
      dataLoading: true,
      lightboxactive: false,
      toggler: false,
      fsToggler: false,
      imageType: "evidence",
      formtype: this.props.match.params.formType,
      reportingFormId: this.props.match.params.formId,
      serviceId: this.props.match.params.serviceId,
      projectId: this.props.match.params.projectId,
      title: this.props.match.params.title,
      userFormView: [],
      timeline_id: this.props.match.params.workPermitTimelineId || 0,
      submitFormStatus: true,
      unlockFormPopup: false,
      isCheckLowSpace: false,
      lowStorageMessage: "",
      lowStorageStatus: 200,
      showLowStoragePopup: false,
      showConfirmSubmitPopup: false,
      finalQDATA: [],
      form_action_type: 1,
      sourcesList: [],
      sourcesType: [],
      deleteEvidencesIds: [],
      workPermitUseId: this.props.match.params.surveyUseId,
      showRecommendationAdd: false,
      recommendationAddType: null,
      recommendationIndex: null,
      recommendationSectionIndex: null,
      priorityList: [
        { id: 1, priority: "Low" },
        { id: 2, priority: "Medium" },
        { id: 3, priority: "High" },
        { id: 4, priority: "Extreme" },
      ],
      selectedPriority: null,
      priorityError: false,
      recommendation: "",
      recommendationError: false,
      selectedTargetDate: new Date(),
      targetDateError: false,
      maxDatepickerDate: new Date(),
      categoryList: [],
      selectedCategory: null,
      userList: [],
      responsiblePerson: null,
      responsiblePersonError: false,
      recommendationData: [],
      isRecommendation: null,
      deleteRecommendationIds: [],
      recommendationId: 0,
      declarationChecked: false,
      declarationAvailable: 0,
      selectedFiles: [],
      deleteFileIds: [],
      showImage: null,
      showLightBoxImage: false,
      userInfo: JSON.parse(localStorage.getItem("userData")) || null,
      maxDatepickerDate: new Date(),
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      departmentList: [],
      contractorList: [],
      zoneList: [],
      locationList: [],
      activityList: [],

      selectedServiceId: null,
      selectedProjectId: null,
      selectedDepartmentId: null,
      selectedContractorId: null,
      selectedZoneId: null,
      selectedLocationId: null,
      selectedActivity: null,

      // formId: this.props.formId,
      // approve: this.props.approve,
      approveModal: [],
      userList: [],
      approvalUser: [],
      contractorUserList: [],
      selectedContractorUser: null,
      selectedContractorUserError: false,
      isAlreadyChangedApprovalLevel: false,
      surveyId: this.props.match.params.surveyUseId,
      windowWidth: null,
    };
    this.currentPathname = null;
    this.currentSearch = null;
  }

  componentDidMount() {
    this.userFormView();
    this._fetchCategoryList();
    this._fetchUserList();
    // this._fetchServiceList();
    // if (this.state.userInfo?.designation_flag != 1 && this.state.userInfo.designation_flag != 3) {
    //   this._fetchUserServiceProject();
    // }

    window.addEventListener("resize", () => {
      this.setState({ windowWidth: document.body.clientWidth });
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", () => { });
  }

  _handleChange = async (item = null, type = "start_date") => {
    await this.setState((prevState) => {
      let { selectedStartDate, selectedEndDate } = prevState;
      switch (type) {
        case "start_date":
          selectedStartDate = item;
          var tempDate = new Date(item);
          selectedEndDate = tempDate.setHours(tempDate.getHours() + 1);
          return { selectedStartDate, selectedEndDate };

        case "end_date":
          // let { selectedEndDate } = prevState;
          selectedEndDate = item;
          return { selectedEndDate };

        default:
          return prevState;
      }
    });
  };

  _fetchUserServiceProject = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.userServiceProject);
      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { selectedServiceId, selectedProjectId } = prevState;
              selectedServiceId = response.data.data[0].service_id;
              selectedProjectId = response.data.data[0].project_id;
              return { selectedServiceId, selectedProjectId };
            });
            // this._setDataLoading(false);
            this._fetchOptionList();
          }
        })
        .catch((error) => {
          // this._setDataLoading(false);
        });
    });
  };

  _serviceChangeHandle = async (serviceId) => {
    await this.setState((prevState) => {
      let { selectedServiceId, serviceError } = prevState;
      selectedServiceId = serviceId;
      serviceError = serviceId ? false : true;
      return { selectedServiceId, serviceError };
    });
    await this._resetDropdwon();
    this._fetchProjectList(serviceId);
  };

  _fetchOptionList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .all([
        // User list
        axios.post(USERLIST_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Department list
        axios.post(DEPT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Contractor List
        axios.post(CONT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Zone List
        axios.post(ZONE_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Activity List
        axios.post(ACT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
      ])
      .then(
        axios.spread(
          async (userListData, department, contractor, zone, activity) => {
            await this.setState((prevState) => {
              let {
                userList,
                contractorList,
                departmentList,
                zoneList,
                hazardList,
                activityList,
                barrierList,
                dataLoading,
              } = prevState;

              userList =
                userListData.data.status === 200 ? userListData.data?.data : [];
              departmentList =
                department.data.status === 200 ? department.data?.data : [];
              contractorList =
                contractor.data.status === 200 ? contractor.data?.data : [];
              zoneList = zone.data.status === 200 ? zone.data?.data : [];

              activityList =
                activity.data.status === 200 ? activity.data?.data : [];

              dataLoading = false;

              return {
                userList,
                contractorList,
                departmentList,
                zoneList,
                hazardList,
                activityList,
                barrierList,
                dataLoading,
              };
            });
          }
        )
      )
      .catch((error) => {
        this._setDataLoading(false);
      });
  };

  _zoneChangeHandle = async (zoneId) => {
    await this.setState((prevState) => {
      let { selectedZoneId, zoneError } = prevState;
      selectedZoneId = zoneId;
      zoneError = zoneId ? false : true;
      return { selectedZoneId, zoneError };
    });
    await this._fetchLocationList();
  };

  _fetchLocationList = async (zoneId) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
      zone_id: this.state.selectedZoneId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.locationList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { locationList } = prevState;
              locationList = response.data.data;
              return { locationList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _departmentChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedDepartmentId, departmentError } = prevState;
      selectedDepartmentId = id;
      departmentError = id ? false : true;
      return { selectedDepartmentId, departmentError };
    });
  };

  _contractorChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedContractorId, contractorError } = prevState;
      selectedContractorId = id;
      contractorError = id ? false : true;
      return { selectedContractorId, contractorError };
    });
    if (this.state.formType == 10) {
      await this._fetchContratorUserList();
    }
  };

  _fetchContratorUserList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
      type: 2,
      contractor_id: this.state.selectedContractorId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .all([
        // User list
        axios.post(USERLIST_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
      ])
      .then(
        axios.spread(async (userListData) => {
          await this.setState((prevState) => {
            let { contractorUserList, dataLoading } = prevState;
            contractorUserList =
              userListData.data.status === 200 ? userListData.data?.data : [];
            dataLoading = false;
            return { contractorUserList, dataLoading };
          });
        })
      )
      .catch((error) => {
        this._setDataLoading(false);
      });
  };

  _locationChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedLocationId, locationError } = prevState;
      selectedLocationId = id;
      locationError = id ? false : true;
      return { selectedLocationId, locationError };
    });
  };

  _activityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedActivity, activityError } = prevState;
      selectedActivity = id;
      activityError = id ? false : true;
      return { selectedActivity, activityError };
    });
  };

  _aprrovalLevelAddHandle = async (id) => {
    console.log(id);
    await this.setState((prevState) => {
      let {
        approvalUser,
        approvalError,
        userList,
        isAlreadyChangedApprovalLevel,
      } = prevState;
      // let tempList = userList;
      if (!isAlreadyChangedApprovalLevel) {
        approvalUser = [];
        isAlreadyChangedApprovalLevel = true;
      }
      approvalUser.push(userList.find((item) => item.id == id));
      console.log(approvalUser);
      userList = userList.filter((item) => item.id != id);
      // approvalError = id ? false : true;
      return { approvalUser, userList, isAlreadyChangedApprovalLevel };
    });
  };

  _contractorUserChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedContractorUser, selectedContractorUserError } = prevState;
      selectedContractorUser = id;
      selectedContractorUserError = false;
      return { selectedContractorUser, selectedContractorUserError };
    });
  };

  _resetDropdwon = async (projectReset = true) => {
    await this.setState((prevState) => {
      let {
        selectedProjectId,
        selectedContractorId,
        selectedDepartmentId,
        selectedActivity,
        selectedLocationId,
        selectedZoneId,
        contractorList,
        departmentList,
        activityList,
        locationList,
        zoneList,
      } = prevState;

      selectedContractorId = null;
      selectedActivity = null;
      selectedLocationId = null;
      selectedDepartmentId = null;
      selectedZoneId = null;

      departmentList = [];
      contractorList = [];
      locationList = [];
      activityList = [];
      zoneList = [];

      return {
        selectedProjectId,
        selectedContractorId,
        selectedDepartmentId,
        selectedActivity,
        selectedLocationId,
        selectedZoneId,
        contractorList,
        departmentList,
        activityList,
        locationList,
        zoneList,
      };
    });
  };

  userFormView = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    //console.log("assas :" + this.state.surveyId);

    await this.setState({
      dataLoading: true,
    });

    await axios
      .post(
        Url.external.externalUserFormDetails,
        qs.stringify({
          form_id: this.state.reportingFormId,
          survey_use_id: this.state.surveyId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        if (response.data.status == 200) {
          //  Addign errorStatus field in Response state
          let responseData = rfdc(response.data.data);
          responseData.questions = responseData.questions.map((item) => {
            if (item.mandatory) {
              item.errorStatus = 0;
              item.errorMessage = "";
            }
            //   if (item.notes) {
            //    item.note_value = "";
            //  }
            if (!item.evidence) {
              item.evidence_file = "";
              item.evidence_url = [];
            }
            //item.answer_value = "";

            /* Logic Question adding errorStatus , notevalue , answer_value fields */
            if (item.response.length > 0) {
              item.response = item.response.map((qItem) => {
                if (
                  qItem.response_id == item.answer_value ||
                  qItem.response == item.answer_value
                ) {
                  console.log("inside logic");
                  qItem.isActive = 1;
                } else {
                  qItem.isActive = 0; //logic field radio button status
                }
                console.log(qItem);
                qItem.logic_questions = qItem.logic_questions.map((lItem) => {
                  if (lItem.mandatory === 1) {
                    lItem.errorStatus = 0;
                    lItem.errorMessage = "";
                  }

                  return lItem;
                });
                return qItem;
              });
            }
            return item;
          });

          // Section data adding required fields
          responseData.section = responseData.section.map((sectionItem) => {
            sectionItem.section_questions = sectionItem.section_questions.map(
              (questionItem) => {
                if (questionItem.mandatory) {
                  questionItem.errorStatus = 0;
                  questionItem.errorMessage = "";
                }
                // if (questionItem.notes) {
                //   questionItem.note_value = "";
                // }

                if (!questionItem.evidence) {
                  questionItem.evidence_file = "";
                  questionItem.evidence_url = [];
                }

                //questionItem.answer_value = "";

                /* Logic Question adding errorStatus , notevalue , answer_value fields */
                if (questionItem.section_question_response.length > 0) {
                  questionItem.section_question_response =
                    questionItem.section_question_response.map((qItem) => {
                      if (
                        qItem.response_id == questionItem.answer_value ||
                        qItem.response == questionItem.answer_value
                      ) {
                        console.log("inside logic");
                        qItem.isActive = 1;
                      } else {
                        qItem.isActive = 0; //logic field radio button status
                      }
                      qItem.section_logic_questions =
                        qItem.section_logic_questions.map((lItem) => {
                          if (lItem.mandatory === 1) {
                            lItem.errorStatus = 0;
                            lItem.errorMessage = "";
                          }
                          //  if (lItem.notes === 1) {
                          //    lItem.note_value = "";
                          //  }
                          if (lItem.evidence === 1) {
                            lItem.evidence_file = "";
                            lItem.evidence_url = [];
                          }
                          //lItem.answer_value = "";
                          return lItem;
                        });
                      return qItem;
                    });
                }
                return questionItem;
              }
            );
            return sectionItem;
          });

          await this.setState((prevState) => {
            let { userFormView, title, timeline_id, isRecommendation } =
              prevState;
            userFormView = responseData;
            title = responseData?.basic_info?.title;
            timeline_id = responseData.timeline_id;
            isRecommendation = responseData?.basic_info?.recommendation;
            return { userFormView, title, timeline_id, isRecommendation };
          });
        }

        await this.setState((prevState) => {
          let { dataLoading } = prevState;
          dataLoading = false;
          return { dataLoading };
        });
      })
      .catch(async (error) => {
        await this.setState((prevState) => {
          let { dataLoading } = prevState;
          dataLoading = false;
          return { dataLoading };
        });
      });
  };

  showUnlockModal = (visible) => {
    this.setState({ unlockFormPopup: visible });
  };

  showModal = () => {
    this.setState({ submitPopup: true });
  };

  hideModal = () => {
    this.setState({ submitPopup: false });
  };

  _getFileString = async (file_object) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      return reader.result;
    };
    reader.readAsDataURL(file_object);
  };

  _removePreviewFile = async (
    index = 0,
    section_index = 0,
    type = "question",
    subType = "evidence",
    fileIndex
  ) => {
    await this.setState((prevState) => {
      let { userFormView, deleteEvidencesIds, deleteFileIds } = prevState;

      // question file remove
      if (type === "question") {
        let item = userFormView.questions[index];
        if (subType == "evidence") {
          item.evidence_url = [];
          item.evidence_file = "";
          deleteEvidencesIds.push(item.evidence_id);
        } else if (subType == "question_upload") {
          item.answer_value_url = [];
          let obj = item.files.filter((data, idx) => idx == fileIndex)[0];
          console.log(obj);
          deleteFileIds.push(obj.file_id);
          let tempArr = item.files.filter((data, idx) => idx != fileIndex);
          item.answer_value = [];
          item.files = tempArr;
        }
        userFormView.questions[index] = item;

        // Section file remove
      } else if (type === "section") {
        let section = userFormView.section[index];
        let sub_section = section.section_questions[section_index];

        if (subType === "evidence") {
          sub_section.evidence_url = "";
          sub_section.evidence_file = "";
          deleteEvidencesIds.push(sub_section.evidence_id);
        }
        section.section_questions[section_index] = sub_section;
        userFormView.section[index] = section;
      }
      return { userFormView, deleteEvidencesIds, deleteFileIds };
    });
  };

  _removeLogicPreviewFile = async (
    index = 0,
    section_index = 0,
    type = "question",
    logic_index = 0
  ) => {
    await this.setState((prevState) => {
      let { userFormView } = prevState;

      if (type === "question") {
        let item = userFormView.questions[index];
        let logic_item = item.response[logic_index];
        logic_item.logic_questions[0].evidence_url = [];
        item.response[logic_index] = logic_item;
        userFormView.questions[index] = item;
      } else if (type === "section") {
        let section = userFormView.section[index];
        let sub_section = section.section_questions[section_index];
        let logic_item = sub_section.section_question_response[logic_index];
        logic_item.section_logic_questions[0].evidence_url = [];
        sub_section.section_question_response[logic_index] = logic_item;
        section.section_questions[section_index] = sub_section;
        userFormView.section[index] = section;
      }

      return { userFormView };
    });
  };

  _handleInputChange = async (
    event,
    index = 0,
    type,
    section_index = 0,
    logic_radio_index = 0,
    recommendation1 = "",
    deleteEdit = 0,
    recIndex = 0
  ) => {
    debugger
    if (event.target === undefined && recommendation1 != "recommendation") {
      var name = `question-file-upload-${index}`;
    } else if (recommendation1 == "recommendation") {
      var value = event;
    } else {
      var value = event.target.value;
      var name = event.target.name;
      var input_type = event.target.type;
    }

    if (input_type === "file") {
      var evidence_file = Array.from(event.target.files);
      console.log(evidence_file);
    }

    await this.setState((prevState) => {
      let {
        userFormView,
        recommendation,
        selectedCategory,
        selectedPriority,
        selectedTargetDate,
        responsiblePerson,
        showRecommendationAdd,
        recommendationAddType,
        recommendationIndex,
        recommendationId,
      } = prevState;

      /* Question validation handling */
      if (type === "question") {
        debugger
        let item = userFormView.questions[index];
        /* Checking mandatory filed and value for validation */
        const response_arr = [
          `responses[${index}].number`,
          `responses[${index}].answer`,
          `color-${index}`,
          `single-${index}`,
          `responses[${index}].days`,
          `responses[${index}].hours`,
          `responses[${index}].recommendation`,
        ];
        if (response_arr.includes(name)) {
          if (item.mandatory && value == "") {
            item.errorStatus = 1;
            item.errorMessage = requiredMessage;
          } else {
            item.errorStatus = 0;
            item.errorMessage = "";
          }

          if (
            isNaN(value) &&
            (name == `responses[${index}].number` ||
              // name == `responses[${index}].days` ||
              name == `responses[${index}].hours`)
          ) {
            item.errorStatus = 1;
            item.errorMessage = validNumberMessage;
          } else {
            item.errorStatus = 0;
            item.errorMessage = "";
          }

          item.answer_value = value || [];

          /* Hide / show logic box  */
          if (item.logic === 1) {
            // make every isActive = 0 and then set isActive = 1 for selected radio field
            item.response = item.response.map((lItem) =>
              lItem.response_id == value && lItem.status === 1
                ? {
                  ...lItem,
                  isActive: 1,
                }
                : {
                  ...lItem,
                  isActive: 0,
                }
            );
          }
        } else if (name === `responses[${index}].notes`) {
          item.note_value = value;
        } else if (name === `responses[${index}].file`) {
          const lowStorage = this.checkLowStorage();
          lowStorage
            .then(async (response) => {
              const { status, msg } = response.data;

              if (status == 200) {
                item.evidence_url = evidence_file;
                // Create file string
                let reader = new FileReader();
                reader.readAsDataURL(evidence_file[0]);
                reader.onloadend = () => {
                  item.evidence_url = reader.result;
                };
              }
              await this.setState({
                lowStorageStatus: status,
                lowStorageMessage: msg,
                showLowStoragePopup: status == 200 ? false : true,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (name === `question-file-upload-${index}`) {
          const lowStorage = this.checkLowStorage();
          lowStorage
            .then(async (response) => {
              const { status, msg } = response.data;

              if (status == 200) {
                item.errorStatus = 0;
                item.errorMessage = "";

                // item.answer_value_url = evidence_file;

                let reader = new FileReader();
                reader.readAsDataURL(event);
                reader.onloadend = () => {
                  let temp = item.answer_value ? [...item.answer_value] : [];
                  temp.push({
                    file: reader.result,
                    type: event.type,
                    name: event.name,
                  });
                  item.answer_value = temp;
                  console.log(item.answer_value);
                };
              }
              await this.setState({
                lowStorageStatus: status,
                lowStorageMessage: msg,
                showLowStoragePopup: status == 200 ? false : true,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (recommendation1 == "recommendation") {
          let recData = item.recommendationData || [];
          console.log(recData);
          item.recommendationData = recData ? [...recData, value] : [value];
        }
        if (deleteEdit === 1) {
          let recObj = item.recommendationData.filter(
            (data, idx) => idx === recIndex
          )[0];
          if (recObj) {
            this.state.deleteRecommendationIds.push(recObj.recommendation_id);
          }
          item.recommendationData = item.recommendationData.filter(
            (data, idx) => idx !== recIndex
          );
        }
        if (deleteEdit === 2) {
          let recObj = item.recommendationData.filter(
            (data, idx) => idx === recIndex
          )[0];
          console.log(recObj);
          recommendation = recObj.recommendation;
          selectedCategory = recObj.category;
          selectedPriority = recObj.priority;
          responsiblePerson = recObj.res_person;
          selectedTargetDate = new Date(recObj.target_date);
          showRecommendationAdd = true;
          recommendationAddType = "question";
          recommendationIndex = index;
          recommendationId = recObj.recommendation_id;
          item.recommendationData = item.recommendationData.filter(
            (data, idx) => idx !== recIndex
          );
        }
        console.log(item);
        userFormView.questions[index] = item;
        console.log(userFormView);
        return {
          userFormView,
          recommendation,
          selectedCategory,
          selectedPriority,
          selectedTargetDate,
          responsiblePerson,
          showRecommendationAdd,
          recommendationAddType,
          recommendationIndex,
          recommendationId,
        };
      }

      /* Section Question validation */
      if (type === "section") {
        let section = userFormView.section[index];
        let sub_section = section.section_questions[section_index];

        /* Checking mandatory filed and value for validation */
        const response_arr = [
          `responses[${section_index}].number`,
          `responses[${section_index}].answer`,
          `color-${section_index}`,
          `single-${index}-${section_index}`,
          `responses[${section_index}].days`,
          `responses[${section_index}].hours`,
          `responses[${section_index}].recommendation`,
        ];

        if (response_arr.includes(name)) {
          if (sub_section.mandatory && value == "") {
            sub_section.errorStatus = 1;
            sub_section.errorMessage = requiredMessage;

            // if (value === "") {
            // } else if (
            //   isNaN(value) &&
            //   (name == `responses[${section_index}].number` ||
            //     name == `responses[${section_index}].days` ||
            //     name == `responses[${section_index}].hours`)
            // ) {
            //   sub_section.errorStatus = 1;
            //   sub_section.errorMessage = validNumberMessage;
          } else {
            sub_section.errorStatus = 0;
            sub_section.errorMessage = "";
          }
          // }

          if (
            isNaN(value) &&
            (name == `responses[${section_index}].number` ||
              // name == `responses[${section_index}].days` ||
              name == `responses[${section_index}].hours`)
          ) {
            sub_section.errorStatus = 1;
            sub_section.errorMessage = validNumberMessage;
          } else {
            sub_section.errorStatus = 0;
            sub_section.errorMessage = "";
          }

          sub_section.answer_value = value;

          if (sub_section.logic === 1) {
            // make every isActive = 0 and then set isActive = 1 for selected radio field
            sub_section.section_question_response =
              sub_section.section_question_response.map((lItem) => ({
                ...lItem,
                isActive: 0,
              }));

            var section_logic_item =
              sub_section.section_question_response[logic_radio_index];
            if (section_logic_item.status == 1) {
              section_logic_item.isActive = 1;
            } else {
              section_logic_item.isActive = 0;
            }
            sub_section.section_question_response[logic_radio_index] =
              section_logic_item;
          }
        } else if (name === `responses[${section_index}].notes`) {
          sub_section.note_value = value;
        } else if (name === `section_responses[${section_index}].file`) {
          const lowStorage = this.checkLowStorage();
          lowStorage
            .then(async (response) => {
              const { status, msg } = response.data;

              if (status == 200) {
                sub_section.evidence_url = evidence_file;

                // Create file string
                let reader = new FileReader();
                reader.readAsDataURL(evidence_file[0]);
                reader.onloadend = () => {
                  sub_section.evidence_file = reader.result;
                };
              }

              await this.setState({
                lowStorageStatus: status,
                lowStorageMessage: msg,
                showLowStoragePopup: status == 200 ? false : true,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (name === `file-upload-${section_index}`) {
          const lowStorage = this.checkLowStorage();
          lowStorage
            .then(async (response) => {
              const { status, msg } = response.data;

              if (status == 200) {
                sub_section.errorStatus = 0;
                sub_section.errorMessage = "";

                sub_section.answer_value_url = evidence_file;

                let reader = new FileReader();
                reader.readAsDataURL(evidence_file[0]);
                reader.onloadend = () => {
                  sub_section.answer_value = reader.result;
                  sub_section.file_type = evidence_file[0].type.includes(
                    "image"
                  )
                    ? 1
                    : 2;
                };
              }

              await this.setState({
                lowStorageStatus: status,
                lowStorageMessage: msg,
                showLowStoragePopup: status == 200 ? false : true,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (recommendation1 == "recommendation") {
          let recData = sub_section.recommendationData || [];
          console.log(recData);
          sub_section.recommendationData = recData
            ? [...recData, value]
            : [value];
        }
        if (deleteEdit === 1) {
          let recObj = sub_section.recommendationData.filter(
            (data, idx) => idx === recIndex
          )[0];
          if (recObj) {
            this.state.deleteRecommendationIds.push(recObj.recommendation_id);
            console.log(this.state.deleteRecommendationIds);
          }
          sub_section.recommendationData =
            sub_section.recommendationData.filter(
              (data, idx) => idx !== recIndex
            );
        }
        if (deleteEdit === 2) {
          let recObj = sub_section.recommendationData.filter(
            (data, idx) => idx === recIndex
          )[0];
          console.log(recObj);
          recommendation = recObj.recommendation;
          selectedCategory = recObj.category;
          selectedPriority = recObj.priority;
          responsiblePerson = recObj.res_person;
          selectedTargetDate = new Date(recObj.target_date);
          showRecommendationAdd = true;
          recommendationAddType = "section-question";
          recommendationIndex = index;
          recommendationId = recObj.recommendation_id;
          sub_section.recommendationData =
            sub_section.recommendationData.filter(
              (data, idx) => idx !== recIndex
            );
        }

        console.log("section == ", section);
        console.log("sub section ==", sub_section);
        userFormView.section[index] = section;
        return {
          userFormView,
          recommendation,
          selectedCategory,
          selectedPriority,
          selectedTargetDate,
          responsiblePerson,
          showRecommendationAdd,
          recommendationAddType,
          recommendationIndex,
          recommendationId,
        };
      }
    });
  };

  _handleInputLogic = async (
    event,
    index = 0,
    type = "question",
    section_index = 0,
    logic_radio_index = 0,
    index2 = 0,
    recommendation1 = "",
    deleteEdit = 0,
    recIndex = 0
  ) => {
    debugger
    if (recommendation1 == "recommendation") {
      var value = event;
    } else {
      var value = event.target.value;
      var name = event.target.name;
      var input_type = event.target.type;
    }

    if (input_type === "file") {
      var evidence_file = Array.from(event.target.files);
    }

    await this.setState((prevState) => {
      let {
        userFormView,
        recommendation,
        selectedCategory,
        selectedPriority,
        selectedTargetDate,
        responsiblePerson,
        showRecommendationAdd,
        recommendationAddType,
        recommendationIndex,
        recommendationId,
      } = prevState;

      /* Setting state value for Logic box Questions */
      if (type === "question") {
        let item = userFormView.questions[index];
        if (item.logic === 1 && item.response.length > 0) {
          debugger
          item.response = item.response.map((qItem, qIndex) => {
            debugger
            qItem.logic_questions = qItem.logic_questions.map((lItem) => {
              const response_arr = [
                `responses[${index}].number`,
                `responses[${index}].answer`,
                `color${index}`,
                `single${index}`,
                `responses[${index}].days`,
                `responses[${index}].hours`,
                `responses[${index}].recommendation`,
              ];
              if (response_arr.includes(name)) {
                if (lItem.mandatory == 1 && value == "") {
                  lItem.errorStatus = 1;
                  // lItem.answer_value = value;
                } else {
                  lItem.errorStatus = 0;
                  // lItem.answer_value = value;
                }
                lItem.answer_value = value;
              } else if (name === `responses[${index}].notes`) {
                lItem.note_value = value;
              } else if (name === `question_logic_responses[${index}].file`) {
                const lowStorage = this.checkLowStorage();
                lowStorage
                  .then(async (response) => {
                    const { status, msg } = response.data;

                    if (status == 200) {
                      lItem.evidence_url = evidence_file;
                      let reader = new FileReader();
                      reader.readAsDataURL(evidence_file[0]);
                      reader.onloadend = () => {
                        lItem.evidence_file = reader.result;
                      };
                    }

                    await this.setState({
                      lowStorageStatus: status,
                      lowStorageMessage: msg,
                      showLowStoragePopup: status == 200 ? false : true,
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (recommendation1 == "recommendation") {
                let recData = lItem.recommendationData || [];
                console.log(recData);
                lItem.recommendationData = recData
                  ? [...recData, value]
                  : [value];
              }
              if (deleteEdit === 1) {
                let recObj = lItem.recommendationData.filter(
                  (data, idx) => idx === recIndex
                )[0];
                if (recObj) {
                  this.state.deleteRecommendationIds.push(
                    recObj.recommendation_id
                  );
                  console.log(this.state.deleteRecommendationIds);
                }
                lItem.recommendationData = lItem.recommendationData.filter(
                  (data, idx) => idx !== recIndex
                );
              }
              if (deleteEdit === 2) {
                let recObj = lItem.recommendationData.filter(
                  (data, idx) => idx === recIndex
                )[0];
                console.log(recObj);
                recommendation = recObj.recommendation;
                selectedCategory = recObj.category;
                selectedPriority = recObj.priority;
                responsiblePerson = recObj.res_person;
                selectedTargetDate = new Date(recObj.target_date);
                showRecommendationAdd = true;
                recommendationAddType = "logic";
                recommendationIndex = index;
                recommendationId = recObj.recommendation_id;
                lItem.recommendationData = lItem.recommendationData.filter(
                  (data, idx) => idx !== recIndex
                );
              }
              console.log(lItem);
              return lItem;
            });
            return qItem;
          });
        }
        userFormView.questions[index] = item;
        return {
          userFormView,
          recommendation,
          selectedCategory,
          selectedPriority,
          selectedTargetDate,
          responsiblePerson,
          showRecommendationAdd,
          recommendationAddType,
          recommendationIndex,
          recommendationId,
        };
      }
      if (type === "section") {
        debugger
        let section = userFormView.section[index];
        let sub_section = section.section_questions[section_index];
        if (
          sub_section.logic == 1 &&
          sub_section.section_question_response.length > 0
        ) {
          sub_section.section_question_response =
            sub_section.section_question_response.map((qItem, qIndex) => {
              qItem.section_logic_questions = qItem.section_logic_questions.map(
                (lItem) => {
                  const response_arr = [
                    `responses[${section_index}].number`,
                    `responses[${section_index}].answer`,
                    `color-${section_index}`,
                    // `single`,
                    // `single-section-${index}-${section_index-logic_radio_index}`,
                    `single-section-${index}-[${section_index}-${logic_radio_index}]`,
                    //  ` single-section-[${index}-${section_index}-${logic_radio_index}].question`
                    // `single-section-${index-section_index}-${logic_radio_index}`
                    // `single-section-${index}-que-${section_index}-${logic_radio_index}`
                    `responses[${section_index}].days`,
                    `responses[${section_index}].hours`,
                    `responses[${section_index}].recommendation`,
                  ];
                  if (response_arr.includes(name)) {
                    if (lItem.mandatory == 1 && value == "") {
                      lItem.errorStatus = 1;
                      lItem.answer_value = value;
                    } else {
                      lItem.errorStatus = 0;
                      lItem.answer_value = value;
                    }
                  } else if (name === `responses[${section_index}].notes`) {
                    lItem.note_value = value;
                  } else if (
                    name === `section_logic_responses[${section_index}].file`
                  ) {
                    const lowStorage = this.checkLowStorage();
                    lowStorage
                      .then(async (response) => {
                        const { status, msg } = response.data;

                        if (status == 200) {
                          lItem.evidence_url = evidence_file;
                          let reader = new FileReader();
                          reader.readAsDataURL(evidence_file[0]);
                          reader.onloadend = () => {
                            lItem.evidence_file = reader.result;
                          };
                        }

                        await this.setState({
                          lowStorageStatus: status,
                          lowStorageMessage: msg,
                          showLowStoragePopup: status == 200 ? false : true,
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  if (recommendation1 == "recommendation") {
                    let recData = lItem.recommendationData || [];
                    console.log(recData);
                    lItem.recommendationData = recData
                      ? [...recData, value]
                      : [value];
                  }
                  if (deleteEdit === 1) {
                    let recObj = lItem.recommendationData.filter(
                      (data, idx) => idx === recIndex
                    )[0];
                    if (recObj) {
                      this.state.deleteRecommendationIds.push(
                        recObj.recommendation_id
                      );
                      console.log(this.state.deleteRecommendationIds);
                    }
                    lItem.recommendationData = lItem.recommendationData.filter(
                      (data, idx) => idx !== recIndex
                    );
                  }
                  if (deleteEdit === 2) {
                    let recObj = lItem.recommendationData.filter(
                      (data, idx) => idx === recIndex
                    )[0];
                    console.log(recObj);
                    recommendation = recObj.recommendation;
                    selectedCategory = recObj.category;
                    selectedPriority = recObj.priority;
                    responsiblePerson = recObj.res_person;
                    selectedTargetDate = new Date(recObj.target_date);
                    showRecommendationAdd = true;
                    recommendationAddType = "section-logic";
                    recommendationIndex = index;
                    recommendationId = recObj.recommendation_id;
                    lItem.recommendationData = lItem.recommendationData.filter(
                      (data, idx) => idx !== recIndex
                    );
                  }
                  return lItem;
                }
              );
              return qItem;
            });
        }
        /* Checking mandatory filed and value for validation */
        section.section_questions[section_index] = sub_section;
        userFormView.section[index] = section;
        return {
          userFormView,
          recommendation,
          selectedCategory,
          selectedPriority,
          selectedTargetDate,
          responsiblePerson,
          showRecommendationAdd,
          recommendationAddType,
          recommendationIndex,
          recommendationId,
        };
      }
    });
  };

  _handleSubmit = async (event, form_action_type) => {
    event.preventDefault();

    if (
      !this.state.declarationChecked &&
      form_action_type === 2 &&
      this.state.declarationAvailable === 1
    ) {
      AlertMessage({
        message: "Please read the Declaration carefully and tick it",
        type: "error",
      });
      return;
    }

    var FORM_STATUS = [];
    let numberDecimalRegex = /^[0-9]+\.?[0-9]*$/;

    /* Question Array process */
    let handleValidationPromise = new Promise((resolve, error) => {
      // Question promise
      let questionPromise = new Promise((qResolve, qError) => {
        let { userFormView } = this.state;
        if (userFormView.questions && userFormView.questions.length) {
          userFormView.questions.forEach(async (element, index) => {
            // mandatory Question
            if (element.mandatory == 1) {
              let message = requiredMessage;
              let errorFlag = 0;

              if (element.answer_value == "") {
                FORM_STATUS = [...FORM_STATUS, false];
                message = requiredMessage;
                errorFlag = 1;
              } else if (
                (element.response_type == 3 ||
                  // element.response_type == 6 ||
                  element.response_type == 7) &&
                !numberDecimalRegex.test(element.answer_value)
              ) {
                FORM_STATUS = [...FORM_STATUS, false];
                message = validNumberMessage;
                errorFlag = 1;
              }

              await this.setState((prevState) => {
                let { userFormView, submitFormStatus } = prevState;
                let item = userFormView.questions[index];
                /* Checking mandatory filed and value for validation */
                item.errorStatus = errorFlag;
                item.errorMessage = message;
                userFormView.questions[index] = item;
                submitFormStatus = false;
                return { userFormView, submitFormStatus };
              });
            } else if (
              (element.response_type == 3 ||
                // element.response_type == 6 ||
                element.response_type == 7) &&
              !numberDecimalRegex.test(element.answer_value)
            ) {
              FORM_STATUS = [...FORM_STATUS, false];

              await this.setState((prevState) => {
                let { userFormView, submitFormStatus } = prevState;
                let item = userFormView.questions[index];
                item.errorStatus = 1;
                item.errorMessage = validNumberMessage;
                userFormView.questions[index] = item;
                submitFormStatus = false;
                return { userFormView, submitFormStatus };
              });
            }

            if (element.response_type == 1 || element.response_type == 4) {
              debugger
              element.answer_value =
                element.response.filter(
                  (data, ind) => element.answer_value == data.response
                )[0]?.response_id || element.answer_value;
            }

            // Logic field process
            if (
              element.logic == 1 &&
              element.response.length > 0 &&
              (element.question_type == 1 || element.question_type == 4)
            ) {
              element.response.forEach(async (subitem, subindex) => {
                if (
                  (element.answer_value == subitem.response ||
                    element.answer_value == subitem.response_id) &&
                  subitem.status
                ) {
                  subitem.logic_questions.forEach(
                    async (logic_item, logic_index) => {
                      if (logic_item.mandatory == 1) {
                        let message = requiredMessage;
                        let errorFlag = 0;

                        if (logic_item.answer_value == "") {
                          FORM_STATUS = [...FORM_STATUS, false];
                          message = requiredMessage;
                          errorFlag = 1;
                        } else if (
                          (logic_item.response_type == 3 ||
                            // logic_item.response_type == 6 ||
                            logic_item.response_type == 7) &&
                          !numberDecimalRegex.test(logic_item.answer_value)
                        ) {
                          FORM_STATUS = [...FORM_STATUS, false];
                          message = validNumberMessage;
                          errorFlag = 1;
                        }

                        await this.setState((prevState) => {
                          let { userFormView, submitFormStatus } = prevState;
                          let item = userFormView.questions[index];
                          item.response = item.response.map((qItem) => {
                            qItem.logic_questions = qItem.logic_questions.map(
                              (lItem) => {
                                lItem.errorStatus = errorFlag;
                                lItem.errorMessage = message;
                                return lItem;
                              }
                            );
                            return qItem;
                          });
                          userFormView.questions[index] = item;
                          submitFormStatus = false;
                          return { userFormView, submitFormStatus };
                        });
                      } else if (
                        (logic_item.response_type == 3 ||
                          // logic_item.response_type == 6 ||
                          logic_item.response_type == 7) &&
                        !numberDecimalRegex.test(logic_item.answer_value)
                      ) {
                        FORM_STATUS = [...FORM_STATUS, false];

                        await this.setState((prevState) => {
                          let { userFormView, submitFormStatus } = prevState;
                          let item = userFormView.questions[index];
                          item.response = item.response.map((qItem) => {
                            qItem.logic_questions = qItem.logic_questions.map(
                              (lItem) => {
                                lItem.errorStatus = 1;
                                lItem.errorMessage = validNumberMessage;
                                return lItem;
                              }
                            );
                            return qItem;
                          });
                          userFormView.questions[index] = item;
                          submitFormStatus = false;
                          return { userFormView, submitFormStatus };
                        });
                      }
                      if (
                        logic_item.response_type == 1 ||
                        logic_item.response_type == 4
                      ) {
                        debugger
                        logic_item.answer_value =
                          logic_item?.logic_response?.filter(
                            (data, ind) =>
                              // (logic_item.answer_value != undefined) &&
                              logic_item.answer_value == data.response
                          )[0].logic_response_id || logic_item.answer_value;
                      }
                    }
                  );
                }
              });
            }
            if (index === this.state.userFormView.questions.length - 1) {
              qResolve(true);
            }
          });
        } else {
          qResolve(true);
        }
      });

      questionPromise.then((response) => {
        /* OnSubmit checking section questions filed validations */

        let { userFormView } = this.state;
        if (userFormView.section && userFormView.section.length) {
          userFormView.section.forEach(async (element, index) => {
            element.section_questions.forEach(async (item, subindex) => {
              if (item.mandatory == 1) {
                let message = requiredMessage;
                let errorFlag = 0;

                if (item.answer_value == "") {
                  FORM_STATUS = [...FORM_STATUS, false];
                  message = requiredMessage;
                  errorFlag = 1;
                } else if (
                  (item.response_type == 3 ||
                    // item.response_type == 6 ||
                    item.response_type == 7) &&
                  !numberDecimalRegex.test(item.answer_value)
                ) {
                  FORM_STATUS = [...FORM_STATUS, false];
                  message = validNumberMessage;
                  errorFlag = 1;
                }

                await this.setState((prevState) => {
                  let { userFormView } = prevState;
                  let section = userFormView.section[index];
                  let sub_section = section.section_questions[subindex];

                  sub_section.errorStatus = errorFlag;
                  sub_section.errorMessage = message;

                  section.section_questions[subindex] = sub_section;
                  userFormView.section[index] = section;
                  return { userFormView };
                });
              } else if (
                (item.response_type == 3 ||
                  // item.response_type == 6 ||
                  item.response_type == 7) &&
                !numberDecimalRegex.test(item.answer_value)
              ) {
                let errorFlag = 0;
                FORM_STATUS = [...FORM_STATUS, false];
                errorFlag = 1;

                await this.setState((prevState) => {
                  let { userFormView } = prevState;
                  let section = userFormView.section[index];
                  let sub_section = section.section_questions[subindex];

                  sub_section.errorStatus = errorFlag;
                  sub_section.errorMessage = validNumberMessage;

                  section.section_questions[subindex] = sub_section;
                  userFormView.section[index] = section;
                  return { userFormView };
                });
              }

              if (item.response_type == 1 || item.response_type == 4) {
                debugger
                item.answer_value =
                  item?.section_question_response?.filter(
                    (data, ind) => item.answer_value == data.response
                  )[0]?.response_id || item.answer_value;
              }

              /* OnSubmit checking logic filed validations */
              if (
                item.logic == 1 &&
                item.section_question_response.length > 0
              ) {
                item.section_question_response.forEach(
                  async (subItem, subIndex) => {
                    if (
                      (item.answer_value == subItem.response ||
                        item.answer_value == subItem.response_id) &&
                      subItem.status
                    ) {
                      subItem.section_logic_questions.forEach(async (lItem) => {
                        if (lItem.mandatory === 1) {
                          let message = requiredMessage;
                          let errorFlag = 0;

                          if (lItem.answer_value == "") {
                            FORM_STATUS = [...FORM_STATUS, false];
                            errorFlag = 1;
                          } else if (
                            (lItem.response_type == 3 ||
                              // lItem.response_type == 6 ||
                              lItem.response_type == 7) &&
                            !numberDecimalRegex.test(lItem.answer_value)
                          ) {
                            FORM_STATUS = [...FORM_STATUS, false];
                            message = validNumberMessage;
                            errorFlag = 1;
                          }

                          await this.setState((prevState) => {
                            let { userFormView } = prevState;
                            let section = userFormView.section[index];
                            let sub_section =
                              section.section_questions[subindex];
                            sub_section.section_question_response =
                              sub_section.section_question_response.map(
                                (qItem) => {
                                  qItem.section_logic_questions =
                                    qItem.section_logic_questions.map(
                                      (lItem) => {
                                        lItem.errorStatus = errorFlag;
                                        lItem.errorMessage = message;
                                        return lItem;
                                      }
                                    );
                                  return qItem;
                                }
                              );
                            section.section_questions[subindex] = sub_section;
                            userFormView.section[index] = section;
                            return { userFormView };
                          });
                        } else if (
                          (lItem.response_type == 3 ||
                            // lItem.response_type == 6 ||
                            lItem.response_type == 7) &&
                          !numberDecimalRegex.test(lItem.answer_value)
                        ) {
                          FORM_STATUS = [...FORM_STATUS, false];

                          await this.setState((prevState) => {
                            let { userFormView } = prevState;
                            let section = userFormView.section[index];
                            let sub_section =
                              section.section_questions[subindex];
                            sub_section.section_question_response =
                              sub_section.section_question_response.map(
                                (qItem) => {
                                  qItem.section_logic_questions =
                                    qItem.section_logic_questions.map(
                                      (lItem) => {
                                        lItem.errorStatus = 1;
                                        lItem.errorMessage = validNumberMessage;
                                        return lItem;
                                      }
                                    );
                                  return qItem;
                                }
                              );
                            section.section_questions[subindex] = sub_section;
                            userFormView.section[index] = section;
                            return { userFormView };
                          });
                        }
                        if (
                          lItem.response_type == 1 ||
                          lItem.response_type == 4
                        ) {
                          debugger
                          lItem.answer_value =
                            lItem?.section_logic_response?.filter(
                              (data, ind) => lItem.answer_value == data.response
                            )[0].logic_response_id || lItem.answer_value;
                        }
                      });
                    }
                  }
                );
              }
            });
            if (index === this.state.userFormView.section.length - 1) {
              resolve(FORM_STATUS);
            }
          });
        } else {
          resolve(FORM_STATUS);
        }
      });
    });

    handleValidationPromise.then(async (response) => {
      // If form not have any error
      if (!response.includes(false)) {
        var QDATA = [];

        let { userFormView } = this.state;
        if (userFormView.questions && userFormView.questions.length) {
          QDATA = userFormView.questions.map((element, index) => {
            console.log(element);
            return {
              question_id: element.question_id,
              question_input_id: element.response_type,
              input_answer:
                element.response_type == 5 || element.response_type == 11
                  ? ""
                  : element.answer_value,
              scoring: element.total_score > 0 ? 1 : 0,
              recommendation_data: element.recommendationData,
              file:
                element.response_type == 5 || element.response_type == 11
                  ? element.answer_value
                  : null,
              note: element.note_value,
              user_response_logic: element.response
                .filter((ele) => ele.logic_questions.length > 0)
                .map((logic_item) => {
                  return {
                    user_response_logic_id:
                      logic_item.logic_questions[0].logic_question_id,
                    input_answer: logic_item.logic_questions[0].answer_value,
                    note: logic_item.logic_questions[0].note_value,
                    scoring:
                      logic_item.logic_questions[0].total_score > 0
                        ? 1
                        : 0,
                    recommendation_data:
                      logic_item.logic_questions[0].recommendationData,
                    user_response_logic_evidence:
                      logic_item.logic_questions[0].evidence_file !== ""
                        ? [
                          {
                            evidence:
                              logic_item.logic_questions[0].evidence_file,
                          },
                        ]
                        : [],
                  };
                }),
              user_response_evidence:
                element.evidence_url?.length > 200
                  ? [{ evidence: element.evidence_url }]
                  : [],
            };
          });
        }

        var SDATA = [];
        if (userFormView.section && userFormView.section.length > 0) {
          userFormView.section.forEach((sectionItem) => {
            var arr = [];
            arr = sectionItem.section_questions.map((subItem) => {
              return {
                question_id: subItem.question_id,
                question_input_id: subItem.response_type,
                input_answer: subItem.answer_value,
                scoring: subItem.total_score > 0 ? 1 : 0,
                file: subItem.response_type == 5 ? subItem.answer_value : null,
                file_type: subItem.file_type,
                note: subItem.note_value,
                recommendation_data: subItem.recommendationData,
                user_response_logic: subItem.section_question_response
                  .filter((ele) => ele.section_logic_questions.length > 0)
                  .map((logic_item) => {
                    return {
                      input_answer:
                        logic_item.section_logic_questions[0].answer_value,
                      note: logic_item.section_logic_questions[0].note_value,
                      recommendation_data:
                        logic_item.section_logic_questions[0]
                          .recommendationData,
                      scoring:
                        logic_item.section_logic_questions[0].total_score > 0
                          ? 1
                          : 0,
                      user_response_logic_evidence:
                        logic_item.section_logic_questions[0].evidence_file !==
                          ""
                          ? [
                            {
                              evidence:
                                logic_item.section_logic_questions[0]
                                  .evidence_file,
                            },
                          ]
                          : [],
                    };
                  }),
                user_response_evidence:
                  subItem.evidence_file?.length > 200
                    ? [{ evidence: subItem.evidence_file }]
                    : [],
              };
            });
            SDATA = [...SDATA, ...arr];
          });
        }

        QDATA = [...QDATA, ...SDATA];
        if (QDATA.length > 0) {
          this.setState({
            finalQDATA: QDATA,
            form_action_type: form_action_type,
          });
          this._showSubmitConfirmPopup();
          // this._submitAPIHandler();
        }
      } else {
        console.log(response);
      }
    });
  };

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _handleLowSpace = async (statusFlag) => {
    await this.setState({ showLowStoragePopup: false });
  };

  _submitUserResponse = async () => {
    await this._submitAPIHandler();
  };

  _submitAPIHandler = async () => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = true;
      return { dataLoading };
    });

    let { finalQDATA, form_action_type } = this.state;

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    await axios
      .post(
        Url.external.externalUserResponse,
        {
          form_info: {
            form_id: Number(this.state.reportingFormId),
            work_permit_use_id: Number(this.state.surveyId) || 0,
          },
          delete_evidence_ids: this.state.deleteEvidencesIds.toString(),
          delete_recommendation_ids:
            this.state.deleteRecommendationIds.toString(),
          delete_file_ids: this.state.deleteFileIds.toString(),
          recommendation_data: this.state.userFormView.recommendationData,
          user_response: finalQDATA,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        if (response.data.status === 200) {
          AlertMessage({ message: userFormSubmitMessage, type: "success" });
          this.props.history.push(`/`);
        }
        await this.setState((prevState) => {
          let { dataLoading } = prevState;
          dataLoading = false;
          return { dataLoading };
        });
      })
      .catch((error) => {
        this.setState((prevState) => {
          let { dataLoading } = prevState;
          dataLoading = true;
          return { dataLoading };
        });
      });
  };

  _getDynamicAlignment = (align = 0) => {
    return `que-option d-flex flexAlignment${align}`;
  };

  _showSubmitConfirmPopup = async () => {
    await this.setState({ showConfirmSubmitPopup: true });
  };

  _handleSubmitConfirmCancel = async () => {
    await this.setState({ showConfirmSubmitPopup: false });
  };

  _handleLightBox = async (evidence, type) => {
    if (this.state.dataLoading === false) {
      await this.setState({
        sourcesList: [],
        sourcesType: [],
      });
      if (this.state.imageType && this.state.imageType === "evidence") {
        await this.setState({
          sourcesList: [...this.state.sourcesList, evidence],
          sourcesType: [
            ...this.state.sourcesType,
            type == 2 ? "video" : "image",
          ],
        });
      }
    }
    this.setState({
      lightboxactive: true,
      toggler: true,
      fsToggler: !this.state.fsToggler,
      imageType: "evidence",
    });
  };

  _priorityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedPriority, priorityError } = prevState;
      selectedPriority = id;
      priorityError = id ? false : true;
      return { selectedPriority, priorityError };
    });
  };

  _recommendationChangeHandle = async (text) => {
    await this.setState((prevState) => {
      let { recommendation, recommendationError } = prevState;
      recommendation = text;
      recommendationError = text ? false : true;
      return { recommendation, recommendationError };
    });
  };

  _fetchCategoryList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      page_no: 0,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(Url.adminMaster.categoryList, qs.stringify(requestData), {
        headers: ContentType,
      })
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({ categoryList: response.data.data.list });
        }
      })
      .catch((error) => { });
  };

  _categoryChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedCategory, categoryError } = prevState;
      selectedCategory = id;
      categoryError = id ? false : true;
      return { selectedCategory, categoryError };
    });
    console.log(id);
  };

  _targetDateChangeHandle = async (date) => {
    await this.setState((prevState) => {
      let { selectedTargetDate, targetDateError } = prevState;
      selectedTargetDate = date;
      targetDateError = date ? false : true;
      return { selectedTargetDate, targetDateError };
    });
  };

  _fetchUserList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      service_id: this.state.serviceId,
      project_id: this.state.projectId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(Url.UserList, qs.stringify(requestData), {
        headers: ContentType,
      })
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({ userList: response.data.data });
        }
      })
      .catch((error) => { });
  };

  _responsiblePersonHandle = async (id) => {
    console.log(id);
    await this.setState((prevState) => {
      let { responsiblePerson, responsiblePersonError } = prevState;
      responsiblePerson = id;
      responsiblePersonError = id ? false : true;
      return { responsiblePerson, responsiblePersonError };
    });
  };

  _addRecommendation = async (index, type, subIndex) => {
    await this._formValidation();

    // Checking form have any Error
    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          formErrorStatus = true;
          return;
        } else {
          formErrorStatus = false;
        }
      }
    }

    // If form not have error then submit form
    if (!formErrorStatus) {
      let obj = {
        recommendation: this.state.recommendation,
        res_person: this.state.responsiblePerson,
        category: this.state.selectedCategory,
        // target_date: moment(this.state.selectedTargetDate).format("YYYY-MM-DD"),
        target_date: convertDateToUTC(
          this.state.selectedTargetDate,
          "YYYY-MM-DD"
        ),
        priority: this.state.selectedPriority,
        recommendation_id: this.state.recommendationId,
      };
      if (type == "form") {
        await this._addFormRecommendation(obj);
      } else if (type == "question" || type == "section") {
        await this._handleInputChange(
          obj,
          index,
          type,
          subIndex,
          0,
          "recommendation"
        );
      } else {
        await this._handleInputLogic(
          obj,
          index,
          type == "question-logic" ? "question" : "section",
          subIndex,
          0,
          "recommendation"
        );
      }

      await this.setState({
        showRecommendationAdd: false,
        recommendation: null,
        responsiblePerson: null,
        selectedCategory: null,
        selectedTargetDate: new Date(),
        selectedPriority: null,
        recommendationId: 0,
        recommendationSectionIndex: null,
        recommendationIndex: null,
      });
    }
  };

  _addFormRecommendation = async (obj) => {
    this.setState((prevState) => {
      let { userFormView } = prevState;
      let recData = userFormView.recommendationData || [];
      console.log(recData);
      userFormView.recommendationData = recData ? [...recData, obj] : [obj];
      console.log(userFormView);
      return { userFormView };
    });
  };

  _handleFormRecommendationDelete = async (index) => {
    this.setState((prevState) => {
      let { userFormView } = prevState;
      let recObj = userFormView.recommendationData.filter(
        (data, idx) => idx == index
      )[0];
      this.state.deleteRecommendationIds.push(recObj.recommendation_id);
      userFormView.recommendationData = userFormView.recommendationData.filter(
        (data, idx) => idx !== index
      );
      console.log(userFormView);
      return { userFormView };
    });
  };

  _handleFormRecommendationEdit = async (index) => {
    this.setState((prevState) => {
      let {
        userFormView,
        recommendation,
        selectedCategory,
        selectedPriority,
        selectedTargetDate,
        responsiblePerson,
        showRecommendationAdd,
        recommendationAddType,
        recommendationIndex,
        recommendationId,
      } = prevState;

      let recObj = userFormView.recommendationData.filter(
        (data, idx) => idx === index
      )[0];
      console.log(recObj);
      recommendation = recObj.recommendation;
      selectedCategory = recObj.category;
      selectedPriority = recObj.priority;
      responsiblePerson = recObj.res_person;
      selectedTargetDate = new Date(recObj.target_date);
      showRecommendationAdd = true;
      recommendationAddType = "form";
      recommendationId = recObj.recommendation_id;
      userFormView.recommendationData = userFormView.recommendationData.filter(
        (data, idx) => idx !== index
      );

      console.log(userFormView);
      return {
        userFormView,
        recommendation,
        selectedCategory,
        selectedPriority,
        selectedTargetDate,
        responsiblePerson,
        showRecommendationAdd,
        recommendationAddType,
        recommendationIndex,
        recommendationId,
      };
    });
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.recommendationError = tempObj.recommendation ? false : true;

      prevState.priorityError = tempObj.selectedPriority ? false : true;

      prevState.targetDateError = tempObj.selectedTargetDate ? false : true;

      prevState.responsiblePersonError = tempObj.responsiblePerson
        ? false
        : true;

      prevState.categoryError = tempObj.selectedCategory ? false : true;

      return prevState;
    });
  };

  createMarkup(rawHtml) {
    return { __html: rawHtml };
  }

  _uploadFile = async (file) => {
    await this.setState((prevState) => {
      let { selectedFiles } = prevState;
      selectedFiles.push(file);
      console.log(selectedFiles);
      return { selectedFiles };
    });
  };

  _handleLightBoxView = async (imageURL) => {
    await this.setState({
      showImage: imageURL,
      showLightBoxImage: !this.state.showLightBoxImage,
    });
  };

  _showChangeStartDatePopup = async () => {
    AlertMessage({ message: "Please change the Start Date", type: "error" });
  };

  _showChangeEndDatePopup = async () => {
    AlertMessage({
      message:
        "End date is of previous date. Please change the End Date or Continue",
    });
  };

  render() {
    return (
      <div className="clide-v2-colume_title" id="templateDetail">
        <FsLightbox
          toggler={this.state.fsToggler}
          types={this.state.sourcesType}
          sources={this.state.sourcesList}
          disableLocalStorage={true}
          key={this.state.sourcesList}
        />
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        <div className="create-form">
          <div className="profile-section">
            <div className="profile-top white-bg">
              {/* <h2>
                <Backbutton />
                {this.state.title} 
              </h2> */}
            </div>
          </div>

          <div
            className="user-section"
            style={{
              width: this.state.windowWidth > mediaQuery.phone ? "50%" : "100%",
              //more styling :)
            }}
          >
            {/* {JSON.stringify(this.state.windowWidth)} */}

            <Row
              className={`${this.state.windowWidth > mediaQuery.phone ? "sm" : "xl"
                } first-row`}
            >
              <Col>
               
                <Row>
                  {!this.state.workPermitUseId && (
                    <div className="form-group">
                      <label className="form-label">Scheduled</label>
                      <p className="form-view-text">
                        {isValid(
                          formType[this.state.userFormView.basic_info?.type]
                        )
                          ? formType[this.state.userFormView.basic_info?.type]
                          : "N/A"}
                      </p>
                    </div>
                  )}
                </Row>
              </Col>
              {/* <Col sm={8}>
                        <div className="form-group">
                          <label className="form-label">Description</label>
                          <p className="form-view-text">
                            {isValid(
                              this.state.userFormView.basic_info?.description
                            )
                              ? this.state.userFormView.basic_info?.description
                              : "N/A"}
                          </p>
                        </div>
                      </Col> */}
            </Row>
            {(this.state.userFormView.basic_info?.objective.length > 0 ||
              this.state.userFormView.basic_info?.benefit.length > 0) && (
                <Row className="second-row box-dynamic first-box-dynamic p-4">
                  <Col className="pl-0">
                    {this.state.userFormView.basic_info?.objective.length > 0 ? (
                      <div className="form-group box-dynamic first-box-dynamic">
                        <label className="form-label clide-v2-primary p-2 clide-v2-section-info">Objective</label>
                        {this.state.userFormView.basic_info?.objective &&
                          this.state.userFormView.basic_info?.objective.map(
                            (objective, index) => (
                              <ul key={index}>
                                <li className="form-view-text">
                                  {objective.objectives}
                                </li>
                              </ul>
                            )
                          )}
                      </div>
                    ) : null}
                  </Col>
                  <Col className="pr-0">
                    {this.state.userFormView.basic_info?.benefit.length > 0 ? (
                      <div className="form-group box-dynamic first-box-dynamic">
                        <label className="form-label clide-v2-primary p-2 clide-v2-section-info">
                          Benefit to Organization
                        </label>
                        {this.state.userFormView.basic_info?.benefit &&
                          this.state.userFormView.basic_info?.benefit.map(
                            (benefit, index) => (
                              <ul key={index}>
                                <li className="form-view-text">
                                  {benefit.benefits}
                                </li>
                              </ul>
                            )
                          )}
                      </div>
                    ) : null}
                  </Col>
                  <Row className="box-dynamic first-box-dynamic text-center mb-1">
              <div
               className="  clide-v2-border-radius-12 "
              >
                {this.state.userFormView.basic_info?.file_name && (
                  <div>
                    {(this.state.userFormView.basic_info?.file_name.includes(
                      ".png"
                    ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".jpg"
                      ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".jpeg"
                      )) && (
                        <img
                          style={{ height: "auto" }}
                          src={this.state.userFormView.basic_info?.file_name}
                          onClick={() =>
                            this._handleLightBoxView(
                              this.state.userFormView.basic_info?.file_name
                            )
                          }
                        />
                      )}
                    {(this.state.userFormView.basic_info?.file_name.includes(
                      ".mp4"
                    ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".mkv"
                      ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".wav"
                      ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".omv"
                      )) && (

                        <VideoBox url={this.state.userFormView.basic_info
                          ?.file_name} />
                      )}
                  </div>
                )}
                {this.state.userFormView.basic_info?.youtube_link && (
                  <VideoBox url={this.state.userFormView.basic_info?.youtube_link} />
                )}
              </div>
            </Row>
                </Row>
              )}
            {/* <Row className="my-4 box-dynamic first-box-dynamic text-center">
              <div
               className="  clide-v2-border-radius-12"
              >
                {this.state.userFormView.basic_info?.file_name && (
                  <div>
                    {(this.state.userFormView.basic_info?.file_name.includes(
                      ".png"
                    ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".jpg"
                      ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".jpeg"
                      )) && (
                        <img
                          style={{ height: "auto" }}
                          src={this.state.userFormView.basic_info?.file_name}
                          onClick={() =>
                            this._handleLightBoxView(
                              this.state.userFormView.basic_info?.file_name
                            )
                          }
                        />
                      )}
                    {(this.state.userFormView.basic_info?.file_name.includes(
                      ".mp4"
                    ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".mkv"
                      ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".wav"
                      ) ||
                      this.state.userFormView.basic_info?.file_name.includes(
                        ".omv"
                      )) && (

                        <VideoBox url={this.state.userFormView.basic_info
                          ?.file_name} />
                      )}
                  </div>
                )}
                {this.state.userFormView.basic_info?.youtube_link && (
                  <VideoBox url={this.state.userFormView.basic_info?.youtube_link} />
                )}
              </div>
            </Row> */}

            {
              // If Question not found then show not available message
              this.state.dataLoading === false &&
              this.state.userFormView.questions &&
              this.state.userFormView.questions.length === 0 &&
              this.state.userFormView.section &&
              this.state.userFormView.section.length === 0 && (
                <div className="user-section">
                  <div className="">
                    <p className="gray-para">Form not available for Submit</p>
                  </div>
                </div>
              )
            }

            {
              // if form have question then show form
              ((this.state.userFormView.questions &&
                this.state.userFormView.questions.length > 0) ||
                (this.state.userFormView.section &&
                  this.state.userFormView.section.length > 0)) && (
                <>
                  <div className="user-section">
                    {/* Question View Stated */}
                    {this.state.userFormView.questions &&
                      this.state.userFormView.questions.length > 0 && (
                        <div className="">
                          {this.state.userFormView.questions.map(
                            (queData, index2) => {
                              if (
                                !(
                                  queData.response_type === 10 ||
                                  queData.response_type === 5 ||
                                  queData.response_type === 11
                                )
                              ) {
                                return (
                                  <div
                                    className={
                                      "box-dynamic first-box-dynamic " +
                                      (this.state.userFormView.questions
                                        .length ==
                                        index2 + 1
                                        ? "last-user-box"
                                        : "")
                                    }
                                    key={queData.question_id}
                                  >
                                    <h5
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {queData.response_type === 9 ? (
                                        <div
                                          className="p-2"
                                          style={{
                                            display: "inline-block",
                                          }}
                                          dangerouslySetInnerHTML={this.createMarkup(
                                            queData.question
                                          )}
                                        />
                                      ) : (
                                        queData.question
                                      )}
                                      {queData.mandatory === 1 && (
                                        <span
                                          className="star"
                                          style={{ marginLeft: "-37vw" }}
                                        >
                                          *
                                        </span>
                                      )}
                                      {/* {!(queData.total_score == 0) && queData.total_score && (
                                            <span>{`Score : ${queData.score_obtained 
                                              }/${queData.total_score}`}</span>
                                          )}  */}
                                    </h5>

                                    {/* <p className="gray-para">
                                          {queData.question_description}
                                        </p> */}
                                    {queData.response_type === 1 && (
                                      <div className="que-option">
                                        {queData.response &&
                                          queData.response.map(
                                            (singleData, singleIndex) => (
                                              <label
                                                className="d-flex flex-row-reverse justify-content-end gap-2"
                                                key={singleIndex}
                                              >
                                                {singleData.response}
                                                <input
                                                  type="radio"
                                                  name={`single-${index2}`}
                                                  value={singleData.response_id}
                                                  checked={
                                                    queData.answer_value ==
                                                      singleData.response_id ||
                                                      queData.answer_value ==
                                                      singleData.response
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    this._handleInputChange(
                                                      e,
                                                      index2,
                                                      "question",
                                                      0,
                                                      singleIndex
                                                    )
                                                  }
                                                />
                                                <span className="checkmark"   ></span>
                                              </label>
                                            )
                                          )}
                                        {queData.mandatory === 1 &&
                                          queData.errorStatus === 1 && (
                                            <p className="inputErrorMsg">
                                              {queData.errorMessage}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                    {queData.response_type === 2 && (
                                      <div className="que-option">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name={`responses[${index2}].answer`}
                                            placeholder="Write Short Answer"
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index2,
                                                "question"
                                              )
                                            }
                                            className="form-control"
                                            value={queData.answer_value}
                                          />
                                          {queData.mandatory === 1 &&
                                            queData.errorStatus === 1 && (
                                              <p className="inputErrorMsg">
                                                {queData.errorMessage}
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                    {queData.response_type === 3 && (
                                      <div className="que-option">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name={`responses[${index2}].number`}
                                            placeholder="Add Number"
                                            className="form-control"
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index2,
                                                "question"
                                              )
                                            }
                                            value={queData.answer_value}
                                          />
                                          {!!queData.errorStatus && (
                                            <p className="inputErrorMsg">
                                              {queData.errorMessage}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {queData.response_type === 4 && (
                                      <div className="que-option">
                                        <Row>
                                          <Col sm={4}>
                                            <div className="form-group">
                                              <select
                                                as="select"
                                                name={`color-${index2}`}
                                                className="form-control"
                                                onChange={(e) =>
                                                  this._handleInputChange(
                                                    e,
                                                    index2,
                                                    "question"
                                                  )
                                                }
                                                value={queData.answer_value}

                                              >
                                                <option value="">Select Option</option>
                                                {queData.response &&
                                                  queData.response.map(
                                                    (
                                                      dropdownData,
                                                      dropdownIndex
                                                    ) => (
                                                      <>

                                                        <option
                                                          value={
                                                            dropdownData.response_id
                                                          }
                                                          key={dropdownIndex}
                                                        >
                                                          {dropdownData.response}
                                                        </option>
                                                      </>
                                                    )
                                                  )}
                                              </select>
                                              {queData.mandatory === 1 &&
                                                queData.errorStatus === 1 && (
                                                  <p className="inputErrorMsg">
                                                    {queData.errorMessage}
                                                  </p>
                                                )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                    {queData.response_type === 6 && (
                                      <div className="que-option">
                                        <Row>
                                          <Col sm={10}>
                                            <div className="form-group">
                                              <input
                                                type="date"
                                                name={`responses[${index2}].days`}
                                                placeholder="Add Date"
                                                className="form-control"
                                                onChange={(e) =>
                                                  this._handleInputChange(
                                                    e,
                                                    index2,
                                                    "question"
                                                  )
                                                }
                                                value={queData.answer_value}
                                              />
                                              {!!queData.errorStatus && (
                                                <p className="inputErrorMsg">
                                                  {queData.errorMessage}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                          {/* <Col>
                                        <div className="form-group">
                                          <label className="label-middle">
                                            Day(s)
                                          </label>
                                        </div>
                                      </Col> */}
                                        </Row>
                                      </div>
                                    )}
                                    {queData.response_type === 7 && (
                                      <div className="que-option">
                                        <Row>
                                          <Col sm={10}>
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                name={`responses[${index2}].hours`}
                                                placeholder="Add Hours"
                                                className="form-control"
                                                onChange={(e) =>
                                                  this._handleInputChange(
                                                    e,
                                                    index2,
                                                    "question"
                                                  )
                                                }
                                                value={queData.answer_value}
                                              />
                                              {!!queData.errorStatus && (
                                                <p className="inputErrorMsg">
                                                  {queData.errorMessage}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                          <Col>
                                            <div className="form-group">
                                              <label className="label-middle">
                                                Hrs
                                              </label>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                    {queData.response_type === 8 && (
                                      <div className="que-option">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name={`responses[${index2}].recommendation`}
                                            placeholder="Add Recommendation"
                                            className="form-control"
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index2,
                                                "question"
                                              )
                                            }
                                            value={queData.answer_value}
                                          />
                                          {queData.mandatory === 1 &&
                                            queData.errorStatus === 1 && (
                                              <p className="inputErrorMsg">
                                                {queData.errorMessage}
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                    {queData.response_type == 0 && (
                                      <div
                                        className={this._getDynamicAlignment(
                                          queData.alignment || 0
                                        )}
                                      >
                                        {queData.file_status == 1 ? (
                                          <div className="browse-image">
                                            <img
                                              className="inner-logo"
                                              src={queData.file_link}
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <VideoPlayer
                                            videoUrl={queData.file_link || ""}
                                            snapshotAt={1}
                                          />
                                        )}
                                      </div>
                                    )}
                                    {queData.notes == 1 && (
                                      <div className="note-option">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name={`responses[${index2}].notes`}
                                            placeholder="Notes"
                                            className="form-control"
                                            value={queData.note_value}
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index2,
                                                "question"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {queData.evidence === 1 && (
                                      <div key={queData.question_id}>
                                        <div className="file-upload">
                                          <label
                                            htmlFor={`responses[${index2}].file`}
                                            className="file-upload__label"
                                          >
                                            Add More Evidence
                                          </label>
                                          <input
                                            id={`responses[${index2}].file`}
                                            name={`responses[${index2}].file`}
                                            type="file"
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index2,
                                                "question"
                                              )
                                            }
                                            className="inputfile inputfile-2"
                                            accept="image/*"
                                          />
                                        </div>
                                        {/* <div className="image-preview">
                                      {queData.evidence_url != null && queData.evidence_url.length > 0 && (
                                        <div className="preview-box">
                                          <img
                                            src={queData.evidence_url}
                                            alt="evidence_image"
                                          />
                                          <Button
                                            variant="outline-primary"
                                            className="close-btn"
                                            onClick={() =>
                                              this._removePreviewFile(
                                                index2,
                                                0,
                                                "question",
                                                "evidence"
                                              )
                                            }
                                          >
                                            X
                                          </Button>
                                        </div>
                                      )}
                                    </div> */}
                                        {queData.evidence_url != null &&
                                          queData.evidence_url.length > 0 && (
                                            <div>
                                              <div className="image-preview">
                                                <div className="preview-box">
                                                  <img
                                                    onClick={() =>
                                                      this._handleLightBox(
                                                        queData.evidence_url
                                                      )
                                                    }
                                                    src={queData.evidence_url}
                                                    alt="evidence_img"
                                                  />
                                                  <Button
                                                    variant="outline-primary"
                                                    className="close-btn"
                                                    onClick={() =>
                                                      this._removePreviewFile(
                                                        index2,
                                                        0,
                                                        "question",
                                                        "evidence"
                                                      )
                                                    }
                                                  >
                                                    X
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    )}

                                    {/* Recommendation data view */}
                                    {queData.recommendationData.length > 0 && (
                                      <div className="published-section published-table">
                                        <Table bordered striped>
                                          <thead>
                                            <tr>
                                              {/* <th>#</th> */}
                                              <th className="left">
                                                Recommendation
                                              </th>
                                              <th>Priority</th>
                                              <th>Category</th>
                                              <th>Target Date</th>
                                              <th>Responsible Person</th>
                                              <th className="last-th">
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {queData.recommendationData.map(
                                              (data, idx) => (
                                                <tr
                                                  key={idx}
                                                  style={{
                                                    borderBottom:
                                                      "5px solid #f5f5f5",
                                                  }}
                                                >
                                                  <td className="left">
                                                    {data.recommendation}
                                                  </td>
                                                  <td>
                                                    {
                                                      this.state.priorityList.filter(
                                                        (priority) =>
                                                          priority.id ==
                                                          data.priority
                                                      )[0]?.priority
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      this.state.categoryList.filter(
                                                        (category) =>
                                                          category.id ==
                                                          data.category
                                                      )[0]?.name
                                                    }
                                                  </td>
                                                  <td>
                                                    {`${convertDateFromTimezone(
                                                      data.target_date
                                                    )}`}
                                                  </td>
                                                  <td>
                                                    {
                                                      this.state.userList.filter(
                                                        (user) =>
                                                          user.id ==
                                                          data.res_person
                                                      )[0]?.name
                                                    }
                                                  </td>
                                                  <td className="last-td">
                                                    <Button
                                                      className="icon-btn"
                                                      onClick={(e) =>
                                                        this._handleInputChange(
                                                          e,
                                                          index2,
                                                          "question",
                                                          0,
                                                          0,
                                                          0,
                                                          2,
                                                          idx
                                                        )
                                                      }
                                                    >
                                                      <Edit2 className="icon-style" />
                                                    </Button>
                                                    <Button
                                                      className="icon-btn"
                                                      onClick={(e) =>
                                                        this._handleInputChange(
                                                          e,
                                                          index2,
                                                          "question",
                                                          0,
                                                          0,
                                                          0,
                                                          1,
                                                          idx
                                                        )
                                                      }
                                                    >
                                                      <Trash className="icon-style" />
                                                    </Button>
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      </div>
                                    )}

                                    {/* Add Recommendation button  */}
                                    {queData.recommendation == 1 && (
                                      <div className="file-upload">
                                        <label
                                          htmlFor={`responses[${index2}].file`}
                                          className="file-upload__label"
                                          onClick={async () =>
                                            await this.setState({
                                              showRecommendationAdd:
                                                !this.state
                                                  .showRecommendationAdd,
                                              recommendationAddType: "question",
                                              recommendationIndex: index2,
                                            })
                                          }
                                        >
                                          Add Recommendation
                                        </label>
                                      </div>
                                    )}

                                    {/* Recommendation data fill up */}
                                    {queData.recommendation == 1 &&
                                      this.state.showRecommendationAdd &&
                                      this.state.recommendationAddType ===
                                      "question" &&
                                      this.state.recommendationIndex ==
                                      index2 && (
                                        <Row>
                                          <Col sm={10}>
                                            {/* <div className="rd-box-head"> */}
                                            <label>
                                              Recommendation
                                              <span className="inputErrorMsg">
                                                &nbsp;*
                                              </span>
                                            </label>
                                            <input
                                              className="form-control"
                                              value={this.state.recommendation}
                                              onChange={(event) =>
                                                this._recommendationChangeHandle(
                                                  event.target.value
                                                )
                                              }
                                              placeholder={
                                                "Write recommendation..."
                                              }
                                            />
                                            {!!this.state
                                              .recommendationError && (
                                                <p className="inputErrorMsg">
                                                  {requiredMessage}
                                                </p>
                                              )}
                                            {/* </div> */}
                                          </Col>
                                          <Col sm={2}>
                                            <Button
                                              className="icon-btn"
                                              onClick={() =>
                                                this._addRecommendation(
                                                  index2,
                                                  "question"
                                                )
                                              }
                                            >
                                              <Save className="icon-style" />
                                            </Button>
                                          </Col>
                                          <Col sm={3}>
                                            <CommonDropdown
                                              title="Priority"
                                              optionList={
                                                this.state.priorityList
                                              }
                                              onChangeHandle={
                                                this._priorityChangeHandle
                                              }
                                              isRequired={true}
                                              value={
                                                this.state.selectedPriority
                                              }
                                            />
                                            {!!this.state.priorityError && (
                                              <p className="inputErrorMsg">
                                                {requiredMessage}
                                              </p>
                                            )}
                                          </Col>
                                          <Col sm={3}>
                                            <CommonDropdown
                                              title="Category"
                                              optionList={
                                                this.state.categoryList
                                              }
                                              onChangeHandle={
                                                this._categoryChangeHandle
                                              }
                                              value={
                                                this.state.selectedCategory
                                              }
                                              isRequired={true}
                                            />
                                            {!!this.state.categoryError && (
                                              <p className="inputErrorMsg">
                                                {requiredMessage}
                                              </p>
                                            )}
                                          </Col>
                                          <Col sm={3}>
                                            <div className="form-group">
                                              <label className="form-label">
                                                Target Date
                                                <span className="inputErrorMsg">
                                                  &nbsp;*
                                                </span>
                                              </label>
                                              <div className="calender-div">
                                                <DatePicker
                                                  selected={
                                                    this.state
                                                      .selectedTargetDate
                                                  }
                                                  minDate={
                                                    this.state.maxDatepickerDate
                                                  }
                                                  onChange={(jsDate) =>
                                                    this._targetDateChangeHandle(
                                                      jsDate
                                                    )
                                                  }
                                                />
                                                <Calendar className="calender-icon" />
                                              </div>
                                            </div>
                                            {!!this.state.targetDateError && (
                                              <p className="inputErrorMsg">
                                                {requiredMessage}
                                              </p>
                                            )}
                                          </Col>
                                          <Col sm={3}>
                                            <CommonDropdown
                                              title="Responsible Person"
                                              optionList={this.state.userList}
                                              onChangeHandle={
                                                this._responsiblePersonHandle
                                              }
                                              value={
                                                this.state.responsiblePerson
                                              }
                                              isRequired={true}
                                            />
                                            {!!this.state
                                              .responsiblePersonError && (
                                                <p className="inputErrorMsg">
                                                  {requiredMessage}
                                                </p>
                                              )}
                                          </Col>
                                        </Row>
                                      )}

                                    {/* logic box started */}
                                    {(queData.response_type === 1 ||
                                      queData.response_type === 4) &&
                                      queData.logic === 1 &&
                                      queData.response.map((res, index) =>
                                        res.isActive === 1 ? (
                                          <div className="logic-view">
                                            <h6>Logic</h6>
                                            <div className="que-display">
                                              <h5
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                {res.logic_questions[0] !==
                                                  undefined &&
                                                  res.logic_questions[0]
                                                    .question}
                                                {res.logic_questions[0] !==
                                                  undefined &&
                                                  res.logic_questions[0]
                                                    .mandatory === 1 && (
                                                    <span className="star">
                                                      *
                                                    </span>
                                                  )}
                                                {res.logic_questions[0] !==
                                                  undefined &&
                                                  res.logic_questions[0]
                                                    .total_score > 0 && (
                                                    <span>{`Score : ${res.logic_questions[0]
                                                      .score_obtained || 0
                                                      }/${res.logic_questions[0]
                                                        .total_score
                                                      }`}</span>
                                                  )}
                                              </h5>
                                              <p className="gray-para">
                                                {res.logic_questions[0] !==
                                                  undefined &&
                                                  res.logic_questions[0]
                                                    .description}
                                              </p>
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .response_type === 1 && (
                                                  <div className="que-option">
                                                    {res.logic_questions[0]
                                                      .logic_response &&
                                                      res.logic_questions[0].logic_response.map(
                                                        (
                                                          singleLogicData,
                                                          singleLogicIndex
                                                        ) => (
                                                          <label
                                                            className="d-flex flex-row-reverse justify-content-end gap-2"
                                                            key={
                                                              singleLogicIndex
                                                            }
                                                          >
                                                            {
                                                              singleLogicData.response
                                                            }
                                                            <input
                                                              type="radio"
                                                              name={`single${index2}`}
                                                              value={
                                                                singleLogicData.logic_response_id
                                                              }
                                                              checked={
                                                                res
                                                                  .logic_questions[0]
                                                                  .answer_value ==
                                                                  singleLogicData.logic_response_id ||
                                                                  res
                                                                    .logic_questions[0]
                                                                    .answer_value ==
                                                                  singleLogicData.response
                                                                  ? true
                                                                  : false
                                                              }
                                                              onChange={(e) =>
                                                                this._handleInputLogic(
                                                                  e,
                                                                  index2,
                                                                  "question"
                                                                )
                                                              }
                                                            />
                                                            <span className="checkmark"   ></span>
                                                          </label>
                                                        )
                                                      )}
                                                  </div>
                                                )}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .response_type === 2 && (
                                                  <div className="que-option">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        name={`responses[${index2}].answer`}
                                                        placeholder="Write Short Answer"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index2,
                                                            "question"
                                                          )
                                                        }
                                                        value={
                                                          res.logic_questions[0]
                                                            .answer_value
                                                        }
                                                      />
                                                      {res.logic_questions[0]
                                                        .mandatory === 1 &&
                                                        res.logic_questions[0]
                                                          .errorStatus ===
                                                        1 && (
                                                          <p className="inputErrorMsg">
                                                            This is mandatory
                                                            field
                                                          </p>
                                                        )}
                                                    </div>
                                                  </div>
                                                )}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .response_type == 3 && (
                                                  <div className="que-option">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        name={`responses[${index2}].number`}
                                                        placeholder="Add Number"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index2,
                                                            "question"
                                                          )
                                                        }
                                                        value={
                                                          res.logic_questions[0]
                                                            .answer_value
                                                        }
                                                      />
                                                      {!!res.logic_questions[0]
                                                        .errorStatus && (
                                                          <p className="inputErrorMsg">
                                                            {
                                                              res
                                                                .logic_questions[0]
                                                                .errorMessage
                                                            }
                                                          </p>
                                                        )}
                                                    </div>
                                                  </div>
                                                )}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .response_type === 4 && (
                                                  <div className="que-option">
                                                    <Row>
                                                      <Col sm={4}>
                                                        <div className="form-group">
                                                          <select
                                                            as="select"
                                                            name={`color${index2}`}
                                                            className="form-control"
                                                            onChange={(e) =>
                                                              this._handleInputLogic(
                                                                e,
                                                                index2,
                                                                "question"
                                                              )
                                                            }
                                                            value={
                                                              res
                                                                .logic_questions[0]
                                                                .answer_value
                                                            }
                                                          >
                                                            {res
                                                              .logic_questions[0]
                                                              .logic_response &&
                                                              res.logic_questions[0].logic_response.map(
                                                                (
                                                                  dropdownLogicData,
                                                                  dropdownLogicIndex
                                                                ) => (
                                                                  <option
                                                                    value={
                                                                      dropdownLogicData.logic_response_id
                                                                    }
                                                                    key={
                                                                      dropdownLogicIndex
                                                                    }
                                                                  >
                                                                    {
                                                                      dropdownLogicData.response
                                                                    }
                                                                  </option>
                                                                )
                                                              )}
                                                          </select>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .response_type == 6 && (
                                                  <div className="que-option">
                                                    <Row>
                                                      <Col sm={10}>
                                                        <div className="form-group">
                                                          <input
                                                            type="date"
                                                            name={`responses[${index2}].days`}
                                                            placeholder="Add Date"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                              this._handleInputLogic(
                                                                e,
                                                                index2,
                                                                "question"
                                                              )
                                                            }
                                                            value={
                                                              res
                                                                .logic_questions[0]
                                                                .answer_value
                                                            }
                                                          />

                                                          {!!res
                                                            .logic_questions[0]
                                                            .errorStatus && (
                                                              <p className="inputErrorMsg">
                                                                {
                                                                  res
                                                                    .logic_questions[0]
                                                                    .errorMessage
                                                                }
                                                              </p>
                                                            )}
                                                        </div>
                                                      </Col>
                                                      {/* <Col>
                                                  <div className="form-group">
                                                    <label className="label-middle">
                                                      Day(s)
                                                    </label>
                                                  </div>
                                                </Col> */}
                                                    </Row>
                                                  </div>
                                                )}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .response_type === 7 && (
                                                  <div className="que-option">
                                                    <Row>
                                                      <Col sm={10}>
                                                        <div className="form-group">
                                                          <input
                                                            type="text"
                                                            name={`responses[${index2}].hours`}
                                                            placeholder="Add Hours"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                              this._handleInputLogic(
                                                                e,
                                                                index2,
                                                                "question"
                                                              )
                                                            }
                                                            value={
                                                              res
                                                                .logic_questions[0]
                                                                .answer_value
                                                            }
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label className="label-middle">
                                                            Hrs
                                                          </label>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .response_type === 8 && (
                                                  <div className="que-option">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        name={`responses[${index2}].recommendation`}
                                                        placeholder="Add Recommendation"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index2,
                                                            "question"
                                                          )
                                                        }
                                                        value={
                                                          res.logic_questions[0]
                                                            .answer_value
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0].notes ===
                                                1 && (
                                                  <div className="que-option">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        name={`responses[${index2}].notes`}
                                                        placeholder="Notes"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index2,
                                                            "question"
                                                          )
                                                        }
                                                        value={
                                                          res.logic_questions[0]
                                                            .note_value
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .evidence === 1 && (
                                                  <div>
                                                    <div className="file-upload">
                                                      <label className="file-upload__label">
                                                        Add More Evidence
                                                      </label>
                                                      <input
                                                        name={`question_logic_responses[${index2}].file`}
                                                        type="file"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index2,
                                                            "question"
                                                          )
                                                        }
                                                        className="inputfile-section inputfile-2"
                                                        accept="image/*"
                                                      />
                                                    </div>
                                                    <div className="image-preview">
                                                      {res
                                                        .logic_questions[0] !==
                                                        undefined &&
                                                        res.logic_questions[0]
                                                          .evidence_url &&
                                                        res.logic_questions[0]
                                                          .evidence_url.length >
                                                        0 && (
                                                          <div className="preview-box">
                                                            <img
                                                              onClick={() =>
                                                                this._handleLightBox(
                                                                  res
                                                                    .logic_questions[0]
                                                                    .evidence_url
                                                                )
                                                              }
                                                              src={
                                                                res
                                                                  .logic_questions[0]
                                                                  .evidence_url
                                                              }
                                                              alt="logic Evidence"
                                                            />
                                                            <Button
                                                              variant="outline-primary"
                                                              className="close-btn"
                                                              onClick={() =>
                                                                this._removeLogicPreviewFile(
                                                                  index2,
                                                                  0,
                                                                  "question",
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              X
                                                            </Button>
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                )}

                                              {/* Recommendation data view */}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .recommendationData.length >
                                                0 && (
                                                  <div className="published-section published-table">
                                                    <Table bordered striped>
                                                      <thead>
                                                        <tr>
                                                          {/* <th>#</th> */}
                                                          <th className="left">
                                                            Recommendation
                                                          </th>
                                                          <th>Priority</th>
                                                          <th>Category</th>
                                                          <th>Target Date</th>
                                                          <th>
                                                            Responsible Person
                                                          </th>
                                                          <th className="last-th">
                                                            Action
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {res.logic_questions[0].recommendationData.map(
                                                          (data, idx) => (
                                                            <tr
                                                              key={idx}
                                                              style={{
                                                                borderBottom:
                                                                  "5px solid #f5f5f5",
                                                              }}
                                                            >
                                                              <td className="left">
                                                                {
                                                                  data.recommendation
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  this.state.priorityList.filter(
                                                                    (
                                                                      priority
                                                                    ) =>
                                                                      priority.id ==
                                                                      data.priority
                                                                  )[0]?.priority
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  this.state.categoryList.filter(
                                                                    (
                                                                      category
                                                                    ) =>
                                                                      category.id ==
                                                                      data.category
                                                                  )[0]?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {`${convertDateFromTimezone(
                                                                  data.target_date
                                                                )}`}
                                                              </td>
                                                              <td>
                                                                {
                                                                  this.state.userList.filter(
                                                                    (user) =>
                                                                      user.id ==
                                                                      data.res_person
                                                                  )[0]?.name
                                                                }
                                                              </td>
                                                              <td className="last-td">
                                                                <Button
                                                                  className="icon-btn"
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this._handleInputLogic(
                                                                      e,
                                                                      index2,
                                                                      "question",
                                                                      0,
                                                                      0,
                                                                      0,
                                                                      2,
                                                                      idx
                                                                    )
                                                                  }
                                                                >
                                                                  <Edit2 className="icon-style" />
                                                                </Button>
                                                                <Button
                                                                  className="icon-btn"
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this._handleInputLogic(
                                                                      e,
                                                                      index2,
                                                                      "question",
                                                                      0,
                                                                      0,
                                                                      0,
                                                                      1,
                                                                      idx
                                                                    )
                                                                  }
                                                                >
                                                                  <Trash className="icon-style" />
                                                                </Button>
                                                              </td>
                                                            </tr>
                                                          )
                                                        )}
                                                      </tbody>
                                                    </Table>
                                                  </div>
                                                )}

                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .recommendation === 1 && (
                                                  <div className="file-upload">
                                                    <label
                                                      className="file-upload__label"
                                                      onClick={async () =>
                                                        await this.setState({
                                                          showRecommendationAdd:
                                                            !this.state
                                                              .showRecommendationAdd,
                                                          recommendationAddType:
                                                            "logic",
                                                          recommendationIndex:
                                                            index2,
                                                        })
                                                      }
                                                    >
                                                      Add Recommendation
                                                    </label>
                                                  </div>
                                                )}
                                              {res.logic_questions[0] !==
                                                undefined &&
                                                res.logic_questions[0]
                                                  .recommendation === 1 &&
                                                this.state
                                                  .showRecommendationAdd &&
                                                this.state
                                                  .recommendationAddType ===
                                                "logic" &&
                                                this.state
                                                  .recommendationIndex ==
                                                index2 && (
                                                  <Row>
                                                    <Col sm={10}>
                                                      {/* <div className="rd-box-head"> */}
                                                      <label>
                                                        Recommendation
                                                        <span className="inputErrorMsg">
                                                          &nbsp;*
                                                        </span>
                                                      </label>
                                                      <input
                                                        className="form-control"
                                                        value={
                                                          this.state
                                                            .recommendation
                                                        }
                                                        onChange={(event) =>
                                                          this._recommendationChangeHandle(
                                                            event.target.value
                                                          )
                                                        }
                                                        placeholder={
                                                          "Write recommendation..."
                                                        }
                                                      />
                                                      {!!this.state
                                                        .recommendationError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                      {/* </div> */}
                                                    </Col>
                                                    <Col sm={2}>
                                                      {/* <Button className="icon-btn" onClick={() => this._onHideBlock()}>
                                                  <Trash className="icon-style"/>
                                                </Button> */}
                                                      <Button
                                                        className="icon-btn"
                                                        onClick={() =>
                                                          this._addRecommendation(
                                                            index2,
                                                            "question-logic"
                                                          )
                                                        }
                                                      >
                                                        <Save className="icon-style" />
                                                      </Button>
                                                    </Col>
                                                    <Col sm={3}>
                                                      <CommonDropdown
                                                        title="Priority"
                                                        optionList={
                                                          this.state
                                                            .priorityList
                                                        }
                                                        onChangeHandle={
                                                          this
                                                            ._priorityChangeHandle
                                                        }
                                                        isRequired={true}
                                                        value={
                                                          this.state
                                                            .selectedPriority
                                                        }
                                                      />
                                                      {!!this.state
                                                        .priorityError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                    </Col>
                                                    <Col sm={3}>
                                                      <CommonDropdown
                                                        title="Category"
                                                        optionList={
                                                          this.state
                                                            .categoryList
                                                        }
                                                        onChangeHandle={
                                                          this
                                                            ._categoryChangeHandle
                                                        }
                                                        value={
                                                          this.state
                                                            .selectedCategory
                                                        }
                                                        isRequired={true}
                                                      />
                                                      {!!this.state
                                                        .categoryError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                    </Col>
                                                    <Col sm={3}>
                                                      <div className="form-group">
                                                        <label className="form-label">
                                                          Target Date
                                                          <span className="inputErrorMsg">
                                                            &nbsp;*
                                                          </span>
                                                        </label>
                                                        <div className="calender-div">
                                                          <DatePicker
                                                            selected={
                                                              this.state
                                                                .selectedTargetDate
                                                            }
                                                            minDate={
                                                              this.state
                                                                .maxDatepickerDate
                                                            }
                                                            onChange={(
                                                              jsDate
                                                            ) =>
                                                              this._targetDateChangeHandle(
                                                                jsDate
                                                              )
                                                            }
                                                          />
                                                          <Calendar className="calender-icon" />
                                                        </div>
                                                      </div>
                                                      {!!this.state
                                                        .targetDateError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                    </Col>
                                                    <Col sm={3}>
                                                      <CommonDropdown
                                                        title="Responsible Person"
                                                        optionList={
                                                          this.state.userList
                                                        }
                                                        onChangeHandle={
                                                          this
                                                            ._responsiblePersonHandle
                                                        }
                                                        value={
                                                          this.state
                                                            .responsiblePerson
                                                        }
                                                        isRequired={true}
                                                      />
                                                      {!!this.state
                                                        .responsiblePersonError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                    </Col>
                                                  </Row>
                                                )}
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                      )}
                    {/* Section View Started */}
                    {this.state.userFormView.section &&
                      this.state.userFormView.section.map((data, index) => {
                        return (
                          <div
                            className=""
                            key={data.section_name}
                          >
                            <div className="box-dynamic first-box-dynamic">
                              <label
                                className="clide-v2-primary p-2 clide-v2-section-info px-4 mb-0"
                              >
                                {data.section_name}
                              </label>
                              <p className="gray-para">
                                {data.section_description}
                              </p>
                            </div>
                            {data.section_questions &&
                              data.section_questions.map(
                                (sectionQue, index1) => (
                                  <div
                                    className={
                                      "box-dynamic first-box-dynamic " +
                                      (data.section_questions.length ===
                                        index1 + 1
                                        ? "last-user-box"
                                        : "")
                                    }
                                    key={sectionQue.question_id}
                                  >
                                    <h5
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {sectionQue.response_type === 9 ? (
                                        <div
                                          className="p-2"
                                          style={{ display: "inline-block" }}
                                          dangerouslySetInnerHTML={this.createMarkup(
                                            sectionQue.question
                                          )}
                                        />
                                      ) : (
                                        sectionQue.question
                                      )}
                                      {/* {sectionQue.question} */}
                                      {sectionQue.mandatory === 1 && (
                                        <span className="star">*</span>
                                      )}
                                      {/* {sectionQue.total_score && (
                                          <span>{`Score : ${sectionQue.score_obtained || 0
                                            }/${sectionQue.total_score}`}</span>
                                        )} */}
                                    </h5>
                                    <p className="gray-para">
                                      {sectionQue.question_description}
                                    </p>
                                    {sectionQue.response_type === 1 && (
                                      <div className="que-option">
                                        {sectionQue.section_question_response &&
                                          sectionQue.section_question_response.map(
                                            (singleSecData, singleSecIndex) => (
                                              <label
                                                className="d-flex flex-row-reverse justify-content-end gap-2"
                                                key={singleSecIndex}
                                              >
                                                {singleSecData.response}
                                                <input
                                                  type="radio"
                                                  name={`single-${index}-${index1}`}
                                                  value={
                                                    singleSecData.response_id
                                                  }
                                                  checked={
                                                    sectionQue.answer_value ==
                                                      singleSecData.response_id ||
                                                      sectionQue.answer_value ==
                                                      singleSecData.response
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    this._handleInputChange(
                                                      e,
                                                      index,
                                                      "section",
                                                      index1,
                                                      singleSecIndex
                                                    )
                                                  }
                                                />
                                                <span className="checkmark"   ></span>
                                              </label>
                                            )
                                          )}
                                        {sectionQue.mandatory === 1 &&
                                          sectionQue.errorStatus === 1 && (
                                            <p className="inputErrorMsg">
                                              {sectionQue.errorMessage}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                    {sectionQue.response_type === 2 && (
                                      <div className="que-option">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name={`responses[${index1}].answer`}
                                            placeholder="Write Short Answer"
                                            className="form-control"
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index,
                                                "section",
                                                index1
                                              )
                                            }
                                            value={sectionQue.answer_value}
                                          />
                                          {sectionQue.mandatory === 1 &&
                                            sectionQue.errorStatus === 1 && (
                                              <p className="inputErrorMsg">
                                                {sectionQue.errorMessage}
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                    {sectionQue.response_type === 3 && (
                                      <div className="que-option">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name={`responses[${index1}].number`}
                                            placeholder="Add Number"
                                            className="form-control"
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index,
                                                "section",
                                                index1
                                              )
                                            }
                                            value={sectionQue.answer_value}
                                          />
                                          {!!sectionQue.errorStatus && (
                                            <p className="inputErrorMsg">
                                              {sectionQue.errorMessage}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {sectionQue.response_type === 4 && (
                                      <div className="que-option">
                                        <Row>
                                          <Col sm={4}>
                                            <div className="form-group">
                                              <select
                                                as="select"
                                                name={`color-${index1}`}
                                                className="form-control"
                                                onChange={(e) =>
                                                  this._handleInputChange(
                                                    e,
                                                    index,
                                                    "section",
                                                    index1
                                                  )
                                                }
                                                value={sectionQue.answer_value}
                                              >
                                                <option value="">Select Option</option>
                                                {sectionQue.section_question_response &&
                                                  sectionQue.section_question_response.map(
                                                    (
                                                      dropdownSecData,
                                                      dropdownSecIndex
                                                    ) => (
                                                      <option
                                                        value={
                                                          dropdownSecData.response_id
                                                        }
                                                        key={dropdownSecIndex}
                                                      >
                                                        {
                                                          dropdownSecData.response
                                                        }
                                                      </option>
                                                    )
                                                  )}
                                              </select>
                                              {sectionQue.mandatory === 1 &&
                                                sectionQue.errorStatus ===
                                                1 && (
                                                  <p className="inputErrorMsg">
                                                    {sectionQue.errorMessage}
                                                  </p>
                                                )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                    {sectionQue.response_type === 6 && (
                                      <div className="que-option">
                                        <Row>
                                          <Col sm={10}>
                                            <div className="form-group">
                                              <input
                                                type="date"
                                                name={`responses[${index1}].days`}
                                                placeholder="Add Date"
                                                className="form-control"
                                                onChange={(e) =>
                                                  this._handleInputChange(
                                                    e,
                                                    index,
                                                    "section",
                                                    index1
                                                  )
                                                }
                                                value={sectionQue.answer_value}
                                              />
                                              {!!sectionQue.errorStatus && (
                                                <p className="inputErrorMsg">
                                                  {sectionQue.errorMessage}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                    {sectionQue.response_type === 7 && (
                                      <div className="que-option">
                                        <Row>
                                          <Col sm={10}>
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                name={`responses[${index1}].hours`}
                                                placeholder="Add Hours"
                                                className="form-control"
                                                onChange={(e) =>
                                                  this._handleInputChange(
                                                    e,
                                                    index,
                                                    "section",
                                                    index1
                                                  )
                                                }
                                                value={sectionQue.answer_value}
                                              />
                                              {!!sectionQue.errorStatus && (
                                                <p className="inputErrorMsg">
                                                  {sectionQue.errorMessage}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                          <Col>
                                            <div className="form-group">
                                              <label className="label-middle">
                                                Hrs
                                              </label>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                    {sectionQue.response_type === 8 && (
                                      <div className="que-option">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name={`responses[${index1}].recommendation`}
                                            placeholder="Add Recommendation"
                                            className="form-control"
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index,
                                                "section",
                                                index1
                                              )
                                            }
                                            value={sectionQue.answer_value}
                                          />
                                          {sectionQue.mandatory === 1 &&
                                            sectionQue.errorStatus === 1 && (
                                              <p className="inputErrorMsg">
                                                {sectionQue.errorMessage}
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                    {sectionQue.response_type == 0 && (
                                      <div
                                        className={this._getDynamicAlignment(
                                          sectionQue.alignment || 0
                                        )}
                                      >
                                        {sectionQue.file_status === 1 ? (
                                          <div className="browse-image">
                                            <img
                                              className="inner-logo"
                                              src={sectionQue.file_link}
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <VideoPlayer
                                            videoUrl={
                                              sectionQue.file_link || ""
                                            }
                                            snapshotAt={1}
                                          />
                                        )}
                                      </div>
                                    )}
                                    {sectionQue.notes == 1 && (
                                      <div className="note-option">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name={`responses[${index1}].notes`}
                                            placeholder="Notes"
                                            className="form-control"
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index,
                                                "section",
                                                index1
                                              )
                                            }
                                            value={sectionQue.note_value}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {sectionQue.evidence === 1 && (
                                      <div>
                                        <div className="file-upload">
                                          <label
                                            htmlFor={`responses[${index1}].file`}
                                            className="file-upload__label"
                                          >
                                            Add More Evidence
                                          </label>
                                          <input
                                            id={`section_responses[${index1}].file`}
                                            name={`section_responses[${index1}].file`}
                                            type="file"
                                            onChange={(e) =>
                                              this._handleInputChange(
                                                e,
                                                index,
                                                "section",
                                                index1
                                              )
                                            }
                                            className="inputfile-section inputfile-2"
                                            accept="image/*"
                                          />
                                        </div>
                                        <div className="image-preview">
                                          {typeof sectionQue?.evidence_url ===
                                            "string" &&
                                            sectionQue?.evidence_url?.length >
                                            0 ? (
                                            <div className="preview-box">
                                              <img
                                                src={sectionQue.evidence_url}
                                                alt="section evidence url"
                                                onClick={() =>
                                                  this._handleLightBox(
                                                    sectionQue.evidence_url
                                                  )
                                                }
                                              />
                                              <Button
                                                variant="outline-primary"
                                                className="close-btn"
                                                onClick={() =>
                                                  this._removePreviewFile(
                                                    index,
                                                    index1,
                                                    "section",
                                                    "evidence"
                                                  )
                                                }
                                              >
                                                X
                                              </Button>
                                            </div>
                                          ) : sectionQue.evidence_url ? (
                                            <div className="preview-box" style={{ overflow: "hidden" }}>
                                              <img
                                                src={URL.createObjectURL(
                                                  sectionQue.evidence_url[0]
                                                )}
                                                alt="section evidence url"
                                              />
                                              <Button
                                                variant="outline-primary"
                                                className="close-btn"
                                                onClick={() =>
                                                  this._removePreviewFile(
                                                    index,
                                                    index1,
                                                    "section",
                                                    "evidence"
                                                  )
                                                }
                                              >
                                                X
                                              </Button>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    )}

                                    {/* Recommendation data view */}
                                    {sectionQue.recommendationData.length >
                                      0 && (
                                        <div className="published-section published-table">
                                          <Table bordered striped>
                                            <thead>
                                              <tr>
                                                {/* <th>#</th> */}
                                                <th className="left">
                                                  Recommendation
                                                </th>
                                                <th>Priority</th>
                                                <th>Category</th>
                                                <th>Target Date</th>
                                                <th>Responsible Person</th>
                                                <th className="last-th">
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {sectionQue.recommendationData.map(
                                                (data, idx) => (
                                                  <tr
                                                    key={idx}
                                                    style={{
                                                      borderBottom:
                                                        "5px solid #f5f5f5",
                                                    }}
                                                  >
                                                    <td className="left">
                                                      {data.recommendation}
                                                    </td>
                                                    <td>
                                                      {
                                                        this.state.priorityList.filter(
                                                          (priority) =>
                                                            priority.id ==
                                                            data.priority
                                                        )[0]?.priority
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        this.state.categoryList.filter(
                                                          (category) =>
                                                            category.id ==
                                                            data.category
                                                        )[0]?.name
                                                      }
                                                    </td>
                                                    <td>
                                                      {`${convertDateFromTimezone(
                                                        data.target_date
                                                      )}`}
                                                    </td>
                                                    <td>
                                                      {
                                                        this.state.userList.filter(
                                                          (user) =>
                                                            user.id ==
                                                            data.res_person
                                                        )[0]?.name
                                                      }
                                                    </td>
                                                    <td className="last-td">
                                                      <Button
                                                        className="icon-btn"
                                                        onClick={(e) =>
                                                          this._handleInputChange(
                                                            e,
                                                            index1,
                                                            "section",
                                                            index,
                                                            0,
                                                            0,
                                                            2,
                                                            idx
                                                          )
                                                        }
                                                      >
                                                        <Edit2 className="icon-style" />
                                                      </Button>
                                                      <Button
                                                        className="icon-btn"
                                                        onClick={(e) =>
                                                          this._handleInputChange(
                                                            e,
                                                            index1,
                                                            "section",
                                                            index,
                                                            0,
                                                            0,
                                                            1,
                                                            idx
                                                          )
                                                        }
                                                      >
                                                        <Trash className="icon-style" />
                                                      </Button>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </Table>
                                        </div>
                                      )}

                                    {sectionQue.recommendation == 1 && (
                                      <div className="file-upload">
                                        <label
                                          className="file-upload__label"
                                          onClick={async () =>
                                            await this.setState({
                                              showRecommendationAdd:
                                                !this.state
                                                  .showRecommendationAdd,
                                              recommendationAddType:
                                                "section-question",
                                              recommendationSectionIndex: index,
                                              recommendationIndex: index1,
                                            })
                                          }
                                        >
                                          Add Recommendation
                                        </label>
                                      </div>
                                    )}

                                    {sectionQue.recommendation == 1 &&
                                      this.state.showRecommendationAdd &&
                                      this.state.recommendationAddType ===
                                      "section-question" &&
                                      this.state.recommendationIndex ==
                                      index1 &&
                                      this.state.recommendationSectionIndex ==
                                      index && (
                                        <Row>
                                          <Col sm={10}>
                                            {/* <div className="rd-box-head"> */}
                                            <label>
                                              Recommendation
                                              <span className="inputErrorMsg">
                                                &nbsp;*
                                              </span>
                                            </label>
                                            <input
                                              className="form-control"
                                              value={this.state.recommendation}
                                              onChange={(event) =>
                                                this._recommendationChangeHandle(
                                                  event.target.value
                                                )
                                              }
                                              placeholder={
                                                "Write recommendation..."
                                              }
                                            />
                                            {!!this.state
                                              .recommendationError && (
                                                <p className="inputErrorMsg">
                                                  {requiredMessage}
                                                </p>
                                              )}
                                            {/* </div> */}
                                          </Col>
                                          <Col sm={2}>
                                            <Button
                                              className="icon-btn"
                                              onClick={() =>
                                                this._addRecommendation(
                                                  index,
                                                  "section",
                                                  index1
                                                )
                                              }
                                            >
                                              <Save className="icon-style" />
                                            </Button>
                                          </Col>
                                          <Col sm={3}>
                                            <CommonDropdown
                                              title="Priority"
                                              optionList={
                                                this.state.priorityList
                                              }
                                              onChangeHandle={
                                                this._priorityChangeHandle
                                              }
                                              isRequired={true}
                                              value={
                                                this.state.selectedPriority
                                              }
                                            />
                                            {!!this.state.priorityError && (
                                              <p className="inputErrorMsg">
                                                {requiredMessage}
                                              </p>
                                            )}
                                          </Col>
                                          <Col sm={3}>
                                            <CommonDropdown
                                              title="Category"
                                              optionList={
                                                this.state.categoryList
                                              }
                                              onChangeHandle={
                                                this._categoryChangeHandle
                                              }
                                              value={
                                                this.state.selectedCategory
                                              }
                                              isRequired={true}
                                            />
                                            {!!this.state.categoryError && (
                                              <p className="inputErrorMsg">
                                                {requiredMessage}
                                              </p>
                                            )}
                                          </Col>
                                          <Col sm={3}>
                                            <div className="form-group">
                                              <label className="form-label">
                                                Target Date
                                                <span className="inputErrorMsg">
                                                  &nbsp;*
                                                </span>
                                              </label>
                                              <div className="calender-div">
                                                <DatePicker
                                                  selected={
                                                    this.state
                                                      .selectedTargetDate
                                                  }
                                                  minDate={
                                                    this.state.maxDatepickerDate
                                                  }
                                                  onChange={(jsDate) =>
                                                    this._targetDateChangeHandle(
                                                      jsDate
                                                    )
                                                  }
                                                />
                                                <Calendar className="calender-icon" />
                                              </div>
                                            </div>
                                            {!!this.state.targetDateError && (
                                              <p className="inputErrorMsg">
                                                {requiredMessage}
                                              </p>
                                            )}
                                          </Col>
                                          <Col sm={3}>
                                            <CommonDropdown
                                              title="Responsible Person"
                                              optionList={this.state.userList}
                                              onChangeHandle={
                                                this._responsiblePersonHandle
                                              }
                                              value={
                                                this.state.responsiblePerson
                                              }
                                              isRequired={true}
                                            />
                                            {!!this.state
                                              .responsiblePersonError && (
                                                <p className="inputErrorMsg">
                                                  {requiredMessage}
                                                </p>
                                              )}
                                          </Col>
                                        </Row>
                                      )}

                                    {/* logic box started */}
                                    {/* {(sectionQue.response_type === 1 ||
                                      sectionQue.response_type === 4) &&
                                    sectionQue.logic === 1 &&
                                    sectionQue.section_question_response[0] !==
                                      undefined &&
                                    sectionQue.section_question_response[0]
                                      .isActive === 1 ? ( */}

                                    {(sectionQue.response_type === 1 ||
                                      sectionQue.response_type === 4) && sectionQue.logic === 1 &&
                                      sectionQue.section_question_response.map((sectionQueRes, index2) =>
                                        sectionQueRes.isActive === 1 ? (


                                          <div className="logic-view">
                                            <h6>Logic</h6>
                                            <div className="que-display">
                                              <h5
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "space-between",
                                                }}
                                              >
                                                {
                                                  // sectionQue
                                                  //   .section_question_response[0]
                                                  //   .section_logic_questions[0]
                                                  //   .question

                                                  sectionQueRes.section_logic_questions[0] !== undefined &&
                                                  sectionQueRes.section_logic_questions[0].question

                                                }
                                                {
                                                  sectionQueRes.section_logic_questions[0] !== undefined &&
                                                  sectionQueRes.section_logic_questions[0].mandatory === 1
                                                  && (<span className="star">*</span>)

                                                }
                                                {sectionQueRes
                                                  .section_logic_questions[0]
                                                  .total_score > 0 ? (
                                                  <span>{`Score : ${sectionQueRes
                                                    .section_logic_questions[0]
                                                    .score_obtained || 0
                                                    }/${sectionQueRes
                                                      .section_logic_questions[0]
                                                      .total_score
                                                    }`}</span>
                                                ) : null}
                                              </h5>
                                              <p className="gray-para">
                                                {
                                                  sectionQueRes
                                                    .section_logic_questions[0]
                                                    .question_description
                                                }
                                              </p>
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .response_type === 1 && (
                                                  <div className="que-option">
                                                    {sectionQueRes
                                                      .section_logic_questions[0]
                                                      .section_logic_response &&
                                                      sectionQueRes.section_logic_questions[0].section_logic_response.map(
                                                        (
                                                          singleLogicData,
                                                          singleLogicIndex
                                                        ) => (
                                                          <label
                                                            className="d-flex flex-row-reverse justify-content-end gap-2"
                                                            key={singleLogicIndex}
                                                          >
                                                            {singleLogicData.response}
                                                            <input
                                                              type="radio"
                                                              // name="single"
                                                              //  name={`single-section-${index}-${index1-singleLogicIndex}`}
                                                              name={`single-section-${index}-[${index1}-${singleLogicIndex}]`}
                                                              // name={`single-section-${index}-${index1}-${singleLogicIndex}`}
                                                              // name ={ ` single-section-[${index}-${index1}-${singleLogicIndex}].question`}
                                                              // name={`single-section-${index-index1}-${singleLogicIndex}`}
                                                              // name={`single-section-${index}-que-${index1}-${singleLogicIndex}`}
                                                              checked={
                                                                sectionQueRes
                                                                  .section_logic_questions[0]
                                                                  .answer_value ==
                                                                  singleLogicData.logic_response_id ||
                                                                  sectionQueRes
                                                                    .section_logic_questions[0]
                                                                    .answer_value ==
                                                                  singleLogicData.response
                                                                  ? true
                                                                  : false
                                                              }
                                                              value={
                                                                singleLogicData.logic_response_id
                                                              }
                                                              onChange={(e) =>
                                                                this._handleInputLogic(
                                                                  e,
                                                                  index,
                                                                  "section",
                                                                  //index1,
                                                                  index1,
                                                                  singleLogicIndex, index2
                                                                )
                                                              }
                                                            />
                                                            <span className="checkmark" ></span>
                                                          </label>
                                                        )
                                                      )}
                                                  </div>
                                                )}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .response_type === 2 && (
                                                  <div className="que-option">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        name={`responses[${index1}].answer`}
                                                        placeholder="Write Short Answer"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index,
                                                            "section",
                                                            index1
                                                          )
                                                        }
                                                        value={
                                                          sectionQueRes
                                                            .section_logic_questions[0]
                                                            .answer_value
                                                        }
                                                      />
                                                      {sectionQueRes
                                                        .section_logic_questions[0]
                                                        .mandatory === 1 &&
                                                        sectionQueRes
                                                          .section_logic_questions[0]
                                                          .errorStatus === 1 && (
                                                          <p className="inputErrorMsg">
                                                            {
                                                              sectionQueRes
                                                                .section_logic_questions[0]
                                                                .errorMessage
                                                            }
                                                          </p>
                                                        )}
                                                    </div>
                                                  </div>
                                                )}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .response_type === 3 && (
                                                  <div className="que-option">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        name={`responses[${index1}].number`}
                                                        placeholder="Add Number"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index,
                                                            "section",
                                                            index1
                                                          )
                                                        }
                                                        value={
                                                          sectionQueRes
                                                            .section_logic_questions[0]
                                                            .answer_value
                                                        }
                                                      />
                                                      {sectionQueRes
                                                        .section_logic_questions[0]
                                                        .errorStatus ? (
                                                        <p className="inputErrorMsg">
                                                          {
                                                            sectionQueRes
                                                              .section_logic_questions[0]
                                                              .errorMessage
                                                          }
                                                        </p>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                )}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .response_type === 4 && (
                                                  <div className="que-option">
                                                    <Row>
                                                      <Col sm={4}>
                                                        <div className="form-group">
                                                          <select
                                                            as="select"
                                                            name={`color-${index}`}
                                                            className="form-control"
                                                            onChange={(e) =>
                                                              this._handleInputLogic(
                                                                e,
                                                                index,
                                                                "section",
                                                                index1
                                                              )
                                                            }
                                                            // value={
                                                            //   sectionQue.answer_value
                                                            // }
                                                            value={
                                                              sectionQueRes
                                                                .section_logic_questions[0]
                                                                .answer_value
                                                            }
                                                          >
                                                            {sectionQueRes
                                                              .section_logic_questions[0]
                                                              .section_logic_response &&
                                                              sectionQueRes.section_logic_questions[0].section_logic_response.map(
                                                                (
                                                                  dropdownLogicData,
                                                                  dropdownLogicIndex
                                                                ) => (
                                                                  <option
                                                                    value={
                                                                      dropdownLogicData.logic_response_id
                                                                    }
                                                                    key={
                                                                      dropdownLogicIndex
                                                                    }
                                                                  >
                                                                    {
                                                                      dropdownLogicData.response
                                                                    }
                                                                  </option>
                                                                )
                                                              )}
                                                          </select>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .response_type === 6 && (
                                                  <div className="que-option">
                                                    <Row>
                                                      <Col sm={10}>
                                                        <div className="form-group">
                                                          <input
                                                            type="date"
                                                            name={`responses[${index1}].days`}
                                                            placeholder="Add Date"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                              this._handleInputLogic(
                                                                e,
                                                                index,
                                                                "section",
                                                                index1
                                                              )
                                                            }
                                                            value={
                                                              sectionQueRes
                                                                .section_logic_questions[0]
                                                                .answer_value
                                                            }
                                                          />
                                                          {sectionQueRes
                                                            .section_logic_questions[0]
                                                            .errorStatus ? (
                                                            <p className="inputErrorMsg">
                                                              {
                                                                sectionQueRes
                                                                  .section_logic_questions[0]
                                                                  .errorMessage
                                                              }
                                                            </p>
                                                          ) : null}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .response_type === 7 && (
                                                  <div className="que-option">
                                                    <Row>
                                                      <Col sm={10}>
                                                        <div className="form-group">
                                                          <input
                                                            type="text"
                                                            name={`responses[${index1}].hours`}
                                                            placeholder="Add Hours"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                              this._handleInputLogic(
                                                                e,
                                                                index,
                                                                "section",
                                                                index1
                                                              )
                                                            }
                                                            value={
                                                              sectionQueRes
                                                                .section_logic_questions[0]
                                                                .answer_value
                                                            }
                                                          />
                                                          {sectionQueRes
                                                            .section_logic_questions[0]
                                                            .errorStatus ? (
                                                            <p className="inputErrorMsg">
                                                              {
                                                                sectionQueRes
                                                                  .section_logic_questions[0]
                                                                  .errorMessage
                                                              }
                                                            </p>
                                                          ) : null}
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label className="label-middle">
                                                            Hrs
                                                          </label>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .response_type === 8 && (
                                                  <div className="que-option">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        name={`responses[${index1}].recommendation`}
                                                        placeholder="Add Recommendation"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index,
                                                            "section",
                                                            index1
                                                          )
                                                        }
                                                        value={
                                                          sectionQueRes
                                                            .section_logic_questions[0]
                                                            .answer_value
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .notes === 1 && (
                                                  <div className="que-option">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        name={`responses[${index1}].notes`}
                                                        placeholder="Notes"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index,
                                                            "section",
                                                            index1
                                                          )
                                                        }
                                                        value={
                                                          sectionQueRes
                                                            .section_logic_questions[0]
                                                            .note_value
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .evidence === 1 && (
                                                  <div>
                                                    <div className="file-upload">
                                                      <label
                                                        htmlFor={`responses[${index1}].file`}
                                                        className="file-upload__label"
                                                      >
                                                        Add More Evidence
                                                      </label>
                                                      <input
                                                        id={`responses[${index1}].file`}
                                                        name={`section_logic_responses[${index1}].file`}
                                                        type="file"
                                                        onChange={(e) =>
                                                          this._handleInputLogic(
                                                            e,
                                                            index,
                                                            "section",
                                                            index1
                                                          )
                                                        }
                                                        className="inputfile-section inputfile-2"
                                                        accept="image/*"
                                                      />
                                                    </div>
                                                    <div className="image-preview">
                                                      {sectionQueRes
                                                        .section_logic_questions[0]
                                                        .evidence_url.length > 0 && (
                                                          <div className="preview-box">
                                                            <img
                                                              src={
                                                                sectionQueRes
                                                                  .section_logic_questions[0]
                                                                  .evidence_url
                                                              }
                                                              alt="section logic "
                                                              onClick={() =>
                                                                this._handleLightBox(
                                                                  sectionQueRes
                                                                    .section_logic_questions[0]
                                                                    .evidence_url
                                                                )
                                                              }
                                                            />
                                                            <Button
                                                              variant="outline-primary"
                                                              className="close-btn"
                                                              onClick={() =>
                                                                this._removeLogicPreviewFile(
                                                                  index,
                                                                  index1,
                                                                  "section",
                                                                  0
                                                                )
                                                              }
                                                            >
                                                              X
                                                            </Button>
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                )}

                                              {/* Recommendation data view */}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .recommendationData.length > 0 && (
                                                  <div className="published-section published-table">
                                                    <Table bordered striped>
                                                      <thead>
                                                        <tr>
                                                          {/* <th>#</th> */}
                                                          <th className="left">
                                                            Recommendation
                                                          </th>
                                                          <th>Priority</th>
                                                          <th>Category</th>
                                                          <th>Target Date</th>
                                                          <th>Responsible Person</th>
                                                          <th className="last-th">
                                                            Action
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {sectionQueRes.section_logic_questions[0].recommendationData.map(
                                                          (data, idx) => (
                                                            <tr
                                                              key={idx}
                                                              style={{
                                                                borderBottom:
                                                                  "5px solid #f5f5f5",
                                                              }}
                                                            >
                                                              <td className="left">
                                                                {data.recommendation}
                                                              </td>
                                                              <td>
                                                                {
                                                                  this.state.priorityList.filter(
                                                                    (priority) =>
                                                                      priority.id ==
                                                                      data.priority
                                                                  )[0]?.priority
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  this.state.categoryList.filter(
                                                                    (category) =>
                                                                      category.id ==
                                                                      data.category
                                                                  )[0]?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {`${convertDateFromTimezone(
                                                                  data.target_date
                                                                )}`}
                                                              </td>
                                                              <td>
                                                                {
                                                                  this.state.userList.filter(
                                                                    (user) =>
                                                                      user.id ==
                                                                      data.res_person
                                                                  )[0]?.name
                                                                }
                                                              </td>
                                                              <td className="last-td">
                                                                <Button
                                                                  className="icon-btn"
                                                                  onClick={(e) =>
                                                                    this._handleInputLogic(
                                                                      e,
                                                                      index,
                                                                      "section",
                                                                      index1,
                                                                      0,
                                                                      0,
                                                                      2,
                                                                      idx
                                                                    )
                                                                  }
                                                                >
                                                                  <Edit2 className="icon-style" />
                                                                </Button>
                                                                <Button
                                                                  className="icon-btn"
                                                                  onClick={(e) =>
                                                                    this._handleInputLogic(
                                                                      e,
                                                                      index,
                                                                      "section",
                                                                      index1,
                                                                      0,
                                                                      0,
                                                                      1,
                                                                      idx
                                                                    )
                                                                  }
                                                                >
                                                                  <Trash className="icon-style" />
                                                                </Button>
                                                              </td>
                                                            </tr>
                                                          )
                                                        )}
                                                      </tbody>
                                                    </Table>
                                                  </div>
                                                )}

                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .recommendation === 1 && (
                                                  <div className="file-upload">
                                                    <label
                                                      className="file-upload__label"
                                                      onClick={async () =>
                                                        await this.setState({
                                                          showRecommendationAdd:
                                                            !this.state
                                                              .showRecommendationAdd,
                                                          recommendationAddType:
                                                            "section-logic",
                                                          recommendationSectionIndex:
                                                            index,
                                                          recommendationIndex: index1,
                                                        })
                                                      }
                                                    >
                                                      Add Recommendation
                                                    </label>
                                                  </div>
                                                )}
                                              {sectionQueRes
                                                .section_logic_questions[0]
                                                .recommendation === 1 &&
                                                this.state.showRecommendationAdd &&
                                                this.state.recommendationAddType ===
                                                "section-logic" &&
                                                this.state.recommendationIndex ==
                                                index1 &&
                                                this.state
                                                  .recommendationSectionIndex ==
                                                index && (
                                                  <Row>
                                                    <Col sm={10}>
                                                      {/* <div className="rd-box-head"> */}
                                                      <label>
                                                        Recommendation
                                                        <span className="inputErrorMsg">
                                                          &nbsp;*
                                                        </span>
                                                      </label>
                                                      <input
                                                        className="form-control"
                                                        value={
                                                          this.state.recommendation
                                                        }
                                                        onChange={(event) =>
                                                          this._recommendationChangeHandle(
                                                            event.target.value
                                                          )
                                                        }
                                                        placeholder={
                                                          "Write recommendation..."
                                                        }
                                                      />
                                                      {!!this.state
                                                        .recommendationError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                      {/* </div> */}
                                                    </Col>
                                                    <Col sm={2}>
                                                      {/* <Button className="icon-btn" onClick={() => this._onHideBlock()}>
                                                <Trash className="icon-style"/>
                                              </Button> */}
                                                      <Button
                                                        className="icon-btn"
                                                        onClick={() =>
                                                          this._addRecommendation(
                                                            index,
                                                            "section-logic",
                                                            index1
                                                          )
                                                        }
                                                      >
                                                        <Save className="icon-style" />
                                                      </Button>
                                                    </Col>
                                                    <Col sm={3}>
                                                      <CommonDropdown
                                                        title="Priority"
                                                        optionList={
                                                          this.state.priorityList
                                                        }
                                                        onChangeHandle={
                                                          this._priorityChangeHandle
                                                        }
                                                        isRequired={true}
                                                        value={
                                                          this.state
                                                            .selectedPriority
                                                        }
                                                      />
                                                      {!!this.state
                                                        .priorityError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                    </Col>
                                                    <Col sm={3}>
                                                      <CommonDropdown
                                                        title="Category"
                                                        optionList={
                                                          this.state.categoryList
                                                        }
                                                        onChangeHandle={
                                                          this._categoryChangeHandle
                                                        }
                                                        value={
                                                          this.state
                                                            .selectedCategory
                                                        }
                                                        isRequired={true}
                                                      />
                                                      {!!this.state
                                                        .categoryError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                    </Col>
                                                    <Col sm={3}>
                                                      <div className="form-group">
                                                        <label className="form-label">
                                                          Target Date
                                                          <span className="inputErrorMsg">
                                                            &nbsp;*
                                                          </span>
                                                        </label>
                                                        <div className="calender-div">
                                                          <DatePicker
                                                            selected={
                                                              this.state
                                                                .selectedTargetDate
                                                            }
                                                            minDate={
                                                              this.state
                                                                .maxDatepickerDate
                                                            }
                                                            onChange={(jsDate) =>
                                                              this._targetDateChangeHandle(
                                                                jsDate
                                                              )
                                                            }
                                                          />
                                                          <Calendar className="calender-icon" />
                                                        </div>
                                                      </div>
                                                      {!!this.state
                                                        .targetDateError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                    </Col>
                                                    <Col sm={3}>
                                                      <CommonDropdown
                                                        title="Responsible Person"
                                                        optionList={
                                                          this.state.userList
                                                        }
                                                        onChangeHandle={
                                                          this
                                                            ._responsiblePersonHandle
                                                        }
                                                        value={
                                                          this.state
                                                            .responsiblePerson
                                                        }
                                                        isRequired={true}
                                                      />
                                                      {!!this.state
                                                        .responsiblePersonError && (
                                                          <p className="inputErrorMsg">
                                                            {requiredMessage}
                                                          </p>
                                                        )}
                                                    </Col>
                                                  </Row>
                                                )}
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        ))}
                                  </div>
                                )
                              )}
                          </div>
                        );
                      })}
                  </div>
                  {this.state.userFormView.questions &&
                    this.state.userFormView.questions.length > 0 && (
                      <>
                        {this.state.userFormView.questions.map(
                          (queData, index2) => {
                            if (
                              queData.response_type === 5 ||
                              queData.response_type === 11
                            ) {
                              return (
                                <div className="user-section">
                                  <div className="">
                                    <div
                                      className={
                                        "box-dynamic first-box-dynamic " +
                                        (this.state.userFormView.questions
                                          .length ==
                                          index2 + 1
                                          ? "last-user-box"
                                          : "")
                                      }
                                      key={queData.question_id}
                                      style={{ borderBottom: "none" }}
                                    >
                                      <h5>{queData.question}</h5>

                                      {queData.response_type === 5 && (
                                        <>
                                          <div className="que-option">
                                            <div
                                              className="file-upload"
                                              style={{
                                                display: "flex",
                                                alignItems: "baseline",
                                              }}
                                            >
                                              <div className="file-format">
                                                <p>Allowed file format</p>
                                                <img
                                                  src={require("assets/images/icons/fpdf.svg")}
                                                />
                                                <img
                                                  src={require("assets/images/icons/ffile.svg")}
                                                />
                                                <img
                                                  src={require("assets/images/icons/fcross.svg")}
                                                />
                                              </div>
                                              <MyDropzone
                                                type="6"
                                                uploadFile={(e) => {
                                                  this._handleInputChange(
                                                    e,
                                                    index2,
                                                    "question"
                                                  );
                                                }}
                                              />
                                            </div>
                                            {queData.mandatory === 1 &&
                                              queData.errorStatus === 1 && (
                                                <p className="inputErrorMsg">
                                                  {queData.errorMessage}
                                                </p>
                                              )}
                                          </div>
                                          {queData.files &&
                                            queData.files.length > 0 && (
                                              <div className="rd-box-bottom">
                                                <div className="images-section">
                                                  {queData.files.map(
                                                    (data, idx) =>
                                                      data.file_link && (
                                                        <div className="image-box2">
                                                          {data.file_link.substring(
                                                            data.file_link.lastIndexOf(
                                                              "."
                                                            )
                                                          ) === ".pdf" && (
                                                              <a
                                                                href={
                                                                  data.file_link
                                                                }
                                                                download
                                                              >
                                                                <img
                                                                  style={{
                                                                    paddingRight:
                                                                      "0.5vw",
                                                                  }}
                                                                  src={require("assets/images/icons/fpdf.svg")}
                                                                />
                                                              </a>
                                                            )}
                                                          {(data.file_link.substring(
                                                            data.file_link.lastIndexOf(
                                                              "."
                                                            )
                                                          ) === ".xls" ||
                                                            data.file_link.substring(
                                                              data.file_link.lastIndexOf(
                                                                "."
                                                              )
                                                            ) === ".xlsx") && (
                                                              <a
                                                                href={
                                                                  data.file_link
                                                                }
                                                                download
                                                              >
                                                                <img
                                                                  style={{
                                                                    paddingRight:
                                                                      "0.5vw",
                                                                  }}
                                                                  src={require("assets/images/icons/ffile.svg")}
                                                                />
                                                              </a>
                                                            )}
                                                          {(data.file_link.substring(
                                                            data.file_link.lastIndexOf(
                                                              "."
                                                            )
                                                          ) === ".doc" ||
                                                            data.file_link.substring(
                                                              data.file_link.lastIndexOf(
                                                                "."
                                                              )
                                                            ) === ".docx") && (
                                                              <a
                                                                href={
                                                                  data.file_link
                                                                }
                                                                download
                                                              >
                                                                <img
                                                                  style={{
                                                                    paddingRight:
                                                                      "0.5vw",
                                                                  }}
                                                                  src={require("assets/images/icons/fcross.svg")}
                                                                />
                                                              </a>
                                                            )}
                                                          <Button
                                                            variant="outline-link"
                                                            onClick={() =>
                                                              this._removePreviewFile(
                                                                index2,
                                                                0,
                                                                "question",
                                                                "question_upload",
                                                                idx
                                                              )
                                                            }
                                                          >
                                                            X
                                                          </Button>
                                                        </div>
                                                      )
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                        </>
                                      )}
                                      {queData.response_type === 11 && (
                                        <>
                                          <div className="que-option">
                                            <div
                                              className="file-upload"
                                              style={{
                                                display: "flex",
                                                alignItems: "baseline",
                                              }}
                                            >
                                              <div className="file-format">
                                                <p>Allowed file format</p>
                                                <img
                                                  src={require("assets/images/icons/fimage.svg")}
                                                />
                                                <img
                                                  src={require("assets/images/icons/play-grey.svg")}
                                                />
                                                <img
                                                  src={require("assets/images/icons/play-grey.svg")}
                                                  style={{ opacity: 0 }}
                                                />
                                              </div>
                                              <MyDropzone
                                                type="5"
                                                uploadFile={(e) => {
                                                  this._handleInputChange(
                                                    e,
                                                    index2,
                                                    "question"
                                                  );
                                                }}
                                              />
                                            </div>
                                            {queData.mandatory === 1 &&
                                              queData.errorStatus === 1 && (
                                                <p className="inputErrorMsg">
                                                  {queData.errorMessage}
                                                </p>
                                              )}
                                          </div>
                                          {queData.files &&
                                            queData.files.length > 0 && (
                                              <div className="rd-box-bottom">
                                                <div className="images-section">
                                                  {queData.files.map(
                                                    (data, idx) =>
                                                      data.file_link && (
                                                        <div className="image-box">
                                                          {data.file_link.substring(
                                                            data.file_link.lastIndexOf(
                                                              "."
                                                            )
                                                          ) === ".jpeg" ||
                                                            data.file_link.substring(
                                                              data.file_link.lastIndexOf(
                                                                "."
                                                              )
                                                            ) === ".png" ? (
                                                            <img
                                                              onClick={() =>
                                                                this._handleLightBox(
                                                                  data.file_link
                                                                )
                                                              }
                                                              src={
                                                                data.file_link
                                                              }
                                                              alt="question upload"
                                                            />
                                                          ) : (
                                                            <video
                                                              src={
                                                                data.file_link
                                                              }
                                                              onClick={() =>
                                                                this._handleLightBox(
                                                                  data.file_link,
                                                                  2
                                                                )
                                                              }
                                                            />
                                                          )}
                                                          <Button
                                                            variant="outline-link"
                                                            onClick={() =>
                                                              this._removePreviewFile(
                                                                index2,
                                                                0,
                                                                "question",
                                                                "question_upload",
                                                                idx
                                                              )
                                                            }
                                                          >
                                                            X
                                                          </Button>
                                                        </div>
                                                      )
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          }
                        )}
                      </>
                    )}
                  {this.state.userFormView.questions &&
                    this.state.userFormView.questions.length > 0 && (
                      <>
                        {this.state.userFormView.questions.map(
                          (queData, index2) => {
                            if (queData.response_type === 10) {
                              return (
                                this.setState({ declarationAvailable: 1 }),
                                (
                                  <div className="">
                                    <div className="">
                                      <div
                                        className={
                                          "box-dynamic first-box-dynamic " +
                                          (this.state.userFormView.questions
                                            .length ==
                                            index2 + 1
                                            ? "last-user-box"
                                            : "")
                                        }
                                        key={queData.question_id}
                                        style={{ borderBottom: "none" }}
                                      >
                                        <h5>
                                          <input
                                            type="checkbox"
                                            value={
                                              this.state.declarationChecked
                                            }
                                            onChange={() =>
                                              this.setState({
                                                declarationChecked:
                                                  !this.state
                                                    .declarationChecked,
                                              })
                                            }
                                          />
                                          <div
                                            className="p-2"
                                            style={{
                                              display: "inline-block",
                                            }}
                                            dangerouslySetInnerHTML={this.createMarkup(
                                              queData.question
                                            )}
                                          />
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                )
                              );
                            }
                          }
                        )}
                      </>
                    )}
                  <div className="box-dynamic first-box-dynamic last-user-box">
                    {this.state.isRecommendation == 1 && (
                      <div className="user-section">
                        <div className="">
                          {/* Recommendation data view */}
                          {this.state.userFormView.recommendationData.length >
                            0 && (
                              <div className="published-section published-table">
                                <Table bordered striped>
                                  <thead>
                                    <tr>
                                      {/* <th>#</th> */}
                                      <th className="left">Recommendation</th>
                                      <th>Priority</th>
                                      <th>Category</th>
                                      <th>Target Date</th>
                                      <th>Responsible Person</th>
                                      <th className="last-th">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.userFormView.recommendationData.map(
                                      (data, idx) => (
                                        <tr
                                          key={idx}
                                          style={{
                                            borderBottom: "5px solid #f5f5f5",
                                          }}
                                        >
                                          <td className="left">
                                            {data.recommendation}
                                          </td>
                                          <td>
                                            {
                                              this.state.priorityList.filter(
                                                (priority) =>
                                                  priority.id == data.priority
                                              )[0]?.priority
                                            }
                                          </td>
                                          <td>
                                            {
                                              this.state.categoryList.filter(
                                                (category) =>
                                                  category.id == data.category
                                              )[0]?.name
                                            }
                                          </td>
                                          <td>
                                            {`${convertDateFromTimezone(
                                              data.target_date
                                            )}`}
                                          </td>
                                          <td>
                                            {
                                              this.state.userList.filter(
                                                (user) =>
                                                  user.id == data.res_person
                                              )[0]?.name
                                            }
                                          </td>
                                          <td className="last-td">
                                            <Button
                                              className="icon-btn"
                                              onClick={(e) =>
                                                this._handleFormRecommendationEdit(
                                                  idx
                                                )
                                              }
                                            >
                                              <Edit2 className="icon-style" />
                                            </Button>
                                            <Button
                                              className="icon-btn"
                                              onClick={(e) =>
                                                this._handleFormRecommendationDelete(
                                                  idx
                                                )
                                              }
                                            >
                                              <Trash className="icon-style" />
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            )}

                          {/* Add Recommendation button  */}
                          {this.state.isRecommendation == 1 && (
                            <div
                              className="file-upload"
                              style={{ display: "block" }}
                            >
                              <label
                                className="file-upload__label"
                                onClick={async () =>
                                  await this.setState({
                                    showRecommendationAdd:
                                      !this.state.showRecommendationAdd,
                                    recommendationAddType: "form",
                                  })
                                }
                              >
                                Add Recommendation
                              </label>
                            </div>
                          )}
                          {this.state.showRecommendationAdd &&
                            this.state.recommendationAddType === "form" && (
                              <Row>
                                <Col sm={10}>
                                  {/* <div className="rd-box-head"> */}
                                  <label>
                                    Recommendation
                                    <span className="inputErrorMsg">
                                      &nbsp;*
                                    </span>
                                  </label>
                                  <input
                                    className="form-control"
                                    value={this.state.recommendation}
                                    onChange={(event) =>
                                      this._recommendationChangeHandle(
                                        event.target.value
                                      )
                                    }
                                    placeholder={"Write recommendation..."}
                                  />
                                  {!!this.state.recommendationError && (
                                    <p className="inputErrorMsg">
                                      {requiredMessage}
                                    </p>
                                  )}
                                  {/* </div> */}
                                </Col>
                                <Col sm={2}>
                                  {/* <Button className="icon-btn" onClick={() => this._onHideBlock()}>
                                  <Trash className="icon-style"/>
                                </Button> */}
                                  <Button
                                    className="icon-btn"
                                    onClick={() =>
                                      this._addRecommendation(0, "form")
                                    }
                                  >
                                    <Save className="icon-style" />
                                  </Button>
                                </Col>
                                <Col sm={3}>
                                  <CommonDropdown
                                    title="Priority"
                                    optionList={this.state.priorityList}
                                    onChangeHandle={this._priorityChangeHandle}
                                    isRequired={true}
                                    value={this.state.selectedPriority}
                                  />
                                  {!!this.state.priorityError && (
                                    <p className="inputErrorMsg">
                                      {requiredMessage}
                                    </p>
                                  )}
                                </Col>
                                <Col sm={3}>
                                  <CommonDropdown
                                    title="Category"
                                    optionList={this.state.categoryList}
                                    onChangeHandle={this._categoryChangeHandle}
                                    value={this.state.selectedCategory}
                                    isRequired={true}
                                  />
                                  {!!this.state.categoryError && (
                                    <p className="inputErrorMsg">
                                      {requiredMessage}
                                    </p>
                                  )}
                                </Col>
                                <Col sm={3}>
                                  <div className="form-group">
                                    <label className="form-label">
                                      Target Date
                                      <span className="inputErrorMsg">
                                        &nbsp;*
                                      </span>
                                    </label>
                                    <div className="calender-div">
                                      <DatePicker
                                        selected={this.state.selectedTargetDate}
                                        minDate={this.state.maxDatepickerDate}
                                        onChange={(jsDate) =>
                                          this._targetDateChangeHandle(jsDate)
                                        }
                                      />
                                      <Calendar className="calender-icon" />
                                    </div>
                                  </div>
                                  {!!this.state.targetDateError && (
                                    <p className="inputErrorMsg">
                                      {requiredMessage}
                                    </p>
                                  )}
                                </Col>
                                <Col sm={3}>
                                  <CommonDropdown
                                    title="Responsible Person"
                                    optionList={this.state.userList}
                                    onChangeHandle={
                                      this._responsiblePersonHandle
                                    }
                                    value={this.state.responsiblePerson}
                                    isRequired={true}
                                  />
                                  {!!this.state.responsiblePersonError && (
                                    <p className="inputErrorMsg">
                                      {requiredMessage}
                                    </p>
                                  )}
                                </Col>
                              </Row>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="publish-btn">
                    {/* <button
                          type="submit"
                          className="gray-btn"
                          onClick={(e) => this._handleSubmit(e, 1)}
                        >
                          SAVE
                        </button> */}
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={(e) => this._handleSubmit(e, 2)}
                    >
                      SUBMIT
                    </button>
                  </div>
                </>
              )
            }
          </div>
          {this.state.showLightBoxImage && (
            <Lightbox
              mainSrc={this.state.showImage}
              onCloseRequest={this._handleLightBoxView}
              imageTitle={<h2>Form Image</h2>}
            />
          )}
        </div>
        <Modal centered show={this.state.submitPopup} className="submit-modal">
          <Modal.Header closeButton onClick={this.hideModal}></Modal.Header>

          <Modal.Body>
            <img
              className="submit-img"
              src={require("assets/images/submit-popup.svg")}
              alt={`submit-popup`}
            />
            <p className="submit-text">Data submitted successfully</p>
            <button type="submit" className="red-btn">
              Done
            </button>
          </Modal.Body>
        </Modal>

        {this.state.showLowStoragePopup ? (
          <LowSpace
            message={this.state.lowStorageMessage}
            handleLowSpace={this._handleLowSpace}
          />
        ) : null}

        <ConfirmPopup
          showConfirmPopup={this.state.showConfirmSubmitPopup}
          _handleCancel={this._handleSubmitConfirmCancel}
          _handleConfirmAction={this._submitUserResponse}
          actionText={this.state.form_action_type === 1 ? "SAVE" : "SUBMIT"}
          message={
            this.state.form_action_type === 1 ? saveMessage : submitMessage
          }
        />
      </div>
    );
  }
}

export default withRouter(ExternalUserFormView);
