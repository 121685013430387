import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";
import { Filter, Search, ChevronDown, Calendar } from 'react-feather';
import { Scrollbars } from 'react-custom-scrollbars';
import { Row, Col, ProgressBar, Accordion, Modal, Button} from 'react-bootstrap';
import Url from 'app/shared/constants/Url';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDateFromTimezone } from "app/shared/utility/tzUtilFunction";
import LoadingView from "app/shared/components/Loader/LoadingView";

const qs = require('qs');

const severityId = [
  {
    id: '4',
    name: 'Extreme'
  },
  {
    id: '3',
    name: 'High'
  },
  {
    id: '2',
    name: 'Medium'
  },
  {
    id: '1',
    name: 'Low'
  },
]

export default class TrackerList extends Component{
  constructor(props) {
    super(props);

    this.state = {
      dataLoading: false,
      currentPage: 1,
      nmHeaderCount: [],
      completed_number: '',
      inprogress_number: '',
      searchList: "",
      searchList2: "",
      searchBtnClicked : false,
      searchBtnClicked2 : false,
      searchCompleted: [],
      trackerFilterPopup: false,
      startDate: new Date(),
      endDate: new Date(),
      overdue: 0,
      service: [],
      serviceSelect: '',
      project: [],
      projectSelect: '',
      R_person: [],
      extremeSelect: 0,
      extremeValue: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      all: 0,
      allValue: [],
      allS: [],
      severity: [],
      personSelect: [],
      CategoryFilter: [],
      dflag: JSON.parse(localStorage.getItem('userData'))
    };
    this._getnmHeaderCount();
    this._getService();
    this._getProject();
    this.selectSeverity = this.selectSeverity.bind(this);
  }

  componentDidMount = () => {
    this._getnmHeaderCount();
    this._getService();
    this._getProject();
    this.props.setClick(this.nmTrackerList);
  }

  trackerModal = (visible, statusCheck) => {
    this.setState({ trackerFilterPopup: visible, statusCheck: statusCheck });
  };

  _getnmHeaderCount = async () => {
    let userId  = localStorage.getItem('userId');
    let token = localStorage.getItem('token');

    await axios.post(Url.nmModule.nmHeaderCount,
      qs.stringify({
        auth_key: token,
        user_id: userId,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then((response) => {
      if (response.status == 200) {
        this.setState({nmHeaderCount: response.data.data, dataLoading: false});
      }
    })
    .catch((error) =>{
      throw error;
    });
  }

  _getService = async () => {
    let userId  = localStorage.getItem('userId');
    let token = localStorage.getItem('token');

    await axios.post(Url.serviceList,
      qs.stringify({
        auth_key: token,
        user_id: userId,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then((response) => {
      if (response.data.status == 200) {
        this.setState({service: response.data.data, dataLoading: false});
        if(this.state.dflag.designation_flag == 4){
          var serviceobs = this.state.service.map(item => item.service_id);
          this.setState({serviceSelect: serviceobs[0]})
          this._getProject()
        }
      }
      // console.log(this.state.service ? this.state.service : 0)
    })
    .catch((error) =>{
      throw error;
    });
  }

  _getProject = async () => {
    let userId  = localStorage.getItem('userId');
    let token = localStorage.getItem('token');

    await axios.post(Url.projectList,
      qs.stringify({
        auth_key: token,
        user_id: userId,
        service_id: this.state.serviceSelect
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then((response) => {
      if (response.data.status == 200) {
        this.setState({project: response.data.data, dataLoading: false});
        if(this.state.dflag.designation_flag == 4){
          var projectobs = this.state.project.map(item => item.project_id);
          this.setState({projectSelect: projectobs[0]})
        }
      }
      // console.log('result: ',this.state.project ? this.state.project : 0)
    })
    .catch((error) =>{
      throw error;
    });
  }

  _handleServiceChange = async( event ) => {
    const selectedServiceID = event.target.value;
    if ( selectedServiceID ) {
      await this.setState({ serviceSelect : selectedServiceID});
      this._getProject();  
    }
  }

  resetStates() {
        this.setState({extremeSelect: 0,highSelect: 0,mediumSelect: 0,lowSelect: 0, startDate: new Date(), severity: [],
          endDate: new Date(), serviceSelect: null, projectSelect: null, categorySelect: null, personSelect: null, overdue: 0, all: 0 });
      }



    selectSeverity(e) {
      const severity = this.state.severity
      let index

      if (e.target.checked) {
        severity.push(+e.target.value)
      } else {
        index = severity.indexOf(+e.target.value)
        severity.splice(index, 1)
      }
      this.setState({ severity: severity })
    }


  FilterData(param, value) {
        if(value == 4){
          if(param == 0){
            this.setState({ extremeSelect: 4,  all: 0})
          }else{
            this.setState({ extremeSelect: 0, all: 0})
          }
        }if(value == 3){
          if(param == 0){
            this.setState({ highSelect: 3, all: 0})
          }else{
            this.setState({ highSelect: 0, all: 0})
          }
        }if(value == 2){
          if(param == 0){
            this.setState({ mediumSelect: 2, all: 0})
          }else{
            this.setState({ mediumSelect: 0, all: 0})
          }
        }if(value == 1){
          if(param == 0){
            this.setState({ lowSelect: 1, all: 0})
          }else{
            this.setState({ lowSelect: 0, all: 0})
          }
        }if(value == 'overdue'){
          if(param == 0){
            this.setState({ overdue: 1, all: 0})
          }else{
            this.setState({ overdue: 0, all: 0})
          }
        } if(value == 'all'){
          if(param == 0){
            this.setState({ all: 1, extremeSelect: 4, highSelect: 3, mediumSelect: 2, lowSelect: 1, severity: [1,2,3,4]})
          }else{
            this.setState({ all: 0, extremeSelect: 0, highSelect: 0, mediumSelect: 0, lowSelect: 0, severity:[]})
          }
        }
      }

  render() {
    return (
      <div className="open-section nm-section">
        {/* <div className="loader" style={{display: this.state.dataLoading ? 'block': 'none'}}>
          <img className="loader-img" src={require('assets/images/loader1.gif')} />
        </div> */}
        <LoadingView showLoading={this.state.dataLoading}/>
        <div className="open-divide">
          <div className="open-head inprogress">
            <h2>IN PROGRESS | {this.props.progress}</h2>
          </div>
          <div className="search-box">
            <div className="search-input">
              <input type="text" placeholder="Search Category" name="search" value={this.props.searchList2} onChange={(e) => this.props._handleInputChange2(e)}/>
              <button className="search-button" ><Search/></button>
            </div>
            <button className="filter-button" onClick={() => this.trackerModal(true, 0)}><Filter /></button>
          </div>
          <Scrollbars style={{height : `calc(100vh - 18vw)`}}
           renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
           renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
           renderThumbVertical={props => <div {...props} style={{position: "relative",display: "block",width: "100%",cursor: "pointer",borderRadius: "inherit", backgroundColor: "rgba(0, 0, 0, 0.2)",height: "100px"}} />}
           >
           { this.props.progress_list.map((data, index) => (
             <>
             { data.status == 0  &&
            <div className="open-content" key={index}>
              <Accordion defaultActiveKey="0">
                <div className="nm-box open-box" style={{borderColor: data.severity_color }}>
                  <div className="open-bhead open-tracker-head">
                    <h4>{data.type_of_Act } | {data.category}</h4>
                    <div className="tracker-right">
                      <p className="nm-label">Overdue | <span className="days">{data.overdue_deviation}</span></p>
                      <ProgressBar now={data.progress} label={data.progress+'%'}/>
                      <Accordion.Toggle eventKey="0" className="nm-toggle-button">
                        <ChevronDown color="#999999" />
                      </Accordion.Toggle>
                    </div>
                  </div>
                  <Accordion.Collapse eventKey="0">
                    <div className="open-bbody">
                      <Row>
                        <Col sm={4}>
                          <p className="nm-label">Activity</p>
                          <p className="nm-text">{data.activity}</p>
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Service</p>
                          <p className="nm-text">{data.service}</p>
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Project</p>
                          <p className="nm-text">{data.project}</p>
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Corrective action By</p>
                          <p className="nm-text">{data.recommendedby}</p>
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Assigned To</p>
                          {data.tracking_responsible_person.map((person, index) => (
                          <p key={index} className="nm-text">{person.person_name}</p> ))}
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Due Date</p>
                          {/* <p className="nm-text">{data.target_date}</p> */}
                          <p className="nm-text">{`${convertDateFromTimezone(data.target_date)}`}</p>

                        </Col>
                        <Col sm={12}>
                          <p className="nm-label">Corrective action</p>
                          <p className="nm-text">{data.recommendation}</p>
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Collapse>
                </div>
            </Accordion>
           </div> }</> ))}
           {Number.isInteger(this.props.progress_list.filter(item => item.status == 0).length/10) && this.props.progress_list.filter(item => item.status == 0) != '' && this.props.progress != this.props.progress_list.filter(item => item.status == 0).length &&
           <button className="filter-button1" onClick={() => this.props.iratrackerlist((this.props.progress_list.filter(item => item.status == 0).length/10)+1)}>Load more</button>}
          </Scrollbars>
        </div>
        <div className="open-divide">
          <div className="open-head completed">
            <h2>COMPLETED | {this.props.completed}</h2>
          </div>
          <div className="search-box">
            <div className="search-input">
              <input type="text" placeholder="Search Category" name="search" value={this.props.searchList3} onChange={(e) => this.props._handleInputChange3(e)}/>
              <button className="search-button"><Search /></button>
            </div>
            <button className="filter-button" onClick={() => this.trackerModal(true, 1)}><Filter /></button>
          </div>
          <Scrollbars style={{height : `calc(100vh - 18vw)`}}
           renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
           renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
           renderThumbVertical={props => <div {...props} style={{position: "relative",display: "block",width: "100%",cursor: "pointer",borderRadius: "inherit", backgroundColor: "rgba(0, 0, 0, 0.2)",height: "100px"}} />}
           >
           {this.props.completed_list.map((data, index) => (
            <>
            { data.status == 1  &&
            <div className="open-content" key={index}>
              <Accordion defaultActiveKey="0">
                <div className="nm-box open-box" style={{borderColor: data.severity_color }}>
                  <div className="open-bhead open-tracker-head" >
                    <h4>{data.type_of_act } | {data.category}</h4>
                    <div className="tracker-right">
                      <p className="nm-label">Overdue | <span className="days">{data.overdue_deviation}</span></p>
                      <Accordion.Toggle eventKey="0" className="nm-toggle-button">
                        <ChevronDown className="completed-arrow"/>
                      </Accordion.Toggle>
                    </div>
                  </div>
                  <Accordion.Collapse eventKey="0">
                    <div className="open-bbody">
                      <Row>
                        <Col sm={4}>
                          <p className="nm-label">Activity</p>
                          <p className="nm-text">{data.activity}</p>
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Service</p>
                          <p className="nm-text">{data.service}</p>
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Project</p>
                          <p className="nm-text">{data.project}</p>
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Corrective action By</p>
                          <p className="nm-text">{data.recommendedby}</p>
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Assigned To</p>
                          {data.tracking_responsible_person.map((person, index) => (
                         <p key={index} className="nm-text">{person.person_name}</p> ))}
                        </Col>
                        <Col sm={4}>
                          <p className="nm-label">Due Date</p>
                          {/* <p className="nm-text">{data.target_date}</p> */}
                          <p className="nm-text">{`${convertDateFromTimezone(data.target_date)}`}</p>

                        </Col>
                        <Col sm={12}>
                          <p className="nm-label">Corrective action</p>
                          <p className="nm-text">{data.recommendation}</p>
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Collapse>
                </div>
           </Accordion>
            </div> }</> ))}
            {Number.isInteger(this.props.completed_list.filter(item => item.status == 1).length/10) && this.props.completed_list.filter(item => item.status == 1) != '' && this.props.completed != this.props.completed_list.filter(item => item.status == 1).length &&
            <button className="filter-button1" onClick={() => this.props.nmTrackerCompletedList((this.props.completed_list.filter(item => item.status == 1).length/10)+1)}>Load more</button>}
          </Scrollbars>
        </div>
        <Modal
          centered
          show={this.state.trackerFilterPopup}
          onHide={() => this.trackerModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          <Button variant="outline-primary" className="modal-btn1"  onClick={() => this.props.nmtrackerlist()}>RESET  |  </Button>
            <Button type="submit" variant="outline-primary" className="modal-btn" onClick={() => {this.state.statusCheck == 0 ? this.props.nmFilterProgress(this.state.severity, this.state.overdue, this.state.serviceSelect, this.state.projectSelect, this.state.categorySelect, this.state.startDate, this.state.endDate, this.state.personSelect, this.state.statusCheck)
              : this.props.nmFilterCompleted(this.state.severity, this.state.overdue, this.state.serviceSelect, this.state.projectSelect, this.state.categorySelect, this.state.startDate, this.state.endDate, this.state.personSelect, this.state.statusCheck)}}>DONE</Button>
          </Modal.Header>

          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <label>Severity</label>
                <div className="along-label">
                  <label className="check-box">All
                    <input name="abc" type="checkbox" checked={this.state.all}
                      onClick={() => this.FilterData(this.state.all, 'all')}/>
                    <span className="checkmark1"></span>
                  </label>
                </div>
                <Row>
                  <Col sm={6}>
                    <label className="check-box">Extreme
                      <input value={4} type="checkbox" checked={this.state.extremeSelect}
                      onChange={this.selectSeverity.bind(this) }
                      onClick={() => {this.FilterData(this.state.extremeSelect, 4)} }/>
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">High
                      <input value={3} type="checkbox" checked={this.state.highSelect}
                        onChange={this.selectSeverity.bind(this) }
                       onClick={() => {this.FilterData(this.state.highSelect, 3)} }/>
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">Medium
                      <input value={2} type="checkbox" checked={this.state.mediumSelect}
                       onChange={this.selectSeverity.bind(this) }
                       onClick={() => {this.FilterData(this.state.mediumSelect, 2)} }/>
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">Low
                      <input value={1} id='1' type="checkbox" checked={this.state.lowSelect}
                        onChange={this.selectSeverity.bind(this) }
                       onClick={() => {this.FilterData(this.state.lowSelect, 1)} }/>
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">Overdue
                      <input name="abc" type="checkbox" checked={this.state.overdue}
                      onClick={() => this.FilterData(this.state.overdue, 'overdue')}/>
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>By Service</label>
                    <select className="form-control" value={this.state.serviceSelect} onChange={this._handleServiceChange} >
                    <option value=''>Select Service</option>
                       {this.state.service.map((service, index) => (
                     <option key={index} value={service.service_id}>{service.service_name}</option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>By Project</label>
                    <select className="form-control" value={this.state.projectSelect} onChange={(e) => {this.setState({projectSelect: e.target.value})}}>
                      <option>Select Project</option>
                      {this.state.project.map((project, index) => (
                      <option key={index} value={project.project_id}>{project.project}</option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>By Category</label>
                    <select className="form-control" value={this.state.categorySelect} onChange={(e) => {this.setState({categorySelect: e.target.value})}} >
                      <option value="">Select Category</option>
                      { this.props.CategoryFilter.map((data, index) => (
                      <option key={index} value={data.category_id}>{data.category}</option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>By Responsible Person</label>
                    <select className="form-control" value={this.state.personSelect} onChange={(e) => {this.setState({personSelect: e.target.value})}}>
                      <option value="0">Select Responsible Person</option>
                      {this.props.R_person.map((data, index) => (
                      <option key={index} value={data.person_id}>{data.person}</option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label>By Start Date & End Date</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={(jsDate) => this.setState({startDate: jsDate})}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">To</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={(jsDate) => this.setState({endDate: jsDate})}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

TrackerList.defaultProps = {
  progress_list : [],
  completed_list : [],
  CategoryFilter : []
}
