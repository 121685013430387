import React from "react";
import _ from "lodash";
//components
import { CommentView } from "app/views/LiveFeed/components/Feed/components/feedCard";
//assets
import { TimelineCalendarIcon } from "assets/Iconsv2/Iconsv2";
import { useTranslation } from "react-i18next";
const DetailCommentSection = ({ state, functionContent }) => {
  console.log("comment Section", functionContent);
  const { t } = useTranslation('util');
  return (
    <React.Fragment>
      <li id="discuss" ref={functionContent.myRef}>
        <div className="cbp_tmicon cbp_tmicon-mail">
          <TimelineCalendarIcon />
        </div>
        <div className="cbp_tmlabel clide-v2-white-box">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-8">
              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-2">
                {" "}
              </p>
            </div>
            <div className="col-12 col-md-4 col-lg-4 text-end">
              <button className="clide-v2-btn-template clide-v2-primary my-1 px-3 ">
                <span className="clide-v2-white-color">{t('discuss')}</span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <CommentView
                content={{
                  ...functionContent,
                  sendComment: functionContent.CommentAdd,
                  sendReply: functionContent.ReplyCommentAdd,
                }}
              />
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default DetailCommentSection;
