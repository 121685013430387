import React, { Component } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { Filter } from "react-feather";
import Url from "app/shared/constants/Url";
import axios from "axios";
import Highcharts, { charts } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Scrollbars } from "react-custom-scrollbars";
import { FunnelChart } from "react-funnel-pipeline";
import { CHANGE_TAB } from "app/redux/constant/ActionType";
import { renderToolTip } from "app/shared/utility/formHelper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TourIRA from "./TourIRA"
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import UaUcGraph from "app/shared/components/PredictiveAnalysis/UaUcGraph";
import { config } from "config.js";
import { Bar, Line } from 'react-chartjs-2'
import CountGraph from "app/views/Graphs/Graphs/CountGraph";
import ChartDataLabels from "chartjs-plugin-datalabels";

const qs = require("qs");

const mapStateToProps = (state) => {
  const data = { ...state.ira.ira };
  return {
    service_id: data.service_id,
    project_id: data.project_id,
    service_name: data.service_name,
    project_name: data.project_name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTab: (tabName) => {
      dispatch({ type: CHANGE_TAB, payload: tabName });
    },
  };
};
const blackListFunnelTooltipModule = ["First Aid", "Notable"];
const funnelModuleFullForm = {
  FA: "Fatal",
  MTC: "Medical Treatment Case",
  TRA: "Transport Road Accident",
  LTI: "Lost Time Injury",
  OI: "Occupational Illness",
};

const severity = [
  { value: 1, label: "LTI" },
  { value: 2, label: "MTC" },
  { value: 3, label: "RTA" },
  { value: 4, label: "FA" },
  { value: 5, label: "First Aid" },
  { value: 6, label: "OI" },
  { value: 7, label: "Notable" },
];
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: [],
      incident_type: [],
      activity_severity_graph: [],
      reset_activity_severity_graph: [],
      contributing_factor: [],
      immediate_factor: [],
      rca_factor: [],
      serviceSeverityGraph: [],
      injury_by_bodyPart: [],
      department_severity_graph: [],
      LocationSeverityGraph: [],
      resetdepartment_severity_graph: [],
      resetServiceSeverityGraph: [],
      resetLocationSeverityGraph: [],
      work_type: [],
      reset_work_type: [],
      incident_time_graph: [],
      reset_incident_time_graph: [],
      days_since_last_incidence: "",
      total_ira_reported: "",
      service: [],
      serviceSelect: "",
      activitylist: [],
      activitySelect: "",
      departmentlist: [],
      departmentSelect: "",
      project: [],
      projectSelect: "",
      R_person: [],
      fa: 0,
      extremeValue: 0,
      mtc: 0,
      rta: 0,
      lti: 0,
      all: 0,
      allValue: [],
      allS: [],
      severity: severity,
      personSelect: [],
      CategoryFilter: [],
      hasMore: [],
      workingHoursPopup: false,
      ActivityIncidentPopup: false,
      ServiceIncidentPopup: false,
      ProjectIncidentPopup: false,
      selectedOption: null,
      projectFilterSelectedService: null,
      selectedOptionseverity: null,
      selectedProjectFilterSeverity: null,
      selectedActivityFilterSeverity: null,
      selectedOptionproject: null,
      selectedOptionactivity: null,
      selectedOptionworkhours: null,
      elements_achieving: [],
      isFirstFetching: false,
      workingTypeList: [],
      selectedOptionWorkingType: [],
      time_of_incident: [],
      working_shift: [],
      departmentIncidentGraph: [],
      contractorIncidentGraph: [],
      zoneIncidentGraph: [],
      locationIncidentGraph: [],
      incidentTypeGraphData: [],
      incidentTypeGraph: "department",
      shiftIncidentGraphData: [],
      shiftIncidentGraph: "Time of Incident",
      total_data: null,
      userInfo: JSON.parse(localStorage.getItem("userData")) || null,
      zoneStatus: null,
      locationStatus: null,
      departmentStatus: null,
      contractorStatus: null,
    };
  }

  componentDidMount() {
    this.iraDashboard("service");
    this._getService();
    this._getProject();
    this._getActivity();
    this._getWorkingHourShiftList();
    // this._getDepartment();

    if (this.state.userInfo.designation_flag == 4) {
      this._getZoneRights();
    } else {
      this.setState({
        zoneStatus: 1,
        locationStatus: 1,
        departmentStatus: 1,
        contractorStatus: 1,
      });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.project_id !== prevState.project_id ||
      nextProps.service_id !== prevState.service_id
    ) {
      return {
        service_id: nextProps.service_id,
        project_id: nextProps.project_id,
      };
    }
    return null;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.service_id !== prevProps.service_id ||
      this.props.project_id !== prevProps.project_id
    ) {
      // after redux store update function get excuted, to prevent first time call added condition
      if (this.state.isFirstFetching) {
        await this.iraDashboard();
      } else {
        await this.setState({ isFirstFetching: true });
      }
    }
  }


  _getZoneRights = async () => {

    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let URL = Url.soiDashboard.dashboard.zoneRights;

    let requestData = {
      auth_key: token,
      user_id: userId
    }

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios.post(URL, qs.stringify(requestData), ContentType)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            zoneStatus: response.data.zone_right,
            locationStatus: response.data.location_right,
            departmentStatus: response.data.dept_right,
            contractorStatus: response.data.contractor_right,

          });
        }
      })
      .catch((error) => { });
  }

  iraDashboard = async (type = "PROJECT") => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();
    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("auth_key", token);
    data.append("user_id", userId);
    let URL = Url.iraModule.iraDashboard;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then(async (response) => {
        if (response.data.status === 200) {
          const {
            days_since_last_incidence,
            status,
            total_ira_reported,
            incident_type,
            activity_severity_graph,
            contributing_factor,
            working_shift,
            time_of_incident,
            immediate_factor,
            rca_factor,
            injury_by_bodyPart,
            service_severity_graph,
            project_severity_graph,
            work_type,
            incident_time_graph,
            elements_achieving_safety_performance,
            department_severity_graph,
            contractor_severity_graph,
            zone_severity_graph,
            location_severity_graph,
          } = response.data.data;

          await this.setState({
            days_since_last_incidence: days_since_last_incidence,
            status: status,
            total_ira_reported: total_ira_reported,
            incident_type: incident_type,
            activity_severity_graph: activity_severity_graph,
            reset_activity_severity_graph: activity_severity_graph,
            contributing_factor: contributing_factor,
            working_shift: working_shift,
            time_of_incident: time_of_incident,
            immediate_factor: immediate_factor,
            rca_factor: rca_factor,
            serviceSeverityGraph: service_severity_graph,
            injury_by_bodyPart: injury_by_bodyPart,
            department_severity_graph: service_severity_graph,
            LocationSeverityGraph: project_severity_graph,
            resetdepartment_severity_graph: service_severity_graph,
            resetServiceSeverityGraph: service_severity_graph,
            resetLocationSeverityGraph: project_severity_graph,
            work_type: work_type,
            reset_work_type: work_type,
            incident_time_graph: incident_time_graph,
            reset_incident_time_graph: incident_time_graph,
            elements_achieving: elements_achieving_safety_performance,
            isLoading: false,
            departmentIncidentGraph: department_severity_graph,
            contractorIncidentGraph: contractor_severity_graph,
            zoneIncidentGraph: zone_severity_graph,
            locationIncidentGraph: location_severity_graph,
            incidentTypeGraphData: department_severity_graph,
            shiftIncidentGraphData: time_of_incident,
            total_data: response.data.data,
          });
        } else {
          this._setDataLoading(false);
        }
      })
      .catch((error) => {
        this._setDataLoading(false);
      });
  };

  _setDataLoading = async (status) => {
    await this.setState({ isLoading: status });
  };

  ServiceIncidentFilter = async () => {
    await this.setState({
      ServiceIncidentPopup: false,
      dataLoading: true,
    });

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const serviceValue =
      this.state.selectedOption != null &&
      this.state.selectedOption.map((item) => item.service_id);
    const severityValue =
      this.state.selectedOptionseverity != null &&
      this.state.selectedOptionseverity.map((item) => item.value);
    await axios
      .post(
        Url.iraModule.serviceandincidenttypefilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            incident_type: severityValue,
            service_id: serviceValue,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            department_severity_graph:
              response.data.data.service_incident_type_graph,
            isLoading: false,
          });
        }
        // this.resetState();
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  ProjectIncidentFilter = async () => {
    await this.setState({
      ProjectIncidentPopup: false,
      dataLoading: true,
    });

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const projectValue =
      this.state.selectedOptionproject != null &&
      this.state.selectedOptionproject.map((item) => item.project_id);
    const severityValue =
      this.state.selectedProjectFilterSeverity != null &&
      this.state.selectedProjectFilterSeverity.map((item) => item.value);

    await axios
      .post(
        Url.iraModule.projectandincidenttypefilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            incident_type: severityValue,
            project_id: projectValue,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response.data.data);
          this.setState({
            LocationSeverityGraph:
              response.data.data.project_incident_type_graph,
            isLoading: false,
          });
        }
        // this.resetState();
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  ActivityIncidentFilter = async () => {
    await this.setState({
      ActivityIncidentPopup: false,
      dataLoading: true,
    });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const activityValue =
      this.state.selectedOptionactivity != null &&
      this.state.selectedOptionactivity.map((item) => item.activity_id);
    const severityValue =
      this.state.selectedActivityFilterSeverity != null &&
      this.state.selectedActivityFilterSeverity.map((item) => item.value);

    await axios
      .post(
        Url.iraModule.activityandincidenttypefilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            incident_type: severityValue,
            activity_id: activityValue,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            activity_severity_graph:
              response.data.data.activity_incident_type_graph,
            isLoading: false,
          });
        }
        // this.resetState();
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  workingHoursFilter = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const workingTypeValue = this.state.selectedOptionWorkingType.map(
      (item) => item.value
    );

    await this.setState({
      dataLoading: true,
      workingHoursPopup: false,
    });

    await axios
      .post(
        Url.iraModule.incidenttimeandincidenttypefilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            incident_time: workingTypeValue,
          },
          { arrayFormat: "comma" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            work_type: response.data.data.incident_time_graph,
            isLoading: false,
          });
        }
        // this.resetState();
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  ServiceIncident = (visible) => {
    this.setState({ ServiceIncidentPopup: visible });
  };

  ProjectIncident = (visible) => {
    this.setState({ ProjectIncidentPopup: visible });
  };

  ActivityIncident = (visible) => {
    this.setState({ ActivityIncidentPopup: visible });
  };

  showWorkingHours = () => {
    this.setState({ workingHoursPopup: true });
  };

  hideWorkingHours = () => {
    this.setState({ workingHoursPopup: false });
  };

  _getActivity = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.activitylist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: this.state.serviceSelect,
          project_id: this.state.projectSelect,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            activitylist: response.data.data,
            dataLoading: false,
          });
        }
        // console.log('result: ',this.state.project ? this.state.project : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _getDepartment = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.departmentlist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: this.state.serviceSelect,
          project_id: this.state.projectSelect,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            departmentlist: response.data.data,
            dataLoading: false,
          });
        }
        // console.log('result: ',this.state.project ? this.state.project : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _getService = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.serviceList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ service: response.data.data, dataLoading: false });
        }
        // console.log(this.state.service ? this.state.service : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _getProject = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const serviceValue =
      this.state.projectFilterSelectedService != null
        ? this.state.projectFilterSelectedService.service_id
        : 0;
    await axios
      .post(
        Url.projectList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: serviceValue,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ project: response.data.data, dataLoading: false });
        }
        // console.log('result: ',this.state.project ? this.state.project : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _getWorkingHourShiftList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.iraModule.workingTypeMasterList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          try {
            let { work_type } = response.data.data;
            let temp = work_type.map((item) => ({
              value: item.id,
              label: item.work_type,
            }));

            this.setState({ workingTypeList: temp });
          } catch (error) { }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  resetState = () => {
    this.setState({
      selectedOption: null,
      selectedOptionseverity: null,
      selectedOptionproject: null,
      selectedOptionactivity: null,
      selectedOptionworkhours: null,
    });
  };

  handleChange = async (selectedOption) => {
    await this.setState({ selectedOption });
    // await this._getProject();
  };

  handleProjectFilterServiceChange = async (projectFilterSelectedService) => {
    await this.setState({ projectFilterSelectedService });
    await this._getProject();
  };

  handleChangeproject = (selectedOptionproject) => {
    this.setState({ selectedOptionproject });
  };
  handleChangeactivity = (selectedOptionactivity) => {
    this.setState({ selectedOptionactivity });
  };
  handleChangeworkhours = (selectedOptionworkhours) => {
    this.setState({ selectedOptionworkhours });
  };

  handleChangeseverity = (selectedOptionseverity) => {
    this.setState({ selectedOptionseverity });
  };
  handleChangeActivityseverity = (selectedActivityFilterSeverity) => {
    this.setState({ selectedActivityFilterSeverity });
  };

  handleChangeProjectFilterSeverity = (selectedProjectFilterSeverity) => {
    this.setState({ selectedProjectFilterSeverity });
  };

  handleChangeWorkingShift = (selectedOptionWorkingType) => {
    this.setState({ selectedOptionWorkingType });
  };

  _handleResetServiceIncidentFilter = async () => {
    this.ServiceIncident(false);

    await this.setState((prevState) => {
      let {
        department_severity_graph,
        resetdepartment_severity_graph,
        selectedOption,
        selectedOptionseverity,
      } = prevState;
      department_severity_graph = [...resetdepartment_severity_graph];
      selectedOptionseverity = null;
      selectedOption = null;

      return {
        department_severity_graph,
        resetdepartment_severity_graph,
        selectedOption,
        selectedOptionseverity,
      };
    });
  };

  _handleResetProjectIncidentFilter = async () => {
    this.ProjectIncident(false);

    await this.setState((prevState) => {
      let {
        LocationSeverityGraph,
        resetLocationSeverityGraph,
        selectedOptionproject,
        projectFilterSelectedService,
        selectedProjectFilterSeverity,
      } = prevState;
      LocationSeverityGraph = [...resetLocationSeverityGraph];
      selectedProjectFilterSeverity = null;
      selectedOptionproject = null;
      projectFilterSelectedService = null;

      return {
        LocationSeverityGraph,
        resetLocationSeverityGraph,
        selectedOptionproject,
        projectFilterSelectedService,
        selectedProjectFilterSeverity,
      };
    });
  };

  _handleResetActivityIncidentFilter = async () => {
    this.ActivityIncident(false);

    await this.setState((prevState) => {
      let {
        activity_severity_graph,
        reset_activity_severity_graph,
        selectedOptionactivity,
        selectedActivityFilterSeverity,
      } = prevState;
      activity_severity_graph = [...reset_activity_severity_graph];
      selectedActivityFilterSeverity = null;
      selectedOptionactivity = null;

      return {
        activity_severity_graph,
        reset_activity_severity_graph,
        selectedOptionactivity,
        selectedActivityFilterSeverity,
      };
    });
  };

  _handleResetWorkTypeFilter = async () => {
    this.hideWorkingHours();

    await this.setState((prevState) => {
      let { work_type, reset_work_type, selectedOptionWorkingType } = prevState;
      work_type = [...reset_work_type];
      selectedOptionWorkingType = null;
      return {
        work_type,
        reset_work_type,
        selectedOptionWorkingType,
      };
    });
  };

  changeIncidentTypeGraph = async (type) => {
    let data;
    switch (type) {
      case "department":
        data = this.state.departmentIncidentGraph;
        break;
      case "contractor":
        data = this.state.contractorIncidentGraph;
        break;
      case "zone":
        data = this.state.zoneIncidentGraph;
        break;
      case "location":
        data = this.state.locationIncidentGraph;
        break;
      default:
        data = this.state.departmentIncidentGraph;
        break;
    }
    await this.setState({
      incidentTypeGraph: type,
      incidentTypeGraphData: data,
    })
  }

  changeShiftIncidentGraph = async (type) => {
    let data;
    switch (type) {
      case "Time of Incident":
        data = this.state.time_of_incident;
        break;
      case "Working Shift":
        data = this.state.working_shift;
        break;
      default:
        data = this.state.time_of_incident;
        break;
    }
    await this.setState({
      shiftIncidentGraph: type,
      shiftIncidentGraphData: data,
    })
  }

  render() {
    if (this.state.isLoading == false) {
      const { selectedOption } = this.state;
      const { selectedOptionproject } = this.state;
      const { selectedOptionactivity } = this.state;
      const { selectedOptionworkhours } = this.state;
      const {
        selectedOptionseverity,
        selectedOptionWorkingType,
        selectedProjectFilterSeverity,
        selectedActivityFilterSeverity,
      } = this.state;

      const pending =
        this.state.status != undefined ? this.state.status.pending : 0;
      const progress =
        this.state.status != undefined ? this.state.status.inprogress : 0;
      const completed =
        this.state.status != undefined ? this.state.status.completed : 0;
      const lastincidence = this.state.days_since_last_incidence
        ? this.state.days_since_last_incidence
        : 0;

      const lastIncidence = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2.4vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            lastincidence +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: ["#A4E9FF"],
        series: [
          {
            data: [this.state.days_since_last_incidence || 0],
            size: "100%",
            innerSize: "84%",
          },
        ],
      };

      const totalIRA =
        this.state.status != undefined ? this.state.status.total_ira : "";

      let statusGraphValue = [];
      let statusGraphValueData = [];
      if (this.state.status && Object.values(this.state.status).length) {
        const { pending, inprogress, completed } = this.state.status;
        statusGraphValue = [
          ["pending", pending || 0],
          ["inprogress", inprogress || 0],
          ["completed", completed || 0],
        ];
        statusGraphValueData = [
          { color: '#FF7070', name: 'pending', y: pending },
          { color: '#23C6D0', name: 'inprogress', y: inprogress },
          { color: '#23D053', name: 'completed', y: completed }
        ]
      }
      const statusGraphData = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {

            return '<b>' + this.point.name + '</b>: ' + this.y;

          }

        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2.4vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            totalIRA +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: ["#FF7070", "#23C6D0", "#23D053"],
        series: [
          {
            data: statusGraphValue,
            size: "100%",
            innerSize: "84%",
          },
        ],
      };

      // Activity & incident type start
      const fai_color = this.state.activity_severity_graph
        .filter((item) => item.fa_color)
        .map((item) => item.fa_color);
      const ltii_color = this.state.activity_severity_graph
        .filter((item) => item.lti_color)
        .map((item) => item.lti_color);
      const rta_color = this.state.activity_severity_graph
        .filter((item) => item.rta_color)
        .map((item) => item.rta_color);
      const mtc_color = this.state.activity_severity_graph
        .filter((item) => item.mtc_color)
        .map((item) => item.mtc_color);
      const activity_name = this.state.activity_severity_graph.map(
        (item) => item.activity_name
      );
      let activity = activity_name.length;
      for (let index = 0; index < activity; index++) {
        var fa_num = this.state.activity_severity_graph.map(
          (item) => item.fa_num
        );
        var lti_num = this.state.activity_severity_graph.map(
          (item) => item.lti_num
        );
        var mtc_num = this.state.activity_severity_graph.map(
          (item) => item.mtc_num
        );
        var rta_num = this.state.activity_severity_graph.map(
          (item) => item.rta_num
        );
        var fAid3 = this.state.activity_severity_graph.map((item) => item.firstAid_num);
        var oi3 = this.state.activity_severity_graph.map((item) => item.occupational_illness_num);
        var not3 = this.state.activity_severity_graph.map((item) => item.notable_num);
      }

      const activityGraphData = {
        labels: activity_name,
        datasets: [
          {
            label: "FA",
            data: fa_num,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: fai_color[0]
            
          },
          {
            label: "MTC",
            data: mtc_num,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: mtc_color[0],
          },
          {
            label: "RTA",
            data: rta_num,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: rta_color[0]
          },
          {
            label: "LTI",
            data: lti_num,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: ltii_color[0]
          },
          {
            label: "First Aid",
            data: fAid3,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: "rgba(21, 137, 238, 1)"
          },
          {
            label: "Occupational Illness",
            data: oi3,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: "rgba(246, 85, 211, 1)"
          },
          {
            label: "Notable",
            data: not3,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: "rgba(161, 255, 206, 1)"
          }
        ]
      };

      const activityGraph = {
        chart: {
          type: "column",
          height: "30%",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: activity_name,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        legend: {
          enabled: false,
        },
        colors: [fai_color[0], mtc_color[0], rta_color[0], ltii_color[0], "rgb(21, 137, 238)", "rgb(246, 85, 211)", "#A1FFCE"],
        series: [
          {
            name: "FA",
            data: fa_num,
          },
          {
            name: "MTC",
            data: mtc_num,
          },
          {
            name: "RTA",
            data: rta_num,
          },
          {
            name: "LTI",
            data: lti_num,
          },
          {
            name: "First Aid",
            data: fAid3,
          },
          {
            name: "Occupational Illness",
            data: oi3,
          },
          {
            name: "Notable",
            data: not3,
          }
        ],
      };

      // Incedent time graph start
      const incident_time_color = this.state.incident_time_graph.map(
        (item) => item.color
      );
      const incident_times = this.state.incident_time_graph.map(
        (item) => item.incident_time
      );
      let itg_length = incident_times.length;
      let incident_time = [];
      for (let index = 0; index < itg_length; index++) {
        const value = this.state.incident_time_graph.map((item) => item.value);
        incident_time.push({ value });
      }

      const ITG = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2.4vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            incident_time +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: [incident_time_color],
        series: [
          {
            data: [incident_time],
            size: "100%",
            innerSize: "84%",
          },
        ],
      };


      // Work type graph start
      const Worktype_color = this.state.work_type.map((item) => item.color);
      console.log(Worktype_color);
      const Work_type = this.state.work_type.map((item) => item.work_type ?? 0);
      let Work_typelength = Work_type.length;
      for (let index = 0; index < Work_typelength; index++) {
        var WType = this.state.work_type.map((item) => item.value);
      }
      const graphData = [];
      this.state.work_type && this.state.work_type.map((data) => {
        graphData.push({ y: data.value, color: data.color })
      });

      const WorktypeGraphData = {
        labels: Work_type,
        datasets: [
          {
            label: "",
            data: graphData.map(data => data.y),
            fill: true,
            maxBarThickness: 33,
            backgroundColor: graphData.map(data => data.color)
            
          }
        ]
      };
      const Worktype = {
        chart: {
          type: "column",
          height: "30%",
        },
        title: {
          text: "",
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: Work_type,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} </b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        colors: Worktype_color,
        series: [
          {
            data: graphData,
            // color: WColor,
          },
        ],
      };

      //timeOfIncident

      const timeOfIncidentData = {
        labels: this.state.shiftIncidentGraphData.map(
          (item) => {
            switch (this.state.shiftIncidentGraph) {
              case "Time of Incident":
                return item.time_of_incident ?? 0;
              case "Working Shift":
                return item.working_shift ?? 0;
              default:
                return item.time_of_incident ?? 0;
            }
          }
        ),
        datasets: [
          {
            label: "",
            data: this.state.shiftIncidentGraphData.map((data) => data.count),
            fill: true,
            borderWidth: 2,
            backgroundColor: "rgba(124, 181, 236, 0.2)",
            borderColor: "rgba(124, 181, 236, 1)"
          }
        ]
      };

      const timeOfIncident = {
        chart: {
          type: "line",
          height: "30%",
        },
        legend: {
          enabled: false,
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: this.state.shiftIncidentGraphData.map(
            (item) => {
              switch (this.state.shiftIncidentGraph) {
                case "Time of Incident":
                  return item.time_of_incident;
                case "Working Shift":
                  return item.working_shift;
                default:
                  return item.time_of_incident;
              }
            }
          ),
          crosshair: true,
        },
        yAxis: {
          title: {
            text: "Number",
          },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: false,
          },
        },
        series: [
          {
            data: this.state.shiftIncidentGraphData.map((data) => data.count),
          },
        ],
      };

      const cfactorColor = this.state.contributing_factor.map(
        (data) => ({ color: data.factor_color, y: data.factor_value, name: data.factor_name })
      );
      const cfactorValue = this.state.contributing_factor.map(
        (data) => data.factor_value
      );
      const cfv = cfactorValue.reduce(function (acc, val) {
        return acc + val;
      }, 0);
      const cf = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {

            return '<b>' + this.point.name + '</b>: ' + this.y;

          }

        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2.4vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            (cfv || "") +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: cfactorColor,
        series: [
          {
            data: cfactorColor,
            size: "100%",
            innerSize: "84%",
          },
        ],
      };

      const IfactorColor = this.state.immediate_factor.map(
        (data) => ({ color: data.factor_color, y: data.factor_value, name: data.factor_name })
      );
      const IfactorValue = this.state.immediate_factor.map(
        (data) => data.factor_value
      );

      const newIFValue = IfactorValue.reduce(function (acc, val) {
        return acc + val;
      }, 0);

      const IFactor = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {

            return '<b>' + this.point.name + '</b>: ' + this.y;

          }

        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2.4vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            (newIFValue || "") +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: IfactorColor,
        series: [
          {
            data: IfactorColor,
            size: "100%",
            innerSize: "84%",
          },
        ],
      };

      const RcfactorColor = this.state.rca_factor.map(
        (data) => data.factor_color
      );
      const RcfactorValue = this.state.rca_factor.map(
        (data) => data.factor_value
      );

      const newRcaValue = RcfactorValue.reduce(function (acc, val) {
        return acc + val;
      }, 0);
      const RCA = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2.4vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            (newRcaValue || "") +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: RcfactorColor,
        series: [
          {
            data: RcfactorValue,
            size: "100%",
            innerSize: "84%",
          },
        ],
      };

      //bodyofinjuryPart
      const BodyPartColor = this.state.injury_by_bodyPart.map(
        (data) => ({ color: data.color, y: data.count, name: data.injury_by_bodypart })
      );
      const BodyPartValue = this.state.injury_by_bodyPart.map(
        (data) => data.count
      );

      const newBodyPartValue = BodyPartValue.reduce(function (acc, val) {
        return acc + val;
      }, 0);
      const BodyPart = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {

            return '<b>' + this.point.name + '</b>: ' + this.y;

          }
        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2.4vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            (newBodyPartValue || "") +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: BodyPartColor,
        series: [
          {
            data: BodyPartColor,
            size: "100%",
            innerSize: "84%",
          },
        ],
      };

      const barGraphOptions = {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Number',
            position: 'left',
            align: 'center',
            font: {
              weight: 300,
              lineHeight: 1.9,
              family: `"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif`,
              size: 12
            }
          },
          tooltip: {
            backgroundColor: "white",
            bodyColor: "#666666",
            bodyFont: {
              weight: 500
            },
            borderColor: "rgba(0, 0, 0, 0.08)",
            borderWidth: 2,
            cornerRadius: 4,
            bodySpacing: 4,
            padding: {
              top: -10,
              bottom: 8,
              left: 0,
              right: 0
            },
            bodyAlign: 'center',
            displayColors: false,
            titleColor: "transparent"
          },
          datalabels: {
            display: true,
            align: 'top',
            anchor: 'end',
            color: 'black',
          },
        },
        scales: {
          x: {
            display: true,
            offset: true,
            grid: {
              display: false
            },
            ticks: {
              color: "#666666",
              font: {
                size: 11,
                weight: 500,
                family: `"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif`,
              },
              padding: 0
            }
          },
          y: {
            display: true,
            beginAtZero: true,
            grid: {
              color: "#ececec"
            },
            ticks: {
              color: "#666666",
              font: {
                size: 11,
                weight: 500,
                family: `"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif`,
              },
              padding: 0
            }
          }
        }
      };


      // Service and incident type start
      let fa_color = this.state.department_severity_graph
        .filter((item) => item.fa_color)
        .map((item) => item.fa_color);

      let lti_color = this.state.department_severity_graph
        .filter((item) => item.lti_color)
        .map((item) => item.lti_color);

      let mtc_c = this.state.department_severity_graph
        .filter((item) => item.mtc_color)
        .map((item) => item.mtc_color);

      let rta_c = this.state.department_severity_graph
        .filter((item) => item.rta_color)
        .map((item) => item.rta_color);

      // let faid_c = this.state.department_severity_graph
      // .filter((item) => item.rta_color)

      // .map((item) => item.rta_color);
      // let oi_c = this.state.department_severity_graph
      // .filter((item) => item.rta_color)
      // .map((item) => item.rta_color);

      // let not_c = this.state.department_severity_graph
      //   .filter((item) => item.rta_color)
      //   .map((item) => item.rta_color);

      let service_name = this.state.department_severity_graph.map(
        (item) => item.service_name
      );
      let gLength = service_name.length;
      for (let index = 0; index < gLength; index++) {
        var fa = this.state.department_severity_graph.map((item) => item.fa_num);
        var lti = this.state.department_severity_graph.map((item) => item.lti_num);
        var mtc = this.state.department_severity_graph.map((item) => item.mtc_num);
        var rta = this.state.department_severity_graph.map((item) => item.rta_num);
        var fAid = this.state.department_severity_graph.map((item) => item.firstAid_num);
        var oi = this.state.department_severity_graph.map((item) => item.occupational_illness_num);
        var not = this.state.department_severity_graph.map((item) => item.notable_num);
      }

      const ssGraphData = {
        labels: service_name,
        datasets: [
          {
            label: "FA",
            data: fa,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: fa_color[0]
            
          },
          {
            label: "MTC",
            data: mtc,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: mtc_c[0],
          },
          {
            label: "RTA",
            data: rta,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: rta_c[0]
          },
          {
            label: "LTI",
            data: lti,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: lti_color[0]
          },
          {
            label: "First Aid",
            data: fAid,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: "rgba(21, 137, 238, 1)"
          },
          {
            label: "Occupational Illness",
            data: oi,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: "rgba(246, 85, 211, 1)"
          },
          {
            label: "Notable",
            data: not,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: "rgba(161, 255, 206, 1)"
          }
        ]
      };
      const ssgraph = {
        chart: {
          type: "column",
          height: "30%",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: service_name,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        legend: {
          enabled: false,
        },
        colors: [fa_color[0], mtc_c[0], rta_c[0], lti_color[0], "rgb(21, 137, 238)", "rgb(246, 85, 211)", "#A1FFCE"],
        series: [
          {
            name: "FA",
            data: fa,
          },
          {
            name: "MTC",
            data: mtc,
          },
          {
            name: "RTA",
            data: rta,
          },
          {
            name: "LTI",
            data: lti,
          },
          {
            name: "First Aid",
            data: fAid,
          },
          {
            name: "Occupational Illness",
            data: oi,
          },
          {
            name: "Notable",
            data: not,
          }
        ],
      };

      // Departmnet and incident type
      let fa_color1 = this.state.incidentTypeGraphData
        .filter((item) => item.fa_color)
        .map((item) => item.fa_color);

      let lti_color1 = this.state.incidentTypeGraphData
        .filter((item) => item.lti_color)
        .map((item) => item.lti_color);

      let mtc_c1 = this.state.incidentTypeGraphData
        .filter((item) => item.mtc_color)
        .map((item) => item.mtc_color);

      let rta_c1 = this.state.incidentTypeGraphData
        .filter((item) => item.rta_color)
        .map((item) => item.rta_color);

      let name = this.state.incidentTypeGraphData.map(
        (item) => {
          switch (this.state.incidentTypeGraph) {
            case "department":
              return item.department_name;
            case "contractor":
              return item.contractor_name;
            case "zone":
              return item.zone_name;
            case "location":
              return item.location_name;
            default:
              return item.department_name;
          }
        }
      );
      let gLength1 = name.length;
      for (let index = 0; index < gLength1; index++) {
        var fa1 = this.state.incidentTypeGraphData.map((item) => item.fa_num);
        var lti1 = this.state.incidentTypeGraphData.map((item) => item.lti_num);
        var mtc1 = this.state.incidentTypeGraphData.map((item) => item.mtc_num);
        var rta1 = this.state.incidentTypeGraphData.map((item) => item.rta_num);
        var fAid1 = this.state.incidentTypeGraphData.map((item) => item.firstAid_num);
        var oi1 = this.state.incidentTypeGraphData.map((item) => item.occupational_illness_num);
        var not1 = this.state.incidentTypeGraphData.map((item) => item.notable_num);
      }

      const departmentGraphData = {
        labels: name,
        datasets: [
          {
            label: "FA",
            data: fa1,
            fill: true,barPercentage: 0.7,maxBarThickness: 3,
            backgroundColor: fa_color[0]
            
          },
          {
            label: "MTC",
            data: mtc1,
            fill: true,barPercentage: 0.7,maxBarThickness: 3,
            backgroundColor: mtc_c[0],
          },
          {
            label: "RTA",
            data: rta1,
            fill: true,barPercentage: 0.7,maxBarThickness: 3,
            backgroundColor: rta_c[0]
          },
          {
            label: "LTI",
            data: lti1,
            fill: true,barPercentage: 0.7,maxBarThickness: 3,
            backgroundColor: lti_color[0]
          },
          {
            label: "First Aid",
            data: fAid1,
            fill: true,barPercentage: 0.7,maxBarThickness: 3,
            backgroundColor: "rgba(21, 137, 238, 1)"
          },
          {
            label: "Occupational Illness",
            data: oi1,
            fill: true,barPercentage: 0.7,maxBarThickness: 3,
            backgroundColor: "rgba(246, 85, 211, 1)"
          },
          {
            label: "Notable",
            data: not1,
            fill: true,barPercentage: 0.7,maxBarThickness: 3,
            backgroundColor: "rgba(161, 255, 206, 1)"
          }
        ]
      };


      const department = {
        chart: {
          type: "column",
          height: "30%",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: name,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        legend: {
          enabled: false,
        },
        colors: [fa_color1[0], mtc_c1[0], rta_c1[0], lti_color1[0], "rgb(21, 137, 238)", "rgb(246, 85, 211)", "#A1FFCE"],
        series: [
          {
            name: "FA",
            data: fa1,
          },
          {
            name: "MTC",
            data: mtc1,
          },
          {
            name: "RTA",
            data: rta1,
          },
          {
            name: "LTI",
            data: lti1,
          },
          {
            name: "First Aid",
            data: fAid1,
          },
          {
            name: "Occupational Illness",
            data: oi1,
          },
          {
            name: "Notable",
            data: not1,
          }
        ],
      };


      // Service and incident type end

      // Project and incident type graph start
      let fa_clr = this.state.LocationSeverityGraph.filter(
        (item) => item.fa_color
      ).map((item) => item.fa_color);
      let lti_clr = this.state.LocationSeverityGraph.filter(
        (item) => item.lti_color
      ).map((item) => item.lti_color);
      let mtc_clr = this.state.LocationSeverityGraph.filter(
        (item) => item.mtc_color
      ).map((item) => item.mtc_color);
      let rta_clr = this.state.LocationSeverityGraph.filter(
        (item) => item.rta_color
      ).map((item) => item.rta_color);
      let project_name = this.state.LocationSeverityGraph.map(
        (item) => item.project_name
      );
      let graphLength = project_name.length;
      for (let index = 0; index < graphLength; index++) {
        var Data5 = this.state.LocationSeverityGraph.map((item) => item.fa_num);
        var Data6 = this.state.LocationSeverityGraph.map(
          (item) => item.lti_num
        );
        var Data7 = this.state.LocationSeverityGraph.map(
          (item) => item.mtc_num
        );
        var Data8 = this.state.LocationSeverityGraph.map(
          (item) => item.rta_num
        );
        var fAid2 = this.state.LocationSeverityGraph.map((item) => item.firstAid_num);
        var oi2 = this.state.LocationSeverityGraph.map((item) => item.occupational_illness_num);
        var not2 = this.state.LocationSeverityGraph.map((item) => item.notable_num);
      }

      const locationGraphData = {
        labels: project_name,
        datasets: [
          {
            label: "FA",
            data: Data5,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: fa_color[0]
            
          },
          {
            label: "MTC",
            data: Data7,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: mtc_c[0],
          },
          {
            label: "RTA",
            data: Data8,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: rta_c[0]
          },
          {
            label: "LTI",
            data: Data6,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: lti_color[0]
          },
          {
            label: "First Aid",
            data: fAid2,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: "rgba(21, 137, 238, 1)"
          },
          {
            label: "Occupational Illness",
            data: oi2,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: "rgba(246, 85, 211, 1)"
          },
          {
            label: "Notable",
            data: not2,
            fill: true,barPercentage: 0.7,maxBarThickness: 5,
            backgroundColor: "rgba(161, 255, 206, 1)"
          }
        ]
      };

      const Locationgraph = {
        chart: {
          type: "column",
          height: "30%",
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: project_name,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        legend: {
          enabled: false,
        },
        colors: [fa_clr[0], mtc_clr[0], rta_clr[0], lti_clr[0], "rgb(21, 137, 238)", "rgb(246, 85, 211)", "#A1FFCE"],
        series: [
          {
            name: "FA",
            data: Data5,
          },
          {
            name: "MTC",
            data: Data7,
          },
          {
            name: "RTA",
            data: Data8,
          },
          {
            name: "LTI",
            data: Data6,

          },
          {
            name: "First Aid",
            data: fAid2,
          },
          {
            name: "Occupational Illness",
            data: oi2,
          },
          {
            name: "Notable",
            data: not2,
          }
        ],
      };

      // Project and incident type graph start

      const colorfa =
        this.state.incident_type && this.state.incident_type.fa_color;
      const colorlti =
        this.state.incident_type && this.state.incident_type.lti_color;
      const colormtc =
        this.state.incident_type && this.state.incident_type.mtc_color;
      const colorrta =
        this.state.incident_type && this.state.incident_type.tra_color;
      const colorFirstAid =
        this.state.incident_type && this.state.incident_type.firstAid_color;
      const colorOi =
        this.state.incident_type && this.state.incident_type.occupational_Illness_color;
      const colorNotable =
        this.state.incident_type && this.state.incident_type.notable_color;
      const incidentfa =
        this.state.incident_type && this.state.incident_type.fa_number;
      const incidentlti =
        this.state.incident_type && this.state.incident_type.lti_number;
      const incidentmtc =
        this.state.incident_type && this.state.incident_type.mtc_number;
      const incidentFirstAid =
        this.state.incident_type && this.state.incident_type.firstAid_number;
      const incidentNumberOi = this.state.incident_type && this.state.incident_type.occupation_Illness_number;
      const incidentNotable =
        this.state.incident_type && this.state.incident_type.notable_number;
      const incidentrta =
        this.state.incident_type && this.state.incident_type.tra_number;
      const incidentfalabel =
        this.state.incident_type && this.state.incident_type.fa_label;
      const incidentltilabel =
        this.state.incident_type && this.state.incident_type.lti_label;
      const incidentmtclabel =
        this.state.incident_type && this.state.incident_type.mtc_label;
      const incidentrtalabel =
        this.state.incident_type && this.state.incident_type.tra_label;
      const incidentfirstAidLabel =
        this.state.incident_type && this.state.incident_type.firstAid_label;
      const incidentOi =
        this.state.incident_type && this.state.incident_type.occupational_Illness_label;
      const incidentNotableLabel =
        this.state.incident_type && this.state.incident_type.notable_label;

      const incidentTotal =
        incidentfa + incidentlti + incidentmtc + incidentrta  + incidentFirstAid + incidentNumberOi + incidentNotable;

      let incidentArray = [];
      let incidentData = [];

      if (
        this.state.incident_type &&
        Object.values(this.state.incident_type).length
      ) {
        incidentArray = [
          [incidentfalabel, incidentfa],
          [incidentltilabel, incidentlti],
          [incidentmtclabel, incidentmtc],
          [incidentrtalabel, incidentrta],
          [incidentfirstAidLabel, incidentFirstAid],
          [incidentOi, incidentNumberOi],
          [incidentNotableLabel, incidentNotable],
        ];
        incidentData = [
          { color: colorfa, name: incidentfalabel, y: incidentfa },
          { color: colorlti, name: incidentltilabel, y: incidentlti },
          { color: colormtc, name: incidentmtclabel, y: incidentmtc },
          { color: colorrta, name: incidentrtalabel, y: incidentrta },
          { color: colorFirstAid, name: incidentfirstAidLabel, y: incidentFirstAid },
          { color: colorOi, name: incidentOi, y: incidentNumberOi },
          { color: colorNotable, name: incidentNotableLabel, y: incidentNotable },
        ]
      }

      const IncidentType = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {

            return '<b>' + this.point.name + '</b>: ' + this.y;

          }
        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2.4vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            incidentTotal +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: [colorfa, colorlti, colormtc, colorrta, colorFirstAid, colorOi, colorNotable],
        series: [
          {
            data: incidentArray,
            size: "100%",
            innerSize: "84%",
          },
        ],
      };

      const dataArray =
        this.state.elements_achieving != undefined &&
        this.state.elements_achieving.map((item) => item.value);
      // console.log(dataArray)
      const totalCountValues =
        dataArray != [] &&
        dataArray.reduce((result, number) => result + number, 0);

      const dataValueArray =
        this.state.elements_achieving != undefined &&
        this.state.elements_achieving.map((item, key) => {
          return {
            y: item.value,
            name: item.label,
            id: item.id,
            color: item.color,
          };
        });
      const aspChart = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {

            return '<b>' + this.point.name + '</b>: ' + this.y;

          }
        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2.4vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            (totalCountValues || "") +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        // colors: IfactorColor,
        series: [
          {
            data: dataValueArray,
            size: "100%",
            innerSize: "84%",
          },
        ],
      };


      const getCapaCount = (totalCount) => {
        return {
          chart: {
            type: "pie",
            height: "100%",
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          title: {
            useHTML: true,
            text:
              '<span style="color:' +
              "#000" +
              "; font-weight:" +
              "bold" +
              "; font-size: " +
              "2vw" +
              "; text-align:" +
              "center" +
              '; height: 36">' +
              totalCount +
              "</span>",
            verticalAlign: "middle",
          },
          yAxis: {
            title: {
              enabled: false,
            },
          },
          plotOptions: {
            pie: {
              shadow: false,
              borderWidth: 0,
              dataLabels: {
                enabled: false,
              },
            },
          },
          colors: [(this.state.total_data.total_capa_recommendation_count === totalCount) ? "#A4E9FF" : (this.state.total_data.total_capa_open_recommendation_count === totalCount || 0) ? "red" : "green"],
          series: [
            {
              data: [totalCount || 0],
              size: "100%",
              innerSize: "84%",
            },
          ],
        };
      };

      const categoryCapaChart = {
        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {

            return '<b>' + this.point.name + '</b>: ' + this.y;

          }

        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            this.state.total_data.categorywiseOpenCount +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: this.state.total_data.categorywiseCapaOpenCount.length > 0 ? this.state.total_data.categorywiseCapaOpenCount?.map((data) => data.color) : null,
        lang: {
          noData: ''
        },
        category: this.state.total_data.categorywiseCapaOpenCount?.map((data) => data.category),
        series: [
          {
            data: this.state.total_data.categorywiseCapaOpenCount?.map((data) => ({ y: data.count, name: data.category })),
            size: "100%",
            innerSize: "84%",
          },
        ],
      };
      const pc = this.state.total_data.prioritywiseCapaOpenCount?.map((data) => ({ color: data.color, y: data.count, name: data.priority }));

      const priorityCapaChart = {

        chart: {
          type: "pie",
          height: "100%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {

            return '<b>' + this.point.name + '</b>: ' + this.y;

          }
        },
        title: {
          useHTML: true,
          text:
            '<span style="color:' +
            "#000" +
            "; font-weight:" +
            "bold" +
            "; font-size: " +
            "2vw" +
            "; text-align:" +
            "center" +
            '; height: 36">' +
            this.state.total_data.prioritywiseOpenCount +
            "</span>",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        colors: this.state.total_data.prioritywiseCapaOpenCount?.map((data) => data.color),
        lang: {
          noData: ''
        },


        category: this.state.total_data.prioritywiseCapaOpenCount?.map((data) => data.category),
        series: [
          {
            data: pc,
            size: "100%",
            innerSize: "84%",
          },
        ],

      };

      const funnelChartColor = [
        colorfa,
        colorrta,
        colormtc,
        colorlti,
        colorFirstAid,
        colorOi,
        colorNotable,
      ];
      const funnelChartData = [
        {
          name: "FA",
          value: incidentfa,
        },
        { name: "TRA", value: incidentrta },
        {
          name: incidentltilabel,
          value: incidentlti,
        },
        {
          name: incidentmtclabel,
          value: incidentmtclabel,
        },
        { name: "Notable", value: incidentNotable },
        {
          name: "OI",
          value: incidentNumberOi,
        },
        {
          name: incidentfirstAidLabel,
          value: incidentFirstAid,
        },
      ];

      const CountDoughnut = ({ data, total = 0, height = 150, fontSize = '2.5vw' }) => (
        <UaUcGraph
          title=""
          graphData={data ?? []}
          total={total}
          height={height}
          customTitles={{ useHTML: true }}
          list={false}
          cutout={'82%'}
          radius={'80%'}
          fontSize={fontSize}
          fontWeight={500}
        />
      )

      const CountChart = ({ count, color }) => (
        <CountGraph
          count={count}
          radius={'77%'}
          cutout={"82%"}
          height={165}
          fontWeight={500}
          fontSize={'2.1vw'}
          color={color}
        />
      )

      return (

        <Container className="open-section">
          <TourIRA />
          <Row className="mt-4">
            <Col md={3}>
              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">Days Since Last Incident</h5>
                </div>
                <div className="graph-block">
                  {config.isChartJsEnabled ? <CountChart
                    count={lastincidence}
                    color={'#A4E9FF'}
                  />
                    : <HighchartsReact
                      highcharts={Highcharts}
                      options={lastIncidence}
                    />}
                </div>
              </div>
              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">Total CAPA Category</h5>
                </div>
                {config.isChartJsEnabled ?
                  <CountDoughnut data={dataValueArray} total={totalCountValues} /> : (
                    <div className="graph-block">
                      <HighchartsReact highcharts={Highcharts} options={aspChart} />
                    </div>
                  )}

                <Scrollbars style={{ height: `8vw` }}>
                  <div className="outer-legend-block1">
                    {this.state.elements_achieving.map((data, index) => (
                      <div
                        className="soi-kanban-inner-legend-block"
                        key={index}
                      >
                        <span
                          className="soi-kanban-modal-legend-box"
                          style={{ backgroundColor: data.color }}
                        ></span>
                        <span className="sm-graph-modal-legend-text">
                          {data.label}
                        </span>
                        <span className="legend-number">{data.value}</span>
                      </div>
                    ))}
                  </div>
                </Scrollbars>
              </div>
              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title"> Total Contributing Factor's</h5>
                </div>

                {config.isChartJsEnabled ?
                  <CountDoughnut data={cfactorColor} total={cfv} /> : (
                    <div className="graph-block">
                      <HighchartsReact highcharts={Highcharts} options={cf} />
                    </div>)}
                <Scrollbars style={{ height: `8vw` }}>
                  <div className="outer-legend-block1">
                    {this.state.contributing_factor.map((data, index) => (
                      <div
                        className="soi-kanban-inner-legend-block"
                        key={index}
                      >
                        <span
                          className="soi-kanban-modal-legend-box"
                          style={{ backgroundColor: data.factor_color }}
                        ></span>
                        <span className="sm-graph-modal-legend-text">
                          {data.factor_name}
                        </span>
                        <span className="legend-number">
                          {data.factor_value}
                        </span>
                      </div>
                    ))}
                  </div>
                </Scrollbars>
              </div>

              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">Total Immediate Factor's</h5>
                </div>
                {config.isChartJsEnabled ?
                  <CountDoughnut data={IfactorColor} total={newIFValue} /> : (
                    <div className="graph-block">
                      <HighchartsReact highcharts={Highcharts} options={IFactor} />
                    </div>)}
                <Scrollbars style={{ height: `8vw` }}>
                  <div className="outer-legend-block1">
                    {this.state.immediate_factor.map((data, index) => (
                      <div
                        className="soi-kanban-inner-legend-block"
                        key={index}
                      >
                        <span
                          className="soi-kanban-modal-legend-box"
                          style={{ backgroundColor: data.factor_color }}
                        ></span>
                        <span className="sm-graph-modal-legend-text">
                          {data.factor_name}
                        </span>
                        <span className="legend-number">
                          {data.factor_value}
                        </span>
                      </div>
                    ))}
                  </div>
                </Scrollbars>
              </div>

              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">Total Root Causes</h5>
                </div>
                {config.isChartJsEnabled ?
                  <CountDoughnut
                    data={this.state.rca_factor.map(
                      (data) => ({ color: data.factor_color, y: data.factor_value, name: data.factor_name })
                    )}
                    total={newRcaValue}
                  /> : (
                    <div className="graph-block">
                      <HighchartsReact highcharts={Highcharts} options={RCA} />
                    </div>)}
                <Scrollbars style={{ height: `8vw` }}>
                  <div className="outer-legend-block1">
                    {this.state.rca_factor.map((data, index) => (
                      <div
                        className="soi-kanban-inner-legend-block"
                        key={index}
                      >
                        <span
                          className="soi-kanban-modal-legend-box"
                          style={{ backgroundColor: data.factor_color }}
                        ></span>
                        <span className="sm-graph-modal-legend-text">
                          {data.factor_name}
                        </span>
                        <span className="legend-number">
                          {data.factor_value}
                        </span>
                      </div>
                    ))}
                  </div>
                </Scrollbars>
              </div>

              {/* injury_by_bodyPart */}
              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">Injury Body Parts</h5>
                </div>
                {config.isChartJsEnabled ?
                  <CountDoughnut data={BodyPartColor} total={newBodyPartValue} /> : (
                    <div className="graph-block">
                      <HighchartsReact highcharts={Highcharts} options={BodyPart} />
                    </div>)}
                <Scrollbars style={{ height: `8vw` }}>
                  <div className="outer-legend-block1">
                    {this.state.injury_by_bodyPart.map((data, index) => (
                      <div
                        className="soi-kanban-inner-legend-block"
                        key={index}
                      >
                        <span
                          className="soi-kanban-modal-legend-box"
                          style={{ backgroundColor: data.color }}
                        ></span>
                        <span className="sm-graph-modal-legend-text">
                          {data.injury_by_bodypart}
                        </span>
                        <span className="legend-number">
                          {data.count}
                        </span>
                      </div>
                    ))}
                  </div>
                </Scrollbars>

              </div>
              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">IRA Incident Type</h5>
                </div>
                <div className="d-flex flex-row-reverse w-100">
                  <div className="flex-grow-1 pt-5">
                    <FunnelChart
                      heightRelativeToValue={false}
                      getToolTip={(row) => {
                        const { name, value } = row;

                        if (!blackListFunnelTooltipModule.includes(name)) {
                          return `${funnelModuleFullForm[name]} : ${value}`;
                        }
                      }}
                      data={funnelChartData}
                      // style={{ fontFamily: "IBM Plex Mono", fontSize: "32px" }}
                      pallette={funnelChartColor}
                      // getRowStyle={() => {
                      //   return { fontSize: "24px" };
                      // }}
                      getRowNameStyle={(row) => {
                        return row.value < 100
                          ? { fontSize: 12 }
                          : { fontSize: 12 };
                      }}
                      getRowValueStyle={(row) => {
                        return row.value < 100
                          ? { fontSize: 12 }
                          : { fontSize: 12 };
                      }}
                      // getToolTip={(row) => {
                      //   const { name } = row;
                      //   return `This is custom tooltip : ${name}`;
                      // }}
                      title=""
                    />
                  </div>
                  <div>
                    {renderToolTip(
                      <div>
                        <ul
                          className="font-14 overflow-auto"
                          style={{ maxHeight: "13rem" }}
                        >
                          <li>
                            {" "}
                            1. <b>FA - Fatal</b>: The top of the triangle,
                            representing the most severe and rare events.
                          </li>
                          <li>
                            {" "}
                            2. <b>TRA - Transport Road Accident</b>: Often
                            results in severe consequences, hence placed high in
                            severity
                          </li>
                          <li>
                            {" "}
                            3. <b>LTI - Lost Time Injury</b>: Injuries resulting
                            in an employee being unable to work for a period of
                            time.
                          </li>
                          <li>
                            {" "}
                            4. <b>MTC - Medical Treatment Case</b>: Injuries
                            that require medical treatment but do not result in
                            lost work time.
                          </li>
                          <li>
                            {" "}
                            5. <b>Notable</b>: These are significant but not
                            severe incidents that are important for learning and
                            preventing future accidents.
                          </li>
                          <li>
                            {" "}
                            6. <b>OI - Occupational Illness</b>: Illnesses
                            resulting from workplace conditions, which might not
                            be immediately severe but can have long-term
                            impacts.
                          </li>
                          <li>
                            {" "}
                            7. <b>First Aid</b>: Minor injuries that require
                            only basic first aid, representing the most frequent
                            and least severe incidents.
                          </li>
                        </ul>
                        <p className="font-14 font-weight-500 py-2 border-top">
                          The sequence aims to reflect the decreasing severity
                          and increasing frequency of incidents as you move down
                          the triangle.
                        </p>
                      </div>,
                      "top",
                      { width: "1.3rem" },
                      2
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <Row>
                <Col sm={6}>
                  <div className="nm-dashboard-box">
                    <div className="header-block">
                      <h5 className="header-title">Investigation Status</h5>
                    </div>
                    <Row noGutters={true}>
                      <Col sm={6}>
                        {config.isChartJsEnabled ?
                          <CountDoughnut data={statusGraphValueData} total={totalIRA} /> : (
                            <div className="graph-block">
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={statusGraphData}
                              />
                            </div>)}
                      </Col>
                      <Col sm={6} className="right-legend">
                        <div className="outer-legend-block">
                          <div className="soi-kanban-inner-legend-block">
                            <span
                              className="soi-kanban-modal-legend-box"
                              style={{ backgroundColor: "#FF7070" }}
                            ></span>
                            <span className="sm-graph-modal-legend-text">
                              Pending
                            </span>
                            <span className="legend-number">{pending}</span>
                          </div>
                          <div className="soi-kanban-inner-legend-block">
                            <span
                              className="soi-kanban-modal-legend-box"
                              style={{ backgroundColor: "#23C6D0" }}
                            ></span>
                            <span className="sm-graph-modal-legend-text">
                              In Progress
                            </span>
                            <span className="legend-number">{progress}</span>
                          </div>
                          <div className="soi-kanban-inner-legend-block">
                            <span
                              className="soi-kanban-modal-legend-box"
                              style={{ backgroundColor: "#23D053" }}
                            ></span>
                            <span className="sm-graph-modal-legend-text">
                              Completed
                            </span>
                            <span className="legend-number">{completed}</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="nm-dashboard-box">
                    <div className="header-block">
                      <h5 className="header-title">Reported Incident Type </h5>
                    </div>
                    <Row noGutters={true}>
                      <Col sm={6}>
                        {config.isChartJsEnabled ?
                          <CountDoughnut data={incidentData} total={incidentTotal} /> : (
                            <div className="graph-block">
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={IncidentType}
                              />
                            </div>)}
                      </Col>
                      <Col sm={6} className="right-legend">
                        {incidentTotal != "No data" && (
                          <div className="outer-legend-block">
                            <div className="soi-kanban-inner-legend-block">
                              <span
                                className="soi-kanban-modal-legend-box"
                                style={{ backgroundColor: colorfa }}
                              ></span>
                              <span className="sm-graph-modal-legend-text">
                                FA
                              </span>
                              <span className="legend-number">
                                {incidentfa}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block">
                              <span
                                className="soi-kanban-modal-legend-box"
                                style={{ backgroundColor: colormtc }}
                              ></span>
                              <span className="sm-graph-modal-legend-text">
                                MTC
                              </span>
                              <span className="legend-number">
                                {incidentmtc}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block">
                              <span
                                className="soi-kanban-modal-legend-box"
                                style={{ backgroundColor: colorrta }}
                              ></span>
                              <span className="sm-graph-modal-legend-text">
                                TRA
                              </span>
                              <span className="legend-number">
                                {incidentrta}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block">
                              <span
                                className="soi-kanban-modal-legend-box"
                                style={{ backgroundColor: colorlti }}
                              ></span>
                              <span className="sm-graph-modal-legend-text">
                                LTI
                              </span>
                              <span className="legend-number">
                                {incidentlti}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block">
                              <span
                                className="soi-kanban-modal-legend-box"
                                style={{ backgroundColor: colorFirstAid }}
                              ></span>
                              <span className="sm-graph-modal-legend-text">
                                First Aid
                              </span>
                              <span className="legend-number">
                                {incidentFirstAid}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block">
                              <span
                                className="soi-kanban-modal-legend-box"
                                style={{ backgroundColor: colorOi }}
                              ></span>
                              <span className="sm-graph-modal-legend-text">
                                OI
                              </span>
                              <span className="legend-number">
                                {incidentNumberOi}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block">
                              <span
                                className="soi-kanban-modal-legend-box"
                                style={{ backgroundColor: colorNotable }}
                              ></span>
                              <span className="sm-graph-modal-legend-text">
                                Notable
                              </span>
                              <span className="legend-number">
                                {incidentNotable}
                              </span>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col sm={4}>
                      <div className="nm-dashboard-box">
                        <div className="header-block">
                          <h5 className="header-title">
                            Total CAPA Recommendation Count
                          </h5>
                        </div>
                        <div className="graph-block">
                          {config.isChartJsEnabled ? <CountChart
                            count={this.state.total_data
                              ?.total_capa_recommendation_count}
                            color={'#A4E9FF'}
                          />
                            : <HighchartsReact
                              highcharts={Highcharts}
                              options={getCapaCount(
                                this.state.total_data
                                  ?.total_capa_recommendation_count
                              )}
                            />}
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="nm-dashboard-box">
                        <div className="header-block">
                          <h5 className="header-title">
                            CAPA Recommendation  Open Count
                          </h5>
                        </div>
                        <div className="graph-block">
                          {config.isChartJsEnabled ? <CountChart
                            count={this.state.total_data
                              ?.total_capa_open_recommendation_count}
                            color={'red'}
                          />
                            : <HighchartsReact
                              highcharts={Highcharts}
                              options={getCapaCount(
                                this.state.total_data
                                  ?.total_capa_open_recommendation_count
                              )}
                            />}
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="nm-dashboard-box">
                        <div className="header-block">
                          <h5 className="header-title">
                            CAPA Recommendation Closed Count
                          </h5>
                        </div>
                        <div className="graph-block">
                          {config.isChartJsEnabled ? <CountChart
                            count={this.state.total_data
                              ?.total_capa_close_recommendation_count}
                            color={'green'}
                          />
                            : <HighchartsReact
                              highcharts={Highcharts}
                              options={getCapaCount(
                                this.state.total_data
                                  ?.total_capa_close_recommendation_count
                              )}
                            />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col sm={12}>
                  <Row>
                    <Col sm={6}>
                      <div className="nm-dashboard-box">
                        <div className="header-block">
                          <h5 className="header-title">Categorywise CAPA Open Count</h5>
                        </div>
                        <Row noGutters={true}>
                          <Col sm={6}>
                            {config.isChartJsEnabled ?
                              <CountDoughnut
                                data={this.state.total_data.categorywiseCapaOpenCount?.map((data) => ({ y: data.count, name: data.category, color: data.color }))}
                                total={this.state.total_data.categorywiseOpenCount} 
                                height={122}
                                /> : (
                                <div
                                  className="graph-block"
                                  style={{ width: "10vw" }}
                                >
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={categoryCapaChart}
                                  />

                                </div>)}
                          </Col>
                          <Col sm={6} className="right-legend">
                            <Scrollbars style={{ height: `8vw` }}>
                              <div className="outer-legend-block">
                                {this.state.total_data.categorywiseCapaOpenCount.length > 0 && this.state.total_data.categorywiseCapaOpenCount.map(
                                  (data, index) => (
                                    <div
                                      className="soi-kanban-inner-legend-block"
                                      key={index}
                                    >
                                      <span
                                        className="soi-kanban-modal-legend-box"
                                        style={{
                                          backgroundColor:
                                            data.color != ""
                                              ? data.color
                                              : "#FAB95C",
                                        }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text">
                                        {data.category}
                                      </span>
                                      <span className="legend-number">
                                        {data.count}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </Scrollbars>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="nm-dashboard-box">
                        <div className="header-block">
                          <h5 className="header-title">Prioritywise CAPA Open Count</h5>
                        </div>
                        <Row noGutters={true}>
                          <Col sm={6}>
                            {config.isChartJsEnabled ?
                              <CountDoughnut
                                data={pc}
                                total={this.state.total_data.prioritywiseOpenCount} 
                                height={122}
                                /> : (
                            <div
                              className="graph-block"
                              style={{ width: "10vw" }}
                            >
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={priorityCapaChart}
                              />
                            </div>)}
                          </Col>
                          <Col sm={6} className="right-legend">
                            <Scrollbars style={{ height: `8vw` }}>
                              <div className="outer-legend-block">
                                {this.state.total_data.prioritywiseCapaOpenCount.map(
                                  (data, index) => (
                                    <div
                                      className="soi-kanban-inner-legend-block"
                                      key={index}
                                    >
                                      <span
                                        className="soi-kanban-modal-legend-box"
                                        style={{
                                          backgroundColor:
                                            data.color != ""
                                              ? data.color
                                              : "#FAB95C",
                                        }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text">
                                        {data.priority}
                                      </span>
                                      <span className="legend-number">
                                        {data.count}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </Scrollbars>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>


                <Col sm={12}>
                  <div className="nm-dashboard-box">
                    <div className="header-block">
                      <h5 className="header-title">IRA Reported by Service & Incident Type</h5>
                      <Filter
                        className="nm-filter"
                        onClick={() => this.ServiceIncident(true)}
                      />
                    </div>
                    <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                        <Bar
                          data={ssGraphData}
                          options={barGraphOptions}
                          height={90}
                        />
                      ) :
                        (<HighchartsReact
                        highcharts={Highcharts}
                        options={ssgraph}
                      />)}
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="nm-dashboard-box">
                    <div className="uauc-dashboard-second uauc-middle-top">
                      <Row>
                        <Col>
                          <h5 className="header-title">{`IRA Reported by  ${this.state.incidentTypeGraph.charAt(0).toUpperCase() + this.state.incidentTypeGraph.slice(1)} & Incident Type`}</h5>
                        </Col>
                        <Col xs="7">
                          <ul className="middle-indicator cursor-pointer">
                            {this.state.departmentStatus == 1 && (
                              <li onClick={() => { this.changeIncidentTypeGraph("department") }}>
                                <span
                                  className="square-box"
                                  style={{ backgroundColor: "grey", marginRight: '.5vw' }}
                                ></span>
                                Department
                              </li>
                            )}
                            {this.state.contractorStatus == 1 && (
                              <li onClick={() => { this.changeIncidentTypeGraph("contractor") }}>
                                <span
                                  className="square-box"
                                  style={{ backgroundColor: "grey", marginRight: '.5vw' }}
                                ></span>
                                Contractor
                              </li>
                            )}
                            {this.state.zoneStatus == 1 && (
                              <li onClick={() => { this.changeIncidentTypeGraph("zone") }}>
                                <span
                                  className="square-box"
                                  style={{ backgroundColor: "grey", marginRight: '.5vw' }}
                                ></span>
                                Zone
                              </li>)}
                            {this.state.locationStatus == 1 && (
                              <li onClick={() => { this.changeIncidentTypeGraph("location") }}>
                                <span
                                  className="square-box"
                                  style={{ backgroundColor: "grey", marginRight: '.5vw' }}
                                ></span>
                                Location
                              </li>)}
                          </ul>
                        </Col>
                      </Row>
                      {/* <Filter
                        className="nm-filter"
                        onClick={() => this.ServiceIncident(true)}
                      /> */}
                    </div>
                    <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                        <Bar
                          data={departmentGraphData}
                          options={barGraphOptions}
                          height={90}
                        />
                      ) :
                        (<HighchartsReact
                        highcharts={Highcharts}
                        options={department}
                      />)}
                    </div>
                  </div>
                </Col>

                <Col sm={12}>
                  <div className="nm-dashboard-box">
                    <div className="header-block">
                      <h5 className="header-title">
                        IRA Reported by Plant/Project & Incident Type
                      </h5>
                      <Filter
                        className="nm-filter"
                        onClick={() => this.ProjectIncident(true)}
                      />
                    </div>
                    <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                        <Bar
                          data={locationGraphData}
                          options={barGraphOptions}
                          height={90}
                        />
                      ) :
                        (<HighchartsReact
                        highcharts={Highcharts}
                        options={Locationgraph}
                      />)}
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="nm-dashboard-box">
                    <div className="header-block">
                      <h5 className="header-title">IRA Reported by Activity & Incident Type</h5>
                      <Filter
                        className="nm-filter"
                        onClick={() => this.ActivityIncident(true)}
                      />
                    </div>
                    <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                        <Bar
                          data={activityGraphData}
                          options={barGraphOptions}
                          height={90}
                        />
                      ) :
                        (<HighchartsReact
                        highcharts={Highcharts}
                        options={activityGraph}
                      />)}
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="nm-dashboard-box">
                    <div className="header-block">
                      <h5 className="header-title">Injured Person Work Type</h5>
                      <Filter
                        className="nm-filter"
                        onClick={this.showWorkingHours}
                      />
                    </div>
                    <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                        <Bar
                          data={WorktypeGraphData}
                          options={barGraphOptions}
                          height={90}
                        />
                      ) :
                        (<HighchartsReact
                        highcharts={Highcharts}
                        options={Worktype}
                      />)}
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="nm-dashboard-box">
                    <div className="header-block">
                      <Row>
                        <Col>
                          <h5 className="header-title">{this.state.shiftIncidentGraph}</h5>
                        </Col>
                        <Col xs="7">
                          <ul className="middle-indicator cursor-pointer">
                            <li onClick={() => { this.changeShiftIncidentGraph("Time of Incident") }}>
                              <span
                                className="square-box"
                                style={{ backgroundColor: "grey", marginRight: '.5vw' }}
                              ></span>
                              Time of Incident
                            </li>
                            <li onClick={() => { this.changeShiftIncidentGraph("Working Shift") }}>
                              <span
                                className="square-box"
                                style={{ backgroundColor: "grey", marginRight: '.5vw' }}
                              ></span>
                              Working Shift
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                    <div className="full-graph new-height-nm">
                    {config.isChartJsEnabled ? (
                        <Line
                          data={timeOfIncidentData}
                          options={barGraphOptions}
                          plugins={[ChartDataLabels]}
                          height={90}
                        />
                      ) :
                        (<HighchartsReact
                        highcharts={Highcharts}
                        options={timeOfIncident}
                      />)}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Modal
            centered
            onHide={() => this.ServiceIncident(false)}
            show={this.state.ServiceIncidentPopup}
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
              <Button
                className="modal-btn1"
                onClick={this._handleResetServiceIncidentFilter}
              >
                RESET | &nbsp;
              </Button>
              <Button
                disabled={selectedOption == null || !selectedOptionseverity}
                className="modal-btn"
                onClick={this.ServiceIncidentFilter}
              >
                APPLY
              </Button>
            </Modal.Header>

            <Modal.Body>
              <div className="nm-filter-modal">
                <div className="form-group position-relative">
                  <Row>
                    <Col sm={6}>
                      <label>INCIDENT TYPE</label>
                      <Select
                        isClearable
                        isSearchable
                        options={this.state.severity}
                        isMulti
                        value={selectedOptionseverity}
                        onChange={this.handleChangeseverity}
                        placeholder="Select incident type  "
                      />
                    </Col>
                    <Col sm={6}>
                      <label>Service</label>
                      <Select
                        isClearable
                        isSearchable
                        options={this.state.service}
                        isMulti
                        value={selectedOption}
                        onChange={this.handleChange}
                        getOptionLabel={(option) => option["service_name"]}
                        getOptionValue={(option) => option["service_id"]}
                        placeholder="Select service"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            onHide={() => this.ProjectIncident(false)}
            show={this.state.ProjectIncidentPopup}
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
              <Button
                className="modal-btn1"
                onClick={this._handleResetProjectIncidentFilter}
              >
                RESET |
              </Button>
              <Button
                disabled={
                  selectedOptionproject == null ||
                  !selectedProjectFilterSeverity
                }
                className="modal-btn"
                onClick={this.ProjectIncidentFilter}
              >
                APPLY
              </Button>
            </Modal.Header>

            <Modal.Body>
              <div className="nm-filter-modal">
                <div className="form-group position-relative">
                  <Row>
                    <Col sm={6}>
                      <label>Service</label>
                      <Select
                        options={this.state.service}
                        value={this.state.projectFilterSelectedService}
                        onChange={this.handleProjectFilterServiceChange}
                        getOptionLabel={(option) => option["service_name"]}
                        getOptionValue={(option) => option["service_id"]}
                      />
                    </Col>
                    <Col sm={6}>
                      <label>Plant/Project</label>
                      <Select
                        isClearable
                        isSearchable
                        options={this.state.project}
                        isMulti
                        value={selectedOptionproject}
                        onChange={this.handleChangeproject}
                        getOptionLabel={(option) => option["project"]}
                        getOptionValue={(option) => option["project_id"]}
                        placeholder="Select Plant/Project"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <label>Incident type</label>
                      <Select
                        isClearable
                        isSearchable
                        options={this.state.severity}
                        isMulti
                        value={selectedProjectFilterSeverity}
                        onChange={this.handleChangeProjectFilterSeverity}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            onHide={() => this.ActivityIncident(false)}
            show={this.state.ActivityIncidentPopup}
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
              <Button
                className="modal-btn1"
                onClick={this._handleResetActivityIncidentFilter}
              >
                RESET |
              </Button>

              <Button
                disabled={
                  selectedOptionactivity == null ||
                  !selectedActivityFilterSeverity
                }
                className="modal-btn"
                onClick={this.ActivityIncidentFilter}
              >
                APPLY
              </Button>
            </Modal.Header>

            <Modal.Body>
              <div className="nm-filter-modal">
                <div className="form-group position-relative">
                  <Row>
                    <Col sm={6}>
                      <label>Incident type</label>
                      <Select
                        isClearable
                        isSearchable
                        options={this.state.severity}
                        isMulti
                        value={selectedActivityFilterSeverity}
                        onChange={this.handleChangeActivityseverity}
                      />
                    </Col>
                    <Col sm={6}>
                      <label>Activity</label>
                      <Select
                        isClearable
                        isSearchable
                        options={this.state.activitylist}
                        isMulti
                        value={selectedOptionactivity}
                        onChange={this.handleChangeactivity}
                        getOptionLabel={(option) => option["actvity"]}
                        getOptionValue={(option) => option["activity_id"]}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            onHide={this.hideWorkingHours}
            show={this.state.workingHoursPopup}
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>

              <Button
                className="modal-btn1"
                onClick={this._handleResetWorkTypeFilter}
              >
                RESET |
              </Button>

              <Button
                disabled={!selectedOptionWorkingType}
                className="modal-btn"
                onClick={this.workingHoursFilter}
              >
                APPLY
              </Button>
            </Modal.Header>

            <Modal.Body>
              <div className="nm-filter-modal">
                <div className="form-group position-relative">
                  <Row>
                    <Col sm={12}>
                      <label>Working Type</label>
                      <Select
                        isClearable
                        isSearchable
                        options={this.state.workingTypeList}
                        isMulti
                        value={selectedOptionWorkingType}
                        onChange={this.handleChangeWorkingShift}
                        isRequired
                        placeholder="Select Work type"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      );
    } else {
      return (
        <div
          className="loader"
          style={{ display: this.state.incident_time_graph ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
      );
    }
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);

