import React,{ Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// require("highcharts/modules/exporting")(Highcharts);
import { Chart, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { config } from "config.js";

Chart.register(ArcElement, Tooltip, Legend, Title);

const chartStyle = {
  position: "absolute",
  width: "100%",
  top: "50%",
  left: 0,
  textAlign: "center",
  marginTop: "-14px",
  //lineHeight: "20px",
};

export default class CountGraph extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   title: this.props.title,
    //   graphData: this.props.graphData,
    //   type: this.props.type
    // };
  }

  render() {
    const textStyle = {
      fontWeight: this.props.fontWeight ?? "bold",
      fontSize: this.props.fontSize ?? "1.3vw",
    };
    let count = this.props.count;

    const data = {
      datasets: [
        {
          data: [100],
          backgroundColor:
            this.props.color == undefined || typeof this.props.color == "string"
              ? [this.props.color ?? "#A4E9FF"]
              : this.props.color,
          borderWidth: 1,
          radius: this.props.radius ?? "65%",
          cutout: this.props.cutout ?? "82%",
        },
      ],
    };
    const options = {
      padding: 0,
      marginTop: 0,
      responsive: true,
      maintainAspectRatio: false,
      defaultFontSize: "14px",
      rotation: -90,
      circumference: 180,
      cutoutPercentage: 80,
      textAlign: "center",

      plugins: {
        datalabels: {
          color: "#000000",
          anchor: "start",
          align: "end",
          display: "none",
          formatter: function (_value, context) {
            return context.chart.data.labels[context.dataIndex];
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    const countGraph = {
      chart: {
        type: "pie",
        height: "75%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "1.6vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          count +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: ["#A4E9FF"],
      series: [
        {
          data: [count || 0],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    return (
      <div className="graph-block">
        {config.isChartJsEnabled ? (
          <div
            style={{ position: "relative", height: this.props.height ?? 123 }}
          >
            <Doughnut data={data} options={options} />
            <div style={chartStyle}>
              <span style={textStyle}>{count}</span>
            </div>
          </div>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={countGraph} />
        )}
      </div>
    );
  }
}
