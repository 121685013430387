import React from "react";

const Preloader = () => {
  return (
    <div className="demo-only " style={{ height: '100vh', backgroundColor: '#F16A30 ', position: 'relative' }}>
      <div className="slds-spinner_container" style={{ backgroundColor: '#F16A30' }}>
        <div role="status" className="slds-spinner slds-spinner_large slds-spinner_inverse">
          <span className="slds-assistive-text">Loading</span>
          <div className="slds-spinner__dot-a"></div>
          <div className="slds-spinner__dot-b"></div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;