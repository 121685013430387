const PhysicalInfoSketch = (data) => {
  let infoHTML = "<tr>";
  let sketchHTML = "<tr>";

  try {
    data.physical_info.forEach((item, index) => {
      if (index + 1 != 1 && (index + 1) % 2 == 1) {
        infoHTML += "<tr>";
      }
      infoHTML += `<td valign="top">
        <div><img src="${item.image_link}" style="width:232px;height: 150px;border:1px solid #ddd;padding: 10px">
        </div>
        <span style="margin-top:5px">${item.image_description || ''}</span>
      </td>
    `;
      if ((index + 1) % 2 == 0) {
        infoHTML += "</tr>";
      }
    });
  } catch (error) {}

  try {
    data.sketch.forEach((item, index) => {
      if (index + 1 != 1 && (index + 1) % 2 == 1) {
        sketchHTML += "<tr>";
      }
      sketchHTML += `
      <td valign="top">
        <div><img src="${item.image_link}" style="width:232px;height: 150px;border:1px solid #ddd;padding: 10px">
        </div>
        <span style="margin-top:5px">${item.image_description || ''}</span>
      </td>
    `;
      if ((index + 1) % 2 == 0) {
        sketchHTML += "</tr>";
      }
    });
  } catch (error) {}

  return `<table cellpadding="15" cellspacing="0" width="800" style="margin: 0 auto 10px auto;font-family: arial;height: 100vh;font-size: 14px;border: 3px double #797878;position:relative" class="full-table">
<tr>
  <td colspan="2" valign="top" class="inner-table">
  <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Physical Info Evidences</h4>
  <hr />
    <table width="100%" style="text-align: center;"  cellpadding="10">
      ${infoHTML}
    </table>
    <br />
    <br />
    <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Sketch Evidences</h4>
    <hr />
    <table width="100%" style="text-align: center;"  cellpadding="10">
      ${sketchHTML}
    </table>
    <span class="page-number" />
  </td>
</tr>
</table>`;
};

export default PhysicalInfoSketch;
