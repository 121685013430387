import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";
import { Filter, Search, ChevronDown, Trash } from 'react-feather';
import { Scrollbars } from 'react-custom-scrollbars';
import { Row, Col, ProgressBar, Accordion} from 'react-bootstrap';
import LoadingImage from "app/shared/components/Loader/LoadingImage";

export default class ArchiveList extends Component{
  constructor(props) {
    super(props);
    
    this.state = {
      dataLoading: false,
    };
  }

  render() {
    return (
      <div className="open-section nm-list-section">
        <div className="loader" style={{display: this.state.dataLoading ? 'block': 'none'}}>
          <LoadingImage />
        </div>
        <div className="open-divide">
          <div className="open-head pending">
            <h2>PENDING | 09 </h2>
          </div>
          <Scrollbars style={{height : `calc(100vh - 18vw)`}}
           renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
           renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
           renderThumbVertical={props => <div {...props} style={{position: "relative",display: "block",width: "100%",cursor: "pointer",borderRadius: "inherit", backgroundColor: "rgba(0, 0, 0, 0.2)",height: "100px"}} />}
           >
            <div className="open-content">
              <div className="nm-box open-box">
                <div className="open-bhead open-tracker-head">
                  <h4>FA | Category Name 1</h4>
                  <div className="tracker-right">
                    <Trash className="tracker-delete" />
                  </div>
                </div>
                <div className="open-bbody">
                  <Row> 
                    <Col sm={6}>
                      <p className="nm-label">Open</p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="nm-box open-box">
                <div className="open-bhead open-tracker-head">
                  <h4>MTC | Category Name 2</h4>
                  <div className="tracker-right">
                    <Trash className="tracker-delete" />
                  </div>
                </div>
                <div className="open-bbody">
                  <Row> 
                    <Col sm={6}>
                      <p className="nm-label">Open</p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="nm-box open-box">
                <div className="open-bhead open-tracker-head">
                  <h4>FA | Category Name 3</h4>
                  <div className="tracker-right">
                    <Trash className="tracker-delete" />
                  </div>
                </div>
                <div className="open-bbody">
                  <Row> 
                    <Col sm={6}>
                      <p className="nm-label">Open</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        <div className="open-divide">
          <div className="open-head inprogress">
            <h2>IN PROGRESS | 18</h2>
          </div>
          <Scrollbars style={{height : `calc(100vh - 18vw)`}}
           renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
           renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
           renderThumbVertical={props => <div {...props} style={{position: "relative",display: "block",width: "100%",cursor: "pointer",borderRadius: "inherit", backgroundColor: "rgba(0, 0, 0, 0.2)",height: "100px"}} />}
           >
            <div className="open-content">
              <div className="nm-box open-box">
                <div className="open-bhead open-tracker-head">
                  <h4>FA | Category Name 1</h4>
                  <div className="tracker-right">
                    <Trash className="tracker-delete" />
                  </div>
                </div>
                <div className="open-bbody">
                  <Row> 
                    <Col sm={6}>
                      <p className="nm-label">Open</p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="nm-box open-box">
                <div className="open-bhead open-tracker-head">
                  <h4>MTC | Category Name 2</h4>
                  <div className="tracker-right">
                    <Trash className="tracker-delete" />
                  </div>
                </div>
                <div className="open-bbody">
                  <Row> 
                    <Col sm={6}>
                      <p className="nm-label">Open</p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="nm-box open-box">
                <div className="open-bhead open-tracker-head">
                  <h4>FA | Category Name 3</h4>
                  <div className="tracker-right">
                    <Trash className="tracker-delete" />
                  </div>
                </div>
                <div className="open-bbody">
                  <Row> 
                    <Col sm={6}>
                      <p className="nm-label">Open</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        <div className="open-divide">
          <div className="open-head completed">
            <h2>COMPLETED | 12</h2>
          </div>
          <Scrollbars style={{height : `calc(100vh - 18vw)`}}
           renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
           renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
           renderThumbVertical={props => <div {...props} style={{position: "relative",display: "block",width: "100%",cursor: "pointer",borderRadius: "inherit", backgroundColor: "rgba(0, 0, 0, 0.2)",height: "100px"}} />}
           >
            <div className="open-content">
              <div className="nm-box open-box">
                <div className="open-bhead open-tracker-head">
                  <h4>FA | Category Name 1</h4>
                  <div className="tracker-right">
                    <Trash className="tracker-delete" />
                  </div>
                </div>
                <div className="open-bbody">
                  <Row> 
                    <Col sm={6}>
                      <p className="nm-label">Open</p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="nm-box open-box">
                <div className="open-bhead open-tracker-head">
                  <h4>MTC | Category Name 2</h4>
                  <div className="tracker-right">
                    <Trash className="tracker-delete" />
                  </div>
                </div>
                <div className="open-bbody">
                  <Row> 
                    <Col sm={6}>
                      <p className="nm-label">Open</p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="nm-box open-box">
                <div className="open-bhead open-tracker-head">
                  <h4>FA | Category Name 3</h4>
                  <div className="tracker-right">
                    <Trash className="tracker-delete" />
                  </div>
                </div>
                <div className="open-bbody">
                  <Row> 
                    <Col sm={6}>
                      <p className="nm-label">Open</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    )
  }
}