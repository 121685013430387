const CheckFormSettingField = (formSettingList = [], settingName) => {

  const searchSetting = settingName.toLowerCase();
  try {
    const isFound = formSettingList.some(
      listItem =>
        listItem.parameter.trim().toLowerCase() == searchSetting &&
        listItem.parameter_value
    );
    return isFound;
  } catch (error) {
    return false
  }
};

export default CheckFormSettingField;