import React from "react";
import { Info } from "react-feather";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";


const InfoComponent = ({ tooltipText }) => (
  <>
      {/* <Button
      className="icon-btn"
      data-tip={tooltipText}
      data-for="tooltip"
      data-place="bottom"
      data-type="info"
    >
      <Info className="icons" />
    </Button> */}

    <ReactTooltip id="tooltip" effect="solid" html={true} />
  </>
);
//icon-style

InfoComponent.defaultProps = {
  tooltipText: "",
};

export default InfoComponent;
