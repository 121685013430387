const PageFour = (data) => {
  let tempEvidence = "";

  try {
    if (data?.evidence?.length > 0) {
      data.evidence.forEach((item, index) => {
        if (item.evidence_format === "image") {
          if (index + 1 !== 1 && (index + 1) % 2 === 1) {
            tempEvidence += "<tr>";
          }
          tempEvidence += `
            <td valign="top">
              <img src="${item.evidence_image}" style="max-width: 100%; height: auto; border: 3px solid #fff; box-shadow: 0 0 5px #ddd; display: block; margin: 0 auto 20px;">
            </td>`;
          if ((index + 1) % 2 === 0) {
            tempEvidence += "</tr>";
          }
        }
      });
    }

    return `
    <style>
      /* CSS Styles for Page Four only */
      .page-four {
        margin: 0 auto 10px auto;
        font-family: Arial, sans-serif;
        height: 100vh;
        font-size: 14px;
        border: 3px double #797878;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .page-four img {
        max-width: 232px;
        height: 150px;
        border: 3px solid #fff;
        box-shadow: 0 0 5px #ddd;
        margin-bottom: 20px;
      }
      .page-four .inner-content {
        text-align: center;
        margin-top: 10px;
      }
    </style>
    <div class="page-four">
      <div class="inner-content">
        <h4>Incident Evidence</h4>
        <hr />
        <table cellpadding="10">
          ${tempEvidence}
        </table>
      </div>
      <span class="page-number"></span>
    </div>`;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export default PageFour;
