import { UPDATE_UNLOCK_FORM_COUNT } from "../constant/ActionType";

let initialState = {
  update: 0,
};

function unlockFormRequestCountReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_UNLOCK_FORM_COUNT:
      return {
        ...state,
        update: action.payload,
      };
    default:
      return state;
  }
}

export default unlockFormRequestCountReducer;
