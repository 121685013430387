const RootCauseAnalysis = (data) => {
  let tempHtml = "";
  let tableHtml = "";

  try {
    data.chronology_of_events.forEach((item, index) => {
      tempHtml += `
        <li class="event-item">
          <div class="event-container">
            <p class="event-timestamp">
              <span class="event-dot"></span> ${item.date} (${item.time})
            </p>
            <p class="event-description">${item.description || ''}</p>
          </div>
          <div class="event-separator"></div>
          <div class="event-divider"></div>
        </li>
      `;
    });
  } catch (error) {}
  
  try {
    data.chronology_of_events.forEach((item, index) => {
      tableHtml += `
        <tr>
          <td>${item.date || 'N/A'}</td>
          <td>${item.time || 'N/A'}</td>
          <td>${item.description || 'N/A'}</td>
          <td>${item.info_source || 'N/A'}</td>
        </tr>
      `;
    });
  } catch (error) {}

  return (
    `<style>
      /* Define your styles here */
      .event-item {
        display: inline-block;
        width: 25%;
        margin-right: 10px; /* Add margin between boxes */
        margin-bottom: 20px; /* Add margin bottom to space out vertically */
      }
      
      // .event-container {
      //   border: 1px solid #ccc;
      //   height: 100px;
      //   width: 100%;
      //   margin: 0 auto;
      //   border-radius: 4px;
      //   vertical-align: top;
      //   padding: 10px;
      // }
      .event-timestamp {
        margin: 0;
        font-weight: bold;
      }
      .event-dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #000;
        margin-right: 5px;
      }
      .event-description {
        margin: 0;
        padding: 10px;
        /* Add your desired styles below */
        font-size: 14px;
        color: #333;
        line-height: 1.4;
        border: 1px solid #ccc; /* Border added */
        border-radius: 4px; /* Optional: To add rounded corners */
      }
      
      
      .event-separator {
        width: 1px;
        height: 50px;
        margin: 0 auto;
      
      }
      .event-divider {
        width: 100%;
        height: 15px;
      }
    </style>

    <table cellpadding="15" cellspacing="0" width="100%" class="full-table">
      <tr>
        <td valign="top" class="inner-table">
          <h4>Root Cause Analysis</h4>
          <hr />

          <h4>Chronology of Events (Table)</h4>
          <br />

          <table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%">
            <tr>
              <td style="text-align: center;">Date</td>
              <td>Time</td>
              <td>Description</td>
              <td>Info Source</td>
            </tr>
            ${tableHtml}
          </table>

          <br />
          <h4>Chronology of Events (Time Chart)</h4>
          <hr />
          <ul class="events-list">
            ${tempHtml}
          </ul>
          <span class="page-number"></span>
        </td>
      </tr>
    </table>`
  );
};

export default RootCauseAnalysis;
