import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { ReactComponent as SearchIcon } from 'assets/sls-icons/Search/search.svg';
import {
  Filter,
  Search,
  ChevronDown,
  Calendar,
  File,
  Info,
  List, Grid,
  X,
  Video
} from "react-feather";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Row, Col, Modal, Accordion, Button, OverlayTrigger,
  Popover,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Url from "app/shared/constants/Url";
import axios from "axios";
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import moment from "moment";
import ExcelIcon from "assets/images/icons/Excel.svg";
import InfoComponent from "app/shared/utility/InfoComponent";
import { ErrorToast } from "app/shared/components/Popups/ToastMessage";
import { DateTableComponent as DataTable } from "app/shared/components/ReusableComponents/DataTable";
import FsLightbox from "fslightbox-react";
import Workbook from "react-excel-workbook";
import VideoBox from "app/shared/components/ReusableComponents/VideoPlayer/container";
import LoadingView from "app/shared/components/Loader/LoadingView";

const qs = require("qs");

export default class NmList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      dataLoading: false,
      pendingPopup: false,
      inProgressPopup: false,
      completedPopup: false,
      videoUrl: "",
      startDate: null,
      endDate: null,
      overdue: null,
      deviation: null,
      completed: this.props.nmCompleted,
      pending: this.props.nmPending,
      progress: this.props.nmProgress,
      listMode: "kanban",
      pendingService: [],
      inProgressService: [],
      completedService: [],
      service: [],
      serviceSelect: 0,
      pendingServiceSelect: 0,
      inProgressServiceSelect: 0,
      completedServiceSelect: 0,
      project: [],
      projectSelect: 0,
      pendingProject: [],
      pendingProjectSelect: 0,

      progressProject: [],
      progressProjectSelect: 0,

      completedProject: [],
      completedProjectSelect: 0,

      pendingStartDate: null,
      pendingEndDate: null,

      progressStartDate: null,
      progressEndDate: null,

      completedStartDate: null,
      completedEndDate: null,

      pendingExtremeSelect: 0,
      pendingHighSelect: 0,
      pendingMediumSelect: 0,
      pendingLowSelect: 0,
      pendingAll: 0,

      progressExtremeSelect: 0,
      progressHighSelect: 0,
      progressMediumSelect: 0,
      progressLowSelect: 0,
      progressAll: 0,

      completedExtremeSelect: 0,
      completedHighSelect: 0,
      completedMediumSelect: 0,
      completedLowSelect: 0,
      completedAll: 0,

      R_person: [],
      extremeSelect: 0,
      extremeValue: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      all: 0,
      allValue: [],
      allS: [],
      severity: [],
      pendingSeverity: [],
      progressSeverity: [],
      completedSeverity: [],
      personSelect: [],
      CategoryFilter: [],
      hasMore: [],
      dflag: JSON.parse(localStorage.getItem("userData")),
      isFilterApplied: false,
      isProgressFilterApplied: false,
      isCompletedFilterApplied: false,

      fsToggler: false,
      toggler: false,
      lightboxactive: false,
      evidencelightbox: [],
      evidencelightboxType: [],
      pendingFilterFlag: 0,
      pendingFilterPageNo: 1,
      inprogressFilterFlag: 0,
      inprogressFilterPageNo: 1,
      completedFilterFlag: 0,
      completedFilterPageNo: 1,

    };
    // this._getNMList();
    // this._getService();
    // this._getProject();
    this.selectSeverity = this.selectSeverity.bind(this);
    this.child = React.createRef();
    // this.nmTrackerList = this.nmTrackerList.bind(this);

  }

  componentDidMount() {
    this._getNMList();
    this._getService();
    // this._getProject();
  }

  pendingModal = (visible) => {
    if (this.props.nmPending?.length || this.state.isFilterApplied) {
      this.setState({ pendingPopup: visible, isFilterApplied: true });
    } else {
      ErrorToast("Filter Can't apply on empty list");
    }
  };

  inProgressModal = (visible) => {
    if (this.props.nmProgress?.length || this.state.isProgressFilterApplied) {
      this.setState({
        inProgressPopup: visible,
        isProgressFilterApplied: true,
      });
    } else {
      ErrorToast("Filter Can't apply on empty list");
    }
  };

  completedModal = (visible) => {
    if (this.props.nmCompleted?.length || this.state.isCompletedFilterApplied) {
      this.setState({
        completedPopup: visible,
        isCompletedFilterApplied: true,
      });
    } else {
      ErrorToast("Filter Can't apply on empty list");
    }
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  _getNMList = async () => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");


    let data;
    data = new FormData();

    data.append("auth_key", token);
    data.append("user_id", userId);
    await axios
      .post(
        Url.nmModule.nmList,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            progress: response.data.data.inprogressnm.nm_basic_info,
            pending: response.data.data.pendingnm.nm_basic_info,
            completed: response.data.data.completednm.nm_basic_info,
            dataLoading: false,
          });
          this.props.nmlistfilter(
            this.state.pending,
            this.state.progress,
            this.state.completed
          );
        }
        // console.log(response.data.data)
      })
      .catch((error) => { });
  };

  _getService = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    await axios
      .post(
        Url.serviceList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            service: response.data.data,
            pendingService: response.data.data,
            inProgressService: response.data.data,
            completedService: response.data.data,
            dataLoading: false,
          });
          if (this.state.dflag.designation_flag == 4) {
            var serviceobs = this.state.service.map((item) => item.service_id);
            this.setState({ serviceSelect: serviceobs[0] });
            this._getProject("pending", serviceobs[0]);
            this._getProject("progress", serviceobs[0]);
            this._getProject("completed", serviceobs[0]);
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  _getProject = async (
    type = "pending ",
    serviceId = this.state.pendingServiceSelect
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.projectList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: serviceId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          switch (type) {
            case "pending":
              this.setState({
                pendingProject: response.data.data,
                dataLoading: false,
              });
              if (this.state.dflag.designation_flag == 4) {
                var projectobs = this.state.pendingProject.map(
                  (item) => item.project_id
                );
                this.setState({ pendingProjectSelect: projectobs[0] });
              }
              break;
            case "progress":
              this.setState({
                progressProject: response.data.data,
                dataLoading: false,
              });
              if (this.state.dflag.designation_flag == 4) {
                var projectobs = this.state.progressProject.map(
                  (item) => item.project_id
                );
                this.setState({ progressProjectSelect: projectobs[0] });
              }
              break;
            case "completed":
              this.setState({
                completedProject: response.data.data,
                dataLoading: false,
              });
              if (this.state.dflag.designation_flag == 4) {
                var projectobs = this.state.completedProject.map(
                  (item) => item.project_id
                );
                this.setState({ completedProjectSelect: projectobs[0] });
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  pendingResetState = () => {
    this.setState({
      pendingExtremeSelect: 0,
      pendingHighSelect: 0,
      pendingMediumSelect: 0,
      pendingLowSelect: 0,
      pendingStartDate: null,
      pendingEndDate: null,
      pendingServiceSelect: null,
      pendingProjectSelect: null,
      pendingAll: 0,
      pendingSeverity: [],
    });
  };

  progressResetState = () => {
    this.setState({
      progressExtremeSelect: 0,
      progressHighSelect: 0,
      progressMediumSelect: 0,
      progressLowSelect: 0,
      progressStartDate: null,
      progressEndDate: null,
      inProgressServiceSelect: null,
      progressProjectSelect: null,
      progressAll: 0,
      overdue: 0,
      progressSeverity: [],
    });
  };

  completedResetState = () => {
    this.setState({
      completedExtremeSelect: 0,
      completedHighSelect: 0,
      completedMediumSelect: 0,
      completedLowSelect: 0,
      completedStartDate: null,
      completedEndDate: null,
      completedServiceSelect: null,
      completedProjectSelect: null,
      completedAll: 0,
      deviation: 0,
      completedSeverity: [],
    });
  };

  resetState() {
    this.setState({
      extremeSelect: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      startDate: null,
      endDate: null,
      serviceSelect: null,
      projectSelect: null,
      categorySelect: null,
      personSelect: null,
      overdue: 0,
      all: 0,
    });
  }

  pendingSelectSeverity(e) {
    const severity = this.state.pendingSeverity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ pendingSeverity: severity });
  }

  progressSelectSeverity(e) {
    const severity = this.state.progressSeverity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ progressSeverity: severity });
  }

  completedSelectSeverity(e) {
    const severity = this.state.completedSeverity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ completedSeverity: severity });
  }

  selectSeverity(e) {
    const severity = this.state.severity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ severity: severity });
  }

  pendingFilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ pendingExtremeSelect: 4, pendingAll: 0 });
      } else {
        this.setState({ pendingExtremeSelect: 0, pendingAll: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ pendingHighSelect: 3, pendingAll: 0 });
      } else {
        this.setState({ pendingHighSelect: 0, pendingAll: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ pendingMediumSelect: 2, pendingAll: 0 });
      } else {
        this.setState({ pendingMediumSelect: 0, pendingAll: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ pendingLowSelect: 1, pendingAll: 0 });
      } else {
        this.setState({ pendingLowSelect: 0, pendingAll: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, all: 0 });
      } else {
        this.setState({ overdue: 0, all: 0 });
      }
    }

    if (value == "all") {
      if (param == 0) {
        this.setState({
          pendingAll: 1,
          pendingExtremeSelect: 4,
          pendingHighSelect: 3,
          pendingMediumSelect: 2,
          pendingLowSelect: 1,
          pendingSeverity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          pendingAll: 0,
          pendingExtremeSelect: 0,
          pendingHighSelect: 0,
          pendingMediumSelect: 0,
          pendingLowSelect: 0,
          pendingSeverity: [],
        });
      }
    }
  }

  progressFilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ progressExtremeSelect: 4, progressAll: 0 });
      } else {
        this.setState({ progressExtremeSelect: 0, progressAll: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ progressHighSelect: 3, progressAll: 0 });
      } else {
        this.setState({ progressHighSelect: 0, progressAll: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ progressMediumSelect: 2, progressAll: 0 });
      } else {
        this.setState({ progressMediumSelect: 0, progressAll: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ progressLowSelect: 1, progressAll: 0 });
      } else {
        this.setState({ progressLowSelect: 0, progressAll: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, all: 0 });
      } else {
        this.setState({ overdue: 0, all: 0 });
      }
    }

    if (value == "all") {
      if (param == 0) {
        this.setState({
          progressAll: 1,
          progressExtremeSelect: 4,
          progressHighSelect: 3,
          progressMediumSelect: 2,
          progressLowSelect: 1,
          progressSeverity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          progressAll: 0,
          progressExtremeSelect: 0,
          progressHighSelect: 0,
          progressMediumSelect: 0,
          progressLowSelect: 0,
          progressSeverity: [],
        });
      }
    }
  }

  completedFilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ completedExtremeSelect: 4, completedAll: 0 });
      } else {
        this.setState({ completedExtremeSelect: 0, completedAll: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ completedHighSelect: 3, completedAll: 0 });
      } else {
        this.setState({ completedHighSelect: 0, completedAll: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ completedMediumSelect: 2, completedAll: 0 });
      } else {
        this.setState({ completedMediumSelect: 0, completedAll: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ completedLowSelect: 1, completedAll: 0 });
      } else {
        this.setState({ completedLowSelect: 0, completedAll: 0 });
      }
    }
    if (value == "deviation") {
      if (param == 0) {
        this.setState({ deviation: 1, all: 0 });
      } else {
        this.setState({ deviation: 0, all: 0 });
      }
    }

    if (value == "all") {
      if (param == 0) {
        this.setState({
          completedAll: 1,
          completedExtremeSelect: 4,
          completedHighSelect: 3,
          completedMediumSelect: 2,
          completedLowSelect: 1,
          completedSeverity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          completedAll: 0,
          completedExtremeSelect: 0,
          completedHighSelect: 0,
          completedMediumSelect: 0,
          completedLowSelect: 0,
          completedSeverity: [],
        });
      }
    }
  }

  FilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ extremeSelect: 4, all: 0 });
      } else {
        this.setState({ extremeSelect: 0, all: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ highSelect: 3, all: 0 });
      } else {
        this.setState({ highSelect: 0, all: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ mediumSelect: 2, all: 0 });
      } else {
        this.setState({ mediumSelect: 0, all: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ lowSelect: 1, all: 0 });
      } else {
        this.setState({ lowSelect: 0, all: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, all: 0 });
      } else {
        this.setState({ overdue: 0, all: 0 });
      }
    }

    if (value == "all") {
      if (param == 0) {
        this.setState({
          all: 1,
          extremeSelect: 4,
          highSelect: 3,
          mediumSelect: 2,
          lowSelect: 1,
          severity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          all: 0,
          extremeSelect: 0,
          highSelect: 0,
          mediumSelect: 0,
          lowSelect: 0,
          severity: [],
        });
      }
    }
  }

  _handlePendingServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ pendingServiceSelect: selectedServiceID });
      this._getProject("pending", selectedServiceID);
    }
  };

  _handleProgressServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ inProgressServiceSelect: selectedServiceID });
      this._getProject("progress", selectedServiceID);
    }
  };

  _handleCompletedServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ completedServiceSelect: selectedServiceID });
      this._getProject("completed", selectedServiceID);
    }
  };

  _handleServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ serviceSelect: selectedServiceID });
      this._getProject();
    }
  };

  _handlePendingFilter = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.pendingStartDate
        ? moment(data.pendingStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.pendingEndDate
        ? moment(data.pendingEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmPendingListFilter(
        data.pendingSeverity,
        start_date,
        end_date,
        data.pendingServiceSelect,
        data.pendingProjectSelect,
        // data.pending.length / 10 + 1
        data.pendingFilterPageNo,
      );
      this.pendingModal(false);

      this.setState({
        pendingFilterFlag: 1
      });
    } catch (error) { }
  };


  _pendingFilterPagination = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.pendingStartDate
        ? moment(data.pendingStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.pendingEndDate
        ? moment(data.pendingEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmPendingListFilter(
        data.pendingSeverity,
        start_date,
        end_date,
        data.pendingServiceSelect,
        data.pendingProjectSelect,
        // data.pending.length / 10 + 1,
        // data.pendingFilterPageNo + 1,
      );
      this.pendingModal(false);

      this.setState({
        pendingFilterFlag: 1
      });
    } catch (error) { }
  }


  _handlePendingScrollbarPagination = () => {
    if (this.state.pendingFilterFlag == 1) {
      if (this.props.pendingFilterCount != this.props.nmPending.length) {
        // this._handlePendingFilter();
        this._pendingFilterPagination();
      }
    } else {
      if (this.props.nmPending.length != this.props.nmPendingCount) {
        this.props._getNMList(this.props.nmPending.length / 10 + 1)
      }
    }
  }

  _handleInprogressScrollbarPagination = () => {
    if (this.state.inprogressFilterFlag == 1) {
      if (this.props.inprogressFilterCount != this.props.nmProgress.length) {
        // this._handlePendingFilter();
        this._inProgressFilterPagination();
      }
    } else {
      if (this.props.nmProgress.length != this.props.nmProgressCount) {
        this.props._getNMProgressList(this.props.nmProgress.length / 10 + 1)
      }
    }
  }




  _inProgressFilterPagination = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.progressStartDate
        ? moment(data.progressStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.progressEndDate
        ? moment(data.progressEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmProgressListFilter(
        data.progressSeverity,
        start_date,
        end_date,
        data.inProgressServiceSelect,
        data.progressProjectSelect,
        data.overdue,
        // data.inprogressFilterPageNo
      );
      this.inProgressModal(false);

      this.setState({
        inprogressFilterFlag: 1
      });
    } catch (error) { }
  };

  _handleInProgressFilter = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.progressStartDate
        ? moment(data.progressStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.progressEndDate
        ? moment(data.progressEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmProgressListFilter(
        data.progressSeverity,
        start_date,
        end_date,
        data.inProgressServiceSelect,
        data.progressProjectSelect,
        data.overdue,
        data.inprogressFilterPageNo
      );
      this.inProgressModal(false);

      this.setState({
        inprogressFilterFlag: 1
      });
    } catch (error) { }
  };

  _handleCompletedFilter = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.completedStartDate
        ? moment(data.completedStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.completedEndDate
        ? moment(data.completedEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmCompletedListFilter(
        data.completedSeverity,
        start_date,
        end_date,
        data.completedServiceSelect,
        data.completedProjectSelect,
        data.deviation,
        data.completedFilterPageNo,
      );
      this.completedModal(false);
      this.setState({
        completedFilterFlag: 1,
      })
    } catch (error) { }
  };

  _completedPaginationFilter = () => {
    try {
      let { ...data } = this.state;
      const start_date = data.completedStartDate
        ? moment(data.completedStartDate).format("YYYY-MM-DD")
        : "";
      const end_date = data.completedEndDate
        ? moment(data.completedEndDate).format("YYYY-MM-DD")
        : "";

      this.props.nmCompletedListFilter(
        data.completedSeverity,
        start_date,
        end_date,
        data.completedServiceSelect,
        data.completedProjectSelect,
        data.deviation

      );
      this.completedModal(false);
      this.setState({
        completedFilterFlag: 1,
      })
    } catch (error) { }
  };

  _handleCompleteScrollbarPagination = () => {
    if (this.state.completedFilterFlag == 1) {
      if (this.props.completeFilterCount != this.props.nmCompleted.length) {
        this._completedPaginationFilter();
      }
    } else {
      if (this.props.nmCompleted.length != this.props.nmCompletedCount) {
        this.props._getNMCompletedList(this.props.nmCompleted.length / 10 + 1)
      }
    }
  }

  _handleFilterReset = async (moduleName = "pending") => {
    switch (moduleName) {
      case "pending":
        this.props.filterReset("pending");
        this.pendingResetState();
        this.pendingModal(false);
        break;
      case "progress":
        this.props.filterReset("progress");
        this.progressResetState();
        this.inProgressModal(false);
        break;
      case "completed":
        this.props.filterReset("completed");
        this.completedResetState();
        this.completedModal(false);
        break;
      default:
        break;
    }

    this.setState({
      pendingFilterFlag: 0,
      completedFilterFlag: 0,
      inprogressFilterFlag: 0
    });
  };

  comapareTargetDate = (date = "") => {
    if (date) {
      const targetDate = moment(date, "DD/MM/YYYY");
      const today = moment();
      if (targetDate.isSame(today, "day")) {
        return true;
      }
      return false;
    }
  };

  _handleLightBox = async (list) => {
    console.log(list.map((item) => item.format));
    await this.setState({
      lightboxactive: true,
      fsToggler: !this.state.fsToggler,
      evidencelightbox: list.map((item) => item.link),
      evidencelightboxType: list.map((item) =>
        item.format == "img" ? "image" : item.format
      ),
    });
  };

  __toggleGridView = (tabName) => {
    if (this.state.listMode !== tabName) {
      this.setState({ listMode: tabName })
    }
  }
  __generateTableBody = (data, idx, customColumns = [], status = "pending") => {

    return <tr key={idx}>
      <td > <Link
        to={`/nm-detail/${data.nearmiss_id}/kanban/${status}`}
      >{data.type_of_act || ""} </Link></td>
      <td >{data.activity}</td>
      <td>
        {data.evidence.length ? (
          <>
            {data.evidence[0]?.format === "video" ? <img src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
              className="cursor-pointer svg-icons" onClick={() => this.setState({ videoUrl: data.evidence[0]?.link })} /> : <div
              >
              <img
                className="tracker-right cursor-pointer rounded"
                src={data.evidence[0]?.link}
                title="Evidence"
                onClick={() => this._handleLightBox(data.evidence)}
              />
            </div>

            }

          </>
        ) : <div
        >
          <img
            className="tracker-right "
            src={require("assets/images/icons/fimage.svg")}
            title="Evidence"
          />
        </div>}
      </td>
      <td >{data.service}</td>
      <td >{data.project}</td>
      <td ><img src={data.reported_by_pic} className="user-icon " />{data.reported_by}</td>
      <td >{data.report_date}</td>
      {customColumns.length ? <>
        {customColumns.includes("Due Date") ? <td>
          <div
            style={{
              border: `1px solid #faac15`,
              borderRadius: "28px",
              color: "#faac15",
              width: "max-content"
            }}

            className="px-3 py-1 my-2 font-weight-500 "
          >{data.target_date || "NA"}</div></td> : null}
        {customColumns.includes("Closed On") ? <td>
          <div
            style={{
              border: `1px solid #faac15`,
              borderRadius: "28px",
              color: "#faac15",
              width: "max-content"
            }}

            className="px-3 py-1 my-2 font-weight-500 "
          >{data.closing_date || "NA"}</div></td> : null}
        {/* <td>{data.assign_to || "NA"}</td> */}
        <td>{data.team != undefined &&
          data.team.map((team, index) => (
            <div key={index}>
              {team.status == 1 && (
                <div className="people-lead border-0 d-flex align-items-center">
                  <img
                    src={team.pic}
                    className="people-img"
                    title={team.name}
                  />
                  <div >
                    <span className="font-16 font-weight-500">{team.name}</span>
                  </div>
                </div>
              )}
            </div>
          ))}</td>
        {/* <td className="overflow-auto ">
          <Scrollbars style={{ width: '6vw', height: "3vw" }}><div className="d-flex">{data.team != undefined &&
            data.team.map((team, index) => (
              <div key={index} className=" mr-2">
                {team.status == 0 && (
                  <div className="people-normal  ">
                    <img
                      src={team.pic}
                      className="people-normal-img"
                      title={team.name}
                    />
                  </div>
                )}
              </div>
            ))}</div>
          </Scrollbars></td> */}
        <td>
          <div
            style={{
              border: `1px solid #FF0000`,
              borderRadius: "28px",
              color: "#FF0000",
              width: "max-content"
            }}

            className="px-3 py-1 my-2 font-weight-500 "
          >{customColumns.includes("Overdue") ? data.overdue_days || data.overdue : data.overdue_days || data.overdue || "NA"}</div></td>

      </>
        : null
      }


    </tr>
  }
  getFieldProps = (data, customColumns = [], status) => {
    return {
      data,
      columns: ["Act", "Activity", "Evidence", "Service ", "Plant/Project ", "Reported By", "Reported On", ...customColumns],
      bodyRendrer: (data, idx) => this.__generateTableBody(data, idx, customColumns, status)
    }
  }
  debounceFunc = _.debounce(function (callback) {
    callback()
  }, 300);
  render() {
    // console.log( this.props.nmPending)
    return (
      <div className="open-section nm-list-section kanban-board-wrapper">
        {!!this.state.lightboxactive && (
          <FsLightbox
            toggler={this.state.fsToggler}
            types={this.state.evidencelightboxType}
            disableLocalStorage={true}
            sources={this.state.evidencelightbox}
          />
        )}
        {/* <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <img
            className="loader-img"
            src={require("assets/images/loader1.gif")}
          />
        </div> */}
        <LoadingView showLoading={this.state.dataLoading} />
        <div className="task-table-header mt-5">
          <div className="table-heading">
            <div className="mt-4">
              <div className="align-items-center d-flex justify-content-between ">

                <div className="d-flex">
                  {/* <span className="font-20 font-weight-500">UA/UC Open List</span> */}

                  <div class="slds-form-element">

                    <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left">
                      <div className="input-wrapper">
                        <SearchIcon className="search-icon" style={{ height: "1vw", width: "1vw" }} />
                        <input

                          type="text"
                          placeholder="Search by category, activity, act, service, location & reported by"
                          name="search"
                          value={this.props.state.searchList2}
                          onChange={(e) => this.props.handleInputChange(e)}
                          className="slds-input "
                          style={{ width: "22vw", textOverflow: "ellipsis" }}
                        />
                      </div>
                    </div>


                  </div>
                  {/* <div
                    className="search-bar-wrapper border"
                  >
                    <div className="mr-3">
                      <input
                        type="text"
                        placeholder="Search by category, activity, act, service, location & reported by"
                        name="search"
                        value={this.props.state.searchList2}
                        onChange={(e) => this.props.handleInputChange(e)}
                      />

                    </div>
                    <div className="fa-color" >
                      <Search style={{ width: "15px", height: "15px" }} />
                    </div>
                  </div>
                   <Row>
                  <Col className="d-flex">
                    <label className="reminder-middle  mr-2 common-black-1 w-max">From :</label>
                    <div className=" calender-div common-datepick-layout-2">
                      <DatePicker
                        selected={startDate}
                        dateFormat="dd/MM/yyyy"
                         maxDate={endDate}
                        placeholderText="DD-MM-YYYY"
                      onChange={(jsDate) =>
                        setStartDate(jsDate)
                      }
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>

                  <Col className="d-flex px-0">
                    <label className="reminder-middle mr-2 common-black-1">To : </label>
                    <div className=" calender-div common-datepick-layout-2" >
                      <DatePicker
                        selected={endDate}
                        dateFormat="dd/MM/yyyy"
                        minDate={startDate}
                        placeholderText="DD-MM-YYYY"
                      onChange={(jsDate) =>
                        setEndDate(jsDate)
                      }
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col md={1} >
                    <RefreshCw className="cursor-pointer"
                     onClick={_handleResetList}
                    />
                  </Col>
                </Row> */}
                  {/* <div className="common-grey-border-30 d-flex   py-2 mt-2">

                    <input
                      type="text"
                      placeholder="Search Task"
                      className=" mr-3 border-0 font-16"
                    />
                    <Search className="cursor-pointer" />
                  </div> */}
                </div>
                <div>
                  <div className="align-items-center d-flex">
                    <div className="d-flex">
                      <div className="common-control-button-1 px-1 py-1 cursor-pointer common-border">
                        <img
                          src={this.state.listMode === "kanban" ? require("assets/sls-icons/Kanban/kanban.svg") : require("assets/sls-icons/Kanban/kanban1.svg")}
                          className="svg-kanban-uauc"
                          onClick={() => this.__toggleGridView("kanban")}
                        />
                      </div>

                      <div className="common-control-button-1 px-1 py-1 cursor-pointer common-border">
                        <img
                          src={this.state.listMode === "list" ? require("assets/sls-icons/Table/1.svg") : require("assets/sls-icons/Table/picklist_type.svg")}
                          className="svg-list-uauc"
                          onClick={() => this.__toggleGridView("list")}
                        />
                      </div>
                      <div>
                        <Workbook
                          filename="NMList.xlsx"
                          element={
                            <Button className="icon-btn common-control-button-1 px-1 py-1 cursor-pointer common-border" title="Download Excel">
                              <img src={ExcelIcon} />
                            </Button>
                          }
                        >
                          <Workbook.Sheet
                            data={this.props.state.nmPendingexport}
                            name="Pending list"
                          >
                            <Workbook.Column label="ACTIVITY" value="activity" />
                            <Workbook.Column label="CONTRACTOR" value="contractor" />
                            <Workbook.Column label="DEPARTMENT" value="department" />
                            <Workbook.Column label="HAZARD" value="hazard" />
                            <Workbook.Column
                              label="INCIDENT DATE"
                              value="incident_date"
                            />
                            <Workbook.Column
                              label="INCIDENT TIME"
                              value="incident_time"
                            />
                            <Workbook.Column label="OBSERVATION" value="observation" />
                            <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
                            <Workbook.Column label="PROJECT" value="project" />
                            <Workbook.Column label="REPORT DATE" value="report_date" />
                            <Workbook.Column label="REPORTED BY" value="reported_by" />
                            <Workbook.Column label="SERVICE" value="service" />
                            <Workbook.Column label="SEVERITY" value="severity" />
                            <Workbook.Column label="LOCATION" value="location" />
                            <Workbook.Column label="ZONE" value="zone" />
                          </Workbook.Sheet>
                          <Workbook.Sheet
                            data={this.props.state.nmProgressexport}
                            name="InProgress list"
                          >
                            <Workbook.Column label="ACTIVITY" value="activity" />
                            <Workbook.Column label="CONTRACTOR" value="contractor" />
                            <Workbook.Column label="DEPARTMENT" value="department" />
                            <Workbook.Column label="HAZARD" value="hazard" />
                            <Workbook.Column
                              label="INCIDENT DATE"
                              value="incident_date"
                            />
                            <Workbook.Column
                              label="INCIDENT TIME"
                              value="incident_time"
                            />
                            <Workbook.Column label="OBSERVATION" value="observation" />
                            <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
                            <Workbook.Column label="PROJECT" value="project" />
                            <Workbook.Column label="REPORT DATE" value="report_date" />
                            <Workbook.Column label="REPORTED BY" value="reported_by" />
                            <Workbook.Column label="SERVICE" value="service" />
                            <Workbook.Column label="SEVERITY" value="severity" />
                            <Workbook.Column label="LOCATION" value="location" />
                            <Workbook.Column label="ZONE" value="zone" />
                          </Workbook.Sheet>
                          <Workbook.Sheet
                            data={this.props.state.nmCompletedexport}
                            name="Completed list"
                          >
                            <Workbook.Column label="ACTIVITY" value="activity" />
                            <Workbook.Column label="CONTRACTOR" value="contractor" />
                            <Workbook.Column label="DEPARTMENT" value="department" />
                            <Workbook.Column label="HAZARD" value="hazard" />
                            <Workbook.Column
                              label="INCIDENT DATE"
                              value="incident_date"
                            />
                            <Workbook.Column
                              label="INCIDENT TIME"
                              value="incident_time"
                            />
                            <Workbook.Column label="OBSERVATION" value="observation" />
                            <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
                            <Workbook.Column label="PROJECT" value="project" />
                            <Workbook.Column label="REPORT DATE" value="report_date" />
                            <Workbook.Column label="REPORTED BY" value="reported_by" />
                            <Workbook.Column label="SERVICE" value="service" />
                            <Workbook.Column label="SEVERITY" value="severity" />
                            <Workbook.Column label="LOCATION" value="location" />
                            <Workbook.Column label="ZONE" value="zone" />
                          </Workbook.Sheet>
                        </Workbook>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="common-seprator-grey-3 mb-4 mt-3"></div>
            </div>
          </div>
        </div>
        <div className={`open-divide ${this.state.listMode === "list" ? "w-100" : ""}`}>
          <div className="open-head pending">
            <h2>PENDING | {(this.state.pendingFilterFlag == 1) ? (this.props.pendingFilterCount || 0) : (this.props.nmPendingCount || 0)} </h2>
            <div className="d-flex align-items-center">
              <div
                className="search-bar-wrapper  mr-2 py-1 px-1 "
              >
                <div className="mr-3">
                  <input
                    type="text"
                    placeholder="Search by category, activity, act, service, location & reported by"
                    name="search"
                    value={this.props.searchList4}
                    onChange={(e) => this.props._handlePendingSearch(e)}
                  />

                </div>
                <div className="common-white-1">
                  <Search style={{ height: "15px" }} />
                </div>
              </div>
              <span title="Filter">
                <img src={require("assets/sls-icons/V2. New Development/funnel-s.svg")}
                  className="cursor-pointer svg-icons1"
                  onClick={() => {
                    this.pendingModal(true);
                  }}
                />
              </span>
              <InfoComponent
                tooltipText={"Assign team to start Investigation"}
              />
            </div>
          </div>
          {this.state.listMode === "list" ? <div className="mt-3 "><DataTable {...this.getFieldProps(this.props.nmPending)}
            scrollProps={{
              onScrollStop: ((this.props.pendingFilterCount != this.props.nmPending.length) || (this.props.nmPending.length != this.props.nmPendingCount)) ? this._handlePendingScrollbarPagination : null
            }} /></div> :
            <Scrollbars
              style={{ height: `calc(100vh - 18vw)` }}
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  className="track-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbHorizontal={(props) => (
                <div
                  {...props}
                  className="thumb-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbVertical={(props) => (
                <div
                  {...props}
                  style={{
                    position: "relative",
                    display: "block",
                    width: "100%",
                    cursor: "pointer",
                    borderRadius: "inherit",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    height: "100px",
                  }}
                />
              )}

              // onScrollStop={() => (this.props.nmPending.length != this.props.nmPendingCount) &&  
              //   this.props._getNMList(this.props.nmPending.length / 10 + 1)}


              // onScrollStop={() => (this.state.pendingFilterFlag == 1 && this.props.pendingFilterCount != this.state.pending.length) ?   this._handlePendingFilter :  ((this.props.nmPending.length != this.props.nmPendingCount) &&  
              //   this.props._getNMList(this.props.nmPending.length / 10 + 1))}

              onScrollStop={((this.props.pendingFilterCount != this.props.nmPending.length) || (this.props.nmPending.length != this.props.nmPendingCount)) ? this._handlePendingScrollbarPagination : null}
            >
              {this.props.nmPending != undefined &&
                this.props.nmPending.map((data, index) => (
                  <div className="open-content" key={index}>
                    <Accordion defaultActiveKey="0">
                      <div
                        className="nm-box open-box"
                        style={{ borderColor: data.severity_color }}
                      >
                        <div className="open-bhead open-tracker-head">
                          <Link
                            to={`/nm-detail/${data.nearmiss_id}/kanban/pending`}
                          >
                            <h4>
                              {data.type_of_act || data.type_of_Act} | 
                              {data.activity}
                            </h4>
                          </Link>
                          <div className="tracker-right">
                            {/* {(data.overdue || data.overdue_days) != "0 Days" && (
                            <p className="nm-label">
                              Overdue |{" "}
                              <span className="days">
                                {data.overdue_days || data.overdue}
                              </span>
                            </p>
                          )} */}
                            {data.evidence != "" && (
                              <>
                                {data.evidence[0]?.format === "video" ?
                                  <img src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
                                    className="cursor-pointer svg-icons" onClick={() => this.setState({ videoUrl: data.evidence[0]?.link })} /> :
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement="bottom"
                                    rootClose={true}

                                    overlay={
                                      <Popover
                                        id="popover-positioned-bottom"
                                        style={{ width: "15vw", borderRadius: "10px" }}
                                      >

                                        <div
                                          className="image-overlay-box mw-100"
                                        >
                                          {/* <div class="shadow-layer w-100"> </div> */}
                                          <img
                                            className="tracker-right  w-100"
                                            src={data.evidence[0]?.link}
                                            title="Evidence"
                                          />
                                        </div>

                                      </Popover>
                                    }
                                  >
                                    <div
                                    >
                                      <img
                                        className="tracker-right cursor-pointer mw-30 rounded-circle"
                                        src={data.evidence[0]?.link}
                                        title="Evidence"
                                      />
                                    </div>
                                  </OverlayTrigger>}
                              </>
                            )}
                            <Accordion.Toggle
                              eventKey="0"
                              className="nm-toggle-button"
                            >
                              <ChevronDown className="completed-arrow cursor-pointer" />
                            </Accordion.Toggle>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey="0">
                          <div className="open-bbody">
                            <Row>
                              <Col sm={6}>
                                <p className="nm-label">Service</p>
                                <p className="nm-text">{data.service}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Plant/Project</p>
                                <p className="nm-text">{data.project}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Reported By</p>
                                <div className="d-flex">
                                  <img src={data.reported_by_pic} className="user-icon " />
                                  <p className="nm-text">{data.reported_by}</p>

                                </div>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">
                                  <span className="text-white bg-warning rounded px-1">
                                    Reported On
                                  </span>
                                </p>
                                <p className="nm-text"> {data.report_date}</p>
                              </Col>
                            </Row>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    </Accordion>
                  </div>
                ))}


              {/* {Number.isInteger(this.props.nmPending.length / 10) &&
              this.props.nmPending != 0 &&
              this.props.nmPending.length != this.props.nmPendingCount && (
                <button
                  className="filter-button1"
                  onClick={() =>
                    this.props._getNMList(this.props.nmPending.length / 10 + 1)
                  }
                >
                  Load more
                </button>
              )} */}


            </Scrollbars>}
        </div>
        <div className={`open-divide ${this.state.listMode === "list" ? "w-100" : ""}`}>
          <div className="open-head inprogress">
            <h2>IN PROGRESS | {(this.state.inprogressFilterFlag == 1) ? (this.props.inprogressFilterCount || 0) : (this.props.nmProgressCount || 0)}</h2>
            <div className="d-flex align-items-center">
              <div
                className="search-bar-wrapper common-border-black-1  mr-2 py-1 px-1 "
              >
                <div className="mr-3">
                  <input
                    type="text"
                    placeholder="Search by category, activity, act, service, location & reported by"
                    name="search"
                    value={this.props.searchList5}
                    onChange={(e) => this.props._handleProgressSearch(e)}

                  />

                </div>
                <div className="common-white-1">
                  <Search style={{ height: "15px" }} />
                </div>
              </div>
              <span title="Filter">
                <img src={require("assets/sls-icons/V2. New Development/funnel-s.svg")}
                  className="cursor-pointer svg-icons1"
                  onClick={() => {
                    this.inProgressModal(true);
                  }}
                />
              </span >
              <InfoComponent
                tooltipText={"Investigation In-progress"}
              />
            </div >
          </div >
          {
            this.state.listMode === "list" ? <div className="mt-3 "><DataTable {...this.getFieldProps(this.props.nmProgress, ["Due Date", "Team Lead", "Overdue"], "progress")}
              scrollProps={{
                onScrollStop: ((this.props.inprogressFilterCount != this.props.nmProgress.length) || (this.props.nmProgress.length != this.props.nmProgressCount)) ? this._handleInprogressScrollbarPagination : null
              }} /></div> :
              <Scrollbars
                style={{ height: `calc(100vh - 18vw)` }}
                renderTrackHorizontal={(props) => (
                  <div
                    {...props}
                    className="track-horizontal"
                    style={{ display: "none" }}
                  />
                )}
                renderThumbHorizontal={(props) => (
                  <div
                    {...props}
                    className="thumb-horizontal"
                    style={{ display: "none" }}
                  />
                )}
                renderThumbVertical={(props) => (
                  <div
                    {...props}
                    style={{
                      position: "relative",
                      display: "block",
                      width: "100%",
                      cursor: "pointer",
                      borderRadius: "inherit",
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                      height: "100px",
                    }}
                  />
                )}

                // onScrollStop={() => (this.props.nmProgress.length != this.props.nmProgressCount) &&  
                //   this.props._getNMProgressList(this.props.nmProgress.length / 10 + 1)}

                onScrollStop={((this.props.inprogressFilterCount != this.props.nmProgress.length) || (this.props.nmProgress.length != this.props.nmProgressCount)) ? this._handleInprogressScrollbarPagination : null}
              >
                {this.props.nmProgress != undefined &&
                  this.props.nmProgress.map((data, index) => (
                    <div className="open-content" key={index}>
                      <Accordion defaultActiveKey="0">
                        <div
                          className="nm-box open-box"
                          style={{ borderColor: data.severity_color }}
                        >
                          <div className="open-bhead open-tracker-head">
                            <Link
                              to={`/nm-detail/${data.nearmiss_id}/kanban/progress`}
                            >
                              <h4>
                                {data.type_of_act || data.type_of_Act} |
                                {data.activity}
                              </h4>
                            </Link>
                            <div className="tracker-right">
                              {(data.overdue || data.overdue_days) != "0 Days" && (
                                <p className="nm-label mr-4">
                                  Overdue |{" "}
                                  <span className="days">
                                    {data.overdue_days || data.overdue}
                                  </span>
                                </p>
                              )}
                              {data.evidence != "" && (
                                <>
                                  {data.evidence[0]?.format === "video" ?
                                    <img src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
                                      className="cursor-pointer svg-icons" onClick={() => this.setState({ videoUrl: data.evidence[0]?.link })} /> :
                                    <OverlayTrigger
                                      trigger="hover"
                                      placement="bottom"
                                      rootClose={true}

                                      overlay={
                                        <Popover
                                          id="popover-positioned-bottom"
                                          style={{ width: "15vw", borderRadius: "10px" }}
                                        >

                                          <div
                                            className="image-overlay-box mw-100"
                                          >
                                            {/* <div class="shadow-layer w-100"> </div> */}
                                            <img
                                              className="tracker-right  w-100"
                                              src={data.evidence[0]?.link}
                                              title="Evidence"
                                            />
                                          </div>

                                        </Popover>
                                      }
                                    >
                                      <div
                                      >
                                        <img
                                          className="tracker-right cursor-pointer mw-30 rounded-circle"
                                          src={data.evidence[0]?.link}
                                          title="Evidence"
                                        />
                                      </div>
                                    </OverlayTrigger>}
                                </>
                              )}
                              <Accordion.Toggle
                                eventKey="0"
                                className="nm-toggle-button"
                              >
                                <ChevronDown className="completed-arrow cursor-pointer" />
                              </Accordion.Toggle>
                            </div>
                          </div>
                          <Accordion.Collapse eventKey="0">
                            <div className="open-bbody">
                              <Row>
                                <Col sm={6}>
                                  <p className="nm-label">Service</p>
                                  <p className="nm-text">{data.service}</p>
                                </Col>
                                <Col sm={6}>
                                  <p className="nm-label">Plant/Project</p>
                                  <p className="nm-text">{data.project}</p>
                                </Col>
                                <Col sm={6}>
                                  <p className="nm-label">Reported By</p>
                                  <div className="d-flex">
                                    <img src={data.reported_by_pic} className="user-icon " />
                                    <p className="nm-text">{data.reported_by}</p>

                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <p className="nm-label">Team Lead</p>
                                  <div className="d-flex">
                                    <img src={data.assign_to_pic} className="user-icon " />
                                    <p className="nm-text">{data.assign_to}</p>

                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <p className="nm-label">Reported On</p>
                                  <p className="nm-text rounded px-1">{data.report_date}</p>
                                </Col>
                                <Col sm={6}>
                                  <p className="nm-label">
                                    <span
                                      className={`text-white rounded px-1 ${this.comapareTargetDate(data.target_date)
                                        ? "bg-danger"
                                        : "bg-warning"
                                        }text-white bg-warning rounded px-1`}
                                    >
                                      Due Date
                                    </span>
                                  </p>

                                  <p className="nm-text">
                                    {data.target_date || "NA"}
                                  </p>
                                </Col>
                              </Row>

                              <div className="people-aaray">
                                {data.team != undefined &&
                                  data.team.map((team, index) => (
                                    <div key={index}>
                                      {team.status == 1 && (
                                        <div className="people-lead">
                                          <img
                                            src={team.pic}
                                            className="people-img"
                                            title={team.name}
                                          />
                                          <div>
                                            <img className="image-position"
                                              src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                {data.team != undefined &&
                                  data.team.map((team, index) => (
                                    <div key={index}>
                                      {team.status == 0 && (
                                        <div className="people-normal">
                                          <img
                                            src={team.pic}
                                            className="people-normal-img"
                                            title={team.name}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </Accordion>
                    </div>
                  ))}


                {/* {Number.isInteger(this.props.nmProgress.length / 10) &&
              this.props.nmProgress != 0 &&
              this.props.nmProgress.length != this.props.nmProgressCount && (
                <button
                  className="filter-button1"
                  onClick={() =>
                    this.props._getNMProgressList(
                      this.props.nmProgress.length / 10 + 1
                    )
                  }
                >
                  Load more
                </button>
              )} */}


              </Scrollbars>
          }
        </div >
        <div className={`open-divide ${this.state.listMode === "list" ? "w-100" : ""}`}>
          <div className="open-head completed">
            <h2>COMPLETED | {this.state.completedFilterFlag == 1 ? (this.props.completeFilterCount || 0) : (this.props.nmCompletedCount || 0)}</h2>
            <div className="d-flex align-items-center">
              <div
                className="search-bar-wrapper mr-2 py-1 px-1 "
              >
                <div className="mr-3">
                  <input
                    type="text"
                    placeholder="Search by category, activity, act, service, location & reported by"
                    name="search"
                    value={this.props.searchList6}
                    onChange={(e) => this.props._handleCompletedSearch(e)}
                  />

                </div>
                <div className="common-white-1">
                  <Search style={{ height: "15px" }} />
                </div>
              </div>
              <span title="Filter">
                <img src={require("assets/sls-icons/V2. New Development/funnel-s.svg")}
                  className="cursor-pointer svg-icons1"
                  onClick={() => {
                    this.completedModal(true);
                  }}
                />
              </span>
              <InfoComponent
                tooltipText={"Investigation Completed"}
              />
            </div>
          </div>
          {/* <div className="search-box">
            <div className="search-input">
              <input
                type="text"
                placeholder="Search by activity, act, service, location & reported by"
                name="search"
                value={this.props.searchList6}
                onChange={(e) => this.props._handleCompletedSearch(e)}
                autoComplete="off"
              />
              <button className="search-button" />
            </div>
          </div> */}
          {this.state.listMode === "list" ? <div className="mt-3 "><DataTable {...this.getFieldProps(this.props.nmCompleted, ["Closed On", "Team Lead", "Deviation"], "completed")}
            scrollProps={{
              onScrollStop: () => ((this.props.nmCompleted.length != this.props.nmCompletedCount) || (this.props.completeFilterCount != this.props.nmCompleted.length)) ?
                this._handleCompleteScrollbarPagination : null
            }} /></div> :
            <Scrollbars
              style={{ height: `calc(100vh - 18vw)` }}
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  className="track-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbHorizontal={(props) => (
                <div
                  {...props}
                  className="thumb-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbVertical={(props) => (
                <div
                  {...props}
                  style={{
                    position: "relative",
                    display: "block",
                    width: "100%",
                    cursor: "pointer",
                    borderRadius: "inherit",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    height: "100px",
                  }}
                />
              )}

              // onScrollStop={() => (this.props.nmCompleted.length != this.props.nmCompletedCount) && 
              //   this.props._getNMCompletedList(this.props.nmCompleted.length / 10 + 1)}

              onScrollStop={() => ((this.props.nmCompleted.length != this.props.nmCompletedCount) || (this.props.completeFilterCount != this.props.nmCompleted.length)) ?
                this._handleCompleteScrollbarPagination : null}
            >
              {this.props.nmCompleted != undefined &&
                this.props.nmCompleted.map((data, index) => (
                  <div className="open-content" key={index}>
                    <Accordion defaultActiveKey="0">
                      <div
                        className="nm-box open-box"
                        style={{ borderColor: data.severity_color }}
                      >
                        <div className="open-bhead open-tracker-head">
                          <Link
                            to={`/nm-detail/${data.nearmiss_id}/kanban/completed`}
                          >
                            <h4>
                              {data.type_of_act || data.type_of_Act} |
                              {data.activity}
                            </h4>
                          </Link>
                          <div className="tracker-right">
                            {(data.overdue || data.overdue_days) != "0 Days" && (
                              <p className="nm-label mr-4">
                                Deviation |{" "}
                                <span className="days">
                                  {data.overdue_days || data.overdue}
                                </span>
                              </p>
                            )}
                            {data.evidence != "" && (
                              <>
                                {data.evidence[0]?.format === "video" ?
                                  <img src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
                                    className="cursor-pointer svg-icons" onClick={() => this.setState({ videoUrl: data.evidence[0]?.link })} /> :
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement="bottom"
                                    rootClose={true}

                                    overlay={
                                      <Popover
                                        id="popover-positioned-bottom"
                                        style={{ width: "15vw", borderRadius: "10px" }}
                                      >

                                        <div
                                          className="image-overlay-box mw-100"
                                        >
                                          {/* <div class="shadow-layer w-100"> </div> */}
                                          <img
                                            className="tracker-right  w-100"
                                            src={data.evidence[0]?.link}
                                            title="Evidence"
                                          />
                                        </div>

                                      </Popover>
                                    }
                                  >
                                    <div
                                    >
                                      <img
                                        className="tracker-right cursor-pointer mw-30 rounded-circle"
                                        src={data.evidence[0]?.link}
                                        title="Evidence"
                                      />
                                    </div>
                                  </OverlayTrigger>}
                              </>
                            )}
                            <Accordion.Toggle
                              eventKey="0"
                              className="nm-toggle-button"
                            >
                              <ChevronDown className="completed-arrow cursor-pointer" />
                            </Accordion.Toggle>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey="0">
                          <div className="open-bbody">
                            <Row>
                              <Col sm={6}>
                                <p className="nm-label">Service</p>
                                <p className="nm-text">{data.service}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Plant/Project</p>
                                <p className="nm-text">{data.project}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Reported By</p>
                                <div className="d-flex">
                                  <img src={data.reported_by_pic} className="user-icon " />
                                  <p className="nm-text">{data.reported_by}</p>

                                </div>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Team Lead</p>
                                <div className="d-flex">
                                  <img src={data.assign_to_pic} className="user-icon " />
                                  <p className="nm-text">{data.assign_to}</p>

                                </div>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Reported On</p>
                                <p className="nm-text">{data.report_date} </p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Due Date</p>
                                <p className="nm-text">
                                  {data.target_date || "NA"}
                                </p>
                              </Col>
                              <Col sm={12}>
                                <p className="">
                                  <span className="text-white rounded px-1" style={{ backgroundColor: "tomato" }}>
                                    Closed On
                                  </span>
                                </p>
                                <p className="nm-text">
                                  {data.closing_date || "NA"}
                                </p>
                              </Col>

                            </Row>

                            <div className="people-aaray">
                              {data.team != undefined &&
                                data.team.map((team, index) => (
                                  <div key={index}>
                                    {team.status == 1 && (
                                      <div className="people-lead">
                                        <img
                                          src={team.pic}
                                          className="people-img"
                                          title={team.name}
                                        />
                                        <div>
                                          <img className="image-position"
                                            src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              {data.team != undefined &&
                                data.team.map((team, index) => (
                                  <div key={index}>
                                    {team.status == 0 && (
                                      <div className="people-normal">
                                        <img
                                          src={team.pic}
                                          className="people-normal-img"
                                          title={team.name}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              <div style={{ position: "absolute", right: "2vw" }}>
                                <Link
                                  to={`/nm-report/${data.nearmiss_id}`}
                                  target="_blank"
                                  title="View Report"
                                >
                                  <img
                                    src={require("assets/images/icons/fpdf.svg")}
                                    alt="completed pdf"
                                  />
                                </Link>
                              </div>
                            </div>



                          </div>
                        </Accordion.Collapse>
                      </div>
                    </Accordion>
                  </div>
                ))}


              {/* {Number.isInteger(this.props.nmCompleted.length / 10) &&
              this.props.nmCompleted != 0 &&
              this.props.nmCompleted.length != this.props.nmCompletedCount && (
                <button
                  className="filter-button1"
                  onClick={() =>
                    this.props._getNMCompletedList(
                      this.props.nmCompleted.length / 10 + 1
                    )
                  }
                >
                  Load more
                </button>
              )} */}


            </Scrollbars>}
        </div>
        <Modal
          centered
          show={this.state.pendingPopup}
          onHide={() => this.pendingModal(false)}
        >
          <Modal.Header
            className="align-items-center"
          ><div className="align-items-center d-flex justify-content-between">
              <div><span className="font-20 font-weight-500 common-white-2"> Pending Filter</span></div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button onClick={() => this._handleFilterReset("pending")}>
                  Reset
                </button>
                <button 
                onClick={() =>
                  this.debounceFunc(() => {this._handlePendingFilter() })
                }
                
                // onClick={this._handlePendingFilter}
                
                >
                  Apply
                </button>
              </div>
              <X style={{
                height: "1.4vw",
                width: "1.4vw"
              }} className="cursor-pointer mx-2 common-white-2" onClick={() => this.setState({
                pendingPopup: false
              })} />
            </div></Modal.Header>
          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <label>Risk Level</label>
                <div className="along-label1">
                  <label className="check-box">

                    <input
                      name="abc"
                      type="checkbox"
                      checked={this.state.pendingAll}
                      onClick={() =>
                        this.pendingFilterData(this.state.pendingAll, "all")
                      }
                    />
                    <span className="checkmark1"></span>
                  </label>
                </div>
                <Row>
                  <Col sm={6}>
                    <label className="check-box">
                      High Critical
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.pendingExtremeSelect}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(
                            this.state.pendingExtremeSelect,
                            4
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      High
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.pendingHighSelect}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(
                            this.state.pendingHighSelect,
                            3
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Medium
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.pendingMediumSelect}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(
                            this.state.pendingMediumSelect,
                            2
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Low
                      <input
                        value={1}
                        type="checkbox"
                        checked={this.state.pendingLowSelect}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(
                            this.state.pendingLowSelect,
                            1
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  {/* <Col sm={6}>
                    <label className="check-box">
                      Overdue
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.overdue}
                        onClick={() =>
                          this.FilterData(this.state.overdue, "overdue")
                        }
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col> */}
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>Service</label>
                    <select
                      className="form-control"
                      value={this.state.pendingServiceSelect}
                      onChange={this._handlePendingServiceChange}
                    >
                      <option value="">Select Service</option>
                      {this.state.pendingService.map((service, index) => (
                        <option key={index} value={service.service_id}>
                          {service.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>Plant/Project</label>
                    <select
                      className="form-control"
                      value={this.state.pendingProjectSelect}
                      onChange={(e) => {
                        this.setState({ pendingProjectSelect: e.target.value });
                      }}
                    >
                      <option>Select Plant/Project</option>
                      {this.state.pendingProject.map((project, index) => (
                        <option key={index} value={project.project_id}>
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label>By Report On</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.pendingStartDate}
                        onChange={(jsDate) =>
                          this.setState({ pendingStartDate: jsDate })
                        }
                        placeholderText="Select Start Date"
                        maxDate={new Date()}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">To</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.pendingEndDate}
                        onChange={(jsDate) =>
                          this.setState({ pendingEndDate: jsDate })
                        }
                        placeholderText="Select End Date"
                        minDate={this.state.pendingStartDate}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {/* <Button 
              type="submit"
              variant="outline-primary"
              className="modal-btn"
              onClick={this._handlePendingFilter}
            >
             APPLY 
            </Button> */}
          </Modal.Body>
        </Modal>

        {/* In progress filter popup */}
        <Modal
          centered
          show={this.state.inProgressPopup}
          onHide={() => this.inProgressModal(false)}
        >
          <Modal.Header
            className="align-items-center"
          ><div className="align-items-center d-flex justify-content-between">
              <div><span className="font-20 font-weight-500 common-white-2"> Inprogress Filter</span></div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button onClick={() => this._handleFilterReset("progress")}>
                  Reset
                </button>
                <button 
                onClick={() =>
                  this.debounceFunc(() => {this._handleInProgressFilter() })
                }
                
                // onClick={this._handleInProgressFilter}
                
                >
                  Apply
                </button>
              </div>
              <X style={{
                height: "1.4vw",
                width: "1.4vw"
              }} className="cursor-pointer mx-2 common-white-2" onClick={() => this.setState({
                inProgressPopup: false
              })} />
            </div></Modal.Header>

          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <label>Risk Level</label>
                <div className="along-label">
                  <label className="check-box">

                    <input
                      name="abc"
                      type="checkbox"
                      checked={this.state.progressAll}
                      onClick={() =>
                        this.progressFilterData(this.state.progressAll, "all")
                      }
                    />
                    <span className="checkmark1"></span>
                  </label>
                </div>
                <Row>
                  <Col sm={6}>
                    <label className="check-box">
                      High Critical
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.progressExtremeSelect}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(
                            this.state.progressExtremeSelect,
                            4
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      High
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.progressHighSelect}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(
                            this.state.progressHighSelect,
                            3
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Medium
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.progressMediumSelect}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(
                            this.state.progressMediumSelect,
                            2
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Low
                      <input
                        value={1}
                        type="checkbox"
                        checked={this.state.progressLowSelect}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(
                            this.state.progressLowSelect,
                            1
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Overdue
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.overdue}
                        onClick={() =>
                          this.progressFilterData(this.state.overdue, "overdue")
                        }
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
                <div className="modal-footer-btn ">

                </div>

              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>Service</label>
                    <select
                      className="form-control"
                      value={this.state.inProgressServiceSelect}
                      onChange={this._handleProgressServiceChange}
                    >
                      <option value="">Select Service</option>
                      {this.state.inProgressService.map((service, index) => (
                        <option key={index} value={service.service_id}>
                          {service.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>Plant/Project</label>
                    <select
                      className="form-control"
                      value={this.state.progressProjectSelect}
                      onChange={(e) => {
                        this.setState({
                          progressProjectSelect: e.target.value,
                        });
                      }}
                    >
                      <option>Select Plant/Project</option>
                      {this.state.progressProject.map((project, index) => (
                        <option key={index} value={project.project_id}>
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label>By Report Date</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.progressStartDate}
                        onChange={(jsDate) =>
                          this.setState({ progressStartDate: jsDate })
                        }
                        placeholderText="Select Start date"
                        maxDate={new Date()}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">To</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.progressEndDate}
                        onChange={(jsDate) =>
                          this.setState({ progressEndDate: jsDate })
                        }
                        placeholderText="Select End date"
                        minDate={this.state.progressStartDate}
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>

              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Completed Filter */}

        <Modal
          centered
          show={this.state.completedPopup}
          onHide={() => this.completedModal(false)}
        >
          <Modal.Header
            className="align-items-center"
          ><div className="align-items-center d-flex justify-content-between">
              <div><span className="font-20 font-weight-500 common-white-2"> Completed Filter</span></div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button onClick={() => this._handleFilterReset("completed")}>
                  Reset
                </button>
                <button 
                 onClick={() =>
                  this.debounceFunc(() => {this._handleCompletedFilter() })
                }
                
                // onClick={this._handleCompletedFilter}
                
                >
                  Apply
                </button>
              </div>
              <X style={{
                height: "1.4vw",
                width: "1.4vw"
              }} className="cursor-pointer mx-2 common-white-2" onClick={() => this.setState({
                completedPopup: false
              })} />
            </div></Modal.Header>
          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <label>Risk Level</label>
                <div className="along-label">
                  <label className="check-box">

                    <input
                      name="abc"
                      type="checkbox"
                      checked={this.state.completedAll}
                      onClick={() =>
                        this.completedFilterData(this.state.completedAll, "all")
                      }
                    />
                    <span className="checkmark1"></span>
                  </label>
                </div>
                <Row>
                  <Col sm={6}>
                    <label className="check-box">
                      High Critical
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.completedExtremeSelect}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(
                            this.state.completedExtremeSelect,
                            4
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      High
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.completedHighSelect}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(
                            this.state.completedHighSelect,
                            3
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Medium
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.completedMediumSelect}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(
                            this.state.completedMediumSelect,
                            2
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Low
                      <input
                        value={1}
                        type="checkbox"
                        checked={this.state.completedLowSelect}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(
                            this.state.completedLowSelect,
                            1
                          );
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Deviation
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.deviation}
                        onClick={() =>
                          this.completedFilterData(
                            this.state.deviation,
                            "deviation"
                          )
                        }
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>Service</label>
                    <select
                      className="form-control"
                      value={this.state.completedServiceSelect}
                      onChange={this._handleCompletedServiceChange}
                    >
                      <option value="">Select Service</option>
                      {this.state.completedService.map((service, index) => (
                        <option key={index} value={service.service_id}>
                          {service.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>Plant/Project</label>
                    <select
                      className="form-control"
                      value={this.state.completedProjectSelect}
                      onChange={(e) => {
                        this.setState({
                          completedProjectSelect: e.target.value,
                        });
                      }}
                    >
                      <option>Select Plant/Project</option>
                      {this.state.completedProject.map((project, index) => (
                        <option key={index} value={project.project_id}>
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label>By Report Date</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.completedStartDate}
                        onChange={(jsDate) =>
                          this.setState({ completedStartDate: jsDate })
                        }
                        maxDate={new Date()}
                        placeholderText="Select Start date"
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">To</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.completedEndDate}
                        onChange={(jsDate) =>
                          this.setState({ completedEndDate: jsDate })
                        }
                        minDate={this.state.completedStartDate}
                        maxDate={new Date()}
                        placeholderText="Select End date"
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal centered show={this.state.videoUrl !== ""}>
          <Modal.Header closeButton onClick={() => this.setState({ videoUrl: "" })}>
            <Modal.Title>Evidence</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <VideoBox url={this.state.videoUrl} />
          </Modal.Body>
        </Modal>
      </div >
    );
  }
}

NmList.defaultProps = {
  dataRefresh: () => { },
};
