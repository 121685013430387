/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { CarouselComponent } from "app/shared/components/ReusableComponents/Carousel";
import FishboneChart from "fishbone-chart";
// ui kit components
import {
  File,
  Files,
  Tooltip,
  Carousel,
  Input,
  Combobox,
  Checkbox,
} from "@salesforce/design-system-react";
import { Eye } from "react-feather";
//components
import ClideLoader from "app/shared/ui-kit/loader";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
import { HorizontalNavTab } from "app/shared/components/ReportModal";
//assets
import {
  PlusIconRed,
  RoundSave,
  CrossRound,
  RoundDelete,
  RoundEdit,
  TooltipInfoIcon,
  FilterRound,
} from "assets/Iconsv2/Iconsv2";
import { Upload } from "react-feather";
//constans
import { tabs } from "../../../../../../constants/constants";
import { useTranslation } from "react-i18next";

const DetailStep3 = ({ state, functionContent, variablesObj }) => {
  const { isInprogress, isApproveEnabled, isCompleted, isTeamMemeberLoggedIn } =
    variablesObj ?? {};

  const { nmDetail } = state ?? {};
  const { t } = useTranslation(['nm', 'util']);
  return (
    <div>
      <div className="row mt-3">
        <div className="col-12 ">
          <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-between px-0">
            <div className="clide-v2-title mb-1">
              <label className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                {" "}
                {t('step3CapaRecommendation')}
              </label>
            </div>
          </div>
        </div>
        {["progress"].includes(state?.reportStatus) ? (
          <div className="col-12 ">
            <div className="row">
              <div className="col-12 px-0">
                <div className="row">
                  <div className=" col-12 d-flex-center gap-4 px-0">
                    {state?.reportStatus == "progress" ? (
                      <div className="d-flex justify-content-around my-2">
                        <HorizontalNavTab
                          classes="w-100"
                          tabs={tabs}
                          activeItem={state.currentTab}
                          handleChange={(key) =>
                            functionContent.setState({ currentTab: key })
                          }
                          extraValue={{
                            prop: `(${
                              nmDetail?.proposedCapaCount ?? 0
                            })`,
                            final: `(${nmDetail?.finalCapaCount ?? 0})`,
                          }}
                        />
                      </div>
                    ) : null}
                    <div className=" d-flex justify-content-end px-0 gap-2 align-items-center">
                      <button
                        className="clide-v2-icon-btn "
                        title="Filter"
                        onClick={() => {
                          functionContent.setState({
                            isDataFilterEnable: true,
                          });
                        }}
                      >
                        <FilterRound width="33" height="33" />
                      </button>

                      {state.currentTab == "prop" &&
                      (isApproveEnabled || isTeamMemeberLoggedIn) ? (
                        <button
                          className="clide-v2-icon-btn "
                          title="Add"
                          onClick={() => {
                            functionContent.toggleStep3Tab();
                          }}
                        >
                          <PlusIconRed width="30" height="30" />
                        </button>
                      ) : nmDetail.nm_reported_detail_final_recommendation_list
                          ?.length && isApproveEnabled ? (
                        <div>
                          <button
                            className="btn border clide-v2-primary clide-v2-white-color "
                            title="Freeze all"
                            onClick={() => {
                              functionContent.setState({
                                isFreezeEnabled: true,
                              });
                            }}
                          >
                            {t('freezeAll')}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {state.reportStatus == "completed" ? (
        <Scrollbars
          style={{ height: "30rem" }}
          renderView={(props) => (
            <div
              style={{
                paddingRight: "15px",
                paddingBottom: "1rem",
                ...props.style,
                overflowX: "hidden",
              }}
            />
          )}
        >
          {nmDetail?.nm_reported_detail_freezeall_recommendation_list?.map(
            (item, idx) => (
              <div className="row mt-3" key={idx}>
                <div className="col-12 col-md-12 col-lg-12">
                  <div
                    className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10  mb-3"
                    style={{ borderColor: `${item.priority_color}` }}
                  >
                    <div className="w-75 p-3">
                      <label className="clide-v2-fs-4 fw-semibold ">
                        {" "}
                        {item.priority}&nbsp; | {item?.category_name || ""}{" "}
                        &nbsp; | &nbsp;
                        {` ${item.recommendation?.[0].capaType}` || ""}
                      </label>
                    </div>

                    <div className="mb-1">
                      <div className="row p-1">
                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4">{t('activity')} </label>
                          </div>
                          <div className="clide-v2-title mb-1">
                            <label className="">{item.activity_name}</label>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4">{t('closedOn')} </label>
                          </div>
                          <div className="clide-v2-title mb-1">
                            {[
                              ...(item?.recommendation ??
                                item?.responsible_person),
                            ]?.map((data, idx) => (
                              <label key={idx}>
                                {data.closing_date ?? "NA"}
                              </label>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4">{t('dueDate')} </label>
                          </div>
                          <div className="clide-v2-title mb-1">
                            <label className=""> {item.target_date}</label>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4">
                              {t('preventiveAction')}{" "}
                            </label>
                          </div>
                          <div className="clide-v2-title mb-1">
                            <label className="">
                              {` ${item.recommendation?.[0]?.recommendation}` ||
                                ""}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-6 col-lg-8 ">
                          <div className="row p-0">
                            <div className="col-12 col-md-6 col-lg-6 p-0">
                              <div className="clide-v2-title mb-1">
                                <label className="clide-v2-fs-4">
                                  {t('proposedBy')}{" "}
                                </label>
                              </div>
                              <img
                                className="clide-v2-profile-img mr-1"
                                src={item.recommended_by_pic}
                              />
                              <label className="me-2 clide-v2-fs-5 fw-semibold">
                                {item.recommended_by}
                              </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 p-0">
                              <div className="clide-v2-title mb-1">
                                <label className="clide-v2-fs-4">
                                  {t('assignedTo')}{" "}
                                </label>
                                <Scrollbars
                                  style={{
                                    height: "2rem",
                                  }}
                                  renderView={(props) => (
                                    <div
                                      style={{
                                        paddingRight: "15px",

                                        ...props.style,
                                        overflowX: "hidden",
                                      }}
                                    />
                                  )}
                                >
                                  {" "}
                                  {[
                                    ...(item?.responsible_person_list ??
                                      item?.responsible_person),
                                  ]?.map((data, idx) => (
                                    <div>
                                      <img
                                        src={data.person_pic}
                                        className="clide-v2-profile-img"
                                        alt="user-avatar"
                                      />{" "}
                                      <span className="w-max">
                                        {data.person_name}
                                      </span>{" "}
                                    </div>
                                  ))}{" "}
                                </Scrollbars>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-12 mt-3 px-0">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">
                                {t('closingRemark')}{" "}
                              </label>
                            </div>
                            <div className="clide-v2-title mb-1">
                              <label className="">
                                {item.recommendation?.[0].observation || "N/A"}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-2">
                          <CarouselComponent
                            items={
                              item.recommendation?.[0]?.recommendation_evidence
                                .length > 0
                                ? [
                                    {
                                      url: item.recommendation?.[0]
                                        .recommendation_evidence[0]?.evidence,
                                      contentType: "image",
                                    },
                                  ]
                                : []
                            }
                            showArrows={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </Scrollbars>
      ) : state.currentTab == "prop" ? (
        <>
          <Scrollbars
            style={{ height: "30rem" }}
            renderView={(props) => (
              <div
                style={{
                  paddingRight: "15px",
                  paddingBottom: "1rem",
                  ...props.style,
                  overflowX: "hidden",
                }}
              />
            )}
          >
            {nmDetail.nm_reported_detail_proposed_recommendation_list?.map(
              (item, idx, arr) => (
                <div className="row mt-3" key={idx}>
                  <div className="col-12 col-md-12 col-lg-12">
                    <div
                      className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10  mb-3"
                      style={{
                        borderColor: `${
                          item.severity_color ?? item?.priority_color
                        }`,
                      }}
                    >
                      <div className="align-items-center p-3 px-1 row">
                        <div className="col-6 ">
                          <label className="clide-v2-fs-4 fw-semibold mb-0">
                            {" "}
                            {item.priority}&nbsp; | &nbsp;{" "}
                            {item?.category_name || ""} &nbsp; | &nbsp;{" "}
                            {item.recommendation.map((data) => data.capaType)}
                          </label>
                        </div>
                        <div className="col-6 ">
                          <div className="d-flex space-between mt-2 justify-content-end gap-2">
                            {isApproveEnabled ? (
                              <button
                                className="btn border clide-v2-primary clide-v2-white-color "
                                title="Freeze all"
                                onClick={() =>
                                  functionContent.toggleApproveTabCAPA(
                                    item.recommendation_id
                                  )
                                }
                              >
                                {t('approve')}
                              </button>
                            ) : null}
                            {state?.userData?.user_id ===
                            item.recommended_by_id ? (
                              <div>
                                <button
                                  onClick={() =>
                                    functionContent.manageEdit(
                                      item.recommendation_id,
                                      arr
                                    )
                                  }
                                  className="clide-v2-icon-btn "
                                >
                                  <RoundEdit />
                                </button>
                              </div>
                            ) : null}

                            {state?.userData?.user_id ===
                            item.recommended_by_id ? (
                              <div>
                                <button
                                  onClick={() =>
                                    functionContent.toggleDeletePopupCAPA(
                                      item.recommendation_id
                                    )
                                  }
                                  className="clide-v2-icon-btn "
                                >
                                  <RoundDelete />
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">{t('activity')} </label>
                            </div>
                            <div className="clide-v2-title mb-1">
                              <label className="">{item.activity_name}</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">{t('dueDate')} </label>
                            </div>
                            <div className="clide-v2-title mb-1">
                              <label className=""> {item.target_date}</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">
                                {t('correctiveAction')}{" "}
                              </label>
                            </div>
                            <div className="clide-v2-title mb-1">
                              <label className="">
                                {` ${item.recommendation?.[0]?.recommendation}` ||
                                  ""}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-12 col-md-6 col-lg-8 ">
                            <div className="row p-0 mb-3">
                              <div className="col-12 col-md-6 col-lg-6 p-0">
                                <div className="clide-v2-title mb-1">
                                  <label className="clide-v2-fs-4">
                                    {t('proposedBy')}{" "}
                                  </label>
                                </div>
                                <img
                                  className="clide-v2-profile-img mr-1"
                                  src={item.recommended_by_pic}
                                />
                                <label className="me-2 clide-v2-fs-5 fw-semibold">
                                  {item.recommended_by}
                                </label>
                              </div>
                              <div className="col-12 col-md-6 col-lg-6 p-0">
                                <div className="clide-v2-title mb-1">
                                  <label className="clide-v2-fs-4">
                                    {t('assignedTo')}{" "}
                                  </label>
                                  <Scrollbars
                                    style={{
                                      height: "2rem",
                                    }}
                                    renderView={(props) => (
                                      <div
                                        style={{
                                          paddingRight: "15px",

                                          ...props.style,
                                          overflowX: "hidden",
                                        }}
                                      />
                                    )}
                                  >
                                    {" "}
                                    {[
                                      ...(item?.responsible_person_list ??
                                        item?.responsible_person),
                                    ]?.map((data, idx) => (
                                      <div>
                                        <img
                                          src={data.person_pic}
                                          className="clide-v2-profile-img"
                                          alt="user-avatar"
                                        />{" "}
                                        <span className="w-max">
                                          {data.person_name}
                                        </span>{" "}
                                      </div>
                                    ))}{" "}
                                  </Scrollbars>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 mb-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </Scrollbars>
        </>
      ) : (
        <Scrollbars
          style={{ height: "30rem" }}
          renderView={(props) => (
            <div
              style={{
                paddingRight: "15px",
                paddingBottom: "1rem",
                ...props.style,
                overflowX: "hidden",
              }}
            />
          )}
        >
          {nmDetail?.nm_reported_detail_final_recommendation_list?.map(
            (item, idx, arr) => (
              <div className="row mt-3" key={idx}>
                <div className="col-12 col-md-12 col-lg-12">
                  <div
                    className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10  mb-3"
                    style={{
                      borderColor: `${
                        item.severity_color ?? item?.priority_color
                      }`,
                    }}
                  >
                    <div className="align-items-center p-3 px-1 row">
                      <div className="col-6 ">
                        <label className="clide-v2-fs-4 fw-semibold mb-0">
                          {" "}
                          {item.priority}&nbsp; | &nbsp;{" "}
                          {item?.category_name || ""} &nbsp; | &nbsp;{" "}
                          {item.recommendation.map((data) => data.capaType)}
                        </label>
                      </div>
                      <div className="col-6 ">
                        <div className="d-flex space-between mt-2 justify-content-end gap-2">
                          {state?.userData?.user_id ===
                          item.recommended_by_id ? (
                            <div>
                              <button
                                onClick={() =>
                                  functionContent.setState({
                                    fcrdelID: item?.recommendation_id,
                                  })
                                }
                                className="clide-v2-icon-btn "
                              >
                                <RoundDelete />
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="mb-1">
                      <div className="row p-1">
                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4">{t('activity')} </label>
                          </div>
                          <div className="clide-v2-title mb-1">
                            <label className="">{item.activity_name}</label>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4">{t('dueDate')} </label>
                          </div>
                          <div className="clide-v2-title mb-1">
                            <label className=""> {item.target_date}</label>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4">
                              {t('correctiveAction')}{" "}
                            </label>
                          </div>
                          <div className="clide-v2-title mb-1">
                            <label className="">
                              {` ${item.recommendation?.[0]?.recommendation}` ||
                                ""}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-12 col-md-6 col-lg-8 ">
                          <div className="row p-0 mb-3">
                            <div className="col-12 col-md-6 col-lg-6 p-0">
                              <div className="clide-v2-title mb-1">
                                <label className="clide-v2-fs-4">
                                  {t('proposedBy')}{" "}
                                </label>
                              </div>
                              <img
                                className="clide-v2-profile-img mr-1"
                                src={item.recommended_by_pic}
                              />
                              <label className="me-2 clide-v2-fs-5 fw-semibold">
                                {item.recommended_by}
                              </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 p-0">
                              <div className="clide-v2-title mb-1">
                                <label className="clide-v2-fs-4">
                                  {t('assignedTo')}{" "}
                                </label>
                                <Scrollbars
                                  style={{
                                    height: "2rem",
                                  }}
                                  renderView={(props) => (
                                    <div
                                      style={{
                                        paddingRight: "15px",

                                        ...props.style,
                                        overflowX: "hidden",
                                      }}
                                    />
                                  )}
                                >
                                  {" "}
                                  {[
                                    ...(item?.responsible_person_list ??
                                      item?.responsible_person),
                                  ]?.map((data, idx) => (
                                    <div>
                                      <img
                                        src={data.person_pic}
                                        className="clide-v2-profile-img"
                                        alt="user-avatar"
                                      />{" "}
                                      <span className="w-max">
                                        {data.person_name}
                                      </span>{" "}
                                    </div>
                                  ))}{" "}
                                </Scrollbars>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </Scrollbars>
      )}
    </div>
  );
};

export default DetailStep3;
