import React from 'react'
import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';
const StyledNode = styled.div`
  padding: 5px 18px;
  background: #FFFFFF;
  color: #000000;
  display: inline-block;
  font-weight: 500;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25), inset 1px 1px 1px rgba(0, 0, 0, 0.25);
`;

const __renderTreeTemplate = (user) => {
    return <div className="feild-group common-card-border-layout-4 px-2 py-2 rounded-0 shadow-none text-center w-75">
        NA

    </div>
}


export const TreeUiComponent = ({ rootTitle, nodes = [], customTemplate = true, callback }) => {
    return (
        <Tree lineWidth={'2px'}
            lineColor={' #000000'}
            lineHeight={'30px'}
            nodePadding={"20px"}
            lineBorderRadius={'10px'} label={<StyledNode>{rootTitle}</StyledNode>}>
            {nodes?.map((el, idx) => <NodePanel key={idx} item={el} callback={callback} customTemplate={customTemplate} />)}
        </Tree>
    )
}

const NodePanel = ({ item, callback, customTemplate }) => {
    return (<>
        <TreeNode label={<StyledNode>{item.label}</StyledNode>}>
            {item?.children?.map((el, idx) => <>
                <TreeNode key={idx} label={customTemplate ? callback(item, el) : __renderTreeTemplate(el)} />
                {el.children && <NodePanel item={el.children} callback={callback} customTemplate={customTemplate} />}
            </>)}
        </TreeNode>
    </>)
}