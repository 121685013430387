/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import makeAnimated from "react-select/animated";
import Select from "react-select";
//components
import ClideLoader from "app/shared/ui-kit/loader";
//assets
import {
  PlusIconRed,
  RoundSave,
  CrossRound,
  RoundDelete,
  RoundEdit,
} from "assets/Iconsv2/Iconsv2";
import { useTranslation } from "react-i18next";
const animatedComponents = makeAnimated();
const DetailStep1 = ({ state, functionContent, variablesObj }) => {
  const { isApproveEnabled } = variablesObj ?? {};
  const { nmDetail } = state;
  const { t } = useTranslation(['nm', 'util']);
  return (
    <div>
      <div className="row mt-3">
        <div className="col-12 col-md-12 col-lg-12">
          <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-between px-0">
            <div className="clide-v2-title mb-1">
              <label className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                {" "}
                {t('step1IncidentAnalysisQuestion')}
              </label>
            </div>
            <div>
              {state.reportStatus === "progress" && isApproveEnabled ? (
                <button
                  className="clide-v2-icon-btn "
                  onClick={() => {
                    functionContent.toggleAddQuestionTab();
                  }}
                >
                  <PlusIconRed />
                </button>
              ) : null}
            </div>
          </div>

          {nmDetail.nm_reported_detail_incidence_analysis_question?.length ? (
            <div className="border p-2 rounded  w-100">
              <Row>
                <Col md={12}>
                  <Scrollbars
                    style={{ height: "15rem" }}
                    renderView={(props) => (
                      <div
                        style={{
                          paddingRight: "15px",
                          paddingBottom: "1rem",
                          ...props.style,
                          overflowX: "hidden",
                        }}
                      />
                    )}
                  >
                    {nmDetail.nm_reported_detail_incidence_analysis_question?.map(
                      (el, idx) => (
                        <div className="row">
                          <div className="col-md-10 col-lg-10 col-12  px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 w-space-nobreak">
                              {t('q')} {idx + 1} - {el?.question}
                              </label>
                            </div>
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 fw-normal w-space-nobreak">
                                {t('ans')} - {el?.response}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-2 col-lg-2 col-12  px-0 d-flex justify-content-end">
                            {state?.reportStatus === "progress" &&
                            isApproveEnabled ? (
                              <button
                                className="clide-v2-icon-btn my-2"
                                onClick={() =>
                                  functionContent.setState({
                                    deleteQuestionId: el?.question_id,
                                  })
                                }
                              >
                                <RoundDelete />
                              </button>
                            ) : null}
                          </div>
                        </div>
                      )
                    )}
                  </Scrollbars>
                </Col>
              </Row>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DetailStep1;
