/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  GOOD_PRACTICES,
  IRA,
  NM,
  SAFETY_THOUGHTS,
  TOP_PERFORMER,
  TRAINING,
  UAUC,
} from "app/shared/constants/ModuleNames";
import { Modal, Nav, Tab } from "react-bootstrap";
import _ from "lodash"
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import GPReport from "./GPReport";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import TrainingReport from "./TrainingReport";
import Url from "app/shared/constants/Url";
import { checkPermittedRoute } from "app/shared/utility/UtilFunction";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
import axios from "axios";
import "assets/styles/_clide.scss";

//assetsV2
import { IReportCroxIcon, IReportTourIcon } from "assets/Iconsv2/Iconsv2";
import ExternalNmReport from "./ExternalNmReport";
import ExternalGPReport from "./ExternalGPReport";
const qs = require("qs");
const mapStateToProps = (state) => {
  const data = [...state.permitModule.permittedModuleList];
  return {
    permittedModuleList: data,
  };
};

class ReportModal extends Component {
  constructor(props) {
    super(props);
    this.traningRef = React.createRef();
    this.gpRef = React.createRef();
    this.otherRef = React.createRef();
    this.state = {
      encyptedKey: decodeURIComponent(this.props?.match.params.encyptedKey),
      show: false,
      dataLoading: true,
      apiLoading: false,
      reportFeeds: [],
      reportFeedsLoading: true,
      isV2Enabled: this.props?.isVerisonV2 ?? false,
      activeModule: this.props?.activeModule ?? {},
      currentTab: "soi",
      error: false,
      hasMore: true,
      isLoading: false,
      flag: "open",
      graphIndex: null,
      otherDashboardDetails: [],
      sourceList: [],
      otherDetailsLoaded: false,
      rightSideBarType: "performers",
      safetyImages: [],
      safetyThought: "",
      maxFilesAttachLimit: 5,
      safetyThoughtSubmited: false,
      showDeletePopup: false,
      selectedId: null,
      days_since_last_incidence: "",
      reportingSOIPopup: false,
      startDate: new Date(),
      formParameter: [],
      serviceList: [],
      soiFormHidingField: {},
      nmFormHidingField: {},
      iraFormHidingField: {},
      logoUrl: "",
      tagLine: {},
    };
  }

  _getLogo = async () => {
    const requestData = {
      status: 1,
      link: Url.clientURL,
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.compnayLogo,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ logoUrl: response.data.data.logo });
          } 

        })
        .catch((error) => console.log(error));
    });
  };

  componentWillMount = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.loginPageContent);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ contentData: response.data.data.title });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  closeModal = () => {
    this.props.handleClose();
  };

  componentDidMount = async () => {
    await this._validateQrUrl();
    this._getLogo();
    this.componentWillMount();
    //await this._fetchServiceList();
    if (this.props?.isVerisonV2) {
      this.setState({ currentTab: this.props?.activeModule?.key })
    }
  };

  _validateQrUrl = async () => {
    this._setDataLoading(true);
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      url: this.state.encyptedKey,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(Url.adminServices.projects.validateQr, qs.stringify(requestData), {
        headers: ContentType,
      })
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.status != 200) {
            this.props.history.push('/');
            toast.error("No record exists for the given QR code", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false
            });
          }
          await this.setState({
            projectId: response.data.projectId,
            serviceId: response.data.serviceId,
            isVerified: true,
          });
          // await this.setState({ sourceList: response.data.data });

          await this._fetchFormParamList();
          await this._fetchSourceList();
        }
      })
      .catch((error) => {
        this._setDataLoading(false);
      });
  };

  _fetchFormParamList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.externalFormParameter
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let {
                formParameter,
                soiFormHidingField,
                nmFormHidingField,
                iraFormHidingField,
              } = prevState;
              formParameter = response.data.data;
              formParameter.soiSetting.forEach((element, index) => {
                soiFormHidingField[index] = element.parameter;
              });

              formParameter.nmSetting.forEach((element, index) => {
                nmFormHidingField[index] = element.parameter;
              });

              formParameter.iraSetting.forEach((element, index) => {
                iraFormHidingField[index] = element.parameter;
              });

              return {
                formParameter,
                soiFormHidingField,
                nmFormHidingField,
                iraFormHidingField,
              };
            });
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };
  _fetchSourceList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.externalSourceList);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ sourceList: response.data.data });
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _handleTabClick = async (selectedKey = "soi") => {
    console.log(selectedKey);
  };

  _handlePermittedRoute = (checkRoute) =>
    checkPermittedRoute(this.props.permittedModuleList, checkRoute);
  __buildTabs = () => {
    return [
      {
        tabName: "UA/UC",
        key: "soi",
        keyClass: "ir1",
      },
      {
        tabName: "NM",
        key: "nm",
        keyClass: "ir2",
      },
      {
        tabName: "IRA",
        key: "ira",
        keyClass: "ir3",
      },
      {
        tabName: "Good Act",
        key: "gp",
        keyClass: "ir4",
      },
    ];
  };
  __gpEmitter = {};
  __trainingEmitter = {};
  __nmEmitter = {};
  __evenetEmitter = (tab) => {
    if (tab === "gp") {
      this.__gpEmitter();
    } else if (tab === "trn") {
      this.__trainingEmitter();
    } else {
      this.__nmEmitter();
    }
  };

  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);
  render() {
    return (
      <div>
        <Scrollbars
          // className="externalReport-bg"
          style={{ height: "100vh", background: "white" }}
          autoHide={false}
        >
          <div className="align-items-center d-flex justify-content-between">
            {/* {this.state.isV2Enabled ? ( */}
            <div style={{ background: "#ff6347", padding: 10, width: '100%' }}>
                <div className="d-flex align-items-center justify-content-center pb-2 my-2" >
                  <img className="brand-logo1"
                    src={this.state.logoUrl}
                    alt="logo"
                  />
                  <h5 
                  style={{
                      color:"#fff",
                      marginBottom:"0",
                    }}>{this.state.contentData}</h5>
                </div>
              <div>
              <HorizontalNavTab
                tabs={this.__buildTabs()}
                activeItem={this.state.currentTab}
                handleChange={(key) => this.setState({ currentTab: key })}
              />
              </div>
            </div>
          </div>
          <Modal.Body className="px-4 pt-4">
            {/* Data Loading  */}
            <div
              className="loader"
              style={{ display: this.state.dataLoading ? "block" : "none" }}
            >
              <LoadingImage />
            </div>

            {this.state.currentTab === "soi" && (
              <ExternalNmReport
                hideFieldList={this.state.soiFormHidingField}
                reportType="soi"
                close={this.closeModal}
                parentStateSetter={this.setState}
                setClick={(click) => (this.__nmEmitter = click)}
                sourceList={this.state.sourceList}
                serviceId={this.state.serviceId}
                projectId={this.state.projectId}
              />
            )}

            {this.state.currentTab === "nm" && (
              <ExternalNmReport
                hideFieldList={this.state.nmFormHidingField}
                reportType="nm"
                close={this.closeModal}
                parentStateSetter={this.setState}
                setClick={(click) => (this.__nmEmitter = click)}
                serviceId={this.state.serviceId}
                projectId={this.state.projectId}
              />
            )}

            {this.state.currentTab === "ira" && (
              <>
                <ExternalNmReport
                  hideFieldList={this.state.iraFormHidingField}
                  reportType="ira"
                  close={this.closeModal}
                  parentStateSetter={this.setState}
                  setClick={(click) => (this.__nmEmitter = click)}
                  serviceId={this.state.serviceId}
                  projectId={this.state.projectId}
                />
              </>
            )}
            {this.state.currentTab === "gp" && (
              <ExternalGPReport
                hideFieldList={this.state.iraFormHidingField}
                reportType="gp"
                close={this.closeModal}
                parentStateSetter={this.setState}
                setClick={(click) => (this.__gpEmitter = click)}
                serviceId={this.state.serviceId}
                projectId={this.state.projectId}
              />
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center p-3">
            <div className="d-flex-center gap-5">
              <button onClick={this.closeModal} className="border btn mx-2 px-3 py-1 clide-v2-fs-3">Cancel</button>
              <button

                onClick={() =>
                  this.debounceFunc(() => {
                    this.__evenetEmitter(this.state.currentTab)
                  })
                }
                disabled={this.state?.apiLoading}
                className="border clide-v2-common-orange-bg-1 clide-v2-common-white-1 clide-v2-fs-3 font-weight-500 px-3 py-1 rounded"
              >
                {this.state?.apiLoading ? "Loading..." : "Report"}
              </button>
            </div>
          </Modal.Footer>
        </Scrollbars>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(withRouter(ReportModal));

export const HorizontalNavTab = ({
  tabs = [],
  activeItem,
  handleChange,
  classes = "",
  extraValue = {}
}) => {
  const items = tabs.filter(({ skip = false, keyClass = "", ...rest }) => {
    if (skip === false) {
      return rest;
    }
  });
  return (
    <ul className={`horizontal-nav-tab-wrapper ${classes}`}>
      {items.map((el) => (
        <li
          key={el.key}
          onClick={() => handleChange(el.key)}
          className={`tab-item d-flex align-items-center ${el?.keyClass} ${activeItem === el?.key ? "tab-active" : ""
            }`}
        >
          <a id={el.key}>{el?.tabName}</a>
          <div className="mx-1">
            {el?.activeIcon !== undefined && activeItem === el?.key
              ? el?.activeIcon
              : el?.icon}
          </div>
        </li>
      ))}
    </ul>
  );
};
