import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ServiceList = ({ optionList, onChangeHandle, value, hasError = false,
  errorMessage = "", isRquired = true ,customTitle = <></>,   titleview = true,
  selectClass = "slds-select"
}) => {
  useEffect(() => { }, [optionList]);
  const { t } = useTranslation(['workpermit', 'util']);

  return (
    <div className={`slds-form-element ${hasError ? "slds-has-error" : ""} select-remove-icon mb-2`}>
   {titleview &&  <label className="slds-form-element__label ">{t('service')}{isRquired  ? <abbr className="slds-required" title="required">* </abbr> : null }</label>}
     {customTitle}
      <div className="slds-form-element__control">
        <div className="slds-select_container">
          <select
            className={selectClass}
            onChange={(event) => onChangeHandle(event.target.value)}
            value={value}
          >
            {optionList.length ? (
              <>
                <option value="">{t('select')} {t('service')}</option>
                {optionList.map((item, index) => (
                  <option key={`service-option-${index}`} value={item.service_id}>
                    {item.service_name}
                  </option>
                ))}
              </>
            ) : (
              <option>{t('noServiceAvailable')}</option>
            )}
          </select>
        </div>
        {hasError ? <div class="slds-form-element__help" id="error-01">{errorMessage !== "" ? errorMessage : t('mandatory')}</div> : null}
      </div>
    </div >
  );
};

ServiceList.defaultProps = {
  optionList: [],
  value: "",
};

export default ServiceList;
