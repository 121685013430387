import ReactDOMServer from "react-dom/server";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PageFive = (data) => {
  const _getGraph = () => {
    try {
      let {
        nm_uc_number,
        nm_uc_color,
        nm_ua_number,
        nm_ua_color,
      } = data.activity_analysis;

      Highcharts.chart("container", {
        chart: {
          type: "pie",
          height: "60%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
          align: "right",
          verticalAlign: "middle",
          symbolRadius: 0,
          symbolHeight: 12,
          symbolWidth: 12,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          useHTML: true,
          text: "",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            data: [
              { name: "UC", color: nm_uc_color, y: nm_uc_number },
              { name: "UA", color: nm_ua_color, y: nm_ua_number },
            ],
            size: "100%",
            innerSize: "84%",
          },
        ],
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const _getGraphIRA = () => {
    try {
      let {
        fa_number,
        lti_number,
        mtc_number,
        rta_number,
      } = data.activity_analysis;

      Highcharts.chart("ira-container", {
        chart: {
          type: "pie",
          height: "60%",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
          align: "right",
          verticalAlign: "middle",
          symbolRadius: 0,
          symbolHeight: 12,
          symbolWidth: 12,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          useHTML: true,
          text: "",
          verticalAlign: "middle",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        colors: ["#f00", "#0f0", "#00f", "#ff0"],
        series: [
          {
            data: [
              { name: "FA", color: "#f00", y: fa_number },
              { name: "MTC", color: "#0f0", y: mtc_number },
              { name: "LTI", color: "#00f", y: lti_number },
              { name: "RTA", color: "#ff0", y: rta_number },
            ],
            size: "100%",
            innerSize: "84%",
          },
        ],
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  _getGraph();
  _getGraphIRA();
  let tempEvidence = "<tr>";

  try {
    if (data?.team?.length > 0) {
      let temp = [...data.team];
      temp.forEach((item, index) => {
        if (index + 1 !== 1 && (index + 1) % 4 === 1) {
          tempEvidence += "<tr>";
        }
        tempEvidence += `<td style="text-align: center;" valign="top">
            <div><img src="${item.user_pic}" style="width:60px; height:60px; border-radius: 30px;"></div>
            <h5 style="margin: 2px 0 0; font-size: 8px; font-weight: normal;">${item.user_name}</h5>
            <h6 style="margin: 2px 0 0; font-size: 7px; font-weight: normal; color: #888">${item.user_role}</h6>
            <p style="color: #f5b134; font-weight: normal; margin: 2px 0 0; font-size: 8px;">${item.status}</p>
          </td>`;

        if ((index + 1) % 4 === 0) {
          tempEvidence += "</tr>";
        }
      });
    }

    return `<div style="margin: 20px auto; width: 800px; height: 90vh; border: 3px double #797878; position: relative; box-sizing: border-box;">
      <div style="height: calc(90vh - 120px);">
        <table cellpadding="5" cellspacing="2" width="100%" style="font-family: arial; font-size: 8px;">
          <tr>
            <td valign="top" class="inner-table">
              <h4 style="font-size: 20px; margin-bottom: 3px; font-weight: bold; text-align: center;">Investigation Team</h4>
              <hr />
              <table border="0" width="100%" cellpadding="3" cellspacing="0">
                ${tempEvidence}
              </table>
            </td>
          </tr>
          <tr>
            <td valign="top" class="inner-table">
              <span style="font-size: 14px; margin-bottom: 1px; font-weight: bold;">Activity Analysis</span> : <span style="font-size: 12px; margin-bottom: 3px;">${data.activity || "N/A"}</span>
              <hr />
              <table width="100%" cellspacing="5">
                <tr>
                  <td width="50%">
                    <h4 style="margin: 0 0 3px; font-weight: normal; font-size: 12px;">NM - UA Vs UC Analysis</h4>
                    <div style="border: 1px solid #ddd; text-align: center; height: 180px;">
                      <div id="container" />
                    </div>
                  </td>
                  <td width="50%">
                    <h4 style="margin: 0 0 3px; font-weight: normal; font-size: 11px;">IRA Analysis</h4>
                    <div style="border: 1px solid #ddd; text-align: center; height: 180px;">
                      <div id="ira-container" />
                    </div>
                  </td>
                </tr>
              </table>
              <span class="page-number" />
            </td>
          </tr>
        </table>
      </div>
    </div>`;
  } catch (error) {
    console.log(error);
  }
};

export default PageFive;

