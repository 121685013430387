import { useSelector } from "react-redux";

const usePermittedRoute = () => {
  const selector = useSelector(
    (state) => state.permitModule.permittedModuleList
  );

  const checkPermittedRoute = ( checkingPath = "" ) => {
    const searchModule = checkingPath.toLowerCase();
    const find = selector.find(
      (listItem) => listItem.module_name.trim().toLowerCase() == searchModule
    );
    return find == undefined ? false : true;
  }
  return [checkPermittedRoute];
};

export default usePermittedRoute;
