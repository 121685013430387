const ProbableRootCause = (data) => {
  let tempHtml = "";
  let tableHtml = "";

  try {
    let CFData = data.contributing_factor;
    // main category
    if (CFData.root_cause_type.length) {

      CFData.root_cause_type.forEach((mainItem, index) => {
        tempHtml += `<p> ${++index} ${mainItem.root_cause_type}</p>`;

        // Sub category
      });
    }
  } catch (error) { }

  try {
    let CFData = data.contributing_factor;
    // main category
    if (CFData.root_cause_type.length) {

      CFData.root_cause_type.forEach((mainItem, index) => {
        if (mainItem.root_cause.length) {
          mainItem.root_cause.forEach((item, index2) => {
            tableHtml += `<p> ${++index2} ${item.root_cause}</p>`;
          })
        }
      });
    }
  } catch (error) { }

  return `<table cellpadding="15" cellspacing="0" width="800" style="margin: 0 auto 10px auto;font-family: arial;height: 100vh;font-size: 14px;border: 3px double #797878;position:relative" class="full-table">
  <tr>
    <td valign="top" class="inner-table">
      <h4 style="font-size: 15px;margin-bottom: 5px;font-weight:bold">Root Cause Type</h4>
      <hr />
      ${tempHtml}
      <h4 style="font-size: 15px;margin-bottom: 5px;font-weight:bold">Root Causes</h4>
      <hr />
      ${tableHtml}
      <span class="page-number" />
    </td>
  </tr>
</table>`;
};

export default ProbableRootCause;
