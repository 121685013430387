import React, { Component } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import { File, Search, Info } from "react-feather";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import TrackerList from "./../Tracker/TrackerList";
import CapaList from "./../Tracker/CapaList";

import IraList from "./../IraList/IraList";
import ArchiveList from "./../Archive/ArchiveList";
import Url from "app/shared/constants/Url";
import axios from "axios";
import Workbook from "react-excel-workbook";
import { CHANGE_TAB } from "app/redux/constant/ActionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import InfoComponent from "app/shared/utility/InfoComponent";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LoadingImage from "app/shared/components/Loader/LoadingImage";

const qs = require("qs");

const mapStateToProps = (state) => {
  const data = { ...state.ira.ira };
  return {
    service_id: data.service_id,
    project_id: data.project_id,
    service_name: data.service_name,
    project_name: data.project_name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTab: (tabName) => {
      dispatch({ type: CHANGE_TAB, payload: tabName });
    },
  };
};

class IraDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 'dashboard',
      currentPage: 1,
      currentPagepen: 1,
      currentPagepro: 1,
      currentPagecom: 1,
      trackLi: [],
      pending: [],
      progress: [],
      completed: [],
      pending2: [],
      progress2: [],
      completed2: [],
      dataLoading: false,
      currentPage: 1,
      iraHeaderCount: [],
      progress_list: [],
      completed_list: [],
      progress_list2: [],
      completed_list2: [],
      pendingOrignalList: [],
      progressOrignalList: [],
      completedOrignalList: [],
      completed_number: "",
      inprogress_number: "",
      searchListt: "",
      searchList: "",
      searchList2: "",
      searchBtnClicked: false,
      searchBtnClicked2: false,
      searchCompleted: [],
      trackerFilterPopup: false,
      startDate: "Start Date",
      endDate: "End Date",
      overdue: 0,
      service: [],
      serviceSelect: "",
      project: [],
      projectSelect: "",
      R_person: [],
      extremeSelect: 0,
      extremeValue: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      all: 0,
      allValue: [],
      allS: [],
      severity: [],
      personSelect: [],
      CategoryFilter: [],
      trackerexport: [],
      completedno: "",
      progressno: "",
      iraCompletedexport: [],
      iraProgressexport: [],
      iraPendingexport: [],
      isFirstFetching: false,
      capaInprogressList: [],
      capaCompletedList: [],
      pageNumber: 1,
      capaOrignalInprogressList: [],
      capaOrignalCompletedList: [],

      capaCompletedCount: 0,
      capaCompletedCount: 0,
      totalCapaCount: 0,

      pendingFilterCount: 0,
      inprogressFilterCount: 0,
      completeFilterCount: 0,

      iraProgressFilterPageNo: 1,
      iraCompleteFilterPageNo: 1,
      iraPendingFilterPageNo: 1,
    };

    this._getiraHeaderCount();
    this.iraTrackerList(1, "ALL");
    // this.iraTrackerCompletedList(1, "ALL");
    this._getiraList(1, "ALL");
    this._getiraCompletedList(1, "ALL");
    this._getiraProgressList(1, "ALL");
    this._getTrackerListExport();
    this._getIRAListExport();

    this.child = React.createRef();
    this.activeSP = this.activeSP.bind(this);
  }

  componentDidMount() {
    this._handleInputChange2 = async (event) => {
      const val = event.target.value;
      if (!val || val === "") {
        await this.iraTrackerListforSearch();
      }
      await this.setState((prevState) => {
        let {
          searchList2,
          searchBtnClicked2,
          progress_list2,
          progress_list,
        } = prevState;
        searchList2 = val;
        searchBtnClicked2 = true;
        progress_list = progress_list2.filter((data) => {
          if (data.category !== undefined) {
            if (
              data.category.toLowerCase().includes(searchList2.toLowerCase())
            ) {
              return data;
            } else if (
              data.activity.toLowerCase().includes(searchList2.toLowerCase())
            ) {
              return data;
            } else if (
              data.service.toLowerCase().includes(searchList2.toLowerCase())
            ) {
              return data;
            } else if (
              data.project.toLowerCase().includes(searchList2.toLowerCase())
            ) {
              return data;
            } else if (
              data.recommendedby
                .toLowerCase()
                .includes(searchList2.toLowerCase())
            ) {
              return data;
            }
          }
        });
        return { searchList2, searchBtnClicked2, progress_list };
      });
    };

    this._handleInputChange3 = async (event) => {
      const val = event.target.value;

      if (!val || val === "") {
        await this.iraTrackerListforSearch();
      }
      await this.setState((prevState) => {
        let {
          completed_list2,
          completed_list,
          searchList3,
          searchBtnClicked3,
        } = prevState;
        searchList3 = val;
        searchBtnClicked3 = true;
        completed_list = completed_list2.filter((data) => {
          if (data.category !== undefined) {
            if (
              data.category.toLowerCase().includes(searchList3.toLowerCase())
            ) {
              return data;
            } else if (
              data.activity.toLowerCase().includes(searchList3.toLowerCase())
            ) {
              return data;
            } else if (
              data.service.toLowerCase().includes(searchList3.toLowerCase())
            ) {
              return data;
            } else if (
              data.project.toLowerCase().includes(searchList3.toLowerCase())
            ) {
              return data;
            } else if (
              data.recommendedby
                .toLowerCase()
                .includes(searchList3.toLowerCase())
            ) {
              return data;
            }
          }
        });
        return { searchList3, searchBtnClicked3, completed_list };
      });
    };
  }

  _textIncludeCheck = (sourceText = "", searchText = "") => {
    return sourceText.toLowerCase().includes(searchText);
  };

  _handleInputChange = async (event) => {
    const val = event.target.value;

    if (val.trim() == "") {
      await this.setState({
        searchListt: val,
        searchBtnClicked: true,
        capaInprogressList: this.state.capaOrignalInprogressList,
        capaCompletedList: this.state.capaOrignalCompletedList,
      });
    } else {
      await this.setState((prevState) => {
        let {
          searchListt,
          searchBtnClicked,
          capaInprogressList,
          capaOrignalInprogressList,
          capaOrignalCompletedList,
          capaCompletedList,
        } = prevState;
        searchListt = val;
        searchBtnClicked = true;
        let searchTerm = searchListt.toLowerCase();

        capaInprogressList = capaOrignalInprogressList.filter((data) => {
          if (this._textIncludeCheck(data.activity, searchTerm)) {
            return data;
          } else if (this._textIncludeCheck(data.service, searchTerm)) {
            return data;
          } else if (this._textIncludeCheck(data.project, searchTerm)) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.recommendedby, searchTerm)
            )
          ) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.category, searchTerm)
            )
          ) {
            return data;
          } else if (this._textIncludeCheck(data.incident_type, searchTerm)) {
            return data;
          }
        });

        capaCompletedList = capaOrignalCompletedList.filter((data) => {
          if (this._textIncludeCheck(data.activity, searchTerm)) {
            return data;
          } else if (this._textIncludeCheck(data.service, searchTerm)) {
            return data;
          } else if (this._textIncludeCheck(data.project, searchTerm)) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.recommendedby, searchTerm)
            )
          ) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.category, searchTerm)
            )
          ) {
            return data;
          } else if (this._textIncludeCheck(data.incident_type, searchTerm)) {
            return data;
          }
        });

        return {
          searchListt,
          searchBtnClicked,
          capaInprogressList,
          capaCompletedList,
          capaOrignalInprogressList,
          capaOrignalCompletedList,
        };
      });
    }
  };

  activeSP = (eventKey) => {
    this.setState({ active: eventKey });
  };
  // start tracker

  _getiraHeaderCount = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.iraModule.iraheaderCounts,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          this.setState({ iraHeaderCount: response.data.data });
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.project_id !== prevState.project_id ||
      nextProps.service_id !== prevState.service_id
    ) {
      return {
        service_id: nextProps.service_id,
        project_id: nextProps.project_id,
      };
    }
    return null;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.service_id !== prevProps.service_id ||
      this.props.project_id !== prevProps.project_id
    ) {
      if (this.state.isFirstFetching) {
        this.setState(
          {
            dataLoading: true,
            pending: [],
            progress: [],
            completed: [],
            progress_list: [],
            completed_list: [],
            capaInprogressList:[],
            capaCompletedList:[],
          },
          async () => {
            await this.iraTrackerList(1);
            await this._getiraList(1);
            await this._getiraProgressList(1);
            await this._getiraCompletedList(1);
          }
        );
      } else {
        await this.setState({ isFirstFetching: true });
      }
    }
  }

  // dataRefresh = async () => {
  //   this.setState({
  //     dataLoading: true,
  //     pending: [],
  //     progress: [],
  //     completed: [],
  //     progress_list: [],
  //     completed_list: [],
  //   });
  //   await this.iraTrackerList(1);
  //   await this.iraTrackerCompletedList(1);
  //   await this._getiraList(1);
  //   await this._getiraProgressList(1);
  //   await this._getiraCompletedList(1);
  // };

  _handleFilterReset = async (type = "pending") => {
    if (type == "pending") {
      this._handlePendingFilterReset();
    } else if (type == "progress") {
      this._handleProgressFilterReset();
    } else if (type == "completed") {
      this._handleCompletedFilterReset();
    }
  };

  _handlePendingFilterReset = async () => {
    try {
      await this.setState((prevState) => {
        let { pending, pendingOrignalList } = prevState;
        pending = [...pendingOrignalList];
        return {
          pending,
        };
      });
    } catch (error) { }
  };

  _handleProgressFilterReset = async () => {
    try {
      await this.setState((prevState) => {
        let { progress, progress_list, progressOrignalList } = prevState;
        progress = [...progressOrignalList];
        progress_list = [...progressOrignalList];
        return {
          progress,
          progress_list,
        };
      });
      // await this._getiraProgressList(1, "ALL");
    } catch (error) { }
  };

  _handleCompletedFilterReset = async () => {
    try {
      await this.setState((prevState) => {
        let { completed, completedOrignalList } = prevState;

        completed = [...completedOrignalList];

        return {
          completed,
        };
      });
      // await this._getiraCompletedList(1, "ALL");
    } catch (error) { }
  };

  iraTrackerList = async (cno, type = "PROJECT") => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let pageNo = Math.ceil(cno) || 1;
    let data = new FormData();

    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("page_no", Math.ceil(pageNo));
    data.append("auth_key", token);
    data.append("user_id", userId);

    let URL = Url.iraModule.iratrackerlist;

    let headerType = "multipart/form-data";

    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then(async (response) => {
        if (response.data.status === 200) {
          // tracker data
          const {
            completed_tracker_list,
            total_cardCounts,
            inprogress_tracker_list,
            totalCompleted_cardCounts,
            totalInprogress_cardCounts,
          } = response?.data?.data;


          await this.setState({ totalCapaCount: total_cardCounts,pageNumber:Math.ceil(cno) });

          if (this.state.capaInprogressList.length == 0) {
            this.setState({
              capaProgressCount: totalInprogress_cardCounts,
              capaCompletedCount:totalCompleted_cardCounts,
              capaInprogressList: inprogress_tracker_list,
              capaOrignalInprogressList: inprogress_tracker_list,
              capaCompletedList: completed_tracker_list,
              capaOrignalCompletedList: completed_tracker_list,
            });
          } else {
            this.setState({
              capaInprogressList: [
                ...this.state.capaInprogressList,
                ...inprogress_tracker_list,
              ],
              capaOrignalInprogressList: [
                ...this.state.capaOrignalInprogressList,
                ...inprogress_tracker_list,
              ],
              capaCompletedList: [
                ...this.state.capaCompletedList,
                ...completed_tracker_list,
              ],
              capaOrignalCompletedList: [
                ...this.state.capaOrignalCompletedList,
                ...completed_tracker_list,
              ],
            });
          }

          inprogress_tracker_list.map((data) =>
            this.setState({ R_person: data.tracking_responsible_person })
          );
          this.setState({
            CategoryFilter: inprogress_tracker_list,
            total_number: response.data.data.total_number,
          });
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });

      });
      console.log(pageNo,'pagenumb')
  };

  iraTrackerCompletedList = async (cno, type = "PROJECT") => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    let pageNo = Math.ceil(cno) || 1;
    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("page_no", Math.ceil(pageNo));
    data.append("auth_key", token);
    data.append("user_id", userId);
    let URL = Url.iraModule.iratrackerlist;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          const {
            completed_tracker_list,
            completed_number,
          } = response?.data?.data;

          if (this.state.capaCompletedList.length == 0) {
            // this.setState({ capaCompletedList: [] });
            this.setState({
              capaCompletedCount: completed_number,
              capaCompletedList: completed_tracker_list,
              capaOrignalCompletedList: completed_tracker_list,
            });
          } else {
            this.setState({
              capaCompletedCount: completed_number,
              capaCompletedList: [
                ...this.state.capaCompletedList,
                ...completed_tracker_list,
              ],
              capaOrignalCompletedList: [
                ...this.state.capaOrignalCompletedList,
                ...completed_tracker_list,
              ],
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  iraTrackerListforSearch = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();
    if (this.state.project_id && this.state.project_id.length > 0) {
      this.state.project_id.forEach((item) => {
        data.append("project_id", item);
      });
    }

    data.append("page_no", 1);
    data.append("auth_key", token);
    data.append("user_id", userId);
    let URL = Url.iraModule.iratrackerlist;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            inprogress_number: response.data.data.inprogress_number,
          });
          this.setState({ progress_list: response.data.data.tracker_list });
          this.setState({
            completed_number: response.data.data.completed_number,
          });
          this.setState({ completed_list: response.data.data.tracker_list });
          this.setState({ trackerList: response.data.data.tracker_list });
          response.data.data.tracker_list.map((data) =>
            this.setState({ R_person: data.tracking_responsible_person })
          );
          this.setState({ CategoryFilter: response.data.data.tracker_list });
        } else {
          this.setState({
            isLoading: false,
            moreDataLoading: false,
            tracker_listListRefreshing: false,
            isDataNot: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  nmTrackerListFilterProgress = async (
    severitytc,
    overduetrackerc,
    serviceSelecttrackerc,
    projectSelecttrackerc,
    categorySelecttc,
    startDatetc,
    endDatetc,
    personSelecttc,
    statusChecktc
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.iraModule.trackerfilterira,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            page_no: this.state.currentPage,
            severity: severitytc,
            overdue: overduetrackerc,
            service_id: serviceSelecttrackerc,
            project_id: projectSelecttrackerc,
            category: categorySelecttc,
            start_date: new Date(startDatetc).toISOString().slice(0, 10),
            end_date: new Date(endDatetc).toISOString().slice(0, 10),
            responsible_person_id: personSelecttc,
            status: statusChecktc,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            iratrackListRefreshing: false,
            iratrackListLoading: false,
            totalCount: this.state.iraHeaderCount.tracker_number,
            lastPage: Math.ceil(this.state.iraHeaderCount.tracker_number / 10),
            hasMore:
              this.state.currentPage <=
              Math.ceil(this.state.iraHeaderCount.tracker_number / 10),
            currentPage: this.state.currentPage + 1,
            moreDataLoading: false,
          });

          if (this.state.progress_list.length == 0) {
            this.setState({
              inprogress_number: response.data.data.inprogress_number,
            });
            this.setState({ progress_list: [] });
            this.setState({
              progress_list: response.data.data.tracker_list,
              dataLoading: false,
            });
          } else {
            this.setState({
              inprogress_number: response.data.data.inprogress_number,
            });
            this.setState({
              progress_list: [...response.data.data.tracker_list],
              dataLoading: false,
            });
          }
          this.child.current.resetState();
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  nmTrackerListFilterCompleted = async (
    severitytrackerp,
    overduetrackerp,
    serviceSelecttrackerp,
    projectSelecttrackerp,
    categorySelecttp,
    startDatetp,
    endDatetp,
    personSelecttp,
    statusChecktp
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.iraModule.trackerfilterira,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            page_no: this.state.currentPage,
            severity: severitytrackerp,
            overdue: overduetrackerp,
            service_id: serviceSelecttrackerp,
            project_id: projectSelecttrackerp,
            category: categorySelecttp,
            start_date: new Date(startDatetp).toISOString().slice(0, 10),
            end_date: new Date(endDatetp).toISOString().slice(0, 10),
            responsible_person_id: personSelecttp,
            status: statusChecktp,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            iratrackListRefreshing: false,
            iratrackListLoading: false,
            totalCount: this.state.iraHeaderCount.tracker_number,
            lastPage: Math.ceil(this.state.iraHeaderCount.tracker_number / 10),
            hasMore:
              this.state.currentPage <=
              Math.ceil(this.state.iraHeaderCount.tracker_number / 10),
            currentPage: this.state.currentPage + 1,
            moreDataLoading: false,
          });

          if (this.state.completed_list.length == 0) {
            this.setState({
              completed_number: response.data.data.completed_number,
            });
            this.setState({ completed_list: [] });
            this.setState({
              completed_list: response.data.data.tracker_list,
              dataLoading: false,
            });
          } else {
            this.setState({
              completed_number: response.data.data.completed_number,
            });
            this.setState({
              completed_list: [...response.data.data.tracker_list],
              dataLoading: false,
            });
          }
          this.child.current.resetState();
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _getTrackerListExport = async () => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.iraModule.iratrackerlistexport,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            trackerexport: response.data.data.tracker_list,
            completedno: response.data.data.completed_number,
            progressno: response.data.data.inprogress_number,
            dataLoading: false,
          });
        }
      })
      .catch((error) => { });
  };

  activeSP = (eventKey) => {
    this.setState({ active: eventKey });
  };
  // end tracker
  // start ira list
  _getiraList = async (cno, type = "PROJECT") => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    let pageNo = Math.ceil(cno) || 1;
    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("page_no", Math.ceil(pageNo));
    data.append("auth_key", token);
    data.append("user_id", userId);
    let URL = Url.iraModule.irakanbanview;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.pending.length == 0) {
            this.setState({
              pending: response.data.data.pendingira.ira_basic_info,
              pending2: response.data.data.pendingira.ira_basic_info,
              pendingOrignalList: response.data.data.pendingira.ira_basic_info,
            });
          } else {
            this.setState({
              pending: [
                ...this.state.pending,
                ...response.data.data.pendingira.ira_basic_info,
              ],
            });
            this.setState({
              pending2: [
                ...this.state.pending2,
                ...response.data.data.pendingira.ira_basic_info,
              ],
            });
            this.setState({
              pendingOrignalList: [
                ...this.state.pendingOrignalList,
                ...response.data.data.pendingira.ira_basic_info,
              ],
            });
          }
          this.setState({ totalcountira: response.data.data });
        }
      })
      .catch((error) => { });
  };

  _getiraProgressList = async (cno, type = "PROJECT") => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    let pageNo = Math.ceil(cno) || 1;
    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("page_no", Math.ceil(pageNo));
    data.append("auth_key", token);
    data.append("user_id", userId);
    let URL = Url.iraModule.irakanbanview;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.progress.length == 0) {
            this.setState({
              progress: response.data.data.inprogressira.ira_basic_info,
              progress2: response.data.data.inprogressira.ira_basic_info,
              dataLoading: false,
              progressOrignalList:
                response.data.data.inprogressira.ira_basic_info,
            });
          } else {
            this.setState({
              progress: [
                ...this.state.progress,
                ...response.data.data.inprogressira.ira_basic_info,
                
              ],
              dataLoading: false,
            });
            this.setState({
              progress2: [
                ...this.state.progress2,
                ...response.data.data.inprogressira.ira_basic_info,
              ],
            });
            this.setState({
              progressOrignalList: [
                ...this.state.progressOrignalList,
                ...response.data.data.inprogressira.ira_basic_info,
              ],
            });
          }
        }
      })
      .catch((error) => { });
      console.log(pageNo,'progress')
  };

  _getiraCompletedList = async (cno, type = "PROJECT") => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    let pageNo = Math.ceil(cno) || 1;
    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("page_no", Math.ceil(pageNo));
    data.append("auth_key", token);
    data.append("user_id", userId);
    let URL = Url.iraModule.irakanbanview;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.completed.length == 0) {
            this.setState({
              completed: response.data.data.completedira.ira_basic_info,
              dataLoading: false,
              completed2: response.data.data.completedira.ira_basic_info,
              completedOrignalList:
                response.data.data.completedira.ira_basic_info,
            });
          } else {
            this.setState({
              completed: [
                ...this.state.completed,
                ...response.data.data.completedira.ira_basic_info,
              ],
              dataLoading: false,
            });
            this.setState({
              completed2: [
                ...this.state.completed2,
                ...response.data.data.completedira.ira_basic_info,
              ],
            });
            this.setState({
              completedOrignalList: [
                ...this.state.completedOrignalList,
                ...response.data.data.completedira.ira_basic_info,
              ],
            });
          }
        }
      })
      .catch((error) => { });
  };

  setDataLoading = async (flag) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = flag;
      return { dataLoading };
    });
  };

  iraPendingListFilter = async (
    severity,
    serviceSelect,
    projectSelect,
    activitySelect,
    departmentSelect,
    startDate,
    endDate,
    pageNo
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    if (pageNo) {
      await this.setState({
        iraPendingFilterPageNo: pageNo,
        pending: [],
        pendingFilterCount: 0,
      });
    }

    // this.setDataLoading(true);

    await axios
      .post(
        Url.iraModule.iralistfilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            // page_no: 1,
            // page_no: this.state.iraPendingFilterPageNo,
            page_no: pageNo ? pageNo : this.state.iraPendingFilterPageNo,
            incidentType: severity,
            service_id: serviceSelect,
            project_id: projectSelect,
            activity: activitySelect,
            department: departmentSelect,
            start_date: startDate,
            end_date: endDate,
            status: 0,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        this.setDataLoading(false);
        if (response.data.status === 200) {
          this.setState({
            nmtrackListRefreshing: false,
            nmtrackListLoading: false,
            totalCount: response.data.data.list_ira.length,
            lastPage: Math.ceil(response.data.data.list_ira.length / 10),
            hasMore:
              this.state.currentPagepen <=
              Math.ceil(response.data.data.list_ira.length / 10),
            currentPagepen: this.state.currentPagepen + 1,
            moreDataLoading: false,

            iraPendingFilterPageNo: this.state.iraPendingFilterPageNo + 1,

          });
          this.setState({
            // pending: response.data.data.list_ira,

            pending: (this.state.iraPendingFilterPageNo > 1) ? [...this.state.pending, ...response.data.data.list_ira] : response.data.data.list_ira,
            pendingFilterCount: this.state.pendingFilterCount + response.data.count,
            dataLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setDataLoading(false);
      });
  };

  iraProgressListFilter = async (
    severity,
    serviceSelect,
    projectSelect,
    activitySelect,
    departmentSelect,
    startDate,
    endDate,
    overdue,
    pageNo
  ) => {
    this.setDataLoading(true);

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    if (pageNo) {
      await this.setState({
        iraProgressFilterPageNo: pageNo,
        progress: [],
        inprogressFilterCount: 0,
      })
    }

    await axios
      .post(
        Url.iraModule.iralistfilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            // page_no: 1,
            page_no: pageNo ? pageNo : this.state.iraProgressFilterPageNo,
            incidentType: severity,
            service_id: serviceSelect,
            project_id: projectSelect,
            activity: activitySelect,
            department: departmentSelect,
            start_date: startDate,
            end_date: endDate,
            status: 1,
            overdue
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        this.setDataLoading(false);

        if (response.data.status === 200) {
          this.setState({
            lastPage: Math.ceil(response.data.data.list_ira.length / 10),
            hasMore:
              this.state.currentPagepro <=
              Math.ceil(response.data.data.list_ira.length / 10),
            currentPagepro: this.state.currentPagepro + 1,
            moreDataLoading: false,
            inprogressFilterCount: response.data.count,
            iraProgressFilterPageNo: this.state.iraProgressFilterPageNo + 1,
          });

          if (this.state.progress.length == 0) {
            this.setState({
              progress: response.data.data.list_ira,
              dataLoading: false,
            });
          } else {
            this.setState({
              // progress: [...response.data.data.list_ira],

              progress: (this.state.iraProgressFilterPageNo > 1) ? [...this.state.progress, ...response.data.data.list_ira] : response.data.data.list_ira,

              dataLoading: false,
            });
          }
          // this.child.current.resetState();
        }
      })
      .catch((error) => {
        this.setDataLoading(false);
      });
  };

  iraCompletedListFilter = async (
    severity,
    serviceSelect,
    projectSelect,
    activitySelect,
    departmentSelect,
    startDate,
    endDate,
    deviation,
    pageNo
  ) => {
    // this.setDataLoading(true);

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    if (pageNo) {
      await this.setState({
        iraCompleteFilterPageNo: pageNo,
        completed: [],
        completeFilterCount: 0,
      })
    }


    await axios
      .post(
        Url.iraModule.iralistfilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            // page_no: 1,
            page_no: pageNo ? pageNo : this.iraCompleteFilterPageNo,
            incidentType: severity,
            service_id: serviceSelect,
            project_id: projectSelect,
            activity: activitySelect,
            department: departmentSelect,
            start_date: startDate,
            end_date: endDate,
            status: 2,
            deviation
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        this.setDataLoading(false);

        if (response.data.status === 200) {
          this.setState({
            lastPage: Math.ceil(response.data.data.list_ira.length / 10),
            hasMore:
              this.state.currentPagecom <=
              Math.ceil(response.data.data.list_ira.length / 10),
            currentPagecom: this.state.currentPagecom + 1,
            moreDataLoading: false,
            completeFilterCount: response.data.count,
            iraCompleteFilterPageNo: this.state.iraCompleteFilterPageNo + 1,
          });
          if (this.state.completed.length == 0) {
            this.setState({
              completed: response.data.data.list_ira,
              dataLoading: false,
            });
          } else {
            this.setState({
              completed: [...response.data.data.list_ira],
              dataLoading: false,
            });
          }
          // this.child.current.resetState();
        }
      })
      .catch((error) => {
        this.setDataLoading(false);
      });
  };

  _handlePendingSearch = async (event) => {
    const val = event.target.value;

    if (val.trim() === "") {
      await this.setState((prevState) => {
        let { searchList4, searchBtnClicked4, pending } = prevState;
        searchList4 = val;
        searchBtnClicked4 = true;
        pending = this.state.pendingOrignalList;

        return { searchList4, searchBtnClicked4, pending };
      });
    } else {
      await this.setState((prevState) => {
        let { searchList4, searchBtnClicked4, pending2, pending } = prevState;
        searchList4 = val;
        searchBtnClicked4 = true;
        let searchTerm = searchList4.toLowerCase();
        pending = pending2.filter((data) => {
          if (data.activity?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.incident_type?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.service?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.project?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.reported_by?.toLowerCase().includes(searchTerm)) {
            return data;
          }
        });
        return { searchList4, searchBtnClicked4, pending };
      });
    }
  };

  _handleProgressSearch = async (event) => {
    const val = event.target.value;

    if (val.trim() === "") {
      await this.setState((prevState) => {
        let { searchList5, searchBtnClicked5, progress } = prevState;
        searchList5 = val;
        searchBtnClicked5 = true;
        progress = this.state.progressOrignalList;
        return { searchList5, searchBtnClicked5, progress };
      });
    } else {
      await this.setState((prevState) => {
        let { progress2, progress, searchList5, searchBtnClicked5 } = prevState;
        searchList5 = val;
        searchBtnClicked5 = true;
        let searchTerm = searchList5.toLowerCase();

        progress = progress2.filter((data) => {
          if (data.activity?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.incident_type?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.service?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.project?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.reported_by?.toLowerCase().includes(searchTerm)) {
            return data;
          }
        });
        return { searchList5, searchBtnClicked5, progress };
      });
    }
  };

  _handleCompletedSearch = async (event) => {
    const val = event.target.value;

    if (val.trim() === "") {
      await this.setState((prevState) => {
        let { searchList6, searchBtnClicked6, completed } = prevState;
        searchList6 = val;
        searchBtnClicked6 = true;
        completed = this.state.progressOrignalList;
        return { searchList6, searchBtnClicked6, completed };
      });
    } else {
      await this.setState((prevState) => {
        let {
          completed2,
          completed,
          searchList6,
          searchBtnClicked6,
        } = prevState;
        searchList6 = val;
        searchBtnClicked6 = true;
        let seachTerm = searchList6.toLowerCase();
        completed = completed2.filter((data) => {
          if (data.activity?.toLowerCase().includes(seachTerm)) {
            return data;
          } else if (data.incident_type?.toLowerCase().includes(seachTerm)) {
            return data;
          } else if (data.service?.toLowerCase().includes(seachTerm)) {
            return data;
          } else if (data.project?.toLowerCase().includes(seachTerm)) {
            return data;
          } else if (data.reported_by?.toLowerCase().includes(seachTerm)) {
            return data;
          }
        });
        return { searchList6, searchBtnClicked6, completed };
      });
    }
  };

  _handleSearch = async (event) => {
    const val = event.target.value;

    if (val.trim() == "") {
      await this.setState({
        searchListira: val,
        searchBtnClickedira: true,
        pending: this.state.pendingOrignalList,
        progress: this.state.progressOrignalList,
        completed: this.state.completedOrignalList,
      });
    } else {
      await this.setState((prevState) => {
        let {
          searchListira,
          searchBtnClickedira,
          pending,
          progress,
          completed,
          pending2,
          progress2,
          completed2,
        } = prevState;
        searchListira = val;
        searchBtnClickedira = true;
        let searchTerm = searchListira.toLowerCase();

        pending = pending2.filter((data) => {
          if (data.activity?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.incident_type?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.service?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.project?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.reported_by?.toLowerCase().includes(searchTerm)) {
            return data;
          }
        });

        progress = progress2.filter((data) => {
          if (data.activity?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.incident_type?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.service?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.project?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.reported_by?.toLowerCase().includes(searchTerm)) {
            return data;
          }
        });

        completed = completed2.filter((data) => {
          if (data.activity?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.incident_type?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.service?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.project?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.reported_by?.toLowerCase().includes(searchTerm)) {
            return data;
          }
        });
        return {
          searchListira,
          searchBtnClickedira,
          pending,
          progress,
          completed,
        };
      });
    }
  };

  _getIRAListExport = async () => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.iraModule.iralistexport,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            iraPendingexport: response.data.data.pendingira.pendinglist,
            iraProgressexport: response.data.data.inprogressira.inprogresslist,
            iraCompletedexport: response.data.data.completedira.completedlist,
            dataLoading: false,
          });
          // console.log('pending', this.state.nmPending)
        }
      })
      .catch((error) => { });
  };

  // end ira list

  _getDefaultActiveTabKey = () => {
    let defaultTab = "dashboard";

    if (this.props.match.params.type === "tracker") {
      defaultTab = "tracker";
    } else if (this.props.match.params.type === "kanban") {
      defaultTab = "nmlist";
    }
    return defaultTab;
  };
  

  render() {
    // console.log(this.state.pending);
    const completedTrackerExport = this.state.trackerexport.filter(
      (item) => item.status == "Completed"
    );
    const progressTrackerExport = this.state.trackerexport.filter(
      (item) => item.status == "Inprogress"
    );
    const completedResPerson = completedTrackerExport.map(
      (item) => item.person_name
    );
    const progressResPerson = progressTrackerExport.map(
      (item) => item.person_name
    );
    const progress =
      this.state.inprogress_number != undefined && this.state.inprogress_number;
    const completed =
      this.state.completed_number != undefined && this.state.completed_number;
    const iraPendingCount =
      this.state.totalcountira != undefined &&
      this.state.totalcountira.total_pendingira;
    const iraProgressCount =
      this.state.totalcountira != undefined &&
      this.state.totalcountira.total_inprogressira;
    const iraCompletedCount =
      this.state.totalcountira != undefined &&
      this.state.totalcountira.total_completedira;
    return (
      <div className="ua-uc-dashboard">
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        <div className="ua-uc-section">
          <Tabs
            defaultActiveKey={this._getDefaultActiveTabKey()}
            id="uncontrolled-tab-example"
            onSelect={this.activeSP}
            mountOnEnter={true}
          >
            <Tab eventKey="dashboard" title="Dashboard">
              <Dashboard />
            </Tab>
            <Tab
              eventKey="tracker"
              title={`CAPA | ${this.state.totalCapaCount || 0}`}
            >
              <CapaList
                progress_list={this.state.capaInprogressList}
                completed_list={this.state.capaCompletedList}
                progressListCount={this.state.capaProgressCount}
                completedListCount={this.state.capaCompletedCount}
                irReportData={{ progressTrackerExport, completedTrackerExport, progressName: this.state.progressno, completeName: this.state.completedno }}
                search={this.state.searchListt}
                pageNumber={this.state.pageNumber}
                refetch={() => {
                  window.location.reload();
                }}
                getPaginationData={this.iraTrackerList}
                handleSearch={this._handleInputChange}
              />
            </Tab>
            <Tab
              eventKey="nmlist"
              title={
                "IRA List | " +
                (iraPendingCount + iraProgressCount + iraCompletedCount)
              }
            >
              <IraList
                _getiraList={this._getiraList}
                iraCompletedCount={iraCompletedCount}
                iraProgressCount={iraProgressCount}
                iraPendingCount={iraPendingCount}
                ref={this.child}
                iraCompletedListFilter={this.iraCompletedListFilter}
                searchList4={this.state.searchList4}
                searchList5={this.state.searchList5}
                searchList6={this.state.searchList6}
                dataRefresh={this.dataRefresh}
                filterReset={this._handleFilterReset}
                iraPending={this.state.pending}
                iraProgress={this.state.progress}
                iraCompleted={this.state.completed}
                _getiraCompletedList={this._getiraCompletedList}
                _getiraProgressList={this._getiraProgressList}
                iraPendingListFilter={this.iraPendingListFilter}
                iraProgressListFilter={this.iraProgressListFilter}
                _handlePendingSearch={this._handlePendingSearch}
                _handleProgressSearch={this._handleProgressSearch}
                _handleCompletedSearch={this._handleCompletedSearch}
                pendingFilterCount={this.state.pendingFilterCount}
                // pageNumber={this.state.pageNumber}
                inprogressFilterCount={this.state.inprogressFilterCount}
                completeFilterCount={this.state.completeFilterCount}
                search={this.state.searchListira}
                handleSearch={this._handleSearch}
                irReportData={{ iraPendingexport: this.state.iraPendingexport, iraProgressexport: this.state.iraProgressexport, iraCompletedexport: this.state.iraCompletedexport }}
              />
            </Tab>
            {/* <Tab eventKey="archive" title="Archive | 15">
              <ArchiveList />
            </Tab> */}
          </Tabs>
          <div className="top-right published-right-btn">
            {/* {this.state.active == "tracker" && (
              <div className="top-head-search">
                <input
                  type="text"
                  placeholder="Search by category, activity, act, service, location & reported by"
                  name="search"
                  value={this.state.searchListt}
                  onChange={(e) => this._handleInputChange(e)}
                />

              </div>
            )}
            {this.state.active == "tracker" && (
              <Workbook
                filename="IRATrackerList.xlsx"
                element={
                  <Button className="icon-btn" title="Download Excel">
                    <FontAwesomeIcon
                      icon={faFileExcel}
                      className="icon-style"
                      size="lg"
                    />
                  </Button>
                }
              >
                <Workbook.Sheet
                  data={progressTrackerExport}
                  name={"Inprogress Tracker list | " + this.state.progressno}
                >
                  <Workbook.Column label="ACTIVITY" value="activity" />
                  <Workbook.Column label="CATEGORY" value="category" />
                  <Workbook.Column label="PROGRESS" value="progress" />
                  <Workbook.Column label="TARGET DATE" value="target_date" />
                  <Workbook.Column
                    label="OVERDUE DAYS"
                    value="overdue_deviation"
                  />
                  <Workbook.Column label="PROJECT" value="project" />
                  <Workbook.Column
                    label="RECOMMENDATION"
                    value="recommendation"
                  />
                  <Workbook.Column
                    label="RECOMMENDED BY"
                    value="recommendedby"
                  />
                  <Workbook.Column label="SERVICE" value="service" />
                  <Workbook.Column label="SEVERITY" value="severity" />

                </Workbook.Sheet>
                <Workbook.Sheet
                  data={completedTrackerExport}
                  name={"Completed Tracker list | " + this.state.completedno}
                >
                  <Workbook.Column label="ACTIVITY" value="activity" />
                  <Workbook.Column label="CATEGORY" value="category" />
                  <Workbook.Column label="PROGRESS" value="progress" />
                  <Workbook.Column label="TARGET DATE" value="target_date" />
                  <Workbook.Column
                    label="OVERDUE DAYS"
                    value="overdue_deviation"
                  />
                  <Workbook.Column label="PROJECT" value="project" />
                  <Workbook.Column
                    label="RECOMMENDATION"
                    value="recommendation"
                  />
                  <Workbook.Column
                    label="RECOMMENDED BY"
                    value="recommendedby"
                  />
                  <Workbook.Column label="SERVICE" value="service" />
                  <Workbook.Column label="SEVERITY" value="severity" />
                </Workbook.Sheet>
              </Workbook>
            )} */}

            {this.state.active == "nmlist" && (
              <div className="top-head-search">
                {/* <input
                  type="text"
                  placeholder="Search by category, activity, act, service, location & reported by"
                  name="search"
                  value={this.state.searchListira}
                  onChange={(e) => this._handleSearch(e)}
                /> */}
                {/* <Button className="icon-btn">
                  <Search className="icon-style" />
                </Button> */}
              </div>
            )}

            {/* {this.state.active == "nmlist" && (
              <Workbook
                filename="IRAList.xlsx"
                element={
                  <Button className="icon-btn" title="Download Excel">
                    <FontAwesomeIcon
                      icon={faFileExcel}
                      className="icon-style"
                      size="lg"
                    />
                  </Button>
                }
              >
                <Workbook.Sheet
                  data={this.state.iraPendingexport}
                  name="Pending list"
                >
                  <Workbook.Column label="ACTIVITY" value="activity" />
                  <Workbook.Column label="CONTRACTOR" value="contractor" />
                  <Workbook.Column label="DEPARTMENT" value="department" />
                  <Workbook.Column label="HAZARD" value="hazard" />
                  <Workbook.Column
                    label="INCIDENT DATE"
                    value="incident_date"
                  />
                  <Workbook.Column
                    label="INCIDENT TIME"
                    value="incident_time"
                  />
                  <Workbook.Column label="OBSERVATION" value="observation" />
                  <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
                  <Workbook.Column label="PROJECT" value="project" />
                  <Workbook.Column label="REPORT DATE" value="report_date" />
                  <Workbook.Column label="REPORTED BY" value="reported_by" />
                  <Workbook.Column label="SERVICE" value="service" />
                  <Workbook.Column label="SEVERITY" value="severity" />
                  <Workbook.Column label="LOCATION" value="location" />
                  <Workbook.Column label="ZONE" value="zone" />
                </Workbook.Sheet>
                <Workbook.Sheet
                  data={this.state.iraProgressexport}
                  name="InProgress list"
                >
                  <Workbook.Column label="ACTIVITY" value="activity" />
                  <Workbook.Column label="CONTRACTOR" value="contractor" />
                  <Workbook.Column label="DEPARTMENT" value="department" />
                  <Workbook.Column label="HAZARD" value="hazard" />
                  <Workbook.Column
                    label="INCIDENT DATE"
                    value="incident_date"
                  />
                  <Workbook.Column
                    label="INCIDENT TIME"
                    value="incident_time"
                  />
                  <Workbook.Column label="OBSERVATION" value="observation" />
                  <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
                  <Workbook.Column label="PROJECT" value="project" />
                  <Workbook.Column label="REPORT DATE" value="report_date" />
                  <Workbook.Column label="REPORTED BY" value="reported_by" />
                  <Workbook.Column label="SERVICE" value="service" />
                  <Workbook.Column label="SEVERITY" value="severity" />
                  <Workbook.Column label="LOCATION" value="location" />
                  <Workbook.Column label="ZONE" value="zone" />
                </Workbook.Sheet>
                <Workbook.Sheet
                  data={this.state.iraCompletedexport}
                  name="Completed list"
                >
                  <Workbook.Column label="ACTIVITY" value="activity" />
                  <Workbook.Column label="CONTRACTOR" value="contractor" />
                  <Workbook.Column label="DEPARTMENT" value="department" />
                  <Workbook.Column label="HAZARD" value="hazard" />
                  <Workbook.Column
                    label="INCIDENT DATE"
                    value="incident_date"
                  />
                  <Workbook.Column
                    label="INCIDENT TIME"
                    value="incident_time"
                  />
                  <Workbook.Column label="OBSERVATION" value="observation" />
                  <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
                  <Workbook.Column label="PROJECT" value="project" />
                  <Workbook.Column label="REPORT DATE" value="report_date" />
                  <Workbook.Column label="REPORTED BY" value="reported_by" />
                  <Workbook.Column label="SERVICE" value="service" />
                  <Workbook.Column label="SEVERITY" value="severity" />
                  <Workbook.Column label="LOCATION" value="location" />
                  <Workbook.Column label="ZONE" value="zone" />
                </Workbook.Sheet>
              </Workbook>
            )} */}

            <InfoComponent
              tooltipText={
                this.state.active == "dashboard"
                  ? "Data analysis on various parameter for decision making"
                  : "Download Excel"
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IraDashboard)
);
