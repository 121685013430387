/* eslint-disable jsx-a11y/alt-text */
import React from "react";

//assets
import { TeamLeadBadge } from "assets/Iconsv2/Iconsv2";
import CrownIcon from "assets/images/icons/Crown .svg";
//utills
import { getInitials } from "app/views/Nm/container/utils/moduleHelp";
import { useTranslation } from "react-i18next";
const DetailInvestigationTeam = ({ state, functionContent, variablesObj }) => {
  const { nmDetail } = state;
  const { isCrownEnabled, isDeleteEnabled } = variablesObj;
  const { t } = useTranslation(['nm', 'util']);
  return (
    <div>
      {" "}
      <div className="clide-v2-card-box-1 p-3 mt-2">
        <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
          {t('investigationTeam')}
        </p>
        <hr />

        <div className="d-flex flex-wrap gap-2">
          {nmDetail.nm_reported_detail_people_involved_list?.length
            ? nmDetail.nm_reported_detail_people_involved_list.map((item, index) => (
                <div>
                  <div
                    className=" align-items-center border d-flex justify-content-between pr-3 rounded-5 "
                    key={`investigation-team-${index}`}
                  >
                    <button
                      type="button"
                      className="icon-button"
                      title={item.user_name}
                    >
                      <span className="">{getInitials(item.user_name)}</span>
                      {item.status == 1 ? (
                        <span className="icon-button__badge">
                          <TeamLeadBadge />
                        </span>
                      ) : null}
                    </button>
                    &nbsp;{" "}
                    <label className="font-weight-bold mb-0 mx-2">
                      {item.user_name ?? "NA"}{" "}
                    </label>
                    {!item.status == 1 ? (
                      <div>
                        <div className="d-flex align-items-center ">
                          {isCrownEnabled && (
                            <img
                              src={CrownIcon}
                              alt=""
                              onClick={() =>
                                functionContent?.debounceFunc(() => {
                                  functionContent._AddTeamLeader(item.user_id);
                                })
                              }
                              className="user-icon border-0 cursor-p "
                              style={{
                                height: "1.3rem",
                                width: "1.3rem",
                                background: "white",
                                bottom: "-6px",
                                mixBlendMode:"multiply"
                              }}
                            />
                          )}
                          {isDeleteEnabled && (
                            <>
                              <img
                                src={require("assets/sls-icons/V2. New Development/delete (1).svg")}
                                className="cursor-p mx-2"
                                style={{
                                  height: "1.3rem",
                                  width: "1.3rem",
                                }}
                                onClick={() =>
                                  functionContent?.debounceFunc(() => {
                                    functionContent.removeInviteUser(item.user_id);
                                  })
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default DetailInvestigationTeam;
