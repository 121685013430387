const PageOne = (data) => {
  let CFHtml = "";
  let RCAHtml = "";
  let ImmediateHtml = "";

  if (data.contrubiting_factor_list) {
    data.contrubiting_factor_list.forEach((item, index) => {
      CFHtml += `<p style="margin: 0; font-size: 22px; line-height: 1.4; text-align: center;">${item.factor}</p>`;
    });
  }

  if (data.immediate_factor_list) {
    data.immediate_factor_list.forEach((item, index) => {
      ImmediateHtml += `<p style="margin: 0; font-size: 22px; line-height: 1.4; text-align: center;">${item.factor}</p>`;
    });
  }

  if (data.RCA_factor_list) {
    data.RCA_factor_list.forEach((item, index) => {
      RCAHtml += `<p style="margin: 0; font-size: 22px; line-height: 1.4; text-align: center;">${item.factor}</p>`;
    });
  }

  return `
    <div style="margin: 20px auto; width: 800px; height: 98vh; border: 7px double #797878; position: relative; box-sizing: border-box; overflow: hidden;">
      <div style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <style>
          h1, h2, h3, h4, h5, h6 {
            margin: 0;
            font-size: 22px;
            line-height: 1.4;
          }
          h1 {
            font-size: 35px;
            margin: 40px 0;
          }
          h5 {
            margin: 40px 0 5px;
            text-decoration: underline;
            font-weight: bold;
          }
          img {
            height: 180px;
            width: 180px;
            margin-bottom: 20px;
          }
        </style>
        
        <img src="${data.logo}" alt="Logo">
        <h1>NM Investigation Report</h1>
        <h2>${data.heading || 'N/A'}</h2>
        <h5>Activity</h5>
        <h4>${data.activity || 'N/A'}</h4>
        <span class="page-number"></span>
      </div>
    </div>
  `;
};

export default PageOne;

