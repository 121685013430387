// Salseforce ui-kit theme css
  
// import "./App.css";
// import "./Admin.css";
import "./ReactToastify.css";
import "react-image-lightbox/style.css";
import "react-h5-audio-player/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-awesome-lightbox/build/style.css";
import "font-awesome/css/font-awesome.min.css";
import "@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css"

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { persistor, store } from "./app/redux/store";
import { Auth } from "./app/views/Auth";
import ExternalUserFormView from "./app/views/ExternalSurveryForm/ExternalUserFormView";
import { IRAReport } from "./app/views/Ira";
import Market from "./app/views/MarketForm/Market";
import { NMReport } from "./app/views/Nm";
import { PersistGate } from "redux-persist/integration/react";
import Preloader from "./app/shared/components/Preloader";
import { Provider } from "react-redux";
import React from "react";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";

// import 'assets/styles/_app.scss';
// import 'assets/styles/_admin.scss'



import { Chart, ArcElement, LinearScale, Tooltip, Legend, Title, CategoryScale, BarElement, PointElement, LineElement } from 'chart.js';

//constants
import { routeConstants } from "app/shared/constants/routeConstants";
import ExternalReportModal from "app/shared/components/ReportModal/ExternalReportIndex";
Chart.register(ArcElement, PointElement, LineElement, CategoryScale, BarElement, LinearScale, Tooltip, Legend, Title);
const SidebarLayout = React.lazy(() =>
  import("app/shared/components/Layouts/SidebarLayout/SidebarLayout")
);

//V2 sidebar layout
const SidebarLayoutV2 = React.lazy(() =>
  import("app/shared/components/Layouts/SidebarLayoutV2")
);
const AdminSidebarLayout = React.lazy(() =>
  import("app/shared/components/Layouts/AdminSidebarLayout/AdminSidebarLayout")
);
const SafetyAdminSidebarLayout = React.lazy(() =>
  import("app/shared/components/Layouts/SafetyAdminSidebarLayout/SafetyAdminSidebarLayout")
);
const PrivateRoute = React.lazy(() =>
  import("app/shared/components/Authentication/PrivateRoute")
);






const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="inner-text">
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
    </div>
  );
};


const menuItem = [
  "/userside-user",
  "/userside-hira",
  "/userside-safetyteam",
  "/userside-master",
];

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.Suspense fallback={<Preloader />}>
          <Router>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <Switch>
              <PrivateRoute exact path="/">
                <SidebarLayoutV2 />
              </PrivateRoute>
              {/* <PrivateRoute exact path="/v2/">
                
                <SidebarLayoutV2 />
              </PrivateRoute> */}
              <PrivateRoute path={routeConstants.userPoral}>
              
                <SidebarLayoutV2 />
              </PrivateRoute>
              {/* <PrivateRoute path={generateV2Routest(SIDEBAR_ROUTE_PATHS)}>                
                <SidebarLayoutV2 />
              </PrivateRoute> */}

              <PrivateRoute path="/ira-report/:id">
                <IRAReport />
              </PrivateRoute>
              <PrivateRoute path="/nm-report/:id">
                <NMReport />
              </PrivateRoute>

              {menuItem.map((linkItem, index) => (
                <PrivateRoute path={linkItem} key={`menuLink-${index}`}>
                  <SidebarLayout />
                </PrivateRoute>
              ))}
            

              {/* Admin routes */}
              <PrivateRoute path={routeConstants.adminPortal}>
                
                <AdminSidebarLayout />
              </PrivateRoute>

              {/* Safety Admin routes */}
              <PrivateRoute path={routeConstants.safetyAdminPortal}>
                <SafetyAdminSidebarLayout />
              </PrivateRoute>

              <Route path="/auth">
                <Auth />
              </Route>
              {/* <Route path="/eccltable" element={<eccltable />} />
              <Route path="/market">
                <Market />
              </Route> */}
              <Route path="/survey-response/:formId/:surveyUseId">
                <ExternalUserFormView />
              </Route>
              <Route path="/external-report/:encyptedKey">
                <ExternalReportModal />
              </Route>
              <Route path="*" exact={true} component={PageNotFound} />
            </Switch>
          </Router>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
