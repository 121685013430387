import React from "react";

const FooterLink = ({ link, iconName, title }) => {
  return (
    <a href={link} target="_blank" title={title}>
      <i className={`fa ${iconName} social-icon`} aria-hidden="true" />
    </a>
  );
};

FooterLink.defaultProps = {
  link : '',
  iconName : '',
  title : ''
}

export default FooterLink;
