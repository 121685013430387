import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import Url from "app/shared/constants/Url";
import axios from "axios";
import { UPDATE_PERMITTED_MODULE_LIST } from "app/redux/constant/ActionType";
import { connect } from "react-redux";
import { Eye } from "react-feather";
import { TIMEZONE_STR } from "app/shared/utility/helperStrings";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

const qs = require("qs");

const alertMsg = (props) => {
  toast.error(props.message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    showHidePassword: false,
  });
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateModuleList: (payloadData) => {
      dispatch({ type: UPDATE_PERMITTED_MODULE_LIST, payload: payloadData });
    },
  };
};

class LoginForm extends Component {
  state = {
    formSubmitting: false,
    redirectToReferrer: false,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ prevPath: nextProps.location });
  }

  changeShowHidePassword = async () => {
    await this.setState({
      showHidePassword: !this.state.showHidePassword,
    });
  };

  render() {
    if (localStorage.getItem("token") !== null) {
      return <Redirect to={this.state.prevPath} />;
    }

    if (this.state.redirectToReferrer) {
      return <Redirect to={"/"} />;
    }
    const {t} = this.props;
    return (
      <div className="login-form">
        <div className="sign-header-section">
          <div className="sign-in-title" style={{ padding: "3% 0", margin: 0 }}>
            {t('login')}
          </div>
        </div>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            let errors = {};
            if (values.email === "") {
              errors.email = t("userNameRequired");
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = t("invalidEmailAddressFormat");
            }
            if (values.password === "") {
              errors.password = t("passwordRequired");
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            this.setState({ formSubmitting: true });
            axios
              .post(
                Url.login,
                qs.stringify({
                  user_name: values.email,
                  password: values.password,
                  otp_status: 1,
                }),
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then((res) => {
                if (res.data.status == 200) {
                  const { data } = res.data;
                  const { kpi_flag, overview_flag } = data;
                  let tempList = [...data.module];

                  if (kpi_flag) {
                    tempList = [
                      ...tempList,
                      {
                        module_name: "kpi",
                        status: kpi_flag,
                      },
                    ];
                  }

                  if (overview_flag) {
                    tempList = [
                      ...tempList,
                      {
                        module_name: "overview",
                        status: overview_flag,
                      },
                    ];
                  }

                  this.props.updateModuleList(
                    tempList.filter((item) => item.status)
                  );
                  // Delay the reload to ensure the update happens first
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 500); 
                  // setTimeout(() => {
                  //   this.setState({ redirectToReferrer: true });
                  // }, 3000);
                  const userTZ =
                    data.timezone ||
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                  localStorage.setItem("userData", JSON.stringify(data));
                  localStorage.setItem("userId", data.user_id);
                  localStorage.setItem("token", data.auth_key);
                  localStorage.setItem(TIMEZONE_STR, userTZ);
                  moment.tz.setDefault(userTZ);

                } else {
                  alertMsg({ message: res.data.msg });
                }
                this.setState({ formSubmitting: false });
              })
              .catch((error) => {
                this.setState({ formSubmitting: false });
              });

              axios
              .post(
                Url.springLogin + "?username=" + values.email + "&password=" + values.password,
              )
              .then((res) => {
                if (res.status == 200) {
                  localStorage.setItem("springToken", res.data);
                }
              })
              .catch((error) => {
                this.setState({ formSubmitting: false });
              });
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            handleSubmit,
            isValid,
            dirty,
            values,
          }) => (
            <Form onSubmit={handleSubmit} className="input-container">
              <div className="input-group">
                <Field
                  name="email"
                  type="email"
                  placeholder={t('userName')}
                  className="w-100 border-0"
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="inputErrorMsg text-danger"
                />
              </div>
              <div className="input-group">
                <div className="password-container">
                  <Field
                    type={this.state.showHidePassword ? "text" : "password"}
                    name="password"
                    placeholder={t('password')}
                    className="w-100 border-0"
                  />

                  <span className="toggle-password">
                    <Eye
                      style={{ color: "grey", height: "20", width: "20" }}
                      onClick={this.changeShowHidePassword}
                    ></Eye>
                  </span>
                </div>
                <ErrorMessage
                  component="div"
                  name="password"
                  className="inputErrorMsg text-danger"
                />
              </div>
              <div className="forget-div">
                <Link className="color-primary text-decoration-none" to={`/auth/forget-password`}>{t('forgotPassword')}</Link>
              </div>
              <button
                type="submit"
                disabled={!isValid || this.state.formSubmitting || !dirty}
              >
                {this.state.formSubmitting ? t('pleaseWait') : t('login')}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(["util"])
)(LoginForm);
