import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProjectList = ({ optionList, onChangeHandle, value, hasError = false,
  errorMessage = "", isRquired = true,   customTitle = <></>,   titleview = true,
   selectClass = "slds-select"
}) => {
  const [selectValue, setSelectValue] = useState("");
  const { t } = useTranslation(['workpermit', 'util']);
  useEffect(() => {
    setSelectValue("null");
    console.log("updated");
  }, [optionList]);

  return (
    <div className={`slds-form-element ${hasError ? "slds-has-error" : ""} select-remove-icon mb-2`}>
     {titleview &&  <label className="slds-form-element__label ">{t('plantProject')} {isRquired  ? <abbr className="slds-required" title="required">* </abbr> : null }</label>}
     {customTitle}

      <div className="slds-form-element__control">
        <div className="slds-select_container">
          <select
            className={selectClass}
            onChange={(event) => onChangeHandle(event.target.value)}
            value={value}
          >
            {optionList.length ? (
              <>
                <option value="">{t('select')} {t('project')}</option>
                {optionList.map((item, index) => (
                  <option
                    key={`project-option-${index}`}
                    value={item.project_id}
                    showcontractor={item.contractor_flag}
                  >
                    {item.project}
                  </option>
                ))}
              </>
            ) : (
              <option value={null} selected>
                {t('noProjectAvailable')}
              </option>
            )}
          </select>
        </div>
      </div>
      {hasError ? <div class="slds-form-element__help" id="error-01">{errorMessage !== "" ? errorMessage : t('noProjectAvailable')}</div> : null}
    </div>
  );
};

ProjectList.defaultProps = {
  optionList: [],
  onChangeHandle: () => { },
  value: ""
};

export default (ProjectList);
