import { ADMIN_CHANGE_SERVICE_PROJECT, UPDATE_ADMIN_PROJECT_DETAIL, UPDATE_ADMIN_PROJECT_USER_DETAIL, UPDATE_COMPANY_PROFILE_DETAIL } from '../constant/ActionType';

let initialState = {
  serviceProject : {
    service_id : null,
    project_id : null
  },
  updateProjectDetails : false,
  updateProjectUserDetails : false,
  companyProfileUpdated : false
};

function adminReducer(state = initialState, action) {

  switch (action.type) {
    case ADMIN_CHANGE_SERVICE_PROJECT : 
      return {
        ...state,
        serviceProject : {
          ...action.payload
        }
      }
    break;
    case UPDATE_ADMIN_PROJECT_DETAIL : 
      return {
        ...state,
        updateProjectDetails : action.payload
      }
    break;
    case UPDATE_ADMIN_PROJECT_USER_DETAIL : 
      return {
        ...state,
        updateProjectUserDetails : action.payload
      }
    break;
    case UPDATE_COMPANY_PROFILE_DETAIL : 
      return {
        ...state,
        companyProfileUpdated : action.payload
      }
    break;
    default:
      return state;
    break;
  }  
};

export default adminReducer;