import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";

const LinkList = [
  {
    name: "ECLIDE",
    link: "https://eclide.clideoffice.com",
    id: 101,
  },
  {
    name: "CLIDEAnalyser",
    link: "https://clideoffice.com",
    id: 202,
  },
  {
    name: "CLIDEG",
    link: "https://clideg.clideoffice.com",
    id: 303,
  },
];
export default class AuthLeftBottom extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="lbottom-wrapper">
        {/* <div className="clide-logo">
          <img
            className="inner-logo"
            src={require("assets/images/CLIDEOfiice.png")}
            alt="clideOffice"
          />
        </div> */}
        <div className="bottom-tex">
          <h4 className="text-center head-font">{this.props.data.title}</h4>
          <p className="text-center head-pr">{this.props.data.description}</p>
        </div>
        {/* <div className="clide-text">
           {LinkList.map((item) => (
            <a href={item.link} key={item.id} target="_blank">
              <h6>{item.name}</h6>
            </a>
          ))} 
        </div> */}
        
      </div>
    );
  }
}

AuthLeftBottom.defaultProps = {
  data: {
    title: "",
    description: "",
  },
};
